/* Dependenceies */
import React from 'react';
import PropTypes from 'prop-types';
/* Componenets */
import Modal from '../Modal';
/* i18n */
import { t } from '../../i18n';
/* Styles */
import { Description } from './styles';
/* Assets */
import img from '../../assets/images/male-user.png';

function ModalPreview({ toggle, user, messagePreview, children }) {
  return (
    <Modal messagePreview active toggle={toggle} height="auto" width="auto">
      <Description>
        <div className="imgUser">
          <div className="circleImg">
            <img
              className="imgContent"
              src={user ? user.imageUrl : img}
              alt="user-profile"
            />
          </div>
          <div className="msmInfo">
            <div>
              <h2>{`${user.firstName} ${user.lastName}`}</h2>
              <h3>
                <em>{messagePreview.title}</em>
              </h3>
            </div>
            <div className="infoSpace">
              <h3>{t('seeDraft.usedSpace')}: 1 MB</h3>
              <br />
              <span className="text-left">
                {t('seeDraft.creationDate')}: 1{messagePreview.createdAt}
                <br />
                {t('seeDraft.displayName')}: 23/01/2019{' '}
              </span>
            </div>
          </div>
        </div>

        <div className="txt">
          <div className="topBlue" />
          <div className="containerMessage">
            <div dangerouslySetInnerHTML={{ __html: messagePreview.body }} />
            {children}
          </div>

          <div className="separatorbottom" />
        </div>
      </Description>
    </Modal>
  );
}

ModalPreview.propTypes = {
  toggle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  messagePreview: PropTypes.object.isRequired,
};

export default ModalPreview;
