import styled from 'styled-components';
import media from 'styled-media-query';

export const ChangePassword = styled.div`
width: 350px;
margin: 0 auto;
margin-top: 10px;
h3 {
  color: #211261;
  font-size: 23px;
  margin-bottom: 26px;
}
.margin {
  margin-bottom: 15px;
  height: 50px;
}
button {
  width: 185px;
  float: right;
}
${media.lessThan('medium')`
  width: auto;  
`}
`