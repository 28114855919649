import { SET_TUTORIALS, TUTORIALS_BY_SLUG } from './const'
import TutorialService from './TutorialService'

import { setComponentLoading } from '../app/actions'
import { getLanguageById } from '../../i18n'

export const setTutorials = value => ({ type: SET_TUTORIALS, value })
export const setTutorialsBySlug = value => ({ type: TUTORIALS_BY_SLUG, value })

export const getTutorials = () => async dispatch => {
	try {
		dispatch(setComponentLoading(true))		
		const tutorials = await TutorialService.getTutorialTypes({
			include: ['tutorials'],
			where: {
				languageId: getLanguageById()
			}
		})
		dispatch(setTutorials(tutorials.data))
	} catch (e) {
		console.log(e)
	} finally {
		dispatch(setComponentLoading(false))
	}
}

export const getTutorialsBySlug = slug => async dispatch => {
	try {		
		dispatch(setComponentLoading(true))
		const tutorialBySlug = await TutorialService.getTutorialsBySlug(slug)
		if (tutorialBySlug.data.length)
			dispatch(setTutorialsBySlug(tutorialBySlug.data[0]))
	} catch (e) {
		console.log(e)
	} finally {
		dispatch(setComponentLoading(false))
	}
}
