// Dependecies
import React, { Component } from 'react';
// Styled components
import { Container } from './styles';
/* i18n  */
import { t } from '../../i18n';

/**
 * Personalized input with redux
 */
class InputUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFile: '',
    };
  }
  /**
   * get file name
   * @param {event} data
   */
  onChange = (data) => {
    this.props.getfile(data.target);
    const name = data.target.value.split('\\');
    this.setState({
      nameFile: name[name.length - 1],
    });
  };
  render() {
    const { nameFile } = this.state;
    const { title } = this.props;
    return (
      <Container>
        <div className="fileCaption">
          <div className="file-caption-name">
            <p style={{ margin: 0 }}>{nameFile}</p>
          </div>
        </div>
        <div className="btn">
          <i className="far fa-folder-open" />
          <span className="hidden-xs">
            {title || t('buttons.browse') + '...'}
          </span>
          <input
            onChange={this.onChange}
            type="file"
            data-show-preview="false"
            value=""
            data-show-remove="false"
            data-show-upload="false"
            {...this.props.input}
            {...this.props}
            accept={this.props.acceptFormats}
          />
        </div>
      </Container>
    );
  }
}

InputUpload.defaultProps = {
  getfile: null,
};

export default InputUpload;
