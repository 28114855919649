import { HOME, FORM_BENEFICIARIES, FORM_VERIFIERS, SLIDER_CHECKER, SLIDER_BENEFICIARES } from './const'
import HomeService from './HomeService'
/**
 * @param  {HOME} {type
 * @param  {object}
 * @param value {{
 *  {
  logoUrl: {string},
  altLogo: {string},
  description: {string},
  videoUrl: {string},
  descriptionUser: {string},
  descriptionBeneficiary: {string},
  descriptionChecker: {string},
  address: {string},
  phone: {string},
  email: {string},
  termsConditions: {string},
  facebook: {string},
  youtube: {string},
  instagram: {string},
  about: {string},
  howDoesItWork: {string},
  beneficiaryImageUrl: {string},
  altBeneficiaryImage: {string},
  verifierImageUrl: {string},
  altCheckerImage: {string},
  homeImageUrl: {string},
  languageId: {number},
  id: {number},
  createdAt: {date},
  updatedAt: {date}
}
 * } 
 */
export const setHomes = value => ({ type: HOME, value })

/**
 * Activates and deactivates the beneficiaries side menu
 */
export const setFormBeneficiaries = value => ({ type: FORM_BENEFICIARIES, value })
/**
 * Activates and deactivates the verifiers side menu
 */
export const setFormVerifiers = value => ({ type: FORM_VERIFIERS, value })

export const setSlidersCheckers = value => ({ type: SLIDER_CHECKER, value })

export const setSlidersBeneficiares = value => ({ type: SLIDER_BENEFICIARES, value })

export const getSlidersCheckers = () => async dispatch => {
  try {
    const { data } = await HomeService.getSlidersCheckers()
    const slider = data.map(el => ({
      description: el.title,
      ...el
    }))
    dispatch(setSlidersCheckers(slider))
  } catch (e) {
    console.log(e)
  }
} 

export const getSlidersBeneficiares = () => async dispatch => {
  try {
    const { data } = await HomeService.getSlidersBeneficiares()
    const slider = data.map(el => ({
      description: el.title,
      ...el
    }))   
    dispatch(setSlidersBeneficiares(slider))
  } catch (e) {
    console.log(e)
  }
} 
