/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import FileInput from '../../components/FileInput';
import InputRedux from '../../fields/Input';
import male from '../../assets/images/male-user.png';
import SelectRedux from '../../fields/SelectInput';

const constrains = {
	firstName: {
		presence: {
			message: t('validationsInputs.required'),
		},
	},
	lastName: {
		presence: {
			message: t('validationsInputs.required'),
		},
	},
	// id: {
	// 	presence: {
	// 		message: t('validationsInputs.required')
	// 	}
	// },
	// countryId: {
	// 	presence: {
	// 		message: t('validationsInputs.required')
	// 	}
	// },
	// mobile: {
	// 	presence: {
	// 		message: t('validationsInputs.required')
	// 	}
	// },
	// address: {
	// 	presence: {
	// 		message: t('validationsInputs.required')
	// 	}
	// },
	email: {
		presence: {
			message: t('validationsInputs.required'),
		},
		email: {
			message: t('validationsInputs.email'),
		},
	},
};

class UpdateProfile extends Component {
	state = {
		urlPhoto: null,
		countryId: null,
	};
	onChange = (photo) => {
        photo && this.setState({ urlPhoto: URL.createObjectURL(photo) });
    }
	onChangeCountry = (data) =>
		data && this.setState({ countryId: data.target.value });
	render() {
		const { urlPhoto, countryId } = this.state;
		const {
			handleSubmit,
			toggleSetActivedModalChangePass,
			countries,
			imageUrl,
			beneficiaryProfile,
			verifierProfile,
			imageDefault,
			onSubmit,
		} = this.props;
		return (
			<form
				onSubmit={handleSubmit((values) => {
					if (countryId) {
						onSubmit({
							...values,
							countryId,
							genderId: values.genderId || '',
							currentSession: values.currentSession || '',
							optionalEmail: values.optionalEmail || '',
							mobile: values.mobile || '',
							address: values.address || '',
							tokenPaypal: values.tokenPaypal || '',
							identificationTypeId: values.identificationTypeId || '',
						});
					} else {
						onSubmit(values);
					}
				})}
				urlphoto={urlPhoto || imageUrl || male}
			>
				<div className='topForm'>
					<div className='circleImg'>
						<img
							className='imgContent profile'
							src={imageUrl ? urlPhoto || imageUrl || male : imageDefault}
							alt='user-profile'
                            style={{objectFit: 'cover'}}
						/>
						{/* <div>
							
						</div> */}
						<div className='btnEditPhoto'>
							<div className='uploadEdit'>
								<Field
									name='imageUrl'
									type='file'
									onChange={this.onChange}
									component={FileInput}
									accept='.jpg,.png, .webm'
								/>
							</div>
							<p className='cyan'>{t('profile.EditProfilePhoto')}</p>
							<p className='cyan'>(140 x 140 px)</p>
						</div>
						{/*            
                      <div className='uploadEdit'>
              <input type='file' />
            </div> */}
						<p className='cyan'>{t('profile.EditProfilePhoto')}</p>
					</div>
					<div className='formRight'>
						<Field
							type='text'
							name='firstName'
							component={InputRedux}
							placeholder={t('fields.name')}
						/>
						<Field
							type='text'
							name='lastName'
							component={InputRedux}
							placeholder={t('fields.lastName')}
						/>

						<Field
							name='countryId'
							component={SelectRedux}
							onChange={this.onChangeCountry}
						>
							<option value=''>{t('profile.SelectCountry')}</option>
							{countries.map((country, key) => (
								<option
									defaultValue={
										this.props.initialValues &&
										this.props.initialValues.countryId === country.id
									}
									value={country.id}
									key={key}
								>
									{country.name}
								</option>
							))}
						</Field>

						{beneficiaryProfile === 1 ? (
							<div>
								<div
									className='changePassword'
									onClick={toggleSetActivedModalChangePass}
								>
									<i className='fas fa-key' /> {t('menu.changePassword')}
								</div>
								<Button bgColorHover='#892B6E' className='saveChange'>
									{t('buttons.saveChanges')}
								</Button>
							</div>
						) : verifierProfile === 2 ? (
							<div>
								<div
									className='changePassword'
									onClick={toggleSetActivedModalChangePass}
								>
									<i className='fas fa-key' /> {t('menu.changePassword')}
								</div>
								<Button bgColorHover='#892B6E' className='saveChange'>
									{t('buttons.saveChanges')}
								</Button>
							</div>
						) : (
							<div>
								<Field
									type='number'
									name='identificationNumber'
									component={InputRedux}
									placeholder={t('fields.identificationNumber')}
								/>
							</div>
						)}
					</div>
				</div>
				{beneficiaryProfile === 1 ? (
					''
				) : verifierProfile === 2 ? (
					''
				) : (
					<div className='formBottom'>
						<Field
							type='number'
							name='mobile'
							component={InputRedux}
							placeholder={t('fields.phone')}
						/>
						<Field
							type='text'
							name='address'
							component={InputRedux}
							placeholder={t('fields.address')}
						/>
						<Field
							type='email'
							name='email'
							component={InputRedux}
							placeholder={t('fields.primaryEmail')}
						/>
						<Field
							type='email'
							name='optionalEmail'
							component={InputRedux}
							placeholder={t('fields.optionalEmail')}
						/>
						<div
							className='changePassword'
							onClick={toggleSetActivedModalChangePass}
						>
							<i className='fas fa-key' /> {t('menu.changePassword')}
						</div>
						<Button bgColorHover='#892B6E' className='saveChange' type='submit'>
							{t('buttons.saveChanges')}
						</Button>
					</div>
				)}
			</form>
		);
	}
}

export default reduxForm({
	form: 'UpdateProfile',
	validate: (values) => validate(values, constrains),
})(UpdateProfile);
