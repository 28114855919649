/* Dependencies */
import React, { Fragment, Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
/* i18n */
import { t, getLanguage } from "../../i18n";
/* Actions */
import {
	setMessageUser,
	saveDraftLocaleStorege,
} from "../../redux/messages/actions";
import { setModal, setMessage } from "../../redux/app/actions";
/* Components */
import Button from "../../components/Button";
import InputFileButton from "../../fields/InputFileButton";
import InputRedux from "../../fields/Input";
import SelectRedux from "../../fields/SelectInput";

// Validate
import validate from "validate.js";
import { Form } from "./styles";

function validateForm(values, props) {
	const constrains = {
		title: {
			presence: {
				message: t("validationsInputs.required"),
			},
		},
		evenType: {
			presence: {
				message: t("validationsInputs.required"),
			},
		},
	};
	const validated = validate(values, constrains);
	// if (
	// 	props.formMessage &&
	// 	props.formMessage.fields &&
	// 	(props.formMessage.fields.title.touched &&
	// 		props.formMessage.fields.title.error)
	// ) {
	// 	props.setMessage({ name: 'withError', message: 'error' })
	// 	setTimeout(() => props.setMessage(''), 2300)
	// } else {
	// 	props.setMessage('')
	// }
	return validated;
}
class WrittenMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			type: "",
			fileName: "",
		};
	}

	onChange = (data) => data && this.setState({ type: data.target.value });
	onEditorStateChange = (editorState) => this.setState({ editorState });

	onChangeFile = (file) => {
		if (!file) {
			return;
		}
		this.setState({
			fileName: file.name,
			file,
		});
	};
	render() {
		const { editorState, type } = this.state;
		const { handleSubmit, messageTypes, onSubmit, saveDraftLocaleStorege } =
			this.props;
		const messageType = messageTypes.find(
			(messageType) => messageType.code === "written",
		);
		const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));

		return (
			<Fragment>
				<Form
					onSubmit={handleSubmit((values) =>
						onSubmit({
							...values,
							attached: this.state.file,
							messageTypeId: messageType === undefined ? 1 : messageType.id,
							body,
						}),
					)}
				>
					<div className='titleAndDate'>
						<Field
							type='text'
							name='title'
							component={InputRedux}
							placeholder={t("writtenMessage.fields.title")}
						/>
						{/* <Field name='eventType' component='select'>
							<option>Selecionar tipo de evento</option>
							{eventTypes.map((eventType, key) => (
								<option key={key} value={eventType.id}>
									{eventType.name}
								</option>
							))}
						</Field> */}
						<Field
							onChange={this.onChange}
							name='evenType'
							component={SelectRedux}
						>
							<option>{t("fields.selectDate")}</option>
							<option value='absence'>{t("fields.absances")}</option>
							<option value='date'>{t("fields.dates")}</option>
						</Field>

						{type === "date" && (
							<Field
								type='date'
								name='visibleData'
								component={InputRedux}
								placeholder={t("writtenMessage.fields.title")}
							/>
						)}
					</div>
					<div className='container-editor'>
						<Editor
							editorState={editorState}
							wrapperClassName='rdw-storybook-wrapper'
							editorClassName='rdw-storybook-editor'
							onEditorStateChange={this.onEditorStateChange}
							emoji={false}
							localization={{
								locale: getLanguage(),
							}}
						/>
					</div>
					<div className='input'>
						<p className='center'>{t("writtenMessage.attachFile")}:</p>
						<Field
							data-show-preview='false'
							data-show-remove='false'
							data-show-upload='false'
							name='attached'
							nameFile={this.state.fileName}
							onChange={this.onChangeFile}
							component={InputFileButton}
							accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'
						/>
					</div>
					<div className='input'>
						{/* <h4>
							{`${t('writtenMessage.previewMessage')}`}{' '}
							<span onClick={() => setModal('isActivedMessagePreview')}>{`${t(
								'writtenMessage.clickhere'
							)}`}</span>
						</h4> */}
						<div className='options'>
							<Button
								type='button'
								onClick={handleSubmit((values) =>
									saveDraftLocaleStorege({
										...values,
										messageTypeId:
											messageType === undefined ? 1 : messageType.id,
										body,
									}),
								)}
								bgColorHover='#892B6E'
							>
								<i className='far fa-clock' /> {t("buttons.saveDraft")}
							</Button>

							<Button bgColor='#DF382F' bgColorHover='#892B6E'>
								<i className='far fa-clock' /> {t("buttons.scheduleMessage")}
							</Button>
						</div>
					</div>
				</Form>
			</Fragment>
		);
	}
}

WrittenMessage.defaultProps = {
	saveDraftLocaleStorege: () => {},
};
WrittenMessage.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	saveDraftLocaleStorege: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	eventTypes: PropTypes.any.isRequired,
	messageTypes: PropTypes.array.isRequired,
};

function mapStateToProps(store) {
	return {
		eventTypes: store.messages.eventTypes || [],
		messageTypes: store.messages.messageTypes || [],
		formMessage: store.form.writtenMessage,
	};
}
function mapDispachtToProps(dispatch) {
	return {
		setMessageUser: (message, next) => dispatch(setMessageUser(message, next)),
		saveDraftLocaleStorege: (message) =>
			dispatch(saveDraftLocaleStorege(message)),
		setModal: (modal) => dispatch(setModal(modal)),
		setMessage: (message) => dispatch(setMessage(message)),
	};
}

function margeProps(stateProps, dispatchProps, ownProps) {
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		onSubmit: (values) => {
			dispatchProps.setMessageUser(values, () => ownProps.history.push("/"));
		},
	};
}

const enhance = compose(
	connect(mapStateToProps, mapDispachtToProps, margeProps),
	reduxForm({
		form: "writtenMessage",
		validate: validateForm,
	}),
);

export default enhance(WrittenMessage);
