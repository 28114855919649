/* Dependencies */
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validate from 'validate.js'
/* Actions */
import {
	getInfoRelationVerifier,
	setAcceptRelationVerifier
} from '../../redux/user/actions'

import { t } from '../../i18n'

/* Validations */
const constrains = {
	code: {
		presence: {
			message: t('validationsInputs.required')
		}
	}
}

const mapStateToProps = ({ user: { infoRelationVerifier } }) => {
	let values = {}
	if (infoRelationVerifier.user) {
		const { user } = infoRelationVerifier
		values = {
			userMainName: user.userName,
			userMainUrl: user.imageUrl
		}
	}
	return {
		...values
	}
}

const mapDispachtToProps = dispatch =>
	bindActionCreators(
		{
			getInfoRelationVerifier,
			onSubmit: setAcceptRelationVerifier
		},
		dispatch
	)

// const margeProps = (stateProps, dispatchProps, ownProps) => ({
// 	...stateProps,
// 	...dispatchProps,
// 	...ownProps,
// 	getInfoRelationBeneficare: () =>
// 		dispatchProps.getInfoRelationBeneficare()
// })

export default compose(
	connect(
		mapStateToProps,
		mapDispachtToProps		
	),
	reduxForm({
		form: 'RegisterBeneficiare',
		validate: values => validate(values, constrains)
	})
)
