/* Dependencies */
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export const Form = styled.form`
	.containerBtnSubmit {
		margin-top: 35px;
		text-align: center;
	}
	.containerImagePreview {
		width: 216px;
		height: 177px;
		margin-right: 10px;
	}
	select, input {
		width: 100%;
		height: 50px;
		padding: 6px 20px;
		font-size: 13px;
		line-height: 1.42857143;
		color: '#999';
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 25px;
		border: 1px solid #342573;
	}

	.selectPhotos {
		width: 100%;
		height: 200px;
		margin-top: 60px;
		overflow-y: hidden;
		overflow-x: auto;
		border-width: 1px;
		border-style: solid;
		border-color: rgb(204, 204, 204);
		border-image: initial;
		border-radius: 4px;
		padding: 10px;
		margin-bottom: 45px;
		transition: all 0.4s ease-in-out;
		display: flex;
		align-items: center;
		> div {
			position: relative;
			button {
				position: absolute;
				right: 6px;
				z-index: 4;
				top: -4px;
				font-size: 20px;
				color: red;
			}
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
`

export const ModalAudio = styled.div`
	margin: 0 auto;
	margin-bottom: ${prop('bottom', '75px')};
	width: ${prop('width', '50%')};
	height: 80px;
	padding: 10px;
	text-align: center;
`
export const ContainerVideo = styled.div`
	margin: 0 auto;	
	margin-top: 20px;
	margin-bottom: 75px;
`

export const Container = styled.div`  
  width: 300px;
  margin: auto;
  border-radius: 7px;
  box-shadow: 0 0 12px #999;  
  opacity: .8;
  background-color: ${prop('bgColor', 'green')};
  text-align: center;
  animation: fadeInRight .3s ease-in-out;
  cursor: pointer;  
  transition: .4s all;
  display: none;
  p {
    color: #fff!important;
    font-weight: bold;
    font-size: 20px;
    text-transform: initial;
  }
  :hover {
    opacity: 1;
  } 
  ${ifProp('isActived', css`
    display: block;  
  `)}
`
