/* Dependencies */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
/* i18n */
import { t } from '../../i18n';

/* Components */
import Button from '../../components/Button';
import TypeFileUpload from '../../components/TypeFileUpload';

import InputRedux from '../../fields/Input';
import FileInput from '../../fields/FileInput';
/* Styles */
import { ModalAudio, Form, ContainerCam } from './styles';
/* Assets */
import rec from '../../assets/images/take-pic.jpg';
import upload from '../../assets/images/upload-pic.jpg';
/* utils */
import getDataURItoBlob from '../../utils/getDataURItoBlob';

import withStore from './store';
import SelectRedux from '../../fields/SelectInput';

class ImageMessageEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			images: [],
			imagesToDelte: [],
			file: {},
			nameFile: '',
		};
	}

	componentDidMount() {
		const { slug, getSelectMessage } = this.props;
		if (this.props.initialValues.type === 'date') {
			this.setState({
				type: 'date',
			});
		}
		getSelectMessage(parseInt(slug));
	}
	componentDidUpdate(prevProps) {
		const { draftLocaleStorage, setSelectedBeneficiaries } = this.props;
		if (draftLocaleStorage !== prevProps.draftLocaleStorage) {
			const { slug, getSelectMessage } = this.props;
			getSelectMessage(parseInt(slug));
			if (
				draftLocaleStorage &&
				Array.isArray(draftLocaleStorage.messageFiles)
			) {
				this.setState({ images: draftLocaleStorage.messageFiles });
				const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
					(el) => ({
						label: el.beneficiary ? el.beneficiary.firstName : '',
						value: el.beneficiary ? el.beneficiary.id : '',
					}),
				);
				setSelectedBeneficiaries(beneficiaries);
			}
		}
	}

	onChangeFile = (event) =>
		this.setState((state) => ({
			id: state.id + 1,
			nameFile: event.name,
			images: [
				...state.images,
				{ id: state.id, data: event, url: URL.createObjectURL(event) },
			],
		}));

	addImage = (image) => {
		const data = getDataURItoBlob(image);
		this.setState((state) => ({
			id: state.id + 1,
			images: [...state.images, { id: state.id, data, fileUrl: image }],
		}));
	};

	removeImage = ({ id, messageId }) => {
		this.setState((state) => ({
			images: state.images.filter((images) => images.id !== id),
			imagesToDelte: messageId
				? [...state.imagesToDelte, id]
				: state.imagesToDelte,
		}));
	};

	toggleItem = (item) =>
		this.setState((state) => ({
			isActivedItem: item !== state.isActivedItem ? item : '',
		}));
	onChange = (data) => data && this.setState({ type: data.target.value });
	render() {
		const { nameFile } = this.state;
		const { handleSubmit, onSubmit } = this.props;
		const { images, isActivedItem, type, imagesToDelte } = this.state;
		return (
			<Form
				onSubmit={handleSubmit((values) =>
					onSubmit({
						...values,
						fileUrl: images,
						filesToDelte: imagesToDelte,
					}),
				)}
			>
				<div className='titleAndDate'>
					<Field
						type='text'
						name='title'
						component={InputRedux}
						placeholder={t('writtenMessage.fields.title')}
					/>
					{/* <Field name='eventTypeId' component='select'>
						<option>Selecionar tipo de evento</option>
						{eventTypes.map((eventType, key) => (
							<option key={key} value={eventType.id}>
								{eventType.name}
							</option>
						))}
					</Field> */}
					<Field
						onChange={this.onChange}
						name='type'
						component={SelectRedux}
						defaultValue={this.props.initialValues.type}
					>
						<option>{t('fields.selectEvents')}</option>
						<option value='absence'>{t('fields.absances')}</option>
						<option value='date'>{t('fields.dates')}</option>
					</Field>
					{type === 'date' && (
						<Field
							type='date'
							name='visibleData'
							onChange={console.log}
							component={InputRedux}
							placeholder={t('writtenMessage.fields.title')}
						/>
					)}
				</div>
				<div className='actionMessage'>
					<TypeFileUpload
						title={t('pictureMessage.takePhoto')}
						icon='fas fa-video'
						bgImg={rec}
						toggle={() => this.toggleItem('audio')}
					/>
					<TypeFileUpload
						title={t('pictureMessage.attachImage')}
						icon='fas fa-image'
						bgImg={upload}
						toggle={() => this.toggleItem('file')}
					/>
				</div>
				{isActivedItem === 'audio' && (
					<ContainerCam>
						<Camera
							onTakePhoto={(dataUri) => {
								this.addImage(dataUri);
							}}
							idealFacingMode={FACING_MODES.ENVIRONMENT}
							idealResolution={{ width: 640, height: 480 }}
							imageType={IMAGE_TYPES.JPG}
							imageCompression={0.97}
							isMaxResolution={false}
							isImageMirror={false}
							isSilentMode={false}
							isDisplayStartCameraError
							isFullscreen
							sizeFactor={1}
						/>
					</ContainerCam>
				)}
				{isActivedItem === 'file' && (
					<ModalAudio>
						<label>{t('pictureMessage.format')}</label>
						<Field
							component={FileInput}
							name='fileUrl'
							type='file'
							nameFile={nameFile}
							onChange={this.onChangeFile}
							accept='.jpg, .png, .gif'
						/>
					</ModalAudio>
				)}
				<div>
					<div className='selectPhotos'>
						{images.map((image, key) => (
							<div key={key} className='animated fadeInRight'>
								<Button
									type='button'
									isTransparent
									onClick={() => this.removeImage(image)}
								>
									<i className='fas fa-times-circle' />
								</Button>
								<div className='containerImagePreview'>
									<img src={image.fileUrl || image.url} alt='img-preview' />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className='containerBtnSubmit'>
					<Button bgColor='#DF382F' bgColorHover='#892B6E' width='50%'>
						<i className='far fa-clock' /> {t('buttons.scheduleMessage')}
					</Button>
				</div>
			</Form>
		);
	}
}

ImageMessageEdit.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default withStore(ImageMessageEdit);
