import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { handleLogout } from '../../redux/app/actions';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleLogout
    },
    dispatch
  );

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(null, mapDispatchToProps, margeProps);
