//Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
//i18n
import { t } from '../../i18n';
//Components
import BackgroundImage from '../BackgroundImage';
import Button from '../Button';
import Modal from '../Modal';
import Login from '../Login';
import MessageResponse from '../MessageResponse';
import Footer from '../Footer';
//Styled components
import {
  Container,
  ContainerVideo,
  ForgotPassword,
  ForgotPasswordModal,
} from './styles';
//Form
import ForgotPasswordForm from '../../form/ForgotPassword';
import { setModal } from '../../redux/app/actions';

class BackgroundHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModalVideo: false,
    };
  }

  toggleModal = () => {
    this.setState({
      activeModalVideo: !this.state.activeModalVideo,
    });
  };

  render() {
    const { logoUrl, homeImageUrl, message, modal, closeModal } = this.props;
    return (
      <>
        <div
          className="videoHome"
          style={{
            backgroundImage: `url(${homeImageUrl})`,
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: 0,
            bottom: 0,
          }}
        />
        <Container>
          <MessageResponse
            isActived={message === 'errorAuth'}
            bgAlert="red"
            message={t('validations.login.wrong-password')}
          />
          <BackgroundImage
            openModal={this.toggleModal}
            toggleLogin={this.props.toggleLogin}
            isActiveLogin={this.props.activeLogin}
          />
          <Modal active={this.state.activeModalVideo} toggle={this.toggleModal}>
            <ContainerVideo>
              <iframe
                className="video"
                src="https://www.youtube.com/embed/9NMYofrxJZU"
                title="video home"
              />
            </ContainerVideo>
          </Modal>
          <Modal
            fullScreen
            recoverPassword
            active={modal === 'forgotPassword'}
            toggle={() => closeModal('')}
          >
            <ForgotPasswordModal>
              <ForgotPasswordForm />
            </ForgotPasswordModal>
          </Modal>
          <Modal
            fullScreen
            active={this.props.isActiveModalRBeneficia}
            toggle={this.props.toggleModalRBeneficiray}
          >
            <ForgotPassword>
              <form>
                <img src={logoUrl} alt="logo" />
                <p>{t('home.modal.beneficiary.description')}</p>
                <div>
                  <Button
                    type="button"
                    bgColor="#ff8400"
                    bgColorHover="#211261"
                    onClick={this.props.toggleModalRBeneficiray}
                  >
                    {t('home.modal.beneficiary.button')}
                  </Button>
                </div>
              </form>
            </ForgotPassword>
          </Modal>
          <Modal
            fullScreen
            active={this.props.activeModalVerifier}
            toggle={this.props.toggleModalVerifier}
          >
            <ForgotPassword>
              <form>
                <img src={logoUrl} alt="logo" />
                <p>{t('home.modal.verifier.description')}</p>
                <div>
                  <Button
                    type="button"
                    bgColor="#ff8400"
                    bgColorHover="#211261"
                    onClick={this.props.toggleModalVerifier}
                  >
                    {t('home.modal.verifier.button')}
                  </Button>
                </div>
              </form>
            </ForgotPassword>
          </Modal>
          <Login
            toggleLogin={this.props.toggleLogin}
            isActiveLogin={this.props.activeLogin}
            toggleModalForgotPassword={this.props.toggleModalForgotPassword}
            toggleModalRBeneficiray={this.props.toggleModalRBeneficiray}
            toggleModalVerifier={this.props.toggleModalVerifier}
          />
        </Container>
        <div className="container-footer">
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    logoUrl: store.home.homes[0] ? store.home.homes[0].logoUrl : '',
    homeImageUrl: store.home.homes[0] ? store.home.homes[0].homeImageUrl : '',
    message: store.app.message,
    modal: store.app.modal,
  };
}

function mapDispachtToProps(dispatch) {
  return {
    closeModal: (value) => dispatch(setModal(value)),
  };
}
export default connect(mapStateToProps, mapDispachtToProps)(BackgroundHome);
