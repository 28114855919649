import axios from "axios";

axios.defaults.timeout = 14400000;

const config = {
	baseURL: process.env.REACT_APP_API_URL,
	// timeout: 180000 * 4,
	// baseURL: 'http://localhost:8080/api',
	headers: {
		"Content-type": "application/json",
		Authorization: process.env.REACT_APP_API_AUTHORIZATION,
	},
};

export default axios.create(config);
