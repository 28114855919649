/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { changePassword } from '../../redux/user/actions';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import MessageResponse from '../../components/MessageResponse';

// Validate
import validate from 'validate.js';
import InputPassword from '../../fields/InputPassword';

const constrains = {
  currentPassword: {
    presence: {
      message: t('validationsInputs.required'),
    },
    length: { minimum: 6, message: t('validationsInputs.minimun') },
  },
  password: {
    presence: {
      message: t('validationsInputs.required'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
    length: { minimum: 6, message: t('validationsInputs.minimun') },
  },
  newPassword: {
    presence: {
      message: t('validationsInputs.required'),
    },
    equality: {
      attribute: 'password',
      message: t('validationsInputs.noMatch'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
};

class Changepassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      error: true,
    };
  }
  renderError = () => {
    const messageData = this.props.message;
    let message = '';
    if (messageData) {
      switch (messageData) {
        case 'auth/wrong-password':
          message = 'algo salio mal';
          break;
        default:
          message = 'valida tus datos';
          break;
      }
      return (
        <MessageResponse
          bgAlert="red"
          message={message}
          isActived={this.state.error}
          visible={this.visibleMessage}
        />
      );
    }
  };
  visibleMessage = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 2000);
  };
  render() {
    const { handleSubmit, message } = this.props;
    const { visible } = this.state;
    return (
      <div>
        {visible}
        <form
          className="formBeneficiare"
          onSubmit={handleSubmit}
          rendererror={this.renderError()}
        >
          <h3>{t('menu.changePassword')}</h3>
          <Field
            name="currentPassword"
            component={InputPassword}
            placeholder={t('home.modal.changePassword.current')}
          />
          <Field
            name="password"
            component={InputPassword}
            placeholder={t('home.modal.changePassword.newPassword')}
          />
          <Field
            name="newPassword"
            component={InputPassword}
            placeholder={t('home.modal.changePassword.confirmPassword')}
          />
          <Button className="margin" bgColor="#FF8400" bgColorHover="#892B6E">
            {t('menu.changePassword')}
          </Button>
          <MessageResponse
            isActived={message === 'succesPasswordChange'}
            message="¡Contraseña actualizada!"
          />
          <MessageResponse
            isActived={message === 'errorPasswordChange'}
            message="La contraseña no se actualizo"
            bgAlert="red"
          />
          <MessageResponse
            isActived={message === 'invalidPasswordChange'}
            message="Las contraseñas no coinciden"
            bgAlert="red"
          />
        </form>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    message: store.app.message,
  };
};

const mapDispachtToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(changePassword(values)),
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps),
  reduxForm({
    form: 'changePassword',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(Changepassword);
