import styled from 'styled-components'
import { prop } from 'styled-tools'

export const Content = styled.div`
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	text-align: center;
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	p {
		margin: 5px;
		font-size: 16px;		
    max-width: 357px;
    margin-bottom: 13px;
	}
	.description {
		max-width: 384px;
		line-height: 18px;
		font-size: 15px;
		text-align: center;
	}
	.content-form {
		margin: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.img-user {
		margin: 2px 3px 0 0;
		border: 1px solid #333;
		padding: 12px;
		width: fit-content;
		img {
			width: 115px;
			border-radius: 50%;
		}
		figcaption {
			font-size: 16px;
    	color: #333;
		}
	}
	.container-input {
		margin-top: 20px;
		width: 88%;		
	}
	form {
		> h3,
		span {
			color: #211261;
		}
		img {
		}
		> h3 {
			font-size: 24px;
			line-height: 21px;
			margin: 12px 0 17px 0;
			font-family: inherit;
			font-weight: bold;
		}
		> p {
			/* color: #333;
			font-size: 13px; */
		}
		> input {
			margin: 30px 0;
			width: 100%;
		}
		> div {
			float: ${prop('position', 'right')};
			margin-right: 19px;
		}
		button {
			margin-bottom: 14px;
		}
	}
`
