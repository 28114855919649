/* Dependenceies */
import React from 'react';
import PropTypes from 'prop-types';
/* Componenets */
import Modal from '../Modal';
import Button from '../Button';

/* i18n */
import { t } from '../../i18n';
/* Styles */
import { Content } from './styles';
/* Assets */
// import img from '../../assets/images/male-user.png';

function ModalConfirm({ toggle, user, actionConfirm, title, description }) {
  return (
    <Modal active toggle={toggle} heigth="10px" width="auto" confirm>
      <Content>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="btnConfirm">
          <Button width="auto" bgColor="#ff8400" onClick={toggle}>
            No
          </Button>
          <Button width="auto" bgColor="#ff8400" onClick={actionConfirm}>
            {t('buttons.yes')}
          </Button>
        </div>
      </Content>
    </Modal>
  );
}

ModalConfirm.defaultProps = {
  user: '',
};

ModalConfirm.propTypes = {
  toggle: PropTypes.func.isRequired,
  user: PropTypes.any,
  actionConfirm: PropTypes.any.isRequired,
};

export default ModalConfirm;
