/* Dependecies */
import React from 'react'
/* Componenets */
/* i18n */
import { t } from '../../i18n'
/* Styles */
import { Content } from './styles'

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

const InputFileButton = ({
	nameFile,
	placeholder,
	type,
	meta: { touched, error, omitMeta },
	input: { value: omitValue, onChange, onBlur, ...inputProps },
	...props
}) => (
	<Content>
		<div className='fileCaption'>
			<div className='file-caption-name'>{nameFile}</div>
		</div>
		<div className='btn'>
			<i className='far fa-folder-open' />
			<span className='span'>{t('buttons.browse') + '...'}</span>
			<input
				onChange={adaptFileEventToValue(onChange)}
				onBlur={adaptFileEventToValue(onBlur)}
				type='file'
				{...props.input}
				{...props}
			/>
			{touched && (error && <span>{error}</span>)}
		</div>
	</Content>
)

export default InputFileButton
