import {
	ACTIVED_FORM_BENEFICIARES,
	GENDERS,
	IDENTIFICATIONTYPE,
	RELATIONSHIP,
	COUNTRIES,
	FREQUENT_QUESTIONS,
	DATA_REGISTER_BENEFICIARE,
	MODAL_REGISTER_BENEFICIARE,
	MODAL_REGISTER_VERIFIER,
	DATA_REGISTER_VERIFIER,
} from './const';
import RegisterService from './service';
import { reset } from 'redux-form';
import { setMessage, setLoading, handleLogout } from '../app/actions';
import { handleAuth, loginBeneficiare, loginVerifier } from '../user/actions';

import { t } from '../../i18n';
export const setFormBeneficiaries = (value) => ({
	type: ACTIVED_FORM_BENEFICIARES,
	value,
});

export const setGenders = (value) => ({
	type: GENDERS,
	value,
});

export const setIdentificationType = (value) => ({
	type: IDENTIFICATIONTYPE,
	value,
});

export const setRelationship = (value) => ({
	type: RELATIONSHIP,
	value,
});

export const setCountries = (value) => ({
	type: COUNTRIES,
	value,
});

export const setDataRegisterVerifier = (value) => ({
	type: DATA_REGISTER_VERIFIER,
	value,
});

export const setDataRegisterBeneficiare = (value) => ({
	type: DATA_REGISTER_BENEFICIARE,
	value,
});

export const setModalRegisterBeneficiare = (value) => ({
	type: MODAL_REGISTER_BENEFICIARE,
	value,
});

export const setModalRegisterVerifier = (value) => ({
	type: MODAL_REGISTER_VERIFIER,
	value,
});

export const setFrequentQuestions = (value) => ({
	type: FREQUENT_QUESTIONS,
	value,
});
export const createUserMain = (dataUsers, onNavigate) => {
	const dataB =
		dataUsers[1].beneficiaries !== undefined
			? dataUsers[1].beneficiaries
			: console.log('e');
	const dataBeneficiaries2 =
		dataB !== undefined
			? dataB.map((data) => ({ ...data, countryId: dataUsers[0].countryId }))
			: console.log('e');
	const data2 =
		dataUsers[2].beneficiaries2[0].emailBeneficiary !== undefined
			? dataUsers[2].beneficiaries2[0]
			: [];
	const dataBeneficiaries =
		dataBeneficiaries2 === undefined
			? [].concat(data2)
			: [].concat(dataBeneficiaries2, data2);

	const dataVerifieries = [];
	if (dataUsers[2].verifieries[0].emailVerifiers !== undefined) {
		dataVerifieries.push(dataUsers[2].verifieries[0]);
	}
	if (dataUsers[2].verifieries[1].emailVerifiers !== undefined) {
		dataVerifieries.push(dataUsers[2].verifieries[1]);
	}
	if (dataUsers[2].verifieries[2].emailVerifiers !== undefined) {
		dataVerifieries.push(dataUsers[2].verifieries[2]);
	}
	return async (dispatch) => {
		try {
			if (!dataUsers[2].beneficiaries2[0].emailBeneficiary) {
				return dispatch(
					setMessage({
						name: 'withError',
						message: t('register.requiredOneBeneficiarie'),
					}),
				);
			}
			if (!dataUsers[2].verifieries[0].emailVerifiers) {
				return dispatch(
					setMessage({
						name: 'withError',
						message: t('register.requiredOneVerifier'),
					}),
				);
			}
			dispatch(setLoading(true));
			const getResultUserMain = await RegisterService.getValidationUserMain(
				dataUsers[0].email,
			);
			if (getResultUserMain.data.length > 0) {
				dispatch(setMessage('emailIsAlreadyUse'));
			} else {
				const result = await RegisterService.setNewUserMain(
					dataUsers[0],
					dataBeneficiaries,
					dataVerifieries,
				);

				const dataUser = result.data;
				const resultUserFirebase = await RegisterService.setNewUserFirebase(
					dataUser,
				);
				const dataFirebase = resultUserFirebase.data;

				await RegisterService.setUpdateUidUserMain(
					dataUser.id,
					dataUser.email,
					dataFirebase.uid,
					dataUsers[3].imageUrl ? dataUsers[3].imageUrl : null,
				);
				await dispatch(
					handleAuth({
						email: dataUsers[0].email,
						password: dataUsers[0].password,
					}),
				);
				dispatch(setMessage('succesRegister'));
				dispatch(reset('createNewUser'));
				onNavigate('/');
			}
		} catch (e) {
			console.log('REGISTER: ', e);
			// if (e.response.data.error.message === 'You need almost one verifier') {
			// 	dispatch(setMessage('requiredOneVerifier'))
			// } else if (
			// 	e.response.data.error.message === 'You need almost one Beneficiarie'
			// ) {
			// 	dispatch(setMessage('requiredOneBeneficiarie'))
			// } else if (
			// 	e.response.data.error.message ===
			// 	'The email address is already in use by another account'
			// ) {
			// 	dispatch(setMessage('emailIsAlreadyUse'))
			// } else {
			// 	dispatch(setMessage('errorRegister'))
			// }
		} finally {
			setTimeout(() => dispatch(setMessage('')), 3000);
			dispatch(setLoading(false));
		}
	};
};
export const updateUserBeneficiary = (data) => {
	const code = data.code;
	const imageUrl = data.imageUrl;
	let dataUser;
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const userDataBeneficiary = await RegisterService.getBeneficiaryUser(
				code,
			);
			const userDataVerifier = await RegisterService.getVerifierUser(code);

			if (userDataBeneficiary.data.length) {
				const beneficiary = userDataBeneficiary.data[0];

				if (beneficiary.active === false) {
					const resultUserFirebase = await RegisterService.setNewUserFirebase(
						data,
					);
					const updateData = RegisterService.setUpdateBeneficiary(
						beneficiary,
						data,
					);
					updateData.then((response) => {
						dataUser = response.data;
					});
					const dataFirebase = resultUserFirebase.data;
					await dispatch(
						handleAuth({ email: data.email, password: data.password }),
					);

					await RegisterService.setUpdateUidUserMain(
						dataUser.id,
						dataUser.email,
						dataFirebase.uid,
						imageUrl && imageUrl,
					);
					dispatch(setMessage('succesRegister'));
					dispatch(reset('createNewUser'));
					window.location.assign('/');
				} else {
					setTimeout(() => dispatch(setMessage('')), 3000);
					dispatch(setMessage('errorCodeActive'));
				}
			} else if (userDataVerifier.data.length) {
				const verifier = userDataVerifier.data[0];

				if (verifier.active === false) {
					const resultUserFirebase = await RegisterService.setNewUserFirebase(
						data,
					);
					const updateData = RegisterService.setUpdateVerifier(verifier, data);
					updateData.then((response) => {
						dataUser = response.data;
					});

					const dataFirebase = resultUserFirebase.data;
					await dispatch(
						handleAuth({ email: data.email, password: data.password }),
					);

					await RegisterService.setUpdateUidUserMain(
						dataUser.id,
						dataUser.email,
						dataFirebase.uid,
						imageUrl && imageUrl,
					);
					dispatch(setMessage('succesRegister'));
					dispatch(reset('createNewUser'));
					window.location.assign('/');
				} else {
					setTimeout(() => dispatch(setMessage('')), 3000);
					dispatch(setMessage('errorCodeActive'));
				}
			} else {
				setTimeout(() => dispatch(setMessage('')), 3000);
				dispatch(setMessage('errorCodeNotFound'));
			}
		} catch (e) {
			if (e.response !== undefined) {
				if (
					e.response.data.error.message ===
					'The email address is already in use by another account.'
				) {
					dispatch(setMessage('emailIsAlreadyUse'));
				} else {
					dispatch(setMessage('errorRegister'));
				}
			} else {
				dispatch(setMessage('errorRegister'));
			}
		} finally {
			setTimeout(() => dispatch(setMessage('')), 3000);
			dispatch(setLoading(false));
		}
	};
};
export const getGender = () => async (dispatch) => {
	const gender = await RegisterService.getByGenderBylenguaje();
	dispatch(setGenders(gender.data));
};

export const getFrequentQuestions = () => async (dispatch) => {
	const frequentQuestions = await RegisterService.getFrequentQuestion();
	dispatch(setFrequentQuestions(frequentQuestions.data));
};

export const getIdentificationTypes = () => async (dispatch) => {
	const identificationTypes = await RegisterService.getIdentificationType();
	dispatch(setIdentificationType(identificationTypes.data));
};

export const getRelationships = () => async (dispatch) => {
	try {
		const relationships = await RegisterService.getRelationship();
		dispatch(setRelationship(relationships.data));
	} catch (e) {
		console.log(e);
	}
};

export const getCountries = () => async (dispatch) => {
	try {
		const country = await RegisterService.getByCountries();
		dispatch(setCountries(country.data));
	} catch (e) {
		console.log(e);
	}
};

export const getDataRegisterBeneficiare =
	(identification, route) => async (dispatch) => {
		try {
			const { data } = await RegisterService.getdataRegisterBeneficiare(
				identification,
			);
			if (!data) return route();
			dispatch(handleLogout());
			dispatch(setModalRegisterBeneficiare(true));
			dispatch(setDataRegisterBeneficiare(data));
		} catch (e) {
			console.log(e);
			route();
		}
	};

export const getDataRegisterVerifier =
	(identification, route) => async (dispatch) => {
		try {
			const { data } = await RegisterService.getdataRegisterVerifier(
				identification,
			);
			if (!data) return route();
			dispatch(handleLogout());
			dispatch(setModalRegisterVerifier(true));
			dispatch(setDataRegisterVerifier(data));
		} catch (e) {
			console.log(e);
			route();
		}
	};

export const setRegisterBeneficiare =
	({ code, password, email, temporalToken, beneficiareId }) =>
	async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const { data: isValidCode } =
				await RegisterService.getValidationTokenRegisterBeneficiare({
					code,
					temporalToken,
				});
			if (!isValidCode.length) {
				return dispatch(
					setMessage({
						name: 'withError',
						message: t('registerBeneficiare.verifierCode'),
					}),
				);
			}
			const [register] = await Promise.all([
				RegisterService.setNewUserFirebase({ password, email }),
				RegisterService.acceptRelation(code),
			]);
			const {
				data: { uid },
			} = register;
			await RegisterService.setUpdateUid(beneficiareId, uid);
			await dispatch(loginBeneficiare({ password, email }));
			dispatch(setModalRegisterBeneficiare(false));
			dispatch(
				setMessage({
					name: 'success',
					message: t('registerBeneficiare.welcome'),
				}),
			);
		} catch (e) {
			console.log(e);
		} finally {
			setTimeout(() => dispatch(setMessage('')), 2300);
			dispatch(setLoading(false));
		}
	};

export const setRegisterVerifier =
	({ code, password, email, temporalToken, beneficiareId }, route) =>
	async (dispatch) => {
		try {
			dispatch(setLoading(true));

			const { data: isValidCode } =
				await RegisterService.getValidationTokenRegisterVerifier({
					code,
					temporalToken,
				});

			if (!isValidCode.length) {
				return dispatch(
					setMessage({
						name: 'withError',
						message: t('validations.login.wrong-code'),
					}),
				);
			}

			const [register] = await Promise.all([
				RegisterService.setNewUserFirebase({ password, email }),
				RegisterService.acceptRelationVerifier(code),
			]);

			const {
				data: { uid },
			} = register;
			await Promise.all([
				RegisterService.setUpdateUid(beneficiareId, uid),
				dispatch(loginVerifier({ email, password })),
			]);

			dispatch(setModalRegisterVerifier(false));
			route();
			dispatch(
				setMessage({
					name: 'success',
					message: t('registerBeneficiare.welcome'),
				}),
			);
			window.location.reload();
		} catch (e) {
			console.log(e);
			const response = await RegisterService.getDataUser(beneficiareId);

			const uid = response.data.length > 0 ? response.data[0].uidAuth : 0;

			await Promise.all([
				RegisterService.setUpdateUid(beneficiareId, uid),
				dispatch(loginVerifier({ email, password })),
			]);

			dispatch(setModalRegisterVerifier(false));
			route();
			dispatch(
				setMessage({
					name: 'success',
					message: t('registerBeneficiare.welcome'),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage('')), 2300);
			dispatch(setLoading(false));
		}
	};
