// Dependencies
import styled from 'styled-components'
// Assets
import icon from '../../assets/images/sprite-icons.svg'

export const Container = styled.div`
	.boxMessage {
		animation: fadeInUp .4s;
		width: 90%;
		max-width: 380px;
        min-height: 100px;
		height: auto;
		border: 2px solid rgba(118, 35, 106, .6);
		border-left: none;
		border-radius: 4px;
		margin: 20px auto;
		position: relative;
		background: #fff;
        align-items: center;
        display: flex;
		padding-left: 45px;
		transition: all 0.4s ease-in;
		&:hover {
			border: 2px solid #76236a;
		}
		&:hover .messageType {
			box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
			background-color: rgb(118, 35, 106);
			::before, ::after{
				display: none;
			}
		}
	}
	.messageType {
		width: 60px;
		height: 60px;
		border-radius: 30px;
		float: left;
		text-align: center;
		position: absolute;
		top: 18px;
		left: -30px;
		transition: all 0.4s linear 0s;
		background-color: rgba(118, 35, 106, .6);
		display: flex;
		justify-content: center;
		align-items: center;
		i {
			font-size: 33px;
    	color: white;
		}
	}
	.messageType::after {		    
		content: '';
		
    position: absolute;
    
		width: 15px;
top: 111%;
left: 36%;
    border-style: solid;
		border-width: 2px;
		transition: all 0.4s ease-in;
    border-color: transparent transparent rgba(118, 35, 106, .6) transparent;    
		transform: rotate(90deg);		
	}
	.messageType::before {		    
		content: '';
		
    position: absolute;
   
		width: 15px;
bottom: 111%;
left: 36%;
    border-style: solid;
		border-width: 2px;
		transition: all 0.4s ease-in;
    border-color: transparent transparent rgba(118, 35, 106, .6) transparent;    
		transform: rotate(90deg);		
	}
	/* .iconType {
		width: 28px;
		height: 26px;
		display: block;
		margin: 18px auto 0;
		background: url(${icon}) -455px 0/550px 80px;
	} */
	.messageTitle {
		width: 100%;
		padding-right: 5%;
		height: 86px;
		float: left;
		display: table;		
		small {
			font-weight: 400;
			line-height: 1;
			color: #777;
			font-size: 75%;
		}
		p, h4 {
			margin: 0;
			line-height: 16px;
			font-size: 16px;
			color: #454545;
		}
    > h4 {
			margin-top: 5px;			
		}
		b {
			font-weight: 700;
		}
		p {
			padding: 0;
		}
	}
`
