// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Redux
import ContactService from '../../redux/contact/ContactService';
import {
  setQuestion,
  setQuestions,
  setContacts,
} from '../../redux/contact/actions';
import { setComponentLoading } from '../../redux/app/actions';
import ContactForm from '../../form/Contact';
// i18n
import { t, getLanguageById } from '../../i18n';
// Components
import Footer from '../../components/Footer';
import Wrapper from '../../components/Wrapper';
import MessageResponse from '../../components/MessageResponse';
import Question from '../../components/Question';

// Styled components
import { Container, Content, ContainerFormRegister } from './styles';
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuestionActive: null,
    };
  }
  async componentDidMount() {
    const { setComponentLoading, toggleQuestions } = this.props;
    setComponentLoading(true);
    const questions = await ContactService.getFaq({
      where: { languageId: getLanguageById() },
    });
    toggleQuestions(questions.data);
    setComponentLoading(false);
  }

  toggleQuestion = (key) =>
    this.setState((state) => ({
      isQuestionActive: key !== state.isQuestionActive ? key : null,
    }));

  render() {
    const { setContacts, message, question, contactImageUrl } = this.props;
    const { isQuestionActive } = this.state;

    return (
      <Container bgImage={contactImageUrl}>
        <Wrapper className="Wrapper">
          <Content>
            <div className="questions">
              <h2>{t('contact.frequentQuestions')}</h2>
              <span className="text-separator-top" />
              <div className="panel-group">
                {/* {this.props.isComponentLoading && (
                  <Facebook
                    width={500}
                    height={800}
                    speed={2.5}
                    primaryColor="
                  rgba(118, 35, 106, .8)
                  "
                    secondaryColor="rgba(251, 133, 25, .8)"
                  />
                )} */}
                {question.map((item, index) => (
                  <button
                    key={index}
                    className="question"
                    onClick={() => this.toggleQuestion(index)}
                  >
                    <Question
                      ko={item.id}
                      titleQuestion={item.question}
                      contentQuestion={item.answer}
                      isActived={isQuestionActive === index}
                    />
                  </button>
                ))}
              </div>
            </div>
            <div>
              <ContainerFormRegister>
                <ContactForm onSubmit={setContacts} />
              </ContainerFormRegister>
            </div>
          </Content>
          <MessageResponse isActived={message === 'setContactSuccess'} />
          <MessageResponse
            isActived={message === 'setContactError'}
            message="Algo ha salido mal"
            bgAlert="red"
          />
        </Wrapper>
        <Footer />
      </Container>
    );
  }
}
function mapStateToProps(store) {
  return {
    isActivedQuestion: store.contact.isActivedQuestion,
    question: store.contact.questions,
    isComponentLoading: store.app.isComponentLoading,
    message: store.app.message,
    contactImageUrl: store.home.homes[0]
      ? store.home.homes[0].contactImageUrl
      : '',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleQuestion: (action) => dispatch(setQuestion(action)),
    toggleQuestions: (questions) => dispatch(setQuestions(questions)),
    setComponentLoading: (value) => dispatch(setComponentLoading(value)),
    setContacts: (values) => dispatch(setContacts(values)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
