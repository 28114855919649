/* Dependencies */
import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 auto;
	button {
		font-size: 20px;
		color: rgba(255, 0, 0, .8);
		font-size: 40px;
		:hover {
			color: rgba(255, 0, 0, 1);
		}
	}
  .sound-wave {
    width: 100%;
		height: 77px;
		background: red;
  }
`
