import INITIAL_STATE from './initialState';
import {
  MESSAGE_TYPES,
  EVENT_TYPES,
  EVENT_TYPES_ALL,
  MESSAGE_PREVIEW,
  DRAFT_LOCALE_STOREGE,
  FILE_TYPES,
  SELECTED_BENEFICIARIES,
  SET_MESSAGE_BY_USER,
  SET_MESSAGE_EVENTS_BY_USER,
  SET_MESSAGE_ADVICE_BY_USER,
  DRAFT_LOCALE_STORAGE_DETAIL,
  ADVICE_TYPES,
  ADVICE_TYPES_ALL,
} from './const';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGE_TYPES:
      return Object.assign(
        {},
        {
          ...state,
          messageTypes: action.value,
        }
      );
    case EVENT_TYPES:
      return Object.assign(
        {},
        {
          ...state,
          eventTypes: action.value,
        }
      );
    case EVENT_TYPES_ALL:
      return Object.assign(
        {},
        {
          ...state,
          eventTypesAll: action.value,
        }
      );
    case ADVICE_TYPES:
      return Object.assign(
        {},
        {
          ...state,
          adviceTypes: action.value,
        }
      );
    case ADVICE_TYPES_ALL:
      return Object.assign(
        {},
        {
          ...state,
          adviceTypesAll: action.value,
        }
      );

    case MESSAGE_PREVIEW:
      return Object.assign(
        {},
        {
          ...state,
          messagePreview: action.value,
        }
      );
    case DRAFT_LOCALE_STOREGE:
      return Object.assign(
        {},
        {
          ...state,
          draftLocaleStorage: action.value,
        }
      );
    case FILE_TYPES:
      return Object.assign(
        {},
        {
          ...state,
          fileTypes: action.value,
        }
      );
    case SELECTED_BENEFICIARIES:
      return Object.assign(
        {},
        {
          ...state,
          selectedBeneficiaries: action.value,
        }
      );
    case SET_MESSAGE_BY_USER:
      return Object.assign(
        {},
        {
          ...state,
          messageByUser: action.value,
        }
      );
    case SET_MESSAGE_EVENTS_BY_USER:
      return Object.assign(
        {},
        {
          ...state,
          messageEventByUser: action.value,
        }
      );
    case SET_MESSAGE_ADVICE_BY_USER:
      return Object.assign(
        {},
        {
          ...state,
          messageAdviceByUser: action.value,
        }
      );

    case DRAFT_LOCALE_STORAGE_DETAIL:
      return Object.assign(
        {},
        {
          ...state,
          draftLocalStorageDetail: action.value,
        }
      );
    default:
      return state;
  }
};
