import React from 'react';

import { t } from '../../i18n';

import logo from '../../assets/images/logo-1.png';

import Modal from '../Modal';
import Button from '../Button';

import { Content } from './styles';

function changeRole(roleId) {
  window.localStorage.setItem('role', roleId);
  window.location.assign('/');
}

function ModalChangeRole({ active, closeModal, roles, currentRole }) {
  console.log('ModalChangeRole', {
    currentRole,
    roles,
  });
  return (
    <Modal
      width="fit-content"
      padding="35px"
      fullScreen
      active={active}
      toggle={closeModal}
    >
      <Content>
        <img src={logo} alt="logo" />
        <>
          {roles &&
            roles
              .map((el) => el.roleId)
              .filter(function (value, index, self) {
                return self.indexOf(value) === index;
              })
              .map((el) => {
                switch (el) {
                  case 3:
                    if (currentRole === '1') {
                      return null;
                    }
                    return (
                      <Button
                        onClick={() => changeRole(1)}
                        bgColor="#ff8400"
                        bgColorHover="#211261"
                      >
                        {t('buttons.loginBeneficiare')}
                      </Button>
                    );
                  case 2:
                    if (currentRole === '2') {
                      return null;
                    }
                    return (
                      <Button
                        onClick={() => changeRole(2)}
                        bgColor="#ff8400"
                        bgColorHover="#211261"
                      >
                        {t('buttons.loginVerifier')}
                      </Button>
                    );
                  case 1:
                    if (currentRole === 'null' || !currentRole) {
                      return null;
                    }
                    return (
                      <Button
                        onClick={() => changeRole(null)}
                        bgColor="#ff8400"
                        bgColorHover="#211261"
                      >
                        {t('buttons.loginMain')}
                      </Button>
                    );

                  default:
                    return null;
                }
              })}
        </>
      </Content>
    </Modal>
  );
}

export default ModalChangeRole;
