//Dependencies
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import VideoRecorder from 'react-video-recorder';

// import es from 'date-fns/locale/es'
import { t } from '../../i18n';
//Comoponenet
import Message from '../Message';
import TypeFileUpload from '../TypeFileUpload';
import InputUpload from '../InputUpload';
import Button from '../Button';
//Styled Component
import { Container } from './styles';

// registerLocale('es', es)

class FilesMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isActived: false,
    };
  }
  toggleIsActived = () => this.setState({ isActived: !this.state.isActived });

  onChange = (date) =>
    this.setState({ date, isActived: !this.state.isActived });

  render() {
    const {
      boxLeftTitle,
      boxLeftIcon,
      boxLeftImg,
      boxLeftToggle,
      boxRightTitle,
      boxRightIcon,
      boxRightImg,
      boxRightToggle,
      title,
      children,
      isActivedUpload,
      format,
      addImage,
      isActivedVideoRecorder,
      notBox,
      withHoutRecomendations,
    } = this.props;
    const { date, isActived } = this.state;
    return (
      <Message
        withHoutRecomendations={withHoutRecomendations}
        titleTypeMessage={title}
        notButton
        eraser
      >
        <Container isActived={isActived} isActivedUpload={isActivedUpload}>
          <div className="titleAndDate">
            {/* <Field
							type='text'
							name='title'
							component={InputRedux}
							placeholder={t('writtenMessage.fields.title')}
						/>
						<Field name='eventType' component='select'>
							<option>Selecionar tipo de evento</option>
							{/* {eventTypes.map((eventType, key) => (
								<option key={key} value={eventType.id}>
									{eventType.name}
								</option>
							))} */}
            {/* </Field> */}
            <div className="absence">
              {/* <Field
								name='date'
								type='text'
								component={InputRedux}
								placeholder={`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`}
								readonly='readonly'
								onClick={this.toggleIsActived}
							/> */}
              <Calendar
                className="calendar"
                onChange={this.onChange}
                value={date}
                activeStartDate={new Date(date)}
              />
              {/* <DatePicker
								dateFormat='MM-dd-yyyy'
								selected={date}
								onChange={this.onChange}
								minDate={new Date()}
								locale='es'
							/> */}
            </div>
          </div>
          {!notBox && (
            <div className="actionMessage">
              <TypeFileUpload
                title={boxLeftTitle}
                icon={boxLeftIcon}
                bgImg={boxLeftImg}
                toggle={boxLeftToggle}
              />
              <TypeFileUpload
                title={boxRightTitle}
                icon={boxRightIcon}
                bgImg={boxRightImg}
                toggle={boxRightToggle}
              />
              {isActivedVideoRecorder && <VideoRecorder />}
            </div>
          )}

          <div className="containerUpload">
            <label>{format}</label>
            <InputUpload title={t('buttons.browse') + '...'} />
            {addImage && (
              <Button
                bgColor="rgb(33, 18, 97)"
                bgColorHover="rgb(137, 43, 110)"
              >
                {t('buttons.addImage')}
              </Button>
            )}
          </div>
          {children}

          <Container>
            <div className="selectPhotos">
              {/* {images.map((item, i) => (
								<div
									style={{
										width: '150px',
										height: '50px'
									}}>
									<h4>{item.name}</h4>
									<img
										style={{
											width: '100px'
										}}
										src={item.url}
									/>
								</div>
							))} */}
            </div>
          </Container>
        </Container>
      </Message>
    );
  }
}

export default FilesMessage;
