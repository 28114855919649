/* Dependencies */
import styled from 'styled-components';
import media from 'styled-media-query';
import { prop } from 'styled-tools';

export const Container = styled.section`
  width: 100%;
  margin-bottom: 30px;
  min-height: 100vh;
  display: flex;

  .videoHome {
    width: 100%;
    height: 100%;
    /*overflow: hidden;
    box-sizing: border-box;
    > iframe {
      margin-left: -6%;
      width: 111%;
      height: 110%;
      box-sizing: border-box;
    }*/

    background-repeat: no-repeat;
    background-size: cover;
  }
  ${media.lessThan('medium')`
  .videoHome {
        display: none;
    }    
    `}
`;
export const ContainerVideo = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    z-index: 11;
    min-height: 550px;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
  min-height: 350px;        
    `}
  }
`;

export const TermsAndConditions = styled.div`
  > h3 {
    color: #211261;
  }
  > p {
    text-align: justify;
    font-size: 13px;
    color: #333;
    line-height: 22px;
    margin: 0 0 10px;
    padding: 0 1rem;
  }
  > button {
    background: #ff8400;
    padding: 7.5px 20px;
    border: none;
    border-radius: 25px;
    margin: 20px auto 20px;
    text-align: center;
    font-size: 0.7rem;
    cursor: pointer;
    color: white;
    outline: none;
    &:hover {
      animation: pulse 1s;
    }
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
`;

export const ForgotPassword = styled.div`
  margin: 63px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  form {
    > h3,
    span {
      color: #211261;
    }
    img {
    }
    > h3 {
      font-size: 19px;
      line-height: 21px;
      margin: 10px 0;
      font-family: inherit;
      font-weight: bold;
    }
    > p {
      color: #333;
      font-size: 13px;
    }
    > input {
      margin: 30px 0;
      width: 100%;
    }
    > div {
      float: ${prop('position', 'right')};
      margin-right: 19px;
    }
  }
`;
export const ForgotPasswordModal = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  form {
    > h3,
    span {
      color: #211261;
    }
    img {
    }
    > h3 {
      font-size: 23px;
      line-height: 21px;
      margin: 10px 0;
      font-family: inherit;
      font-weight: bold;
    }
    > p {
      color: #333;
      font-size: 13px;
    }
    > input {
      margin: 30px 0;
      width: 100%;
    }
    > div {
      margin-right: 19px;
    }
  }
  button {
    height: 50px;
  }
`;
