import React, { Component } from 'react';
import { Field } from 'redux-form';

import { t } from '../../i18n';

import InputRedux from '../../fields/Input';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import logo from '../../assets/images/logo-1.png';

import { Content } from './styles';

import withStore from './store';
import InputPassword from '../../fields/InputPassword';

class LoginBeneficiary extends Component {
  render() {
    const { active, handleSubmit, closeModal } = this.props;

    return (
      <Modal
        width="387px"
        padding="35px"
        fullScreen
        active={active}
        toggle={closeModal}
      >
        <Content>
          <form onSubmit={handleSubmit}>
            <img src={logo} alt="logo" />
            <p>{t('registerBeneficiare.userBeneficare')}</p>
            <div className="content-form">
              <div className="container-input">
                <Field
                  type="text"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  component={InputRedux}
                />
                <Field
                  name="password"
                  id="password"
                  placeholder="Password"
                  component={InputPassword}
                />
                <Button bgColor="#ff8400" bgColorHover="#211261">
                  {t('home.login.enter')}
                </Button>
              </div>
            </div>
          </form>
        </Content>
      </Modal>
    );
  }
}

export default withStore(LoginBeneficiary);
