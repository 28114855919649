import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  padding: 15px;
  background: rgba(137,43,110,.3);  
  margin: 0 2px;    
  .title {
    text-align: left!important;
    margin-top: 0;
    color: #211261;    
  }
  .caret {
    position: relative;    
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: relative;
    left: 50%;    
    color: #fff;
    display: none!important;
}
`
