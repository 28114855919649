/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { recoverPassword } from '../../redux/user/actions';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
// import InputRedux from '../../fields/Input';
// import MessageResponse from '../../components/MessageResponse';
import Modal from '../../components/Modal';
// Validate
import validate from 'validate.js';
import InputPassword from '../../fields/InputPassword';

import { ChangePassword } from './styles';

const constrains = {
  password: {
    presence: {
      message: t('validationsInputs.required'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
    length: { minimum: 6, message: t('validationsInputs.minimun') },
  },
  newPassword: {
    presence: {
      message: t('validationsInputs.required'),
    },
    equality: {
      attribute: 'password',
      message: t('validationsInputs.noMatch'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
};

class RecoverPassword extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal active width="fit-content" fullScreen padding="40px">
        <ChangePassword>
          <form onSubmit={handleSubmit}>
            <h3>{t('menu.changePassword')}</h3>
            <Field
              name="password"
              component={InputPassword}
              placeholder={t('home.modal.changePassword.newPassword')}
            />
            <Field
              name="newPassword"
              component={InputPassword}
              placeholder={t('home.modal.changePassword.confirmPassword')}
            />
            <Button className="margin" bgColor="#FF8400" bgColorHover="#892B6E">
              {t('menu.changePassword')}
            </Button>
          </form>
        </ChangePassword>
      </Modal>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    message: store.app.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values, navigate) => dispatch(recoverPassword(values, navigate)),
  };
};

const margeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (values) => {
      dispatchProps.onSubmit({ ...values, code: ownProps.code }, () =>
        ownProps.history.push('/')
      );
    },
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, margeProps),
  reduxForm({
    form: 'recoverPassword',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(RecoverPassword);
