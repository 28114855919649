//Dependencies
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Facebook } from "react-content-loader";
import { connect } from "react-redux";
//Redux
import {
	getUser,
	getNotifications,
	updateNotificationSocket,
	getPaymentOrder,
	getSignatureOrder,
} from "../../redux/user/actions";
import {
	setActivedModalChangePass,
	setActivedModalCreateBeneficiare,
	setActivedModalCreateVerifier,
} from "../../redux/profile/actions";
import { setModalTerms, setModal } from "../../redux/app/actions";
import HomeService from "../../redux/home/HomeService";
import {
	setHomes,
	setFormBeneficiaries,
	setFormVerifiers,
} from "../../redux/home/actions";
import { getRelationships } from "../../redux/register/actions";
import CreateBeneficiaryAndVerifier from "../../form/CreateBeneficiaryAndVerifier";
import {
	handleLogout,
	setEventPage,
	setFormBuy,
	setUserMain,
	getRoleUserPrincipal,
	setMessage,
} from "../../redux/app/actions";
import {
	getSpaces,
	setPaymentPaypal,
	setSpaceSelected,
} from "../../redux/payment/actions";
import {
	addBeneficiariesByUserId,
	searchBeneficiareOrCheker,
	deleteBeneficiary,
	setBeneficiarySelected,
	setCheckerSelected,
	setBeneficiaryByUserId,
	setCheckerByUserId,
	editBeneficiaryByUserId,
	setNotificationSelected,
	updateNotification,
	editCheckerByUserId,
} from "../../redux/user/actions";
import { getMessageByUser } from "../../redux/messages/actions";
import moment from "moment";
import "moment/locale/es";

import ChangePasswordForm from "../../form/changepassword";
import AcceptBeneficiary from "../../form/AcceptBeneficiary";
import AcceptVerifier from "../../form/AcceptVerifier";
import ChangeAbsence from "../../form/ChangeAbsence";

//i18n
import {
	t,
	languages,
	setLanguage,
	getLanguage,
	getLanguageById,
} from "../../i18n";
//Components

import Button from "../Button";
import FormRight from "../FormRight";
import ItemBuyForm from "../ItemBuyForm";
import DropDown from "../DropDown";

import MenuButton from "../MenuButton";

import ButtonNotification from "../ButtonNotification";

import Tooltip from "../Tooltip";

import InfoUser from "../InfoUser";

import Modal from "../Modal";

import TermsAndConditions from "../TermsAndConditions";

import UserItem from "../UserItem";

import ModalConfirm from "../ModalConfirm";
import MessageResponse from "../MessageResponse";
import ItemNotification from "../ItemNotification";
import ModalChangeRole from "../ModalChangeRole";

// const routes
import { routes } from "../../routes";
//Styles Components
import {
	Nav,
	Anav,
	Ul,
	ChangePassword,
	ForgotPasswordModal,
	ContainerForm,
} from "./styles";
//Assets
import payu from "../../assets/images/payu.jpg";
import logo from "../../assets/images/logo-1.png";
// import img from '../../assets/images/male-user.png';
import JoinUserMain from "../../form/JoinUserMain";

import { SocketNotification } from "../../libs/socket";

moment.locale(getLanguage());

const langs = languages.map((item) => ({
	label: item.description,
	value: item.name,
	id: item.id,
}));

const currentLang = langs.find((item) => item.value === getLanguage());

class NavLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileMenuVisible: false,
			tooltipConfig: false,
			tooltipNotification: false,
			dropDownLifeGuide: false,
			nav: false,
			itemBuyForm: null,
			itemBuySpace: null,
			statusProfile: "",
			modalChangeRole: false,
			role: localStorage.getItem("role"),
			isOpen: false,
		};
		this.socketNotification = new SocketNotification();
	}

	async componentDidMount() {
		const {
			getUser,
			setHomes,
			searchBeneficiareOrCheker,
			getRelationships,
			getMessageByUser,
			getSpaces,
			getRoleUserPrincipal,
			getNotifications,
		} = this.props;

		await getUser();

		const homes = await HomeService.getHomes({
			where: { languageId: getLanguageById() },
		});

		if (this.props.location.pathname.split("/")[2] === "terms-and-conditions") {
			this.props.setModalTerms(true);
		}
		await getNotifications();
		Promise.all([
			getRelationships(),
			setHomes(homes.data),
			searchBeneficiareOrCheker(),
			getSpaces(),
			getMessageByUser(),
			getRoleUserPrincipal(),
		]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataOrderPayment !== prevProps.dataOrderPayment) {
			this.props.getSignatureOrderDispatch();
		}
		if (this.props.user.length !== prevProps.user.length && this.props.user) {
			this.props.getNotifications();
			this.socketNotification.connectNotification(
				this.props.updateNotificationSocket,
				this.props.user[0].id,
			);
		}
	}

	componentWillUnmount() {
		this.socketNotification.close();
	}
	_actionNotification = (action, notification) => {
		const { setNotificationSelected, setModal } = this.props;
		switch (action) {
			case 3:
				setModal("acceptBeneficiary");
				setNotificationSelected(notification);
				break;
			case 4:
				setModal("acceptVerifier");
				setNotificationSelected(notification);
				break;
			default:
				break;
		}
	};

	_actionNotificationAbsenceChange = (notificationId) => {
		const { setModal } = this.props;
		setModal("changeAbsenceUser");
	};
	getRole = () => {
		if (
			this.props.user &&
			this.props.user[0] &&
			this.props.user[0].assignedRoles &&
			this.props.user[0].assignedRoles.length > 1
		) {
			const role = this.props.user[0].assignedRoles
				.map((el) => {
					switch (el.roleId) {
						case 3:
							return " - " + t("roles.beneficiary");
						case 2:
							return " - " + t("roles.checker");
						case 1:
							return " - " + t("roles.main");
						default:
							return " - " + t("roles.main");
					}
				})
				.filter(function (value, index, self) {
					return self.indexOf(value) === index;
				});
			if (role.length) {
				role[0] = role[0].substring(3);
			}

			return role;
		}
	};
	toggleModalTerms = () =>
		this.props.setModalTerms(!this.props.isActivedModalTerms);
	toggleSetActivedModalChangePass = () =>
		this.props.setActivedModalChangePass(!this.props.changePassword);
	toggleNav = () => this.setState({ nav: !this.state.nav });

	toggleformBeneficiaries = () =>
		this.props.setFormBeneficiaries(!this.props.formBeneficiaries);

	toggleformBeneficiariesOff = () => {
		this.props.setFormBeneficiaries(false);
		this.props.setFormVerifiers(false);
	};

	toggleformVerifiers = () =>
		this.props.setFormVerifiers(!this.props.formVerifiers);

	toggleEventPage = () => this.props.setEventPage(true);

	toggleTooltipConfig = () =>
		this.setState({
			tooltipConfig: !this.state.tooltipConfig,
		});

	toggleTooltipNotification = () => {
		this.props.getNotifications();
		this.setState({
			tooltipNotification: !this.state.tooltipNotification,
		});
	};

	toggleMenu = () =>
		this.setState({
			mobileMenuVisible: !this.state.mobileMenuVisible,
		});

	toggleDropDownLifeGuide = () =>
		this.setState({
			dropDownLifeGuide: !this.state.dropDownLifeGuide,
		});

	isSelectetitemBuyForm = (idSpace, space) => {
		const { getPaymentOrderDispatch, setSpaceSelectedDispatch } = this.props;

		this.setState((state) => ({
			itemBuyForm: idSpace !== state.itemBuyForm ? idSpace : null,
			itemBuySpace: space,
		}));
		console.log("space", space);
		const obj = {
			spaceId: space.id,
		};
		console.log("obj", obj);

		getPaymentOrderDispatch(obj);
		setSpaceSelectedDispatch(space);
	};

	renderFormPayu = () => {
		const { dataSignature, setMessageDispatch, user } = this.props;

		const { itemBuySpace } = this.state;

		if (itemBuySpace && dataSignature.signature) {
			console.log("dataSignature.signature", dataSignature.signature);
			return (
				<>
					<form
						method='post'
						action='https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/'
					>
						<Button
							type='submit'
							style={{ marginBottom: "10px" }}
							border
							className='btnBuy'
							borderColor='#CCC'
						>
							<img src={payu} alt='Payu' />
						</Button>
						<input name='merchantId' type='hidden' value='508029' />
						<input name='accountId' type='hidden' value='512321' />
						<input name='description' type='hidden' value='Test PAYU' />
						<input
							name='referenceCode'
							type='hidden'
							value={`${dataSignature.referenceCode}`}
						/>
						<input
							name='amount'
							type='hidden'
							value={`${itemBuySpace ? itemBuySpace.space : 0}`}
						/>
						<input name='tax' type='hidden' value='0' />
						<input name='taxReturnBase' type='hidden' value='0' />
						<input name='currency' type='hidden' value='COP' />
						<input
							name='signature'
							type='hidden'
							value={`${dataSignature.signature}`}
						/>
						<input name='test' type='hidden' value='1' />
						<input
							name='buyerEmail'
							type='hidden'
							value={`${user[0] ? user[0].email : "test@test.com"}`}
						/>
						<input
							name='responseUrl'
							type='hidden'
							value={`${process.env.REACT_APP_API_URL}/orders/confirmation`}
						/>
						<input
							name='confirmationUrl'
							type='hidden'
							value={`${process.env.REACT_APP_API_URL}/orders/confirmation`}
						/>
					</form>
				</>
			);
		}

		return (
			<>
				<Button
					type='submit'
					style={{ marginBottom: "10px" }}
					border
					className='btnBuy'
					borderColor='#CCC'
					onClick={() => {
						setMessageDispatch({
							name: "withError",
							message: t("messagesResponse.requiredSpace"),
						});
						setTimeout(() => setMessageDispatch(""), 3000);
					}}
				>
					<img src={payu} alt='Payu' />
				</Button>
			</>
		);
	};
	getRoleLogin = () => parseInt(localStorage["role"]);

	closeDivAfterDelay = () => {
		this.setState({ isOpen: false });
		this.toggleformBeneficiariesOff();
		const { formVerifiers } = this.props;
		if (formVerifiers) {
			this.toggleformVerifiers();
		}
	};

	onMouseEnterHandler = () => {
		clearTimeout(this.timeoutId);
	};
	onMouseLeaveHandler = () => {
		if (this.state.isOpen === true) {
			this.timeoutId = setTimeout(this.closeDivAfterDelay, 3000);
		}
	};
	toggleformBeneficiariesAction = () => {
		this.setState({ isOpen: true });
	};
	render() {
		const {
			logoUrl,
			handleLogout,
			formBeneficiaries,
			formVerifiers,
			formBuy,
			changePassword,
			isActivedModalTerms,
			termsConditions,
			deleteBeneficiary,
			message,
			beneficiarySelected,
			setBeneficiaryByUserId,
			setCheckerByUserId,
			beneficiaries,
			user,
			isComponentLoading,
			setModal,
			modal,
			setBeneficiarySelected,
			checkers,
			relationships,
			editBeneficiaryByUserId,
			setFormBuy,
			spaces,
			setPaymentPaypal,
			userIdBeneficiaryData,
			userIdVerifierData,
			closeModal,
			setUserMain,
			dataUserRoles,
			notifications,
			joinUserPrincipal,
			updateNotification,
			withBuySpace,
			checkerSelected,
			setCheckerSelected,
			editCheckerByUserId,
		} = this.props;
		const { mobileMenuVisible, itemBuyForm, statusProfile, role } = this.state;
		const beneficiary =
			dataUserRoles.length > 0 ? statusProfile : userIdBeneficiaryData;
		const verifier = userIdVerifierData;

		return (
			<div
				onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}
				onMouseDown={this.toggleformBeneficiariesAction}
				tabIndex={0}
			>
				<Fragment>
					{beneficiary === 1 || verifier === 2 ? (
						<Nav absolute>
							<Anav>
								<Link to='/' onClick={this.toggleformBeneficiariesOff}>
									<img
										src={logoUrl === "" || logoUrl === "null" ? logo : logoUrl}
										alt='Logo Flüie'
										className='logo'
									/>
								</Link>
							</Anav>
							<Ul actived={mobileMenuVisible}>
								<div className='links'>
									{dataUserRoles.length > 1
										? ""
										: joinUserPrincipal && (
												<Link
													to=''
													onClick={() => this.props.toggleModal("joinMain")}
												>
													{t("menu.joinMain")}
												</Link>
										  )}

									<div className='notification'>
										<div className='setting'>
											<span className='left'>
												<span onClick={this.toggleTooltipConfig} />
											</span>
										</div>
									</div>
								</div>
							</Ul>
							<div className='notificationFull'>
								<div
									className='bell cursorPointer'
									onClick={this.toggleTooltipNotification}
								>
									<ButtonNotification number={notifications.length} />
								</div>
								<div className='setting'>
									<span>
										<span onClick={this.toggleTooltipConfig} />
									</span>
								</div>
							</div>
							<Link className='userIcon' to={routes.PROFILE}>
								<div className='userIcon'>
									<span>
										<span />
									</span>
								</div>
							</Link>

							<Tooltip
								isActive={this.state.tooltipConfig}
								toggleTooltipConfig={this.toggleTooltipConfig}
								responsive
							>
								<li className='clearFix'>
									<div className='langText'>{t("menu.language")}</div>
									<DropDown
										choices={langs}
										defaultValue={currentLang}
										onChange={(value) => {
											setLanguage(value.value, value.id);
										}}
									/>
								</li>
								<li>
									<span
										onClick={() => {
											this.toggleSetActivedModalChangePass();
											this.toggleTooltipConfig();
										}}
									>
										{t("menu.changePassword")}
									</span>
									<Link to='/' onClick={() => handleLogout()}>
										{t("menu.signOut")}
									</Link>
								</li>
							</Tooltip>
							<Tooltip
								left='180px'
								className='notification'
								isActive={this.state.tooltipNotification}
								toggleTooltipConfig={this.toggleTooltipNotification}
							>
								<div className='titleNotification'>
									<h4 className='title'>{t("nav.notification")}</h4>
								</div>
								{!notifications.length ? (
									<ul className='dropdownNotifications'>
										<li className='colorTopbar'>{t("nav.notNotifications")}</li>
									</ul>
								) : (
									<div className='container-notifications'>
										{notifications &&
											notifications.length &&
											notifications.map((notification, index) => (
												<ItemNotification
													key={index}
													updateNotification={() =>
														notification && updateNotification(notification.id)
													}
													withAction={
														(notification &&
															notification.typeNotificationId === 3) ||
														(notification &&
															notification.typeNotificationId === 4 &&
															this.getRoleLogin() !== 1)
													}
													onClick={() =>
														this._actionNotification(
															notification && notification.typeNotificationId,
															notification && notification,
														)
													}
													isAbsence={
														this.getRoleLogin() !== 1 &&
														notification &&
														notification.typeNotificationId === 6
															? true
															: false
													}
													onClickClose={() =>
														this.getRoleLogin() !== 1 &&
														this._actionNotificationAbsenceChange(
															notification.id,
															1,
														)
													}
													userName={notification && notification.userName}
													imageUrl={
														notification &&
														notification.imageUrl &&
														notification &&
														notification.imageUrl.split("/").length > 1 &&
														notification &&
														notification.imageUrl
													}
													message={notification && notification.message}
													time={moment(
														notification && notification.createdAt,
													).fromNow()}
												/>
											))}
									</div>
								)}
							</Tooltip>
							<Tooltip
								top='115px'
								right='536px'
								className='notification'
								isActive={this.state.dropDownLifeGuide}
								toggleTooltipConfig={this.toggleDropDownLifeGuide}
							>
								<ul
									onClick={() => {
										this.toggleDropDownLifeGuide();
									}}
									className='lifeGuideToolTip'
								>
									<li>
										<Link to={routes.WHAT_IS_IT}>{t("menu.whatIsIt")}</Link>
									</li>
									<li onClick={this.toggleEventPage}>
										<Link to={routes.EVENT_MESSAGE}>{t("menu.events")}</Link>
									</li>
									<li onClick={this.toggleEventPage}>
										<Link to={routes.TIPS_MESSAGE}>{t("menu.advice")}</Link>
									</li>
								</ul>
							</Tooltip>
							<div className='notificationResponsive'>
								<div onClick={this.toggleTooltipNotification}>
									<ButtonNotification number={notifications.length} />
								</div>
								<div className='setting'>
									<span>
										<span onClick={this.toggleTooltipConfig} />
									</span>
								</div>
							</div>
							{modal === "joinMain" && (
								<Modal
									fullScreen
									width='fit-content'
									padding='40px'
									active={modal === "joinMain"}
									toggle={() => closeModal("")}
								>
									<ForgotPasswordModal>
										<JoinUserMain
											toggle={() => closeModal("")}
											actionConfirm={() => setUserMain(true)}
										/>
									</ForgotPasswordModal>
								</Modal>
							)}
							{(modal === "isActivedmodalCreateBeneficiare" ||
								modal === "isActivedmodalCreateVerefier") && (
								<Modal fullScreen active toggle={() => setModal("")} top='90px'>
									<CreateBeneficiaryAndVerifier
										onSubmit={
											modal === "isActivedmodalCreateBeneficiare"
												? setBeneficiaryByUserId
												: setCheckerByUserId
										}
										relationships={relationships}
										isActivedmodalCreateBeneficiare={
											modal === "isActivedmodalCreateBeneficiare"
										}
									/>
								</Modal>
							)}

							{(modal === "isEditBeneficiare" || modal === "isEditChecker") && (
								<Modal fullScreen active toggle={() => setModal("")} top='90px'>
									<CreateBeneficiaryAndVerifier
										initialValues={beneficiarySelected}
										onSubmit={
											modal === "isEditBeneficiare"
												? editBeneficiaryByUserId
												: setCheckerByUserId
										}
										relationships={relationships}
										isActivedmodalCreateBeneficiare={
											modal === "isEditBeneficiare"
										}
									/>
								</Modal>
							)}
						</Nav>
					) : (
						<Nav absolute>
							<Anav>
								<Link to='/' onClick={this.toggleformBeneficiariesOff}>
									<img
										src={logoUrl === "" || logoUrl === "null" ? logo : logoUrl}
										alt='Logo Flüie'
										className='logo'
									/>
								</Link>
							</Anav>
							<Ul actived={mobileMenuVisible}>
								<div className='links'>
									<Link
										to={routes.HOME}
										onClick={() => {
											this.toggleformBeneficiariesOff();
											this.toggleMenu();
										}}
									>
										{t("menu.home")}
									</Link>
									<span onClick={this.toggleformBeneficiaries}>
										{t("menu.beneficiaries")}
									</span>
									<span onClick={this.toggleformVerifiers}>
										{t("menu.verifiers")}
									</span>
									<Link
										to={routes.TUTORIALS}
										onClick={() => {
											this.toggleformBeneficiariesOff();
											this.toggleMenu();
										}}
									>
										{t("menu.tutorials")}
									</Link>
									<span className='navPlus' onClick={this.toggleNav} />
									<Tooltip
										width='213px'
										left='180px'
										isActive={this.state.nav}
										toggleTooltipConfig={this.toggleNav}
									>
										<div className='tooltipNav'>
											<Link
												to={routes.WRITTEN_MESSAGE}
												onClick={this.toggleMenu}
											>
												{t("menu.createMessage")}
											</Link>

											<Link to={routes.RECORD} onClick={this.toggleMenu}>
												{t("menu.record")}
											</Link>
											<span onClick={this.toggleDropDownLifeGuide}>
												{t("menu.lifeGuide")}
											</span>
										</div>
									</Tooltip>
									<div className='notification'>
										<div className='setting'>
											<span className='left'>
												<span onClick={this.toggleTooltipConfig} />
											</span>
										</div>
									</div>
								</div>
							</Ul>
							<div className='notificationFull'>
								<div
									className='bell cursorPointer'
									onClick={this.toggleTooltipNotification}
								>
									<ButtonNotification number={notifications.length} />
								</div>
								<div className='setting'>
									<span>
										<span onClick={this.toggleTooltipConfig} />
									</span>
								</div>
							</div>
							<Link className='userIcon' to={routes.PROFILE}>
								<div className='userIcon'>
									<span>
										<span />
									</span>
								</div>
							</Link>

							<Tooltip
								isActive={this.state.tooltipConfig}
								toggleTooltipConfig={this.toggleTooltipConfig}
								responsive
							>
								<li className='clearFix'>
									<div className='langText'>{t("menu.language")}</div>
									<DropDown
										choices={langs}
										defaultValue={currentLang}
										onChange={(value) => {
											setLanguage(value.value, value.id);
										}}
									/>
								</li>
								<li>
									<span
										onClick={() => {
											this.toggleSetActivedModalChangePass();
											this.toggleTooltipConfig();
										}}
									>
										{t("menu.changePassword")}
									</span>
									<Link to='/' onClick={() => handleLogout()}>
										{t("menu.signOut")}
									</Link>
								</li>
							</Tooltip>
							{/* notification user main */}
							<Tooltip
								left='180px'
								className='notification'
								isActive={this.state.tooltipNotification}
								toggleTooltipConfig={this.toggleTooltipNotification}
							>
								<div className='titleNotification'>
									<h4 className='title'>{t("nav.notification")}</h4>
								</div>
								{!notifications.length ? (
									<ul className='dropdownNotifications'>
										<li className='colorTopbar'>{t("nav.notNotifications")}</li>
									</ul>
								) : (
									<div className='container-notifications'>
										{notifications.length &&
											notifications.map((notification, index) => (
												<ItemNotification
													key={index}
													updateNotification={() =>
														notification && updateNotification(notification.id)
													}
													withAction={
														(notification &&
															notification.typeNotificationId === 3) ||
														(notification &&
															notification.typeNotificationId === 4 &&
															this.getRoleLogin() !== 1)
													}
													onClick={() =>
														this._actionNotification(
															notification && notification.typeNotificationId,
															notification && notification,
														)
													}
													isAbsence={
														this.getRoleLogin() !== 1 &&
														notification &&
														notification.typeNotificationId === 6
															? true
															: false
													}
													onClickClose={() =>
														this.getRoleLogin() !== 1 &&
														this._actionNotificationAbsenceChange(
															notification.id,
															1,
														)
													}
													userName={
														(notification && notification.userName) || ""
													}
													imageUrl={
														notification &&
														notification.imageUrl &&
														notification &&
														notification.imageUrl.split("/").length > 1 &&
														notification &&
														notification.imageUrl
													}
													message={notification && notification.message}
													time={moment(
														notification && notification.createdAt,
													).fromNow()}
												/>
											))}
									</div>
								)}
							</Tooltip>
							<Tooltip
								top='115px'
								right='536px'
								className='notification'
								isActive={this.state.dropDownLifeGuide}
								toggleTooltipConfig={this.toggleDropDownLifeGuide}
							>
								<ul
									onClick={() => {
										this.toggleDropDownLifeGuide();
									}}
									className='lifeGuideToolTip'
								>
									<li>
										<Link to={routes.WHAT_IS_IT}>{t("menu.whatIsIt")}</Link>
									</li>
									<li onClick={this.toggleEventPage}>
										<Link to={routes.EVENT_MESSAGE}>{t("menu.events")}</Link>
									</li>
									<li onClick={this.toggleEventPage}>
										<Link to={routes.TIPS_MESSAGE}>{t("menu.advice")}</Link>
									</li>
								</ul>
							</Tooltip>
							<div className='notificationResponsive'>
								<div onClick={this.toggleTooltipNotification}>
									<ButtonNotification number={notifications.length} />
								</div>
								<div className='setting'>
									<span>
										<span onClick={this.toggleTooltipConfig} />
									</span>
								</div>
								<MenuButton action={this.toggleMenu} />
							</div>
							{(modal === "isActivedmodalCreateBeneficiare" ||
								modal === "isActivedmodalCreateVerefier") && (
								<Modal fullScreen active toggle={() => setModal("")} top='90px'>
									<CreateBeneficiaryAndVerifier
										onSubmit={
											modal === "isActivedmodalCreateBeneficiare"
												? setBeneficiaryByUserId
												: setCheckerByUserId
										}
										relationships={relationships}
										isActivedmodalCreateBeneficiare={
											modal === "isActivedmodalCreateBeneficiare"
										}
									/>
								</Modal>
							)}
						</Nav>
					)}
					<InfoUser
						userName={
							user[0] &&
							`${user[0].firstName} ${
								user[0].lastName !== null ? user[0].lastName : ""
							}`
						}
						withBuySpace={withBuySpace}
						withRole={this.getRole()}
						setModalChangeRole={() => this.setState({ modalChangeRole: true })}
						imageUrl={user[0] && user[0].imageUrl}
						spaceTotal={user[0] && user[0].spaceTotal}
						avaliableSpace={user[0] && user[0].avaliableSpace}
					/>
					<FormRight
						isActive={formBeneficiaries}
						toggle={this.toggleformBeneficiaries}
						titleFormRight={t("homeLogin.myBeneficiaries")}
						button={t("buttons.newbenificiary")}
						onClick={() => {
							setModal("isActivedmodalCreateBeneficiare");
							this.toggleformBeneficiaries();
						}}
					>
						{isComponentLoading === "beneficiaries" ? (
							<Facebook
								style={{ marginTop: "20px" }}
								height={280}
								width={280}
								speed={2.5}
								primaryColor='
								rgba(118, 35, 106, .8)
								'
								secondaryColor='rgba(251, 133, 25, .8)'
							/>
						) : (
							beneficiaries.map((itembeneficiary, key) => (
								<UserItem
									key={key}
									actionDelete={() => {
										setModal("deleteBeneficiary");
										setBeneficiarySelected(itembeneficiary);
									}}
									imageUrl={
										(itembeneficiary.beneficiaryUser &&
											itembeneficiary.beneficiaryUser.imageUrl) ||
										""
									}
									actionEdit={() => {
										const {
											id,
											relationshipId,
											beneficiaryUser: { email, firstName, lastName },
										} = itembeneficiary;
										setBeneficiarySelected({
											id,
											relationshipId,
											email,
											firstName,
											lastName,
										});
										setModal("isEditBeneficiare");
									}}
									userName={
										itembeneficiary.beneficiaryUser &&
										itembeneficiary.beneficiaryUser.firstName
									}
									active={
										itembeneficiary.active
											? t("homeLogin.active")
											: t("homeLogin.inactive")
									}
								/>
							))
						)}
					</FormRight>
					<FormRight
						isActive={formVerifiers}
						toggle={this.toggleformVerifiers}
						titleFormRight={t("homeLogin.myVerifiers")}
						button={t("buttons.newVerifier")}
						onClick={() => {
							setModal("isActivedmodalCreateVerefier");
							this.toggleformVerifiers();
						}}
					>
						{isComponentLoading === "checkers" ? (
							<Facebook
								style={{ marginTop: "20px" }}
								height={280}
								width={280}
								speed={2.5}
								primaryColor='
								rgba(118, 35, 106, .8)
								'
								secondaryColor='rgba(251, 133, 25, .8)'
							/>
						) : (
							checkers.map((checker, key) => (
								<UserItem
									key={key}
									actionDelete={() => {
										setModal("deleteCheker");
										setBeneficiarySelected(checker);
									}}
									actionEdit={() => {
										const {
											id,
											verifierUser: { email, firstName, phone, lastName },
										} = checker;
										setCheckerSelected({
											id,
											email,
											phone,
											firstName,
											lastName,
										});
										// setBeneficiarySelected({
										// 	id,
										// 	email,
										// 	firstName,
										// });
										setModal("isEditChecker");
									}}
									userName={
										checker.verifierUser && checker.verifierUser.firstName
									}
									active={checker.active ? "active" : "inactive"}
								/>
							))
						)}
					</FormRight>
					<FormRight
						isActive={formBuy}
						toggle={() => setFormBuy(false)}
						titleFormRight={t("homeLogin.packages")}
					>
						{spaces.map((space, key) => (
							<div key={key}>
								{true ? (
									<ItemBuyForm
										isSelected={() =>
											this.isSelectetitemBuyForm(space.id, space)
										}
										isActived={space.id === itemBuyForm}
										key={key}
										name={space.name}
										price={space.price}
										dateFinish={
											space.duration +
											" " +
											t("menu.month") +
											" " +
											space.space +
											"MB"
										}
									/>
								) : (
									<div className='packageAlert'>
										{t("homeLogin.limitSpace")}.
									</div>
								)}
							</div>
						))}
						<h2 className='txtBlue'>{t("homeLogin.paymentMethod")}</h2>

						{this.renderFormPayu()}

						<Button
							onClick={setPaymentPaypal}
							style={{ border: "1px solid #CCC" }}
							border
						>
							<i
								className='fab fa-paypal'
								style={{ fontSize: 35, color: "blue" }}
							/>
						</Button>
					</FormRight>
					<Modal
						active={changePassword}
						toggle={this.toggleSetActivedModalChangePass}
						width='fit-content'
						fullScreen
						padding='40px'
					>
						<ChangePassword>
							<ChangePasswordForm />
						</ChangePassword>
					</Modal>
					<Modal
						fullScreen
						active={isActivedModalTerms}
						toggle={this.toggleModalTerms}
					>
						<TermsAndConditions>
							<h3>{t("home.terms-and-conditions.title")}</h3>
							<div dangerouslySetInnerHTML={{ __html: `${termsConditions}` }} />
							<button onClick={this.toggleModalTerms}>
								{t("home.terms-and-conditions.button")}
							</button>
						</TermsAndConditions>
					</Modal>
					{(modal === "isEditBeneficiare" || modal === "isEditChecker") && (
						<ContainerForm>
							<Modal
								fullScreen
								active
								toggle={() => setModal("")}
								top='90px'
								confirm
							>
								{console.log("checkerSelected", modal)}
								<CreateBeneficiaryAndVerifier
									initialValues={
										(modal === "isEditBeneficiare" && beneficiarySelected) ||
										(modal === "isEditChecker" && checkerSelected)
									}
									onSubmit={
										modal === "isEditBeneficiare"
											? editBeneficiaryByUserId
											: editCheckerByUserId
									}
									relationships={relationships}
									isActivedmodalCreateBeneficiare={
										modal === "isEditBeneficiare"
									}
								/>
							</Modal>
						</ContainerForm>
					)}
					{modal === "deleteBeneficiary" && (
						<ModalConfirm
							toggle={() => setModal("")}
							actionConfirm={() => deleteBeneficiary(false)}
							title={t("home.modal.confirm.removeBeneficiary")}
							description={`${t(
								"home.modal.confirm.descriptionBeneficiaryFirst",
							)}
							 ${beneficiarySelected.beneficiaryUser.email} ${t(
								"home.modal.confirm.descriptionBeneficiarySecond",
							)}`}
						/>
					)}
					{modal === "deleteCheker" && (
						<ModalConfirm
							toggle={() => setModal("")}
							actionConfirm={() => deleteBeneficiary(true)}
							title={t("home.modal.confirm.removeChecker")}
							description={`${t(
								"home.modal.confirm.descriptionBeneficiaryFirst",
							)}
							 ${beneficiarySelected.verifierUser.email} ${t(
								"home.modal.confirm.descriptionCheckerSecond",
							)}`}
						/>
					)}
					{message === "succesJoinMain" && (
						<MessageResponse
							isActived
							message={t("messagesResponse.successJoinMain")}
						/>
					)}
					{message === "updateSuccess" && (
						<MessageResponse
							isActived
							message={t("messagesResponse.updateSucces")}
						/>
					)}

					{message === "deleteBeneficiaryOrCheckerSuccess" && (
						<MessageResponse
							isActived
							message={t("messagesResponse.deleteSucces")}
						/>
					)}
					{message === "deleteBeneficiaryOrCheckerError" && (
						<MessageResponse isActived bgAlert='red' message='Error' />
					)}
					{message === "setRelationsSuccess" && (
						<MessageResponse
							isActived
							message={t("messagesResponse.success")}
						/>
					)}
					{message === "setRelationsError" ||
						(message === "setRelationsErrorC" && (
							<MessageResponse
								isActived
								bgAlert='red'
								message={
									message === "setRelationsErrorC"
										? t("messagesResponse.errorBeneficiary")
										: t("messagesResponse.errorChecker")
								}
							/>
						))}
					{modal === "acceptBeneficiary" && (
						<AcceptBeneficiary active toggle={() => setModal("")} />
					)}
					{modal === "acceptVerifier" && (
						<AcceptVerifier active toggle={() => setModal("")} />
					)}
					{modal === "changeAbsenceUser" && (
						<ChangeAbsence active toggle={() => setModal("")} />
					)}

					{this.state.modalChangeRole && (
						<ModalChangeRole
							currentRole={role}
							active
							roles={this.props.user[0] && this.props.user[0].assignedRoles}
							closeModal={() =>
								this.setState({
									modalChangeRole: false,
								})
							}
						/>
					)}
				</Fragment>
			</div>
		);
	}
}

function mapStateToProps(store) {
	return {
		termsConditions: store.home.homes[0]
			? store.home.homes[0].termsConditions
			: [],
		notifications: store.user.notifications,
		logoUrl: store.home.homes[0] ? store.home.homes[0].logoUrl : "",
		formBeneficiaries: store.home.formBeneficiaries,
		formVerifiers: store.home.formVerifiers,
		formBuy: store.app.formBuy,
		joinUserPrincipal: store.app.joinUserPrincipal,
		changePassword: store.profile.isActivedModalChangePass,
		isActivedmodalCreateBeneficiare:
			store.profile.isActivedmodalCreateBeneficiare,
		isActivedmodalCreateVerefier: store.profile.isActivedmodalCreateVerefier,
		isActivedModalTerms: store.app.modalTermsAndConditions,
		beneficiaries: store.user.beneficiaries || [],
		checkers: store.user.checkers || [],
		beneficiarySelected: store.user.beneficiarySelected || {},
		checkerSelected: store.user.checkerSelected || {},
		user: store.user.user || [],
		isComponentLoading: store.app.isComponentLoading,
		modal: store.app.modal,
		message: store.app.message,
		relationships: store.register.relationships || [],
		spaces: store.payment.spaces || [],
		withBuySpace: store.user.isUserMain,
		dataOrderPayment: store.user.dataOrderPayment,
		dataSignature: store.user.dataSignature,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setHomes: (homes) => dispatch(setHomes(homes)),
		handleLogout: () => dispatch(handleLogout()),
		setEventPage: (value) => dispatch(setEventPage(value)),
		setFormBeneficiaries: (value) => dispatch(setFormBeneficiaries(value)),
		setFormVerifiers: (value) => dispatch(setFormVerifiers(value)),
		setFormBuy: (value) => dispatch(setFormBuy(value)),
		setActivedModalChangePass: (value) =>
			dispatch(setActivedModalChangePass(value)),
		setActivedModalCreateVerifier: (value) =>
			dispatch(setActivedModalCreateVerifier(value)),
		setActivedModalCreateBeneficiare: (value) =>
			dispatch(setActivedModalCreateBeneficiare(value)),
		setModalTerms: (actived) => dispatch(setModalTerms(actived)),
		addBeneficiariesByUserId: (beneficiare) => {
			dispatch(addBeneficiariesByUserId(beneficiare));
		},
		editBeneficiaryByUserId: (beneficiare) => {
			dispatch(editBeneficiaryByUserId(beneficiare));
		},
		addChekerByUserId: (ckeker) => {
			dispatch(addBeneficiariesByUserId(ckeker, true));
		},
		editCheckerByUserId: (checker) => {
			dispatch(editCheckerByUserId(checker));
		},
		searchBeneficiareOrCheker: () => dispatch(searchBeneficiareOrCheker()),
		getUser: () => dispatch(getUser()),
		setModal: (value) => dispatch(setModal(value)),
		deleteBeneficiary: (value) => dispatch(deleteBeneficiary(value)),
		setBeneficiarySelected: (beneficiary) =>
			dispatch(setBeneficiarySelected(beneficiary)),
		setCheckerSelected: (checker) => dispatch(setCheckerSelected(checker)),
		setBeneficiaryByUserId: (beneficiary) =>
			dispatch(setBeneficiaryByUserId(beneficiary)),
		setCheckerByUserId: (checker) => dispatch(setCheckerByUserId(checker)),
		getRelationships: () => dispatch(getRelationships()),
		getSpaces: () => dispatch(getSpaces()),
		setSpaceSelectedDispatch: (idSpace) => dispatch(setSpaceSelected(idSpace)),
		setPaymentPaypal: () => dispatch(setPaymentPaypal()),
		getMessageByUser: () => dispatch(getMessageByUser()),
		closeModal: (value) => dispatch(setModal(value)),
		toggleModal: (value) => dispatch(setModal(value)),
		setUserMain: (value) => dispatch(setUserMain(value)),
		getNotifications: () => dispatch(getNotifications()),
		updateNotificationSocket: (value) =>
			dispatch(updateNotificationSocket(value)),
		setNotificationSelected: (value) =>
			dispatch(setNotificationSelected(value)),
		getRoleUserPrincipal: () => dispatch(getRoleUserPrincipal()),
		updateNotification: (notificationId) =>
			dispatch(updateNotification(notificationId)),
		getPaymentOrderDispatch: (data) => dispatch(getPaymentOrder(data)),
		getSignatureOrderDispatch: () => dispatch(getSignatureOrder()),
		setMessageDispatch: (name, message) => dispatch(setMessage(name, message)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NavLogin);
