/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* i18n */
import { t } from '../../i18n';
/* Actions */
import {
  setMessageAudio,
  getBasicMessageInformation,
  getFileTypes,
} from '../../redux/messages/actions';
/* Components */
import Button from '../../components/Button';
import Recorder from '../../components/Recorder';
import TypeFileUpload from '../../components/TypeFileUpload';

import InputRedux from '../../fields/Input';
import FileInput from '../../fields/FileInput';
import SelectRedux from '../../fields/SelectInput';
/* Styles */
import { ModalAudio, Form } from './styles';
/* Validate */
import validate from 'validate.js';
import rec from '../../assets/images/rec-audio.jpg';
import upload from '../../assets/images/upload-audio.jpg';
import { Container } from '../VideoMessage/styles';

const constrains = {
  title: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  evenType: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
};
class AudioMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioBlob: null,
      isActivedItem: '',
      type: '',
      nameFile: '',
    };
  }

  componentDidMount() {
    const { getFileTypes, getBasicMessageInformation } = this.props;
    getBasicMessageInformation();
    getFileTypes();
  }

  toggleItem = (item) =>
    this.setState((state) => ({
      isActivedItem: item !== state.isActivedItem ? item : '',
    }));

  recordBlob = (data) => {
    const audioBlob = new File([data], 'audio.webm');
    this.setState({ audioBlob });
  };
  onChange = (data) => data && this.setState({ type: data.target.value });
  onChangeFile = (data) =>
    data &&
    this.setState({
      nameFile: data.name,
    });

  render() {
    const { nameFile } = this.state;
    const {
      handleSubmit,
      onSubmit,
      messageTypes,
      availableSpace
      // fileTypes
    } = this.props;
    const { audioBlob, isActivedItem, type } = this.state;

    const messageType = messageTypes.find(
      (messageType) => messageType.code === 'audio'
    );

    const obtenerPesoArchivoEnMB = (size) => {
      const megabytes = size / (1024 * 1024);
      return megabytes.toFixed(2);
    };
    // const fileType = fileTypes.find(fileType => fileType.code === 'audio')
    return (
      <Form
        onSubmit={handleSubmit((values) =>
          onSubmit({
            ...values,
            fileUrl: audioBlob ? audioBlob : values.fileUrl,
            messageTypeId: messageType === undefined ? 4 : messageType.id,
            fileTypeId: 4,
          })
        )}
      >
        <div className="titleAndDate">
          <Field
            type="text"
            name="title"
            component={InputRedux}
            placeholder={t('writtenMessage.fields.title')}
          />
          {/* <Field name='eventTypeId' component='select'>
						<option>Selecionar tipo de evento</option>
						{eventTypes.map((eventType, key) => (
							<option key={key} value={eventType.id}>
								{eventType.name}
							</option>
						))}
					</Field> */}
          <Field
            onChange={this.onChange}
            name="evenType"
            component={SelectRedux}
          >
            <option>{t('fields.selectDate')}</option>
            <option value="absence">{t('fields.absances')}</option>
            <option value="date">{t('fields.dates')}</option>
          </Field>
          {type === 'date' && (
            <Field
              type="date"
              name="visibleData"
              component={InputRedux}
              placeholder={t('writtenMessage.fields.title')}
            />
          )}
        </div>
        <div className="actionMessage">
          <TypeFileUpload
            title={t('audioMessage.recording')}
            icon="fas fa-microphone"
            bgImg={rec}
            toggle={() => this.toggleItem('audio')}
          />
          <TypeFileUpload
            title={t('audioMessage.attachAudio')}
            icon="fas fa-volume-up"
            bgImg={upload}
            toggle={() => this.toggleItem('file')}
          />
        </div>
        {isActivedItem === 'audio' && (
          <ModalAudio>
            <Recorder recordBlob={this.recordBlob} />
          </ModalAudio>
        )}
        {isActivedItem === 'file' && (
          <ModalAudio>
            <label>{t('audioMessage.format')}</label>
            <Field
              nameFile={nameFile}
              component={FileInput}
              onChange={this.onChangeFile}
              name="fileUrl"
              type="file"
              accept=".mp3,.ogg, .wav"
            />
          </ModalAudio>
        )}
        {audioBlob && audioBlob.size > availableSpace && (
          <Container bgColor='red' isActived>
            <p>{t('messagesResponse.fileBigSize').replace('availableSpace', obtenerPesoArchivoEnMB(availableSpace)).replace('fileSize', obtenerPesoArchivoEnMB(audioBlob && audioBlob.size))}</p>
          </Container>
        )}

        <div className="containerBtnSubmit">
          <Button bgColor="#DF382F" bgColorHover="#892B6E" width="50%" disabled={audioBlob && audioBlob.size > availableSpace}>
            <i className="far fa-clock" /> {t('buttons.scheduleMessage')}
          </Button>
        </div>
      </Form>
    );
  }
}

AudioMessage.defaultProps = {
  isActivedItem: false,
};

AudioMessage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isActivedItem: PropTypes.bool,
};

function mapStateToProps(store) {
  const message = store.messages;
  return {
    eventTypes: message.eventTypes || [],
    messageTypes: message.messageTypes || [],
    fileTypes: message.fileTypes || [],
  };
}

function mapDispachtToProps(dispatch) {
  return {
    onSubmit: (messageWithFile, cb) =>
      dispatch(setMessageAudio(messageWithFile, cb)),
    getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
    getFileTypes: () => dispatch(getFileTypes()),
  };
}

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: async (values) => {
    await dispatchProps.onSubmit(values, () => ownProps.push('/'));
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'audioMessage',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(AudioMessage);
