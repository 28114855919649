// Dependencies
import React from 'react'
// Components
import Button from '../Button'
// Styled component
import { Container } from './styles'
// Assets
import verifiers from '../../assets/images/personPlus.png'

function FormRight (props) {
  const {
		titleFormRight,
		button,
		isActive,
		toggle,
		children,
		imgButton = verifiers,
		onClick
	} = props
  return (
    <Container isActive={isActive}>
      <div className='titleFormRight'>
        <span className='iconForm' />
        <h3>{titleFormRight}</h3>
        <div className='close' onClick={toggle}>
          <span />
        </div>
      </div>
      {button ? (
        <Button
          onClick={onClick}
          border
          borderColor='#211164'
          bgColorHover='rgba(33, 17, 100, .3)'
          txtColor='#211164'>
          {button}
          <img src={imgButton} alt='img' />
        </Button>
			) : null}
      {children}
    </Container>
  )
}

export default FormRight
