import INITIAL_STATE from './initialState'
import CONST from './const'

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CONST.LANGUAGES:
			return Object.assign(
				{},
				{
					...state,
					language: action.value
				}
			)
		case CONST.IS_COMPONENT_LOADING:
			return Object.assign(
				{},
				{
					...state,
					isComponentLoading: action.value
				}
			)
		case CONST.MESSAGE:
			return Object.assign(
				{},
				{
					...state,
					message: action.value
				}
			)
		case CONST.MODAL_TERMS_AND_CONDITIONS:
			return Object.assign(
				{},
				{
					...state,
					modalTermsAndConditions: action.value
				}
			)
		case CONST.MODAL_VIDEO:
			return Object.assign(
				{},
				{
					...state,
					modalVideo: action.value
				}
			)

		case CONST.EVENTS_PAGE:
			return Object.assign(
				{},
				{
					...state,
					eventsPage: action.value
				}
			)
		case CONST.ERROR:
			return Object.assign(
				{},
				{
					...state,
					error: action.value
				}
			)
		case CONST.LOADING:
			return Object.assign(
				{},
				{
					...state,
					isAppLoading: action.value
				}
			)
		case CONST.LOADING_OFF:
			return Object.assign(
				{},
				{
					...state,
					isAppLoading: action.value
				}
			)
		case CONST.AUTHORIZER_USER:
			return Object.assign(
				{},
				{
					...state,
					isLoginUser: action.value
				}
			)
		case CONST.FORM_BUY:
			return Object.assign(
				{},
				{
					...state,
					formBuy: action.value
				}
			)
		case CONST.ARROW:
			return Object.assign(
				{},
				{
					...state,
					arrow: action.value
				}
			)
		case CONST.LIFE_GUIDES:
			return Object.assign(
				{},
				{
					...state,
					lifeGuides: action.value
				}
			)
		case CONST.MODAL:
			return Object.assign(
				{},
				{
					...state,
					modal: action.value
				}
			)
		case CONST.USER_BENEFICIARY_DATA:
			return Object.assign(
				{},
				{
					...state,
					userIdBeneficiaryData: action.value
				}
			)
		case CONST.USER_VERIFIER_DATA:
			return Object.assign(
				{},
				{
					...state,
					userIdVerifierData: action.value
				}
			)
		case CONST.DATA_ASSIGIN_ROLE:
			return Object.assign(
				{},
				{
					...state,
					dataUserRoles: action.value
				}
			)
		case CONST.DATA_VERIFIER_USER_MAIN:
			return Object.assign(
				{},
				{
					...state,
					dataVerifierUserMain: action.value
				}
			)
		case CONST.RECOMMENDATIONS:
			return Object.assign(
				{},
				{
					...state,
					dataRecommendations: action.value
				}
			)
		case CONST.ADVICE_RECOMMENDATIONS:
			return Object.assign(
				{},
				{
					...state,
					adviceRecommendations: action.value
				}
			)
		case CONST.ADVICE_RECOMMENDATIONS_FILTER:
			return Object.assign(
				{},
				{
					...state,
					adviceRecommendationsFilter: action.value
				}
			)

		case CONST.RECOMMENDATION_SELECTED:
			return Object.assign(
				{},
				{
					...state,
					recommendationsSelected: action.value
				}
			)
		case CONST.RECOMMENDATION_EVENT_SELECTED:
			return Object.assign(
				{},
				{
					...state,
					recommendationEventSelected: action.value
				}
      )
    case CONST.ADVICE_RECOMMENDATION_SELECTED:
        return Object.assign(
          {},
          {
            ...state,
            recommendationAdviceSelected: action.value
          }
    ) 
		case CONST.JOIN_USER_PRINCIPAL:
			return Object.assign(
				{},
				{
					...state,
					joinUserPrincipal: action.value
				}
			)
		default:
			return state
	}
}
