/* Dependencies */
import styled from 'styled-components'
import { prop } from 'styled-tools'

export const Form = styled.form`  
	select,
	input {
		width: 100%;
		height: 50px;
		padding: 6px 20px;
		font-size: 13px;
		line-height: 1.42857143;
		color: '#999';
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 25px;
		border: 1px solid #342573;
	}
	.buttons{
	
		i {
			margin-right: 10px;
		}
	} 
	.containerButtonSubmit {
		margin-top: 20px;
		text-align: center;
	}
	.containerSearch {
		> div {
			> ul {
				li:first-child {
					width: 100%;
					height: 50px;
					padding: 6px 20px;
					font-size: 13px;
					line-height: 1.42857143;
					color: '#999';
					background-color: #fff;
					background-image: none;
					border: 1px solid #ccc;
					border-radius: 25px;
					border: 1px solid #342573;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 13px;
				}
			}
		}
	}
	.border {
		> ul {
			border: 1px solid #ccc;
			border-radius: 25px;
			border: 1px solid #342573;
		}
	}
	.actionMessage {
		margin: 0 12%;
		margin-top: 20px;
	}
	.containerImagePreview {
		width: 216px;
		height: 177px;
		margin-right: 10px;
	}
	.selectPhotos {
		width: 100%;
		height: 200px;
		margin-top: 60px;
		overflow-y: hidden;
		overflow-x: auto;
		border-width: 1px;
		border-style: solid;
		border-color: rgb(204, 204, 204);
		border-image: initial;
		border-radius: 4px;
		padding: 10px;
		margin-bottom: 45px;
		transition: all 0.4s ease-in-out;
		display: flex;
		align-items: center;
		> div {
			position: relative;
			button {
				position: absolute;
				right: 6px;
				z-index: 4;
				top: -4px;
				font-size: 20px;
				color: red;
			}
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
	textarea {
		width: 100%;
		margin-top: 20px;
	}
`

export const ModalAudio = styled.div`
	margin: 0 auto;
	margin-bottom: ${prop('bottom', '75px')};
	width: ${prop('width', '50%')};
	height: 80px;
	padding: 10px;
	text-align: center;
`
export const ContainerCam = styled.div`
	width: 50%;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 75px;
	> div {
		video,
		img {
			width: 360px;
			height: 200px;
		}
	}
`
export const ContainerVideo = styled.div`
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 75px;
`
