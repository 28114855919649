/* Dependencies */
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

export const SpanError = styled.div`
	color: red;
	width: fit-content;
	margin-left: 20px;
	margin-top: -15px;

	${ifProp(
		"checkbox",
		css`
			bottom: -12px;
			/* margin-left: 0; */
			position: absolute;
		`,
	)}
`;

export const Content = styled.div`
	${ifProp(
		"isCkeck",
		css`
			position: relative;
			input {
				width: 25px !important;
				height: 25px !important;
				margin: 0;
				margin-bottom: 10px;
			}
		`,
	)}
`;
