/* Dependecies */
import React from 'react';
import PropTypes from 'prop-types';
/* Styles */
import {
	ContentMain,
	ContentChild,
	ContentImage,
	ContentCenter,
	ContentRight,
	ContentLeft,
	Buttons,
	isActive,
	isTitle,
} from './styles';
import { t } from '../../i18n';

import img from '../../assets/images/male-user.png';

function UserItem({ userName, active, actionEdit, actionDelete, imageUrl }) {
	return (
		<ContentMain>
			<ContentChild>
				<ContentLeft>
					<ContentImage>
						<figure
							style={{
								width: '36%',
								height: '40px',
								backgroundSize: 'cover',
								borderRadius: '50%',
								backgroundRepeat: ' no-repeat',
								backgroundImage: `url(${
									imageUrl && imageUrl.split('/').length > 1 ? imageUrl : img
								})`,
							}}
						/>
					</ContentImage>
				</ContentLeft>
				<ContentCenter>
					<div>
						<isTitle>{userName}</isTitle>
					</div>
					<div>
						<isActive>{active}</isActive>
					</div>
				</ContentCenter>
				<ContentRight>
					<Buttons onClick={actionEdit}>{t('infoUser.edit')}</Buttons>
					<Buttons onClick={actionDelete}>{t('infoUser.remove')}</Buttons>
				</ContentRight>
			</ContentChild>
		</ContentMain>
	);
}

UserItem.propTypes = {
	userName: PropTypes.string.isRequired,
	active: PropTypes.string.isRequired,
	actionEdit: PropTypes.func.isRequired,
	actionDelete: PropTypes.func.isRequired,
	imageUrl: PropTypes.string,
};

export default UserItem;
