import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import backgroundImage from '../../assets/images/bg-fluie.jpg'
import { ifProp } from 'styled-tools'

export const Container = styled.section`
  width: 100%;
  height: 120%;
  position: relative;  
  z-index: 8;
  background-image: url(${backgroundImage});  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: none;
  .animation {
    animation: fadeInUp 1.7s ease-in-out;
    ${ifProp(
    'isActiveLogin',
    css`
      animation: fadeInUp 1.7s ease-in-out;
      display: none;   
    `
  )}
  }
  .buttonAnimation {
    animation: fadeInUp 1.7s ease-in-out;
    animation-delay: 0.5s;
  }
  .ButtonStore {
    height: 30px;
    margin: 0.8rem 0.3rem;
    display: inline-block;
    &:hover {
      animation: pulse 1s;
    }
  }
  .container-welcome {
    width: 100%;
    position: relative;
    z-index: 9;
    text-align: center;
    > p {
      color: #fff;
      font-size: 13px;
      line-height: 22px;
      margin: 0 0 10px;
    }
  }
  .box-btn {
    width: 100%;
    margin: 13px auto 183px;
    text-align: center;
  }
  .box-welcolme {
    width: 100%;
    max-width: 425px;
    margin: 0 auto;
    margin-top: 87px;
    text-align: center;
  }
  .box-logo {
    width: 270px;
    margin: -25px auto 5px;
  }
  .txt-welcome {
    width: 100%;
    font-family: 'Handlee', cursive;
    font-size: 39px;
    line-height: 36px;
    color: #fff;
    display: inline-block;
  }
  .box-btn-app {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  ${media.lessThan('medium')`
    display: block;
`}  
`
export const PlayResponsive = styled.button`
  margin-top: 10px auto;
  background-color: #ff8400;
  border-color: #ff8400;
  color: white;
  font-size: 0.5rem;
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  padding: 7px 0px 7px 2px;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  &:hover {
    animation: pulse 1s;
  }
`
