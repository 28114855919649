import React from 'react';
import { Container, ModalContent, ButtonClose } from './styles';

function Modal({
  active,
  toggle,
  fullScreen,
  children,
  txtAlign,
  top,
  width,
  messagePreview,
  confirm,
  zIndex,
  padding,
  recoverPassword,
}) {
  return (
    <Container active={active} top={top} zIndex={zIndex}>
      <div className="overlay animated fadeIn">
        <ModalContent
          className="animated fadeInDown scroll-text"
          fullScreen={fullScreen}
          recoverPassword={recoverPassword}
          txtAlign={txtAlign}
          top={top}
          width={width}
          confirm={confirm}
          padding={padding}
          messagePreview={messagePreview}
        >
          <ButtonClose
            onClick={toggle}
            fullScreen={fullScreen}
            confirm={confirm}
          >
            <i className="fas fa-times" />
          </ButtonClose>
          {children}
        </ModalContent>
      </div>
    </Container>
  );
}

export default Modal;
