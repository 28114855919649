// Dependencies
import React from 'react'
import { connect } from 'react-redux'
// Redux
import { handleAuth } from '../redux/user/actions'
import { setModal } from '../redux/app/actions'
// Component
import SingnIn from '../Auth/SingnIn'

function VisibleSingIn({ onSubmit, setModal }) {
	return (
		<SingnIn
			onSubmit={onSubmit}
			openModal={() => setModal('modalBeneficiareVerifier')}
		/>
	)
}

const mapStateToProps = store => ({
	isLoginUser: store.app.isLoginUser,
	errors: store.form.login ? store.form.login.syncErrors : []
})

const mapDispachtToProps = dispath => ({
	onSubmit: fields => {
		dispath(handleAuth(fields))
	},
	setModal: value => dispath(setModal(value))
})

export default connect(
	mapStateToProps,
	mapDispachtToProps
)(VisibleSingIn)
