import request from '../../libs/request';
import { getLanguageById } from '../../i18n';
import getFormDataObject from '../../utils/getFormatData';

const filterLanguage = JSON.stringify({
	where: {
		languageId: getLanguageById(),
	},
});
class RegisterService {
	//connect my endpoints
	endpoints = {
		gender: '/genders',
		beneficiary: '/beneficiaries-users',
		identificationType: '/identification-types',
		relationship: '/relationships',
		createUserFirebase: '/my-users/create-firebase-user',
		countries: '/countries',
		createMainUsersBV: '/my-users/create-main-user',
		updateUser: '/my-users',
		getBeneficiaryUser: '/beneficiaries-users',
		getVerifierUser: '/verifiers-users',
		updateWithFile: '/update-with-file',
		myUsers: '/my-users',
		testimonies: '/testimonies',
		dataRegisterBeneficiare: '/beneficiaries-users/get-date-register',
		dataRegisterVerifier: 'verifiers-users/get-date-register',
	};
	//create user
	setNewUserMain(mainUser, beneficiaryEmails, verifierEmails) {
		return request.post(this.endpoints.createMainUsersBV, {
			mainUser: { ...mainUser, address: mainUser.addres },
			beneficiaryEmails: beneficiaryEmails,
			verifierEmails: verifierEmails,
		});
	}
	setNewUserFirebase(dataUser) {
		const { email, password } = dataUser;
		return request.post(this.endpoints.createUserFirebase, {
			email,
			password,
		});
	}

	setUpdateUidUserMain(id, email, uidAuth, imageUrl) {
		const { myUsers, updateWithFile } = this.endpoints;
		request.patch(`${myUsers}/${id}`, {
			uidAuth,
		});
		const data = { email, imageUrl };

		return request.patch(
			`${myUsers}/${id}${updateWithFile}`,
			getFormDataObject(data),
		);
	}
	getBeneficiaryUser(code) {
		const filter = JSON.stringify({
			where: {
				code: code,
			},
		});
		return request.get(`${this.endpoints.getBeneficiaryUser}?filter=${filter}`);
	}
	getVerifierUser(code) {
		const filter = JSON.stringify({
			where: {
				code: code,
			},
		});
		return request.get(`${this.endpoints.getVerifierUser}?filter=${filter}`);
	}

	setUpdateBeneficiary(userDataBeneficiary, data) {
		const beneficiaryId = userDataBeneficiary.beneficiaryId;
		request.patch(
			`${this.endpoints.getBeneficiaryUser}/${userDataBeneficiary.id}`,
			{
				active: true,
			},
		);
		return request.patch(`${this.endpoints.updateUser}/${beneficiaryId}`, {
			email: data.email,
			optionalEmail: data.optionalEmail,
			firstName: data.firstName,
			lastName: data.lastName,
			identificationNumber: data.identificationNumber,
			phone: data.phone,
			mobile: data.mobile,
			address: data.addres,
			countryId: data.countryId,
			identificationTypeId: data.identificationTypeId,
		});
	}
	setUpdateUid(beneficiaryId, uidAuth) {
		return request.patch(`${this.endpoints.updateUser}/${beneficiaryId}`, {
			uidAuth,
		});
	}
	setUpdateVerifier(userDataVerifier, data) {
		const verifierId = userDataVerifier.verifierId;
		request.patch(`${this.endpoints.getVerifierUser}/${userDataVerifier.id}`, {
			active: true,
		});
		return request.patch(`${this.endpoints.updateUser}/${verifierId}`, {
			email: data.email,
			optionalEmail: data.optionalEmail,
			firstName: data.firstName,
			lastName: data.lastName,
			identificationNumber: data.identificationNumber,
			phone: data.phone,
			mobile: data.mobile,
			address: data.addres,
			countryId: data.countryId,
			identificationTypeId: data.identificationTypeId,
		});
	}
	getIdentificationType() {
		return request.get(this.endpoints.identificationType);
	}
	getRelationship() {
		return request.get(
			`${this.endpoints.relationship}?filter=${filterLanguage}`,
		);
	}
	getByGenderBylenguaje() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
		});
		return request.get(`${this.endpoints.gender}?filter=${filter}`);
	}
	getFrequentQuestion() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
		});
		return request.get(`${this.endpoints.testimonies}?filter=${filter}`);
	}
	getByCountries() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
		});
		return request.get(`${this.endpoints.countries}?filter=${filter}`);
	}
	getTutorialsBySlug(slug) {
		const filter = JSON.stringify({
			where: {
				slug,
			},
		});
		return request.get(`${this.endpoints.tutorial}?filter=${filter}`);
	}

	getdataRegisterBeneficiare(identification) {
		return request.post(this.endpoints.dataRegisterBeneficiare, {
			identification,
		});
	}

	getdataRegisterVerifier(identification) {
		return request.post(this.endpoints.dataRegisterVerifier, {
			identification,
		});
	}

	getValidationTokenRegisterBeneficiare({ temporalToken, code }) {
		const filter = {
			where: {
				and: [{ code }, { temporalToken }, { active: false }],
			},
		};
		return request.get(
			`${this.endpoints.beneficiary}?filter=${JSON.stringify(filter)}`,
		);
	}
	getValidationTokenRegisterVerifier({ temporalToken, code }) {
		const filter = {
			where: {
				and: [{ code }, { temporalToken }, { active: false }],
			},
		};
		return request.get(
			`${this.endpoints.getVerifierUser}?filter=${JSON.stringify(filter)}`,
		);
	}
	acceptRelation(code) {
		return request.patch('/beneficiaries-users/accept-beneficiary-invitation', {
			code,
		});
	}

	acceptRelationVerifier(code) {
		return request.patch('/verifiers-users/accept-verifier-invitation', {
			code,
		});
	}

	getDataUser(id) {
		const filter = {
			where: {
				id,
			},
		};
		return request.get(
			`${this.endpoints.myUsers}?filter=${JSON.stringify(filter)}`,
		);
	}

	getValidationUserMain(email) {
		const filterMain = {
			where: {
				email,
			},
		};
		return request.get(
			`${this.endpoints.myUsers}?filter=${JSON.stringify(filterMain)}`,
		);
	}
}

export default new RegisterService();
