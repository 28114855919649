import request from '../../libs/request'
import { getLanguageById } from '../../i18n'


const filterLanguage = 
	{where: {
		languageId: getLanguageById()
	}}

/**
 * Get information about the home model
 */
class HomeService {
	endpoint = '/homes'
	sliderChecker = '/slider-checkers'
	sliderBeneficiare = '/slider-beneficiares'
	/**
	 * @param  {object} filter
	 * @returns {Promise<*>}
	 */
	getHomes(filter = {}) {
		return request.get(`${this.endpoint}?filter=${JSON.stringify(filter)}`)
	}
	getSlidersCheckers(){
		return request.get(`${this.sliderChecker}?filter=${JSON.stringify(filterLanguage)}`)
	}

	getSlidersBeneficiares(){
		return request.get(`${this.sliderBeneficiare}?filter=${JSON.stringify(filterLanguage)}`)
	}
}

export default new HomeService()
