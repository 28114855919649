/* Dependencies */
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const Button = styled.button`
  font-size: 15px;
  padding: 7.5px 20px;
  border-radius: 25px;
  margin-top: ${prop('marginTop', '0')};
  margin-left: ${prop('marginLeft', '0')};
  background-color: ${prop('bgColor', '#211261')};
  color: ${prop('txtColor', '#fff')};
  line-height: 1.42857143;
  cursor: pointer;
  width: ${prop('width', '100%')};
  text-align: center;
  border: none;
  transition: background 0.5s linear 0s;
  :hover {
    color: ${prop('txtColorHover', 'none')};
    background-color: ${prop('bgColorHover', 'none')};
    animation: pulse 1s;
  }
  :disabled {
    pointer-events: none;
    opacity: .8
  }
  ${ifProp(
    'border',
    css`
      background: ${prop('bgColor', 'transparent')};
      border: 1px solid ${prop('borderColor', 'white')};
      :hover {
        border: 1px solid ${prop('bgBorderHover', 'none')};
      }
    `
  )}
  ${ifProp(
    'isTransparent',
    css`
      background-color: transparent;
      width: fit-content;
      padding: 0;
    `
  )}
`;
export default Button;
