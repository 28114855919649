import request from '../../libs/request';

class Service {
  getOrders(userId) {
    const filter = JSON.stringify({
      where: { userId, paid: true },
      include: ['space'],
      order: 'id DESC',
    });
    return request.get(`/orders?filter=${filter}`);
  }
}

export default new Service();
