/* Dependencies */
import React from 'react'
import Input from '../../components/Input'
/* Styles */
import { SpanError, Content } from './styles'
const InputRedux = ({ input, placeholder, type, meta: { touched, error } }) => (
	<Content isCkeck={type === 'checkbox'}>
		<Input {...input} type={type} placeholder={placeholder} />
		{touched &&
			(error && (
				<SpanError checkbox={type === 'checkbox'}>
					{error[0]
						.split('-')
						.filter((_, index) => index !== 0)
						.toString() || error}
				</SpanError>
			))}
	</Content>
)

export default InputRedux
