// Dependencies
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
// i18n
import { t } from '../i18n'
// Components
import Button from '../components/Button'
import InputRedux from '../fields/Input'
import InputPassword from '../fields/InputPassword'
// Validate
import validate from 'validate.js'


const constrains = {
	email: {
		email: { message: t('validations.login.email') },
		presence: { message: t('validations.login.required') }
	},
	password: {
		presence: { message: t('validations.login.required') },
		length: {
			minimum: 6,
			message: t('validations.login.wrong-password')
		}
	}
}

class SingnIn  extends Component {
	render(){		
		const { handleSubmit, openModal } = this.props
		return (
			<form onSubmit={handleSubmit}>
				<Field
					type='email'
					name='email'
					id='email'
					placeholder='E-mail'
					component={InputRedux}
				/>

				<Field					
					name='password'				
					placeholder='Password'
					component={InputPassword}
				/>		
				<div className='enterForm'>
					<Button type='submit'>{t('home.login.enter')}</Button>
					<Button type='button' onClick={openModal} className='button-full'>
						{t('buttons.popVerifierAnd')}
					</Button>
				</div>
			</form>
		)
	}
}
	


const SingnInTemp = reduxForm({
	form: 'login',
	validate: values => validate(values, constrains)
})(SingnIn)

export default SingnInTemp
