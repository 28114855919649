// Dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// i18n
import { t } from "../../i18n";
// CONST ROUTES
import { routes } from "../../routes";
// Styles Components
import { ContainerLateralMenu, BoxLateralMenu } from "./styles";
import { getMenssageBeneficiary } from "../../redux/user/actions";

function LateralMenu({
	right,
	titles,
	countMessages,
	countMessageAdvice,
	countMessageEvent,
	user,
	getMenssageBeneficiaryMain,
}) {
	const location = useLocation();
	console.log(location.pathname);
	const [saveFilterName, setSaveFilterName] = useState("");
	const [data, setData] = useState({
		newMessages: 0,
		messagesFutures: 0,
		messagesAll: 0,
		messagesRead: 0,
		events: 0,
		advice: 0,
	});
	useEffect(() => {
		const userId = user.length ? user[0].id : 0;
		axios
			.get(`${process.env.REACT_APP_API_URL}/messages/${userId}/count`)
			.then(({ data }) => {
				setData(data);
			});
	}, [user, saveFilterName]);

	useEffect(() => {
		const query = location.search;
		if (!query) {
			return;
		}

		if (query.substring(query.length - 1) === "#") {
			return;
		}
		const filter = query.split("=")[1];
		setSaveFilterName(filter);

		getMenssageBeneficiaryMain(filter);
	}, [location]);

	return (
		<ContainerLateralMenu right={right}>
			<h4 className='titleLateral'>
				{titles === 1 ? t("menu.inbox") : t("menu.record")}
			</h4>
			<BoxLateralMenu>
				<div className='itemLateral'>
					{titles === 1 ? (
						<div>
							<Link className='test' to={routes.HOME + "?type=NEW"}>
								<div>
									<p className='hiddenXs'>{t("menu.newMessages")}</p>
								</div>
								<span className='number'>{data.newMessages || 0}</span>
							</Link>

							<Link className='test' to={routes.HOME + "?type=FUTURE"}>
								<div>
									<p className='hiddenXs'>{t("menu.messagesFutures")}</p>
								</div>
								<span className='number'>{data.messagesFutures || 0}</span>
							</Link>
							<Link className='test' to={routes.HOME + "?type=ALL"}>
								<div>
									<p className='hiddenXs'>{t("menu.messagesAll")}</p>
								</div>
								<span className='number'>{data.messagesAll || 0}</span>
							</Link>
							<Link className='test' to={routes.HOME + "?type=READED"}>
								<div>
									<p className='hiddenXs'>{t("menu.messagesRead")}</p>
								</div>
								<span className='number'>{data.messagesRead || 0}</span>
							</Link>

							<h4 className='titleLateral'>{t("menu.lifeGuide")}</h4>
							<Link className='test' to={routes.HOME + "?type=EVENTS"}>
								<div>
									<p className='hiddenXs'>{t("menu.events")}</p>
								</div>
								<span className='number'>{data.events || 0}</span>
							</Link>
							<Link className='test' to={routes.HOME + "?type=ADVICE"}>
								<div>
									<p className='hiddenXs'>{t("menu.advice")}</p>
								</div>
								<span className='number'>{data.advice || 0}</span>
							</Link>
						</div>
					) : (
						<div>
							<Link className='test' to={routes.RECORD}>
								<div>
									<span className='iconHistorial iconMessage' />
									<p className='hiddenXs'>{t("menu.messages")}</p>
								</div>
								<span className='number'>{countMessages}</span>
							</Link>
							<Link className='test' to={routes.HISTORY_EVENTOS}>
								<div>
									<span className='iconHistorial iconEvents' />
									<span className='arrow' />
									<p className='hiddenXs'>{t("menu.events")}</p>
								</div>
								<span className='number'>{countMessageEvent}</span>
							</Link>
							<Link className='test' to={routes.HISTORY_ADVICE}>
								<div>
									<span className='iconHistorial iconTips' />
									<p className='hiddenXs'>{t("menu.advice")}</p>
								</div>
								<span className='number'>{countMessageAdvice}</span>
							</Link>
						</div>
					)}
				</div>
			</BoxLateralMenu>
		</ContainerLateralMenu>
	);
}

function mapStateToProps(store) {
	const { user } = store;
	return {
		user: user.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getMenssageBeneficiaryMain: (filter) =>
			dispatch(getMenssageBeneficiary(filter)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps, null)(LateralMenu);
