import styled from "styled-components";
import { prop } from "styled-tools";

export const Inputl = styled.input`
	width: 100%;
	height: ${prop("height", "50px")};
	padding: 6px 20px;
	margin-bottom: 15px;
	font-size: 14.5px;
	line-height: 1.42857143;
	color: #999;
	background-color: #fff;
	border: 1px solid #342573;
	border-radius: 25px;
	font-size: 16px;
	color: #000;
	:focus {
		border-color: #66afe9;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px rgba(102, 175, 233, 0.6);
	}
`;
