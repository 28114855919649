/* Dependencies */
import React, { Fragment, Component } from "react";
import { Field } from "redux-form";
import Select from "react-select";
import VideoRecorder from "react-video-recorder";
import PropTypes from "prop-types";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
/* i18n  */
import { t } from "../../i18n";
/* Fields */
import InputRedux from "../../fields/Input";
import FileInput from "../../fields/FileInput";
import TextArea from "../../fields/TextArea";
import Button from "../../components/Button";
import TypeFileUpload from "../../components/TypeFileUpload";
import Recorder from "../../components/Recorder";

/* Assets */
import take from "../../assets/images/take-pic.jpg";
import upload from "../../assets/images/upload-pic.jpg";
import rec from "../../assets/images/rec-audio.jpg";
import audioleft from "../../assets/images/upload-audio.jpg";
import recVideo from "../../assets/images/rec-video.jpg";
import uploadVideo from "../../assets/images/upload-video.jpg";
/* utils */
import getDataURItoBlob from "../../utils/getDataURItoBlob";
/* Styles */
import { ModalAudio, ContainerCam, Form, ContainerVideo } from "./styles";

import withStore from "./store";
import { ContainerSearch } from "../../components/Message/styles";

class EventMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			type: "written",
			data: [],
			isActivedItem: "",
			isSubmit: true,
			beneficiaries: [],
			beneficiaryId: null,
			date: "",
			nameFile: "",
		};
	}
	componentDidMount() {
		const {
			getFileTypes,
			getBasicMessageInformation,
			getAllBeneficiaryOnlyId,
			getAdviceRecommendations,
		} = this.props;
		Promise.all([
			getBasicMessageInformation(),
			getFileTypes(),
			getAllBeneficiaryOnlyId(),
			getAdviceRecommendations(),
		]);
	}
	onChange = (data) => data && this.setState({ type: data.target.value });
	onChangeDate = (data) => data && this.setState({ date: data.target.value });
	onChangeFile = (event) =>
		this.setState((state) => ({
			id: state.id + 1,
			nameFile: event.name,
			data: [
				...state.data,
				{ id: state.id, data: event, url: URL.createObjectURL(event) },
			],
		}));

	onChangeVideo = (e) => {
		this.setState({
			data: [{ data: e, url: URL.createObjectURL(e) }],
			isSubmit: true,
		});
	};

	addImage = (image) => {
		const data = getDataURItoBlob(image);
		this.setState((state) => ({
			id: state.id + 1,
			data: [...state.data, { id: state.id, data, url: image }],
		}));
	};

	removeImage = (id) =>
		this.setState((state) => ({
			data: state.data.filter((data) => data.id !== id),
		}));
	toggleItem = (type) => {
		type !== this.state.type &&
			this.setState({
				type: type,
				data: [],
				nameFile: "",
			});
	};
	toggleItemBox = (item) =>
		this.setState((state) => ({
			isActivedItem: item !== state.isActivedItem ? item : "",
		}));
	toggleData = (data) => this.setState({ data: [data] });
	toggleBeneficiaryId = (items) => {
		this.setState({ beneficiaryId: items.value });
	};

	toggleBeneficiaryIdAndRecommendation = (items) => {
		this.setState({ beneficiaryId: items.value });

		this.props.getAdviceByRelation(items);
	};

	render() {
		const {
			type,
			isActivedItem,
			data,
			isSubmit,
			beneficiaryId,
			date,
			nameFile,
		} = this.state;
		const {
			eventTypes,
			handleSubmit,
			onSubmit,
			beneficiariesIdName,
			adviceMessage,
			onSubmitAdvice,
			getRecommendationSelected,
			getAdviceRecommendationSelected,
			adviceTypes,
		} = this.props;

		return (
			<Form
				onSubmit={
					isSubmit
						? handleSubmit((values) => {
								getRecommendationSelected({
									target: {
										value: "",
									},
								});
								!adviceMessage
									? onSubmit({
											...values,
											beneficiaryId,
											fileUrl: data.length > 0 ? data : values.fileUrl,
											messageTypeId: type,
									  })
									: onSubmitAdvice({
											...values,
											beneficiaryId,
											fileUrl: data.length > 0 ? data : values.fileUrl,
											messageTypeId: type,
									  });
						  })
						: (e) => e.preventDefault()
				}
			>
				<div className='titleAndDate'>
					<div className='containerSearch'>
						{/* <Search
							items={beneficiariesIdName}
							placeholder={t('buttons.beneficiaryName')}
							maxSelected={3}
							onItemsChanged={
								!adviceMessage
									? this.toggleBeneficiaryId
									: this.toggleBeneficiaryIdAndRecommendation
							}
						/> */}
						<ContainerSearch>
							<Select
								placeholder={t("buttons.beneficiaryName")}
								options={beneficiariesIdName.map((item) => ({
									value: item.id,
									label: item.value,
								}))}
								value={this.beneficiaryId}
								isSearchable={false}
								blurInputOnSelect={false}
								isClearable={false}
								className='beneficiaries-select'
								classNamePrefix='select'
								noOptionsMessage={() => "No options"}
								onChange={
									!adviceMessage
										? this.toggleBeneficiaryId
										: this.toggleBeneficiaryIdAndRecommendation
								}
								style={{
									multiValue: {
										backgroundColor: "red",
									},
								}}
							/>
						</ContainerSearch>
					</div>
					{!adviceMessage ? (
						<Field
							name='eventTypeId'
							onChange={getRecommendationSelected}
							component='select'
						>
							<option>{t("fields.selectEvents")} </option>
							{eventTypes.length > 0 &&
								eventTypes.map(
									(eventType, key) =>
										eventType.events && (
											<option
												key={key}
												value={eventType.events.id ? eventType.events.id : 2}
											>
												{eventType.events.name ? eventType.events.name : "test"}
											</option>
										),
								)}
						</Field>
					) : (
						<Field
							name='eventTypeId'
							onChange={getAdviceRecommendationSelected}
							component='select'
						>
							<option>{t("fields.selectAdvide")}</option>
							{adviceTypes.length > 0 &&
								adviceTypes.map(
									(row, key) =>
										row.advices && (
											<option
												key={key}
												value={row.advices.id ? row.advices.id : 2}
											>
												{row.advices.name ? row.advices.name : "test"}
											</option>
										),
								)}
						</Field>
					)}
					<Field
						className='title'
						type='titleAdvice'
						name='title'
						component={InputRedux}
						placeholder={
							adviceMessage
								? t("tipsMessage.fields.titleAdvice")
								: t("eventMessage.fields.titleAdvice")
						}
					/>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row",
							gap: "3%",
						}}
					>
						<Field onChange={this.onChangeDate} name='type' component='select'>
							<option>{t("fields.selectDate")}</option>
							<option value='absence'>{t("fields.absances")}</option>
							<option value='date'>{t("fields.dates")}</option>
						</Field>
						{date === "date" && (
							<Field
								type='date'
								name='visibleData'
								component={InputRedux}
								placeholder={t("writtenMessage.fields.title")}
							/>
						)}
					</div>
				</div>
				<div className='buttons'>
					<div>
						<Button
							type='button'
							bgColor='rgb(33, 18, 97)'
							bgColorHover='rgb(137, 43, 110)'
							onClick={() => this.toggleItem("written")}
						>
							<i className='fas fa-file-alt' />{" "}
							<div className='txt'>{t("buttons.text")}</div>
						</Button>
						<div className='txtresponsive'>{t("buttons.text")}</div>
					</div>

					<div>
						<Button
							type='button'
							bgColor='rgb(33, 18, 97)'
							bgColorHover='rgb(137, 43, 110)'
							onClick={() => this.toggleItem("video")}
						>
							<i className='fas fa-video' />{" "}
							<div className='txt'>{t("buttons.video")}</div>
						</Button>
						<div className='txtresponsive'>{t("buttons.video")}</div>
					</div>
					<div>
						<Button
							type='button'
							bgColor='rgb(33, 18, 97)'
							bgColorHover='rgb(137, 43, 110)'
							onClick={() => this.toggleItem("audio")}
						>
							<i className='fas fa-volume-up' />
							<div className='txt'>{t("buttons.audio")}</div>
						</Button>
						<div className='txtresponsive'>{t("buttons.audio")}</div>
					</div>

					<div>
						<Button
							type='button'
							bgColor='rgb(33, 18, 97)'
							bgColorHover='rgb(137, 43, 110)'
							onClick={() => this.toggleItem("img")}
						>
							<i className='fas fa-image' />
							<div className='txt'>{t("buttons.photos")}</div>
						</Button>
						<div className='txtresponsive'>{t("buttons.photos")}</div>
					</div>
				</div>
				{type === "audio" && (
					<div>
						<div className='actionMessage'>
							<TypeFileUpload
								title={t("audioMessage.title")}
								icon='fas fa-microphone'
								bgImg={rec}
								toggle={() => this.toggleItemBox("rec")}
							/>
							<TypeFileUpload
								title={t("audioMessage.attachAudio")}
								icon='fas fa-volume-up'
								bgImg={audioleft}
								toggle={() => this.toggleItemBox("fileAudio")}
							/>
						</div>
						{isActivedItem === "rec" && (
							<ModalAudio>
								<Recorder recordBlob={this.toggleData} />
							</ModalAudio>
						)}
						{isActivedItem === "fileAudio" && (
							<ModalAudio>
								<label>{t("audioMessage.format")}</label>
								<Field
									nameFile={nameFile}
									onChange={this.onChangeFile}
									component={FileInput}
									name='fileUrl'
									type='file'
									accept='.mp3,.ogg, .wav'
								/>
							</ModalAudio>
						)}
					</div>
				)}
				{type === "img" && (
					<div>
						<div className='actionMessage'>
							<TypeFileUpload
								title={t("pictureMessage.takePhoto")}
								icon='fas fa-video'
								bgImg={take}
								toggle={() => this.toggleItemBox("audio")}
							/>
							<TypeFileUpload
								title={t("pictureMessage.attachImage")}
								icon='fas fa-image'
								bgImg={upload}
								toggle={() => this.toggleItemBox("file")}
							/>
						</div>
						{isActivedItem === "audio" && (
							<ContainerCam>
								<Camera
									onTakePhoto={(dataUri) => {
										this.addImage(dataUri);
									}}
									idealFacingMode={FACING_MODES.ENVIRONMENT}
									idealResolution={{ width: 640, height: 480 }}
									imageType={IMAGE_TYPES.JPG}
									imageCompression={0.97}
									isMaxResolution={false}
									isImageMirror={false}
									isSilentMode={false}
									isDisplayStartCameraError
									isFullscreen
									sizeFactor={1}
								/>
							</ContainerCam>
						)}
						{isActivedItem === "file" && (
							<ModalAudio>
								<label>{t("pictureMessage.format")}</label>
								<Field
									component={FileInput}
									name='fileUrl'
									type='file'
									nameFile={nameFile}
									onChange={this.onChangeFile}
									accept='.jpg, .png, .gif'
								/>
							</ModalAudio>
						)}
						<div>
							<div className='selectPhotos'>
								{data.map((data, key) => (
									<div key={key} className='animated fadeInRight'>
										<Button
											type='button'
											isTransparent
											onClick={() => this.removeImage(data.id)}
										>
											<i className='fas fa-times-circle' />
										</Button>
										<div className='containerImagePreview'>
											<img src={data.url || data.data} alt='img-preview' />
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{type === "video" && (
					<Fragment>
						<div className='actionMessage'>
							<TypeFileUpload
								title={t("videoMessage.recording")}
								icon='fas fa-video'
								bgImg={recVideo}
								toggle={() => this.toggleItemBox("recVideo")}
							/>
							<TypeFileUpload
								title={t("videoMessage.attach")}
								icon='fas fa-play'
								bgImg={uploadVideo}
								toggle={() => this.toggleItemBox("fileVideo")}
							/>
						</div>
						{isActivedItem === "recVideo" && (
							<ModalAudio bottom='300px' width='100%'>
								<ContainerVideo>
									<VideoRecorder
										onTurnOnCamera={() => this.setState({ isSubmit: false })}
										onStopReplaying={() => this.setState({ isSubmit: false })}
										onStartRecording={() => this.setState({ isSubmit: false })}
										onRecordingComplete={this.onChangeVideo}
									/>
								</ContainerVideo>
							</ModalAudio>
						)}
						{isActivedItem === "fileVideo" && (
							<ModalAudio>
								<label>{t("videoMessage.format")}</label>
								<Field
									component={FileInput}
									onChange={this.onChangeFile}
									nameFile={nameFile}
									name='fileUrl'
									type='file'
									accept='.mp4,.ogg, .webm .mkv'
								/>
							</ModalAudio>
						)}
					</Fragment>
				)}
				{type === "written" && (
					<Fragment>
						<Field
							style={{ width: "100%" }}
							rows='13'
							name='body'
							component={TextArea}
							placeholder={t("eventMessage.fields.area")}
						/>
						{/* <p>{t('eventMessage.format')}</p>
						<Field
							component={FileInput}
							nameFile={nameFile}
							onChange={this.onChangeFile}
							name='fileUrl'
							type='file'
							accept='.pdf,.doc,.docx, .dom,.dot,.docx,.txt,.csv,.dbf,.xla, .xls,.xlsx,.xlt,.bmp,.emf,.pot'
						/> */}
						{/* <h4 className='blue'>{`${t('writtenMessage.previewMessage')} ${t(
							'writtenMessage.clickhere'
						)}`}</h4> */}
					</Fragment>
				)}
				<div className='containerButtonSubmit'>
					<Button bgColor='#DF382F' bgColorHover='#892B6E' width='auto'>
						<i className='far fa-clock' /> {t("buttons.scheduleMessage")}
					</Button>
				</div>
			</Form>
		);
	}
}

EventMessage.defaultProps = {
	isActivedItem: false,
};

EventMessage.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isActivedItem: PropTypes.bool,
};

export default withStore(EventMessage);
