//Dependencies
import React, { Component } from 'react';
//Components
import BackgroundHome from '../../components/BackgroundHome';
import HomeLogin from '../../components/HomeLogin';
import ModalBeneficiareVerifier from '../../components/ModalBeneficiareVerifier';

import RegisterBeneficiare from '../../form/RegisterBeneficiare';
import RegisterVerifier from '../../form/RegisterVerifier';
import LoginBeneficiary from '../../form/LoginBeneficiary';
import LoginVerifier from '../../form/LoginVerifier';
//Styled Components
import { Container } from './styles';

import withStore from './store';
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeLogin: false,
			activeModalForgotPassword: false,
			activeModalRBeneficiray: false,
			activeModalVerifier: false,
		};
	}

	componentDidMount() {
		const {
			match: { params },
			getDataRegisterBeneficiare,
			getDataRegisterVerifier,
		} = this.props;
		if (params.relationBeneneficiareId) {
			getDataRegisterBeneficiare(params.relationBeneneficiareId);
		} else if (params.relationVerifierId) {
			getDataRegisterVerifier(params.relationVerifierId);
		}
	}

	toggleModalRBeneficiray = () => {
		this.setState({
			activeModalRBeneficiray: !this.state.activeModalRBeneficiray,
		});
	};

	toggleModalVerifier = () => {
		this.setState({
			activeModalVerifier: !this.state.activeModalVerifier,
		});
	};

	toggleLogin = () => {
		this.setState({
			activeLogin: !this.state.activeLogin,
		});
	};

	toggleModalForgotPassword = () => {
		this.setState({
			activeModalForgotPassword: !this.state.activeModalForgotPassword,
		});
	};

	// beneficiario || beneficiary
	render() {
		const {
			isLoginUser,
			modalRegisterBeneficiare,
			beneficiareName,
			userMainName,
			userMainUrl,
			closeModal,
			modalRegisterVerifier,
			match: { params },
			modal,
			setModal,
			history,
		} = this.props;
		return (
			<Container>
				{/* <form
					style={{ marginTop: 131 }}
					method='post'
					action='https://checkout.payulatam.com/ppp-web-gateway-payu/'>
					<input name='merchantId' type='hidden' value='557892' />
					<input name='accountId' type='hidden' value='560311' />
					<input name='description' type='hidden' value='test' />
					<input
						name='referenceCode'
						type='hidden'
						value='FLUIE-ebe664ca63513185b1445b8d7ce1f58c-12-4'
					/>
					<input name='amount' type='hidden' value='150' />
					<input name='tax' type='hidden' value='0' />
					<input name='taxReturnBase' type='hidden' value='0' />
					<input name='currency' type='hidden' value='USD' />
					<input name='buyerFullName' type='hidden' value='andres bermudez' />
					<input
						name='signature'
						type='hidden'
						value='c4b09022e156afb2719326a354387385'
					/>
					<input name='test' type='hidden' value='1' />
					<input
						name='buyerEmail'
						type='hidden'
						value='edison.alba@imaginamos.com.co'
					/>
					<input
						name='responseUrl'
						type='hidden'
						value='https://astral-reef-233515.appspot.com/api/orders/confirmation'
					/>
					{/* <input
						name='shippingAddress'
						type='hidden'
						value='cll 123'
					/>
					<input
						name='shippingCity'
						type='hidden'
						value='Bogota'
					/>
					<input
						name='shippingCountry'
						type='hidden'
						value='CO'
					/>					 */}
				<input name='telephone' type='hidden' value='62534567' />
				<input
					name='confirmationUrl'
					type='hidden'
					value='https://fluie.com/confirmation'
				/>
				<input type='submit' value='submit' />
				{/* <Button isTransparent>
							<img src='http://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png' />
						</Button> */}
				{/* </form> */}
				{!isLoginUser ? (
					<div>
						<BackgroundHome
							toggleLogin={this.toggleLogin}
							activeLogin={this.state.activeLogin}
							toggleModalForgotPassword={this.toggleModalForgotPassword}
							isActiveModalForgotPassword={this.state.activeModalForgotPassword}
							toggleModalRBeneficiray={this.toggleModalRBeneficiray}
							isActiveModalRBeneficia={this.state.activeModalRBeneficiray}
							activeModalVerifier={this.state.activeModalVerifier}
							toggleModalVerifier={this.toggleModalVerifier}
						/>
						<div className='buttonStart'>
							{/* <ButtonStart
								openLogin={this.toggleLogin}
								activeLogin={this.state.activeLogin}
							/>*/}
						</div>
					</div>
				) : (
					<div>
						<HomeLogin
							toggleModalForgotPassword={this.toggleModalForgotPassword}
						/>
					</div>
				)}
				{modalRegisterBeneficiare && (
					<RegisterBeneficiare
						active
						params={params}
						beneficiareName={beneficiareName}
						userMainName={userMainName}
						userMainUrl={userMainUrl}
						toggle={closeModal}
					/>
				)}
				{modalRegisterVerifier && (
					<RegisterVerifier active params={params} history={history} />
				)}
				{modal === 'modalBeneficiareVerifier' && (
					<ModalBeneficiareVerifier active closeModal={() => setModal('')} />
				)}
				{modal === 'loginBeneficiary' && (
					<LoginBeneficiary active closeModal={() => setModal('')} />
				)}
				{modal === 'loginVerifier' && (
					<LoginVerifier active closeModal={() => setModal('')} />
				)}
			</Container>
		);
	}
}

export default withStore(Home);
