// Dependencies
import React from 'react';
import { connect } from 'react-redux';

// Styled Components
import { Container } from './styles';

function BackgroundImage(props) {
  return (
    <Container isActiveLogin={props.isActiveLogin}>
      <div className="animation">
        <div className="container-welcome">
          {/*

            <div className='box-welcolme'>
            <h2 className='txt-welcome'>{props.title} !</h2>
          </div>
          <div className='box-logo'>
            <img src={Logo} alt='flüie' />
          </div>
          <p>{props.description}</p>
        </div>
        <div className='buttonAnimation'>
          <div className='box-btn'>
            <PlayResponsive onClick={() => props.openModal()}>
              <i className='fas fa-play' />
            </PlayResponsive>
          </div>
          <ButtonStart openLogin={props.toggleLogin} />
          <div className='box-btn-app'>
            <div className='ButtonStore'>
              <ButtonStore isIOS />
            </div>
            <div className='ButtonStore'>
              <ButtonStore isAndroid />
            </div>
          </div>
            */}
        </div>
      </div>
    </Container>
  );
}

function mapStateToProps(store) {
  return {
    title: store.home.homes[0] ? store.home.homes[0].title : [],
    description: store.home.homes[0] ? store.home.homes[0].description : [],
  };
}

export default connect(mapStateToProps)(BackgroundImage);
