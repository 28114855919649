import React from 'react';
import { Container, ModalContent, ButtonClose, ContainerVideo } from './styles';

function ModalVideoYoutube({
  active,
  toggle,
  fullScreen,
  children,
  txtAlign,
  top,
  width,
  messagePreview,
  confirm,
  zIndex,
  padding,
  videoUrl,
}) {
  return (
    <Container active={active} top={top} zIndex={zIndex}>
      <div className="overlay animated fadeIn" onClick={toggle} />
      <ModalContent
        className="animated fadeInDown scroll-text"
        fullScreen={fullScreen}
        txtAlign={txtAlign}
        top={top}
        width={width}
        confirm={confirm}
        padding={padding}
        messagePreview={messagePreview}
      >
        <ButtonClose onClick={toggle} fullScreen={fullScreen} confirm={confirm}>
          <i className="fas fa-times" />
        </ButtonClose>

        <ContainerVideo>
          {active && (
            <iframe
              className="video"
              src={videoUrl + '?rel=0'}
              title="video home"
            />
          )}
        </ContainerVideo>
      </ModalContent>
    </Container>
  );
}

export default ModalVideoYoutube;
