/* Dependencies */
import styled from 'styled-components'

export const Form = styled.form`
	.containerBtnSubmit {
		margin-top: 35px;
		text-align: center;
	}
	.container-audio-preview {		
    width: 100%;
    display: flex;
    justify-content: center;    
    margin-top: 28px;
	}	
	select, input {
		width: 100%;
		height: 50px;
		padding: 6px 20px;
		font-size: 13px;
		line-height: 1.42857143;
		color: '#999';
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 25px;
		border: 1px solid #342573;
	}
`

export const ModalAudio = styled.div`
	margin: 0 auto;
	margin-bottom: 75px;
	width: 50%;
	height: 80px;
	padding: 10px;
	text-align: center;
`
