import React from 'react';

import { Container } from './styles';
import { Facebook } from 'react-content-loader';

import avatar from '../../assets/images/male-user.png';

function ItemNotification({
	userName,
	message,
	time,
	withAction,
	onClick,
	updateNotification,
	imageUrl,
	onClickClose,
	isAbsence,
}) {
	if (!userName)
		return (
			<Facebook
				style={{ marginTop: '20px' }}
				height={280}
				width={280}
				speed={2.5}
				primaryColor='
      rgba(118, 35, 106, .8)
      '
				secondaryColor='rgba(251, 133, 25, .8)'
			/>
		);
	return (
		<Container
			withAction={withAction || isAbsence}
			onClick={
				withAction && onClick ? onClick : isAbsence ? onClickClose : () => {}
			}
		>
			{!withAction && (
				<button onClick={updateNotification}>
					<i className='fas fa-times-circle' />
				</button>
			)}

			<img src={imageUrl || avatar} alt='imageNotification' style={{objectFit: 'cover'}}/>
			<div>
				<h4>{userName.split(' ')[0]}</h4>
				<p>{message}</p>
			</div>
			<p className='time'>{time}</p>
		</Container>
	);
}

export default ItemNotification;
