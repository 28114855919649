/* Dependencies */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import InputRedux from '../../fields/Input';
import TextArea from '../../fields/TextArea';
// Validate
import validate from 'validate.js';

const constrains = {
  name: {
    presence: true,
  },
  email: {
    email: true,
    presence: true,
  },
  comment: {
    presence: true,
  },
  authorizeData: {
    presence: true,
  },
};

const maxLength = (max) => (value) =>
  value && value.length > max ? t('register.maximumCharacters') : undefined;
const maxLength30 = maxLength(30);
const emailRequire = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t('register.invalidEmail')
    : undefined;
const required = (value) => (value ? undefined : t('register.required'));
const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? t('register.minimun')
    : undefined;
const characters = (value) =>
  value && !/^[ñA-Za-z _]*[ñA-Za-z][ñA-Za-z _]*$/i.test(value)
    ? t('register.onlyLetters')
    : undefined;

function ContactForm(props) {
  const { handleSubmit } = props;
  return (
    <form className="formBeneficiare" onSubmit={handleSubmit}>
      <h2 className="title">{t('menu.contactUs')}</h2>
      <Field
        type="text"
        name="name"
        component={InputRedux}
        placeholder={t('contact.fields.name')}
        validate={[maxLength30, required, characters]}
      />
      <Field
        type="email"
        name="email"
        validate={[emailRequire, required]}
        component={InputRedux}
        placeholder={t('contact.fields.email')}
      />
      <Field
        type="number"
        name="phone"
        component={InputRedux}
        validate={phoneNumber}
        placeholder={t('contact.fields.phone')}
      />
      <Field
        rows="7"
        name="comment"
        component={TextArea}
        placeholder={t('contact.fields.comments')}
        validate={required}
      />
      <div>
        <Field
          name="authorizeData"
          type="checkbox"
          component={InputRedux}
          validate={required}
          placeholder={t('contact.fields.comments')}
        />
        <div className="txt">{t('contact.fields.accept')}</div>
      </div>
      <div className="btnSubmit">
        <Button bgColor="#211261" bgColorHover="#892b6e" type="submit">
          {t('buttons.submit')}
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'contact',
  validate: (values) => validate(values, constrains),
})(ContactForm);
