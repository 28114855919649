// Dependencies
import styled from 'styled-components'
// Assets
import spriteIcon from '../../assets/images/sprite-icons.svg'
import message from '../../assets/images/message.png'
import event from '../../assets/images/event.png'
import advice from '../../assets/images/advice.png'

export const ContainerLateralMenu = styled.div`
	.titleLateral {
		text-align: left;
		margin: 10px 15px;
		color: #342573;
		font-size: 20px;
		font-weight: 900;
		line-height: 34px;
	}
`

export const BoxLateralMenu = styled.div`
	width: 100%;
	margin: 0;
	.itemLateral {
		width: 100%;
		border-right: none;
		transition: all 0.4s linear 0s;
	}
	.test {
		border: 1px solid #C9C9C9;
		cursor: pointer;
		width: 100%;
		height: 45px;
		padding: 0 15px;
		padding-left: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div {
			display: flex;
    	align-items: center;
		}
		}

	}
	&:last-child {
		margin-bottom: 5px;
		border-bottom: 1px solid #ccc;
	}
	.iconHistorial {
		width: 27px;
		height: 27px;
		margin-top: 8px;
	}
	.number {
		min-width: 20px;
		height: 20px;
		border-radius: 3px;
		background: #ff8400;
		color: #fff;
		text-align: center;
		float: right;
		line-height: 20px;
		padding: 0 5px;
	}
	.iconMessage,
	.iconEvents,
	.iconTips,
	.arrow {
		background-repeat: no-repeat !important;
		background-size: contain !important;
	}
	.iconMessage {
		background: url(${message});
	}
	.iconEvents {
		background: url(${event});
		margin-right: -15px;
	}
	.iconTips {
		background: url(${advice});
	}
	.arrow {
		width: 14px;
		height: 14px;
		float: left;
		background: url(${spriteIcon}) -158px -48px/550px 80px;
		position: relative;
		top: 13px;
		left: 79px;
	}
	.hiddenXs {
		line-height: 13px;
		color: #868686;
		font-size: 17px;
		line-height: 20px;
	}
	.text {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.colorTopBar:nth-child(4n + 2) {
		border-top: 4px solid #892b6e;
	}
	.colorTopBar:nth-child(4n + 3) {
		border-top: 4px solid #211261;
	}
`
