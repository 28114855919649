import React from 'react';

import { t } from '../../i18n';

import logo from '../../assets/images/logo-1.png';

import Modal from '../Modal';
import Button from '../Button';

import { Content } from './styles';

import withStore from './store';

function ModalBeneficiareVerifier({
  active,
  closeModal,
  loginBeneficiary,
  loginVerifier,
}) {
  return (
    <Modal
      width="fit-content"
      padding="35px"
      fullScreen
      active={active}
      toggle={closeModal}
    >
      <Content>
        <img src={logo} alt="logo" />
        <Button
          onClick={loginBeneficiary}
          bgColor="#ff8400"
          bgColorHover="#211261"
        >
          {t('buttons.loginBeneficiare')}
        </Button>
        <Button
          onClick={loginVerifier}
          bgColor="#ff8400"
          bgColorHover="#211261"
        >
          {t('buttons.loginVerifier')}
        </Button>
      </Content>
    </Modal>
  );
}

export default withStore(ModalBeneficiareVerifier);
