import React, { Component, Fragment } from 'react'
import { Container, ArrowDropDown, HeaderDropDown, Overlay } from './styles'

class Tooltip extends Component {
  render () {
    const {
			toggleTooltipConfig,
			isActive,
			responsive,
			left,
      children,
      top,
      right,
      width
		} = this.props
    return (
      <Fragment>
        <Overlay
          className='animated fadeIn'
          onClick={toggleTooltipConfig}
          isActive={isActive}
				/>
        <Container
          isActive={isActive}
          onClick={this.toggle}
          responsive={responsive}
          right={right}
          top={top}>
          <ArrowDropDown left={left} />
          <HeaderDropDown width={width}>{children}</HeaderDropDown>
        </Container>
      </Fragment>
    )
  }
}

export default Tooltip
