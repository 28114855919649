//Dependencies
import styled from 'styled-components';
import media from 'styled-media-query';
//Asstes
import lineTitle from '../../assets/images/line-title-2.svg';
import icon from '../../assets/images/sprite-icons-2.svg';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	
	.boxBreadcrumb {
		padding: 0 15px;
		height: 40px;
		float: left;
		width: 35%;
	}
	.breadcrumb {
		display: flex;
		padding: 0px 15px;
		margin-bottom: 20px;
		list-style: none;
		li + li:before {
			padding: 0 5px;
			color: #ccc;
			content: '/\00a0';
		}
		.active {
			color: #777;
		}
	}
	.buttons {
		margin-top: 30px;
		margin-right: 15px;
		display: flex;
		justify-content: flex-end;
		width: calc(56% - 110px);
		max-width: 490px;
		button {
			margin-left: 10px;
		}
		a {
			button {
				width: max-content;
			}
		}
	}
	> a {
		width: 40%;
	}
	/*-----------MOVIL-----------*/
	${media.lessThan('large')`      
	.buttons {
		width: calc(75% - 110px);
	}           	
  `}
	${media.lessThan('medium')`                 
  	flex-direction: column;
		.buttons{
			width: 100%;
			button {
				padding: 6px;				
				font-size: 13px;
			}
		}
  `}
`;
export const Description = styled.div`
	width: 90%;
	margin: 0 auto;
	margin-top: 40px;
	.imgUser {
		display: flex;
	}
	.circleImg {
		width: 120px;
		height: 120px;
		margin-right: 10px
		border-radius: 100px;
		background: #ccc;
		overflow: hidden;
		position: relative;
	}
	.imgContent {
		width: 100%;
		position: absolute;
		top: -100%;
		right: 0;
		bottom: -100%;
		left: 0;
		margin: auto;
	}
	.msmInfo {
		display: flex;
		justify-content: space-between;
		margin: auto 0;
		h2 {
			color: #211261;
			text-transform: capitalize;
		}
	}
	.infoSpace {
		margin-left: 60px;
		h3 {
			color: #892b6e;
		}
		span {
			padding-top: 5px;
		}
	}
	.txt {
		margin-top: 40px;
		width: 80%;
		display: flex;
    flex-direction: column;
    align-items: center;
	}
	.topBlue {
		width: 180px;
		height: 25px;
		background: url(${lineTitle}) 0 0/180px 25px no-repeat;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.separatorbottom {
		width: 180px;
		height: 10px;
		background: url(${icon}) -255px -70px/550px 80px no-repeat;
		margin: 20px auto;
		display: block;
	}
  .container-media {
    margin: 20px auto 0 auto;                  
    max-width: 337px;
    img {
      width: 100%!important;
    }
  }
	/*-----------MOVIL-----------*/
	${media.lessThan('medium')`   
  .container-media {
    margin: 0;                  
    max-width: 100%;
  }              
  	.imgUser, .msmInfo {
			flex-direction: column;
			margin: 0 auto;
		}
		.circleImg {
			margin: 0 auto;
		}
		.infoSpace {
			margin-left: 0px;
		}
		.imgUser {
			text-align: center;
		}				
		.txt {
			width: 100%;
			text-align: center;
		}
  `}
`;

export const ButtonRight = styled.div`
  width: 181spx;
  float: right;
  margin-right: 10px;
  ${media.lessThan('medium')`                 
		margin-bottom: 20px;
		
  `}
`;
export const ButtonsSeeDraft = styled.div`
  display: flex;
  padding: 11px;
  padding-left: 125px;
  a {
    margin-right: 0.8rem;
  }

  margin: 0 auto;
  padding: 0 10px;
  button {
    margin-bottom: 10px;
  }
  button:nth-child(3) {
    margin-bottom: 0;
  }
  ${media.lessThan('medium')`                 
		display: block;
		
  `}
`;
