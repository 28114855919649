// Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// Redux
import { getTutorials } from '../../redux/tutorials/actions';
// i18n
import { t } from '../../i18n';
// Components
import Wrapper from '../../components/Wrapper';
import TutorialBox from '../../components/TutorialBox';
import Footer from '../../components/Footer';
// Styled component
import { Container } from './styles';

class Tutorials extends Component {
  componentDidMount() {
    this.props.getTutorials();
  }

  render() {
    const { isLoginUser, tutorials, isComponentLoading } = this.props;
    return (
      <Fragment>
        <Wrapper>
          <Container marginTop={isLoginUser ? '193px' : '100px'}>
            <h1>{t('menu.tutorials')}</h1>
            <div className="text-separator-top" />
            {isComponentLoading ? (
              <div className="loader-container">
                {/* <Facebook
									speed={2.5}
									primaryColor='#a3a09e'
									secondaryColor='#6d6965'
								/> */}
              </div>
            ) : (
              <div className="container-tutorials">
                {tutorials.map((tutorial, index) => (
                  <TutorialBox
                    key={index}
                    title={tutorial.name}
                    tutorials={tutorial.tutorials}
                  />
                ))}
              </div>
            )}
          </Container>
        </Wrapper>
        <Footer />
      </Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    isComponentLoading: store.app.isComponentLoading,
    tutorials: store.tutorials.tutorials,
    isLoginUser: store.app.isLoginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTutorials: () => dispatch(getTutorials()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials);
