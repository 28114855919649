// Dependencies
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import media from 'styled-media-query'
// Assets
import arrowDown from '../../assets/images/arrowDonw.png'

const CardLogin = styled.div`
	background: rgba(255, 255, 255, 0.75);
	text-align: center;
	padding: 33px 20px;
	margin-bottom: 20px;
	text-align: center;
	border-radius: 12px;
	height: 234px;
	display: flex;
	flex-direction: column;
	transition: all 0.5s ease-in-out;
	position: relative;
	overflow-y: auto;
	h2 {
		color: #342573;
		font-size: 30px;
		font-weight: 900;
		line-height: 34px;
		text-align: center;
		margin-bottom: 0;
		cursor: pointer;
		transition: all .4s;
		:hover {
			font-size: 31px;			
		}
	}
	p {
		text-align: center;
		color: #55535e;
		font-size: 15px;
	}
	.boxButtons {
		padding: 0px 22%;
		display: none;
		animation: fadeInDown 0.5s ease-in-out;
		button {
			margin-bottom: 10px;
		}
	}
	.arrowDown {
		background-image: url(${arrowDown});
		background-repeat: no-repeat;
		height: 34px;
		width: 26px;
		background-size: contain;
		align-self: center;
		position: absolute;
		bottom: 0;
		cursor: pointer;
		transition: all 0.5s ease-in-out;
	}
	.flex {
		display: none;
		grid-template-columns: 1fr 1fr;
		padding: 0;
		grid-column-gap: 26px;
		display: none;
		animation: fadeInDown 0.5s ease-in-out;
		img {
			height: 27px;
			width: 22px;
		}
	}
	.flex,
	.boxButtons {
		button {
			display: flex;
			justify-content: ${prop('alignText', 'space-between')};
			align-items: center;
			padding: 7.5px 24px;
		}
		img {
			height: 27px;
			width: 22px;
		}
	}
	${media.lessThan('medium')`		
	${ifProp(
		'notPadding',
		css`
			.boxButtons {
				padding: 0 20%;
			}
		`
	)}
			
	`}
	${ifProp(
		'isActivedButtons',
		css`
			height: 366px;
			.boxButtons {
				display: block;
			}
			.flex {
				display: grid;
			}
			.arrowDown {
				bottom: 14px;
				transform: rotate(180deg);
			}
		`
	)}
`

export default CardLogin
