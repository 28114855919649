import React from 'react';
import { Container } from './styles';

function ButtonNotification({ number }) {
  return (
    <Container>
      <span>
        <div className="numNotification">
          {number}
          <span />
        </div>
        <span className="bellNotification" />
      </span>
    </Container>
  );
}

ButtonNotification.defaultProps = {
  number: 0,
};
export default ButtonNotification;
