// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//Redux
import {
  setFormBeneficiaries,
  setFormVerifiers,
} from '../../redux/home/actions';
import {
  setFormBuy,
  getProfile,
  getAssingRoles,
  setModal,
} from '../../redux/app/actions';

// i18n
import { t } from '../../i18n';

import Button from '../Button';
import Base from '../Base';
import CardLogin from '../CardLogin';
import TooltipRight from '../TooltipRight';
import HomeVerifier from '../HomeVerifier';
import Modal from '../Modal';

// Routes
import { routes } from '../../routes';
// Styleds Components
import { Container, BtnRight, ProfilesModal } from './styles';
// Assets
import benef from '../../assets/images/beneficiaries.png';
import verif from '../../assets/images/verifiers.png';
import beneficiaryMessages from '../../assets/images/beneficiaryMessages.png';
import tutorial from '../../assets/images/tutorial.png';
import write from '../../assets/images/write.png';
import volume from '../../assets/images/volume.png';
import video from '../../assets/images/video.png';
import picture from '../../assets/images/picture.png';
import eye from '../../assets/images/eye.png';
import Record from '../../pages/Record';

class HomeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMessage: false,
      cardHistory: false,
      cardLife: false,
      statusProfile: '',
      visible: false,
    };
  }

  componentDidMount() {
    // this.props.getProfile()
    // this.props.getAssingRoles()
  }

  toggleCard = (card) =>
    this.setState({
      cardMessage: false,
      cardHistory: false,
      cardLife: false,
      [card]: !this.state[card],
    });

  toggleformBeneficiaries = () =>
    this.props.setFormBeneficiaries(!this.props.formBeneficiaries);

  toggleformVerifiers = () =>
    this.props.setFormVerifiers(!this.props.formVerifiers);

  render() {
    const { cardHistory, cardMessage, cardLife, statusProfile, visible } =
      this.state;
    const {
      toggleModalForgotPassword,
      homeImageUrlMain,
      userIdBeneficiaryData,
      userIdVerifierData,
      dataVerifierUserMain,
      dataUserRoles,
    } = this.props;
    const titles =
      dataUserRoles.length > 0 ? statusProfile : userIdBeneficiaryData;
    const verifierProfile = userIdVerifierData;
    return (
      <div>
        {dataUserRoles.length === 2 ? (
          <div>
            <Modal
              fullScreen
              active={visible}
              toggle={() =>
                this.setState({
                  visible: false,
                })
              }
            >
              <ProfilesModal>
                <div
                  style={{
                    padding: '10px',
                  }}
                >
                  <h2>{t('show.changeProfile')}</h2>
                  <Button
                    style={{
                      margin: '0 0 8px 4px',
                    }}
                    bgColor="#ff8400"
                    bgColorHover="#211261"
                    onClick={(e) =>
                      this.setState({
                        statusProfile: dataUserRoles[0].roleId,
                      })
                    }
                  >
                    {t('show.userMain')}
                  </Button>
                  <Button
                    bgColor="#ff8400"
                    bgColorHover="#211261"
                    onClick={(e) =>
                      this.setState({
                        statusProfile: dataUserRoles[1].roleId,
                      })
                    }
                  >
                    {t('show.messagesBeneficiary')}
                  </Button>
                </div>
              </ProfilesModal>
            </Modal>
          </div>
        ) : (
          ''
        )}
        {titles === 1 ? (
          <Record titles={titles} />
        ) : verifierProfile === 2 ? (
          <HomeVerifier
            toggleModalForgotPassword={toggleModalForgotPassword}
            dataVerifierUserMain={dataVerifierUserMain}
          />
        ) : (
          <Base homeLogin imgBackground={homeImageUrlMain}>
            <h1 className="title">{t('homeLogin.whatDoYou')}</h1>
            <span className="text-separator-top" />
            <Container>
              <BtnRight>
                <TooltipRight
                  small
                  img={benef}
                  title={t('menu.beneficiaries')}
                  toggle={this.toggleformBeneficiaries}
                />
                <TooltipRight
                  small
                  img={verif}
                  title={t('menu.verifiers')}
                  toggle={this.toggleformVerifiers}
                />
                <TooltipRight
                  small
                  img={tutorial}
                  title={t('menu.tutorials')}
                  link={routes.TUTORIALS}
                />
                {dataUserRoles.length === 2 ? (
                  <TooltipRight
                    small
                    img={beneficiaryMessages}
                    title={t('menu.showMessagesBeneficiary')}
                    toggle={(e) =>
                      this.setState({
                        visible: true,
                      })
                    }
                  />
                ) : (
                  ''
                )}
              </BtnRight>
              <CardLogin isActivedButtons={cardMessage}>
                <h2 onClick={() => this.toggleCard('cardMessage')}>
                  {t('homeLogin.createMessage')}
                </h2>
                <p>{t('homeLogin.selectTypeMessage')}</p>
                <div className="boxButtons flex">
                  <Link to={routes.WRITTEN_MESSAGE}>
                    <Button
                      border
                      txtColor="#342573"
                      bgColor="transparent"
                      borderColor="#342573"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('buttons.written')}
                      <img src={write} alt="write" />
                    </Button>
                  </Link>
                  <Link to={routes.VIDEO_MESSAGE}>
                    <Button
                      border
                      borderColor="#342573"
                      txtColor="#342573"
                      bgColor="transparent"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('buttons.video')}
                      <img src={video} alt="video" />
                    </Button>
                  </Link>

                  <Link to={routes.AUDIO_MESSAGE}>
                    <Button
                      border
                      borderColor="#342573"
                      txtColor="#342573"
                      bgColor="transparent"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('buttons.audio')}
                      <img src={volume} alt="volume" />
                    </Button>
                  </Link>
                  <Link to={routes.PICTURE_MESSAGE}>
                    <Button
                      border
                      borderColor="#342573"
                      txtColor="#342573"
                      bgColor="transparent"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('buttons.pictures')}
                      <img src={picture} alt="userpicture" />
                    </Button>
                  </Link>
                </div>
                <div
                  className="arrowDown"
                  onClick={() => this.toggleCard('cardMessage')}
                />
              </CardLogin>
              <CardLogin isActivedButtons={cardHistory} notPadding>
                <h2 onClick={() => this.toggleCard('cardHistory')}>
                  {t('homeLogin.recordMessages')}
                </h2>
                <p>{t('homeLogin.messagesBeneficiaries')}</p>
                <div className="boxButtons">
                  <Link to={routes.RECORD}>
                    <Button
                      border
                      borderColor="#342573"
                      bgColor="transparent"
                      txtColor="#342573"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('buttons.seeMessage')}
                      <img src={eye} alt="eye" />
                    </Button>
                  </Link>
                </div>
                <div
                  className="arrowDown"
                  onClick={() => this.toggleCard('cardHistory')}
                />
              </CardLogin>
              <CardLogin isActivedButtons={cardLife} alignText="center">
                <h2 onClick={() => this.toggleCard('cardLife')}>
                  {t('menu.lifeGuide')}
                </h2>
                <p>{t('homeLogin.registerEvents')}</p>
                <div className="boxButtons">
                  <Link to={routes.WHAT_IS_IT}>
                    <Button
                      border
                      borderColor="#342573"
                      bgColor="transparent"
                      txtColor="#342573"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('menu.whatIsIt')}
                    </Button>
                  </Link>
                  <Link to={routes.EVENT_MESSAGE}>
                    <Button
                      border
                      borderColor="#342573"
                      bgColor="transparent"
                      txtColor="#342573"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('menu.events')}
                    </Button>
                  </Link>
                  <Link to={routes.TIPS_MESSAGE}>
                    <Button
                      border
                      borderColor="#342573"
                      bgColor="transparent"
                      txtColor="#342573"
                      bgColorHover="rgba(52,37,115, .55)"
                      txtColorHover="#fff"
                    >
                      {t('menu.advice')}
                    </Button>
                  </Link>
                </div>
                <div
                  className="arrowDown"
                  onClick={() => this.toggleCard('cardLife')}
                />
              </CardLogin>
            </Container>
          </Base>
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    formBuy: store.app.formBuy,
    homeImageUrlMain: store.home.homes[0]
      ? store.home.homes[0].homeImageUrlMain
      : '',
    formBeneficiaries: store.home.formBeneficiaries,
    formVerifiers: store.home.formVerifiers,
    message: store.app.message,
    user: store.user.user.length ? store.user.user[0].id : [],
    userIdBeneficiaryData: store.app.userIdBeneficiaryData,
    userIdVerifierData: store.app.userIdVerifierData,
    dataVerifierUserMain: store.app.dataVerifierUserMain,
    dataUserRoles: store.app.dataUserRoles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFormBuy: (value) => dispatch(setFormBuy(value)),
    setFormBeneficiaries: (value) => dispatch(setFormBeneficiaries(value)),
    setFormVerifiers: (value) => dispatch(setFormVerifiers(value)),
    getProfile: (user) => {
      dispatch(getProfile(user));
    },
    getAssingRoles: (user) => {
      dispatch(getAssingRoles(user));
    },
    closeModal: (value) => dispatch(setModal(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLogin);
