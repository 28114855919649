import INITIAL_STATE from './initialState'
import { SPACES, SPACE_SELECTED } from './const'

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SPACES:
			return Object.assign(
				{},
				{
					...state,
					spaces: action.value
				}
			)
		case SPACE_SELECTED:
			return Object.assign(
				{},
				{
					...state,
					spaceSelected: action.value
				}
			)
		default:
			return state
	}
}
