import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
	getDataRegisterBeneficiare,
	setModalRegisterBeneficiare,
	setModalRegisterVerifier,
	getDataRegisterVerifier
} from '../../redux/register/actions'

import {
	setModal	
} from '../../redux/app/actions'

import { routes } from '../../routes'

const mapStateToProps = ({
	app: { isLoginUser, modal },
	register: {
		modalRegisterBeneficiare,
		dateRegisterBeneficiare,
		modalRegisterVerifier
	}
}) => {
	let infoModal = {}
	if (dateRegisterBeneficiare && dateRegisterBeneficiare.beneficiary) {
		infoModal = {
			beneficiareName: dateRegisterBeneficiare.beneficiary.userName,
			userMainName: dateRegisterBeneficiare.user.userName,
			userMainUrl: dateRegisterBeneficiare.user.imageUrl
		}
	}
	return {
		isLoginUser,
		modal,
		modalRegisterBeneficiare,
		modalRegisterVerifier,
		...infoModal
	}
}

const mapDispachtToProps = dispatch =>
	bindActionCreators(
		{
			getDataRegisterBeneficiare,
			setModalRegisterBeneficiare,
			setModalRegisterVerifier,
			getDataRegisterVerifier,
			setModal
		},

		dispatch
	)

const margeProps = (stateProps, dispatchProps, ownProps) => ({
	...stateProps,
	...dispatchProps,
	...ownProps,
	getDataRegisterBeneficiare: values =>
		dispatchProps.getDataRegisterBeneficiare(values, () =>
			ownProps.history.push(routes.HOME)
		),
	getDataRegisterVerifier: values =>
		dispatchProps.getDataRegisterVerifier(values, () =>
			ownProps.history.push(routes.HOME)
		),
	closeModal: () => {
		dispatchProps.setModalRegisterBeneficiare(false)
		ownProps.history.push(routes.HOME)
	}
})

export default connect(
	mapStateToProps,
	mapDispachtToProps,
	margeProps
)
