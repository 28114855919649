import request from '../../libs/request';
import getFormDataObject from '../../utils/getFormatData';

import { getLanguageById } from '../../i18n';

const endpoints = {
	myUsers: '/my-users',
	createBeneficiary: '/create-main-beneficiary',
	createChecker: '/create-main-verifier',
	beneficiaryUser: '/beneficiaries-users',
	ckekerUser: '/mainUsersC',
	verifierUsers: '/verifiers-users',
	updateWithFile: '/update-with-file',
	messagesBeneficiaries: '/message-beneficiaries',
	absences: 'absence',
	infoRelaion: '/beneficiaries-users/get-date-register',
	infoRelationVerifier: '/verifiers-users/get-date-register',
	acceptRelationBeneficiare:
		'/beneficiaries-users/accept-beneficiary-invitation',
	acceptRelationVerifier: '/verifiers-users/accept-verifier-invitation',
	notifications: '/notifications',
	roleVerifer: '/assigned-roles/create-role-verifier',
	roleBeneficiare: '/assigned-roles/create-role-beneficiare',
	absenceUserMain: '/absence/request-absence',
	rol: '/assigned-roles',
	recoverPassword: '/reset-password',
	changePassword: '/change-password',
	createOrder: '/orders',
	changeAbsenceUserMain: '/absence/verify-absence-verifiers',
	getDataUserMainOfVerifier: '/assigned-roles/get-role-verifier',
};
class UserService {
	getUser(uidAuth) {
		const filter = JSON.stringify({
			where: {
				uidAuth,
			},
			include: [
				{ relation: 'beneficiaryUsers' },
				{
					relation: 'assignedRoles',
					scope: { include: { relation: 'myRole' } },
				},
			],
		});
		return request.get(`${endpoints.myUsers}?filter=${filter}`);
	}
	getUserInfo(uidAuth) {
		const filter = JSON.stringify({
			where: {
				uidAuth,
			},
		});
		return request.get(`${endpoints.myUsers}?filter=${filter}`);
	}
	getRolBeneficiare(userId, roleId = 3) {
		const filter = JSON.stringify({
			where: {
				userId,
				roleId,
			},
			limit: 1,
		});
		return request.get(`${endpoints.rol}?filter=${filter}`);
	}
	getRolVerifier(userId) {
		const filter = JSON.stringify({
			where: {
				userId,
				roleId: 2,
			},
			limit: 1,
		});
		return request.get(`${endpoints.rol}?filter=${filter}`);
	}
	setUser(user) {
		return request.post(endpoints.myUsers, user);
	}
	setAbsences(data) {
		return request.post(endpoints.absences, data);
	}
	getUserOneByEmail(email) {
		const filter = JSON.stringify({
			where: {
				email,
			},
		});
		return request.get(`${endpoints.myUsers}?filter=${filter}`);
	}
	updateUser(id, user) {
		const { myUsers, updateWithFile } = endpoints;
		return request.patch(
			`${myUsers}/${id}${updateWithFile}`,
			getFormDataObject(user),
		);
	}
	upadateUserInfo(id, user) {
		const { myUsers } = endpoints;
		return request.patch(`${myUsers}/${id}`, user);
	}
	getAllBeneficiary(idUser) {
		const filter = JSON.stringify({
			include: ['beneficiaryUser'],
		});
		return request.get(
			`${endpoints.myUsers}/${idUser}/mainUsersB?filter=${encodeURIComponent(
				filter,
			)}`,
		);
	}
	getByBeneficiaryByUsers(beneficiaryId, action) {
		return request.get(
			`${endpoints.messagesBeneficiaries}/${action}/messages-by-action/${beneficiaryId}`,
		);
	}
	getAllChecker(idUser) {
		const { myUsers, ckekerUser } = endpoints;
		const filter = JSON.stringify({
			include: ['verifierUser'],
		});
		return request.get(
			`${myUsers}/${idUser}${ckekerUser}?filter=${encodeURIComponent(filter)}`,
		);
	}
	deleteBeneficiary(id) {
		return request.delete(`${endpoints.beneficiaryUser}/${id}`);
	}

	deleteCheker(id) {
		return request.delete(`${endpoints.verifierUsers}/${id}`);
	}
	addBeneficiariesByUserId(beneficiare) {
		const { myUsers, createBeneficiary } = endpoints;
		return request.post(`${myUsers}${createBeneficiary}`, beneficiare);
	}
	editBeneficiaryByUserId(beneficiare) {
		const { myUsers, createBeneficiary } = endpoints;
		return request.patch(`${myUsers}${createBeneficiary}`, beneficiare);
	}
	addCheckersByUserId(checker) {
		const { myUsers, createChecker } = endpoints;
		return request.post(`${myUsers}${createChecker}`, checker);
	}

	editCheckersByUserId(checker) {
		const { myUsers, createChecker } = endpoints;
		return request.patch(`${myUsers}${createChecker}`, checker);
	}
	addChekerByUserId(cheker) {
		return request.post(endpoints.ckekerUser, cheker);
	}
	getNotifications(userId) {
		return request.get(
			`/notifications/get-notification-user?userId=${userId}&languageId=${getLanguageById()}`,
		);
	}

	getInfoAcceptBeneficare(identification) {
		return request.post(endpoints.infoRelaion, {
			identification,
		});
	}

	getInfoAcceptVerifier(identification) {
		return request.post(endpoints.infoRelationVerifier, {
			identification,
		});
	}
	setAcceptRelationVerifier(code) {
		return request.patch(endpoints.acceptRelationVerifier, {
			code,
		});
	}
	setAcceptRelationBeneficiare(code) {
		return request.patch(endpoints.acceptRelationBeneficiare, {
			code,
		});
	}

	setRoleVerifer(userId) {
		return request.post(endpoints.roleVerifer, {
			userId,
		});
	}

	setRoleBeneficiare(userId) {
		return request.post(endpoints.roleBeneficiare, {
			userId,
		});
	}

	updateNotification(id) {
		return request.patch(`${endpoints.notifications}/${id}`, {
			view: 1,
		});
	}

	updateSession({ currentSession, userId }) {
		return request.patch(`/my-users/${userId}`, {
			numberOfEmailsSent: 0,
			currentSession,
		});
	}

	setAbsenceUserMain(userMain) {
		return request.post(endpoints.absenceUserMain, getFormDataObject(userMain));
	}

	setResetPassword(email) {
		return request.post(endpoints.myUsers + endpoints.recoverPassword, {
			email,
		});
	}

	recoverPassword(values) {
		return request.post(endpoints.myUsers + endpoints.changePassword, values);
	}

	// create oreder payment PAYU Service

	getOrderPayment(data) {
		return request.post(endpoints.createOrder, data);
	}

	getOrderSignature(id) {
		return request.get(`${endpoints.createOrder}/${id}/signature`);
	}

	getDataUserMainOfVerifier(verifierId) {
		return request.get(`${endpoints.myUsers}/${verifierId}/verifierUsers`);
	}

	changeAbsenceUserMain(userId, verifierId, approved, rolUserMain) {
		return request.get(
			`${
				endpoints.changeAbsenceUserMain
			}?user=${userId}&verifier=${verifierId}&approve=${approved}&rolUserMain=${
				rolUserMain ? rolUserMain : false
			}`,
		);
	}
}

export default new UserService();
