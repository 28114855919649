// Dependecies
import React, { Fragment } from 'react'
// Components
import FormUserTypeVerifiers from '../FormUserTypeVerifiers'
// Styleds Components
import { Container } from './styles'

function DropDownUserVerifiers (props) {
  const { title, description, toggle, isActived, count, genders, relationships } = props
  return (
    <Fragment>
      <Container onClick={toggle}>
        <h2 className='title'>{title}</h2>
        <p>{description}</p>
        <span className='caret' />
      </Container>
      <FormUserTypeVerifiers count={count} isActived={isActived} genders={genders} relationships={relationships} />
    </Fragment>
  )
}

export default DropDownUserVerifiers
