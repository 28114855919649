//Dependencies
import styled from 'styled-components';
import media from 'styled-media-query';
//Asstes
import lineTitle from '../../assets/images/line-title-2.svg';
import icon from '../../assets/images/sprite-icons-2.svg';

export const Description = styled.div`
  width: 66.66666667%;
  margin: 0 auto;
  margin-top: 40px;
  .imgUser {
    display: flex;
  }
  .circleImg {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background: #ccc;
    overflow: hidden;
    position: relative;
  }
  .imgContent {
    width: 100%;
    position: absolute;
    top: -100%;
    right: 0;
    bottom: -100%;
    left: 0;
    margin: auto;
  }
  .msmInfo {
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    h2 {
      color: #211261;
      text-transform: capitalize;
    }
  }
  .infoSpace {
    margin-left: 60px;
    h3 {
      color: #892b6e;
    }
    span {
      padding-top: 5px;
    }
  }
  .txt {
    margin-top: 40px;
    width: 80%;
    text-align: center;
  }
  .topBlue {
    width: 180px;
    height: 25px;
    background: url(${lineTitle}) 0 0/180px 25px no-repeat;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .separatorbottom {
    width: 180px;
    height: 10px;
    background: url(${icon}) -255px -70px/550px 80px no-repeat;
    margin: 20px auto;
    display: block;
  }
  /*-----------MOVIL-----------*/
  ${media.lessThan('medium')`                 
  	.imgUser, .msmInfo {
			flex-direction: column;
			margin: 0 auto;
		}
		.circleImg {
			margin: 0 auto;
		}
		.infoSpace {
			margin-left: 0px;
		}
		.imgUser {
			text-align: center;
		}				
		.txt {
			width: 100%;
			text-align: center;
		}
  `}
`;
