
import styled from 'styled-components'

const Area = styled.textarea`
  height: auto;  
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #999;
  background-color: #fff;
  border: 1px solid #342573;
  resize: none;
    padding: 15px 20px;
    border-radius: 12px;
  &:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  }
`

export default Area
