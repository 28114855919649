// Dependencies
import styled, { css } from "styled-components";
import { ifProp, prop, ifNotProp } from "styled-tools";
import media from "styled-media-query";
// Assets
import spriteIcon from "../../assets/images/sprite-icons.svg";
import spriteIcon2 from "../../assets/images/sprite-icons-2.svg";
import arrowWhite from "../../assets/images/arrowWhite.png";

export const Container = styled.div`
	top: 80px;
	height: 95px;
	width: 100%;
	max-width: 890px;
	position: fixed !important;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 20px 0;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	transition: width 0.5s ease-in-out;
	z-index: 9;
	.packageAlert {
		background: #df382f;
		color: #fff;
		font-size: 14px;
		padding: 10px;
		margin: 0 auto;
	}
	.btnBuy {
		width: 140px !important;
		padding: 0 !important;
		margin: 0 29%;
	}
	.txtBlue {
		margin-left: 10px;
		color: #211261;
	}
	.infoUser {
		min-width: 300px;
		width: 410px;
		height: 68px;
		padding: 0 5px 0 15px;
		border-right: 1px solid #fff;
		color: #fff;
		border-right: 1px solid #211164;
		display: flex;
		align-items: center;
		> a {
			display: flex;
			align-items: center;
			color: currentColor;
		}
		span {
			margin: 0;
			color: #211164;
			font-size: 16px;
			font-weight: 900;
			line-height: 24px;
			margin-left: 10px;
			width: fit-content;
			cursor: pointer;
		}
		.container-description {
			display: flex;
			flex-direction: column;
			align-items: left;
		}
		> span {
			font-weight: bold;
			font-size: 16px;
			color: #211164;
			margin-left: 10px;
			width: fit-content;
			${ifNotProp(
				"withRole",
				css`
					text-decoration: underline;
				`,
			)};
		}
	}
	.imgUser {
		height: 81.19px;
		width: 83.51px;
		border-radius: 50px;
		/* margin: 2px 3px 0 0; */
		background: #E3E3E3;
		${media.lessThan("large")`
			display: none;	
		`}
		img {
			width: 100%;
			height: 100%;
			border-radius: 45px;
            object-fit: cover
		}
	}
	.textInfoUser {
		margin-left: 10px;
		text-align: left;
		width: 36ch;
		overflow: hidden;
		h4 {
			color: #76236a;
			font-size: 20px;
			font-weight: 900;
			line-height: 34px;
			margin: 0;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		p {
			padding: 0;
			margin: 0;
			color: #76236a;
			font-size: 16px;
			font-weight: 900;
			line-height: 24px;
			max-width: 271px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	${ifProp(
		"isActived",
		css`
			.space {
				display: none;
			}
			max-width: 440px;
			.infoUser {
				border-right: none;
			}
		`,
	)}
	${media.lessThan("large")`
		grid-template-columns: 1fr 2fr;		
	`}
	${media.lessThan("medium")`
		grid-template-columns: 1fr;
		justify-content: space-around;
		.infoUser {
			display: none;
		}
		${ifProp(
			"isActived",
			css`
				max-width: 360px;
				.infoUser {
					display: flex;
				}
			`,
		)}

	`}
`;

export const UserConsumption = styled.div`
	display: flex;
	.classified {
		display: grid;
		grid-template-columns: 1fr 3fr;
		justify-items: center;
		> p {
			line-height: 16px;
			color: #fff;
			margin-left: 10px;
			text-align: left;
		}
		.descriptionConsumption {
			width: 100%;
			display: flex;
			justify-content: space-around;
			.iconType {
				width: 27px;
				height: 27px;
				display: block;
				margin: 0 auto;
			}
			.textConsumed {
				width: 100%;
				line-height: 0px;
				margin: 0;
				display: inline-block;
				margin: 3px 0 0;
				text-align: center;
				color: #fff;
			}
			.typeMb {
				.message {
					background: url(${spriteIcon}) -297px 0/550px 80px;
				}
				.video {
					background: url(${spriteIcon}) -326px 0/550px 80px;
				}
				.audio {
					background: url(${spriteIcon}) -356px 0/550px 80px;
				}
				.photo {
					background: url(${spriteIcon}) -386px 0/550px 80px;
				}
			}
		}
	}
	.space {
		animation: fadeInLeft 0.7s ease-in-out;
	}
	.blockBarSpace {
		text-align: left;
		color: #fff;
		margin: 0 30px;
	}
	.barSpace {
		background-color: #b5b5b5;
		margin-top: 5px;
		border-radius: 3px;
		height: 16px;
		border-radius: 8px;
		width: 100%;
	}
	.textUsed {
		display: flex;
		justify-content: space-between;
		color: #55535e;
		font-size: 17px;
		line-height: 20px;
		h2 {
			color: #76236a;
			font-size: 20px;
			font-weight: 900;
			line-height: 24px;
			margin: 0;
		}
	}
	.highlight {
		color: #fb8519;
	}
	.barUsed {
		background-color: #211164;
		width: ${prop("used", "0%")};
		height: 16px;
		border-radius: 8px;
		cursor: pointer;
	}
	.barSpace:hover + .tooltip {
		visibility: visible;
		opacity: 1;
		top: 90px;
	}
	.tooltip {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 42.46px;
		background-color: #464646;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		color: #fff;
		font-size: 15px;
		padding: 5px;
		position: absolute;
		border-radius: 15px;
		top: 88px;
		visibility: hidden;
		transition: opacity 0.5s, visibility 0.5s, top 0.5s;
		opacity: 0;
	}
	.tooltip::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		border-style: solid;
		border-width: 5px;
		border-color: transparent transparent #464646 transparent;
	}
	.buySpace {
		padding-right: 27px;
		margin-left: 8px;
		border-left: 1px solid #fff;
		display: flex;
		justify-content: flex-end;
		justify-items: center;
		button {
			font-size: 12px !important;
		}
	}
	.iconSpace {
		width: 19px;
		height: 19px;
		float: left;
		background: url(${spriteIcon2}) -193px -42px/550px 80px;
		margin-right: 4px;
	}
	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		button {
			font-size: 12px;
		}
		.classified {
			display: grid;
			grid-template-columns: 1fr;
			> p {
				padding: 0;
				text-align: center;
				line-height: 0px !important;
			}
			.descriptionConsumption {
				.iconType {
					margin: -3px auto;
				}
			}
		}
		.space {
			padding: 10px 15px 10px 0px;
		}
	}
	.arrow {
		height: 31.92px;
		width: 20.96px;
		background-image: url(${arrowWhite});
		background-size: contain;
		background-repeat: no-repeat;
		align-self: center;
		cursor: pointer;
		transition: all 0.5s ease-in;
		transform: rotate(180deg);
	}
	${ifProp(
		"isActived",
		css`
			.arrow {
				transform: rotate(0);
			}
		`,
	)}
	@media screen and (max-width: 991px) {
		grid-template-columns: 1fr;
		.space {
			padding: 10px 15px 10px 0px;
		}
		.classified {
			display: none;
		}
	}
	${media.lessThan("medium")`
		grid-template-columns: 1fr;
				
		.space {
			margin-left: 10px;
		}
		.blockBarSpace, .blockBarSpace {
			margin: 0;
		}
		.buySpace {
			align-items: center;
		}
		button {
			width: 129px;
		}
		

	`}
	${media.lessThan("small")`
		grid-template-columns: 1fr;
		.classified {
			display: none;
		}		
		.space {
			margin-left: 10px;
		}
		.buySpace {			
			padding-right: 6px;
		}
	`}
`;
