/*Dependencies */
import React, { Component, Fragment } from 'react';
/* i18n */
import { t } from '../../i18n';
/* Components*/
import FilesMessage from '../../components/FilesMessage';
import MessageResponse from '../../components/MessageResponse';

/* Form */
import PictureMessageForm from '../../form/ImageMessageEdit';

class PictureMessageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivedUpload: false,
      isActived: false,
    };
  }

  toggleIsActived = () =>
    this.setState({ isActivedUpload: !this.state.isActivedUpload });
  render() {
    const {
      message,
      match: {
        params: { messageId },
      },
      history: { push },
    } = this.props;
    return (
      <Fragment>
        <FilesMessage
          withHoutRecomendations
          title={t('pictureMessage.title')}
          notBox
        >
          <PictureMessageForm slug={messageId} push={push} />
        </FilesMessage>
        <MessageResponse isActived={message === 'setMessageUserSucces'} />
        <MessageResponse isActived={message === 'setMessageUserError'} />
      </Fragment>
    );
  }
}

export default PictureMessageEdit;
