// Dependencies
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	margin-top: 20px!important;
	display: none;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	animation: fadeInDown .23s ease-in-out;
	${media.lessThan('large')`
  grid-template-columns: 1fr;	
  `}
	${ifProp(
    'isActived',
    css`
			display: grid;
		`
  )}
	.button {
		display: none;		
		${ifProp(
    'isActivedButtonBeneficiaries',
    css`
			display: block;
			grid-column: 2 / 3;
			${media.lessThan('large')`
  		grid-column: 1;
  `}
		`
  )}
	}	
`
