/* Dependencies */
import React from 'react';
/* Styles */
import { SpanError, Select, Container } from './styles';
const SelectRedux = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
  children,
  isRegister,
  positionIcon,
}) => (
  <Container positionIcon={positionIcon}>
    <Select {...input} type={type} placeholder={placeholder}>
      {children}
    </Select>
    {touched && error && (
      <SpanError isRegister={isRegister}>
        {error[0]
          .split('-')
          .filter((_, index) => index !== 0)
          .toString() || error}
      </SpanError>
    )}
    <i />
  </Container>
);

export default SelectRedux;
