/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
/* i18n */
import { t } from '../../i18n';
/* Actions */
import {
  setMessageImage,
  getBasicMessageInformation,
  getFileTypes,
} from '../../redux/messages/actions';
/* Components */
import Button from '../../components/Button';
import TypeFileUpload from '../../components/TypeFileUpload';

import InputRedux from '../../fields/Input';
import FileInput from '../../fields/FileInput';
import SelectRedux from '../../fields/SelectInput';
/* Styles */
import { ModalAudio, Form, ContainerCam } from './styles';
/* Assets */
import rec from '../../assets/images/take-pic.jpg';
import upload from '../../assets/images/upload-pic.jpg';
/* utils */
import getDataURItoBlob from '../../utils/getDataURItoBlob';

const constrains = {
  title: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  evenType: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
};
class ImageMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      images: [],
      file: {},
      nameFile: '',
    };
  }

  componentDidMount() {
    const { getFileTypes, getBasicMessageInformation } = this.props;
    getBasicMessageInformation();
    getFileTypes();
  }

  onChangeFile = (event) =>
    this.setState((state) => ({
      id: state.id + 1,
      nameFile: event.name,
      images: [
        ...state.images,
        { id: state.id, data: event, url: URL.createObjectURL(event) },
      ],
    }));

  addImage = (image) => {
    const data = getDataURItoBlob(image);
    this.setState((state) => ({
      id: state.id + 1,
      images: [...state.images, { id: state.id, data, url: image }],
    }));
  };

  removeImage = (id) =>
    this.setState((state) => ({
      images: state.images.filter((images) => images.id !== id),
    }));

  toggleItem = (item) =>
    this.setState((state) => ({
      isActivedItem: item !== state.isActivedItem ? item : '',
    }));
  onChange = (data) => data && this.setState({ type: data.target.value });
  render() {
    const { nameFile } = this.state;
    const { handleSubmit, onSubmit, messageTypes, fileTypes } = this.props;
    const { images, isActivedItem, type } = this.state;
    const messageType = messageTypes.find(
      (messageType) => messageType.code === 'img'
    );
    const fileType = fileTypes.find((fileType) => fileType.code === 'image');
    return (
      <Form
        onSubmit={handleSubmit((values) =>
          onSubmit({
            ...values,
            fileUrl: images,
            messageTypeId: messageType === undefined ? 3 : messageType.id,
            fileTypeId: fileType.id,
          })
        )}
      >
        <div className="titleAndDate">
          <Field
            type="text"
            name="title"
            component={InputRedux}
            placeholder={t('writtenMessage.fields.title')}
          />
          {/* <Field name='eventTypeId' component='select'>
						<option>Selecionar tipo de evento</option>
						{eventTypes.map((eventType, key) => (
							<option key={key} value={eventType.id}>
								{eventType.name}
							</option>
						))}
					</Field> */}
          <Field
            onChange={this.onChange}
            name="evenType"
            component={SelectRedux}
          >
            <option>{t('fields.selectDate')}</option>
            <option value="absence">{t('fields.absances')}</option>
            <option value="date">{t('fields.dates')}</option>
          </Field>
          {type === 'date' && (
            <Field
              type="date"
              name="visibleData"
              component={InputRedux}
              placeholder={t('writtenMessage.fields.title')}
            />
          )}
        </div>
        <div className="actionMessage">
          <TypeFileUpload
            title={t('pictureMessage.takePhoto')}
            icon="fas fa-video"
            bgImg={rec}
            toggle={() => this.toggleItem('audio')}
          />
          <TypeFileUpload
            title={t('pictureMessage.attachImage')}
            icon="fas fa-image"
            bgImg={upload}
            toggle={() => this.toggleItem('file')}
          />
        </div>
        {isActivedItem === 'audio' && (
          <ContainerCam>
            <Camera
              onTakePhoto={(dataUri) => {
                this.addImage(dataUri);
              }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              idealResolution={{ width: 640, height: 480 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.97}
              isMaxResolution={false}
              isImageMirror={false}
              isSilentMode={false}
              isDisplayStartCameraError
              isFullscreen
              sizeFactor={1}
            />
          </ContainerCam>
        )}
        {isActivedItem === 'file' && (
          <ModalAudio>
            <label>{t('pictureMessage.format')}</label>
            <Field
              component={FileInput}
              name="fileUrl"
              type="file"
              nameFile={nameFile}
              onChange={this.onChangeFile}
              accept=".jpg, .png, .gif"
            />
          </ModalAudio>
        )}
        <div>
          <div className="selectPhotos">
            {images.map((image, key) => (
              <div key={key} className="animated fadeInRight">
                <Button
                  type="button"
                  isTransparent
                  onClick={() => this.removeImage(image.id)}
                >
                  <i className="fas fa-times-circle" />
                </Button>
                <div className="containerImagePreview">
                  <img src={image.url || image.data} alt="img-preview" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="containerBtnSubmit">
          <Button bgColor="#DF382F" bgColorHover="#892B6E" width="50%">
            <i className="far fa-clock" /> {t('buttons.scheduleMessage')}
          </Button>
        </div>
      </Form>
    );
  }
}

ImageMessage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  const message = store.messages;
  return {
    eventTypes: message.eventTypes || [],
    messageTypes: message.messageTypes || [],
    fileTypes: message.fileTypes || [],
  };
}

function mapDispachtToProps(dispatch) {
  return {
    onSubmit: (messageWithFile, cb) =>
      dispatch(setMessageImage(messageWithFile, cb)),
    getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
    getFileTypes: () => dispatch(getFileTypes()),
  };
}

function margeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (value) => {
      dispatchProps.onSubmit(value, () => ownProps.history.push('/'));
    },
  };
}

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'imageMessage',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(ImageMessage);
