import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'
// Assets
import icon from '../../assets/images/sprite-icons.svg'
import engine from '../../assets/images/engine.png'
import plus from '../../assets/images/plus.png'
import user from '../../assets/images/user.png'

export const Nav = styled.nav`
	display: flex;
	align-items: center;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);  
	padding: 0.7rem 1.8rem;
	background-color: #f8f8f8;
	height: 58px;
	margin-bottom: 58px;
	border: 1px solid transparent;
  justify-content: space-between;  
  position: fixed!important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);  
  }
  .container-notifications {
    margin-top: 45px;
    max-height: 450px;
    overflow: auto;
  }
  .imgUser {
		height: 45.19px;
    width: 39.51px;
		border-radius: 30px;
		margin: 2px 3px 0 0;	
    border-radius: 25px;	
		img {
			width: 100%;
      border-radius: 24px;
		}
	}
  .formBeneficiare {
		width: 85%;
		margin: 0 auto;
		margin-top: 31px;
		h3 {
			color: #342769;
			font-size: 23px;
		}
		p {
			font-size: 15px;
		}
		.flex {
			display: flex;
		}
    button {
      margin-bottom: 31px;
    }
	}
    .muted {
      top: 200px!important;
    }
    .clearFix {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 260px;
			height: 38px;
      padding: 10px 20px;					
      text-transform: uppercase;	
      a {
        font-size: 14px;
        width: 90%;
        height: 90%;
        margin-left: 11px;
      }
      button {
        grid-column: 3;
      }
    }
	.logo {
		width: 123px;
		height: 48px;
	}
	.notificationResponsive {
		align-items: center;
		display: none;
	}
	.notificationFull {
		display: grid;
		grid-template-columns: 2fr 1fr;
		align-items: center;    		
    padding-left: 25px;
    border-left: 1px solid #211164;
    height: 44px;
    button {
       > div {
        left: -3px; 
        a {
      width: 100%;
      
    }
       }      
       }
    }    
		> button {
			position: none !important;
		}
	}	
	.setting {
		display: flex;
		align-items: center;
		justify-content: flex-end;
    ${media.lessThan('medium')`     
        display: none;
    `}
		span {
			span {
				width: 22px;
				height: 22px;
				margin-right: 10px;
				background: url(${engine});
        background-repeat: no-repeat;
        background-size: contain;
				display: inline-block;
        cursor: pointer;
			}
		}
	}
  .userIcon {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		span {
			span {
				width: 22px;
				height: 22px;
				margin-right: 10px;
				background: url(${user});
        background-repeat: no-repeat;
        background-size: contain;
				display: inline-block;
        cursor: pointer;
			}
		}
	}
	${ifProp(
		'absolute',
		css`
			position: absolute;
			width: 100%;
			height: 80px;
			left: 0;
			top: 0;
			right: 0;
			z-index: 10;
		`
	)}
  ${media.lessThan('large')`     
      .notificationFull{
        display:none;                                
        }             
      .notificationResponsive {
        display: flex;
      }      
    `}
    ${media.lessThan('medium')`     
    z-index: 11;
    `}
`
export const Anav = styled.div`
	margin-left: 51px;
	font-size: 18px;
	line-height: 20px;
	color: #777;
	position: relative;
	img {
		max-width: 100%;
		max-height: 50px;
		border: 0;
		padding: 0;
	}
	${media.lessThan('medium')`
    margin-left: 0;    
  `}
`
export const Ul = styled.ul`
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  width: 73%;    
  padding: 0;  
  .notification {
    display: none;    
  }
  
  .links {
    display: flex;    
    align-items: center;
    justify-content: flex-end;
    a, span {
      .iconSetting {
      background: url(${icon}) -185px 0/550px 80px;
    }
    }
  }
  .links Link, a, span {
    font-size: 14px;
    color: #342769;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 3rem;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }
  }
  .links span:hover,a:hover {
    color: #ff8400 !important;
  }
  > div {
    height: 30px;    
  }  
  .language {
    margin-right: -10px;
    margin-left: 15px;
  }
  .navPlus{
    height: 16.11px;
    width: 15px;
    margin: 10px;
    background-image: url(${plus});
    background-repeat: no-repeat;
    :hover {
      height: 4.11px;
      background-image: none;
      background-color: #342769;      
    }    
  } 
  .tooltipNav {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 35px auto;      
    > a, span {
      
    }
    > a {
      margin-bottom: 25px; 
    }
  }

  ${media.lessThan('large')`        
    /*width: 70%;    
    justify-content: flex-start;            
    .links a, span {            
      font-size: 12px;               
      margin-right: 1.5rem;
      &:last-child{
        .links a, span {
          width: 100%;      
          display: block;
          padding: 10px 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 20px; 
          box-sizing: border-box;
          max-width: 100%;
          margin: 0;
          cursor: pointer;
        }
      }    
    }*/
    a, span {
      margin-right: 1.5rem;
    }
  `}  
  ${media.lessThan('medium')`    
    position: absolute;
    width: 100%;
    height: 0;
    top: 100%;        
    box-sizing: border-box;
    left: 0;
    padding: 0;
    border-top: thin solid #ddd;
    background-color: #f8f8f8;
    display: block;
    overflow: hidden;        
    
    ${ifProp(
			'actived',
			css`
				animation: fadeInDown 0.4s ease-in-out;
				overflow: visible;
        height: auto;
				max-height: 232px;
			`
		)}
    .notification {
     display: inline-block;
     /* position: absolute; */
     margin-left: 16px;
     div {
      a {
        padding: 0px;
        margin-left: 19px;
      }
    }
    }
    .links {
      display: block;      
      width: 100%;
      height: auto;
      padding-bottom: 4px;
      min-height: 40px;
      height: auto;
      margin: 0
    }
    .links a, span {
      width: 100%;      
      display: block;
      padding: 10px 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px; 
      box-sizing: border-box;
      max-width: 100%;
      margin: 0;
      cursor: pointer;
    }
    .navPlus {
      margin-left: 15px;
    }
    .left {
      padding: 5px 0 0 0;
    }
    .mediaButton {
      display: none;            
    }
    .language {            
      width: 100%;
      margin: 0;
      border: 0;            
      padding-left:10px;      
      box-sizing: border-box;
      left: -10px;        
    }        
  `}
  .mediaButton {      
      &:hover {
      animation: pulse 1s;
    }
`

export const ChangePassword = styled.div`
	width: 350px;
	margin: 0 auto;
	margin-top: 10px;
	h3 {
		color: #211261;
		font-size: 23px;
		margin-bottom: 26px;
	}
	.margin {
		margin-bottom: 15px;
		height: 50px;
	}
	button {
		width: 185px;
		float: right;
	}
	${media.lessThan('medium')`
    width: auto;  
  `}
`
export const ForgotPasswordModal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	text-align: center;
	form {
		> h3,
		span {
			color: #211261;
		}
		img {
		}
		> h3 {
			font-size: 19px;
			line-height: 21px;
			margin: 10px 0;
			font-family: inherit;
			font-weight: bold;
		}
		> p {
			color: #333;
			font-size: 13px;
		}
		> input {
			margin: 30px 0;
			width: 100%;
		}
		> div {
			margin-right: 19px;
		}
	}
`

export const ContainerForm = styled.div`
	.formBeneficiare {
		width: 85%;
		margin: 0 auto;
		margin-top: 31px;
		h3 {
			color: #342769;
			font-size: 23px;
		}
		p {
			font-size: 15px;
		}
		.flex {
			display: flex;
		}
		button {
			margin-bottom: 31px;
		}
	}
`
