// Dependencies
import styled from 'styled-components'

export const Container = styled.div`
	width: 45px;
	height: 45px;
	border-radius: 25px;
	background: #892b6e;
	box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.2);
	position: fixed;
	right: 15px;
	bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
	&:hover {
		animation: pulse 1s;
	}
	display: none;
`
