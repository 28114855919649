import INITIAL_STATE from './initialState'
import { QUESTION, QUESTIONS } from './const'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUESTION:
      return Object.assign(
        {},
        {
          ...state,
          isActivedQuestion: action.value
        }
      )
    case QUESTIONS:
      return Object.assign(
        {},
        {
          ...state,
          questions: action.value
        }
      )
    default:
      return state
  }
}
