//Dependencies
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// i18n
import { t } from "../../i18n";
import { setModal } from "../../redux/app/actions";
//Components
import FilesMessage from "../../components/FilesMessage";
import ModalPreview from "../../components/ModalPreview";
import MessageResponse from "../../components/MessageResponse";

import VideoMessageForm from "../../form/VideoMessage";

class VideoMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActivedUpload: false,
			isActivedVideoRecorder: false,
		};
	}

	toggleIsActived = (keyName) =>
		this.setState((state) => ({ [keyName]: !state[keyName] }));
	render() {
		const { user, messagePreview, modal, setModal, message } = this.props;
		return (
			<Fragment>
				<FilesMessage
					withHoutRecomendations
					title={t("videoMessage.title")}
					eraser
					notButton
					notBox
				>
					<VideoMessageForm push={this.props.history.push} availableSpace={user[0] && user[0].avaliableSpace}/>
				</FilesMessage>
				{modal === "isActivedMessagePrevieww" && (
					<ModalPreview
						style={{ width: "100%" }}
						user={user[0]}
						messagePreview={messagePreview}
						toggle={() => setModal("")}
					>
						<video
							style={{ width: "100%" }}
							src={messagePreview.fileUrl}
							controls
						/>
					</ModalPreview>
				)}
				{message === "setMessageVideoSuccess" && <MessageResponse isActived />}
				{message === "setMessageVideoError" && (
					<MessageResponse
						isActived
						bgAlert='red'
						message={t("messagesResponse.notSpace")}
					/>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(store) {
	return {
		user: store.user.user || [],
		messagePreview: store.messages.messagePreview || [],
		modal: store.app.modal,
		message: store.app.message,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setModal: (modal) => dispatch(setModal(modal)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoMessage);
