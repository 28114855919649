import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`  
  width: 100%;  
  height: 100vh;  
  .buttonStart {
    animation: fadeInUp 1.5s ease-out;
    ${media.lessThan('large')`    
        display: none;
  `}
  }
`
