/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
import moment from 'moment';
import { t } from '../../i18n';
/* Actions */
import { setModal } from '../../redux/app/actions';

import {
  setUploadFile,
  getSelectMessage,
  setSelectedBeneficiaries,
  setMessageWrittenEdit,
  setMessagePreview,
  saveEditDraftLocaleStorege,
} from '../../redux/messages/actions';

const SAVE_DRAFT = 'saveDraft';

function getEditLocal() {
  return window.location.href.split('?').pop() === 'local=true';
}

function validateForm(values, props) {
  const constrains = {
    title: {
      presence: {
        message: t('validationsInputs.required'),
      },
    },
    evenType: {
      presence: {
        message: t('validationsInputs.required'),
      },
    },
  };
  const validated = validate(values, constrains);
  // if (
  // 	props.formMessage &&
  // 	props.formMessage.fields &&
  // 	(props.formMessage.fields.title.touched &&
  // 		props.formMessage.fields.title.error)
  // ) {
  // 	props.setMessage({ name: 'withError', message: 'error' })
  // 	setTimeout(() => props.setMessage(''), 2300)
  // } else {
  // 	props.setMessage('')
  // }
  return validated;
}

const getMessageLocalStorage = ({ draftId, user }) => {
  if (!user.length) {
    return {};
  }
  const draftLocalStorageDetail = JSON.parse(
    localStorage.getItem(`${SAVE_DRAFT}${user[0].id}`)
  ).find((item) => item.id === parseInt(draftId));

  return {
    draftLocalStorageDetail,
  };
};

function mapStateToProps({
  messages: { eventTypes, messageTypes, draftLocaleStorage },
  user: { user },
}) {
  let initialValues = {};
  if (draftLocaleStorage && draftLocaleStorage.id) {
    initialValues = {
      title: draftLocaleStorage.title,
      visibleData: moment(draftLocaleStorage.visibleData).format('YYYY-MM-DD'),
      type: draftLocaleStorage.visibleData ? 'date' : 'absence',
      messageId: draftLocaleStorage.id,
      messageTypeId: draftLocaleStorage.messageTypeId,
      fileTypeIdText: draftLocaleStorage.messageFiles[0].id,
      evenType: !draftLocaleStorage.visibleData ? 'absence' : 'date',
    };
  }
  return {
    eventTypes,
    messageTypes,
    initialValues,
    draftLocaleStorage,
    user,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUploadFile,
      getSelectMessage,
      setSelectedBeneficiaries,
      setModal,
      setMessagePreview,
      setMessageWrittenEdit,
      saveEditDraftLocaleStorege,
    },
    dispatch
  );

function margeProps(stateProps, dispatchProps, ownProps) {
  const { slug, isLocal } = ownProps;
  const draftLocaleStorage = stateProps.draftLocaleStorage;
  let initialValues = stateProps.initialValues;
  let editorSateLocal = null;
  if (isLocal) {
    const { draftLocalStorageDetail } = getMessageLocalStorage({
      draftId: slug,
      user: stateProps.user,
    });
    if (draftLocalStorageDetail) {
      initialValues = {
        title: draftLocalStorageDetail.title,
        visibleData: moment(draftLocalStorageDetail.visibleData).format(
          'YYYY-MM-DD'
        ),
        type: draftLocalStorageDetail.visibleData ? 'date' : 'absence',
        messageTypeId: draftLocalStorageDetail.messageTypeId,
        evenType: !draftLocalStorageDetail.visibleData ? 'absence' : 'date',
      };
    }
  } else {
    if (draftLocaleStorage && draftLocaleStorage.id) {
      initialValues = {
        title: draftLocaleStorage.title,
        visibleData: moment(draftLocaleStorage.visibleData).format(
          'YYYY-MM-DD'
        ),
        type: draftLocaleStorage.visibleData ? 'date' : 'absence',
        messageId: draftLocaleStorage.id,
        messageTypeId: draftLocaleStorage.messageTypeId,
        fileTypeIdText: draftLocaleStorage.messageFiles[0].id,
        evenType: !draftLocaleStorage.visibleData ? 'absence' : 'date',
      };
    }
  }

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initialValues,
    editorSateLocal,
    onSubmit: (value) => {
      dispatchProps.setMessageWrittenEdit(
        value,
        () => ownProps.history.push('/'),
        ownProps.isLocal,
        ownProps.slug
      );
    },
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps, margeProps),
  reduxForm({
    form: 'writtenMessageEdit',
    validate: validateForm,
    enableReinitialize: getEditLocal(),
  })
);
