import React from 'react'

import { Container } from './styles'

function Recomendation({ isSelected, title, description, onClick }) {
	return (
		<Container onClick={onClick} isSelected={isSelected}>
			<div>
				<h4>{title}</h4>
				<p>{description}</p>
			</div>
			<span>{isSelected && <i className='fas fa-check' />}</span>
		</Container>
	)
}

export default Recomendation
