// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
// Styled component
import { Container } from './styles';

function TooltipRight(props) {
  const { title, img, small, link, toggle, font } = props;
  return (
    <Container small={small} font={font} onClick={toggle}>
      {title && <div className="txt">{title}</div>}
      {link ? (
        <Link to={link}>
          <div className="circleImage">
            {!font ? <img src={img} alt="tolp" /> : <i className={font} />}
          </div>
        </Link>
      ) : (
        <div className="circleImage">
          {!font ? <img src={img} alt="tolp" /> : <i className={font} />}
        </div>
      )}
    </Container>
  );
}

export default TooltipRight;
