/* Dependencies */
import styled from 'styled-components';

const TermsAndConditions = styled.div`
  h3 {
    color: #211261;
  }
  /* > p {
    text-align: justify;
    font-size: 13px;
    color: #333;
    line-height: 22px;
    margin: 0 0 10px;
    padding: 0 1rem;
  } */
  button {
    background: #ff8400;
    padding: 7.5px 20px;
    border: none;
    border-radius: 25px;
    margin: 20px auto 20px;
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;
    color: white;
    outline: none;
    &:hover {
      animation: pulse 1s;
    }
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
`;

export default TermsAndConditions;
