/* Dependencies */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/* Redux */
import {
  setUser,
  getLanguage,
  getAssingRoles,
  setModalTerms,
} from './redux/app/actions';
import { getSession } from './redux/user/actions';
/* Components */
import Nav from './components/Nav';
import NavLogin from './components/NavLogin';

import Loader from './components/Loader';

import MessageResponse from './components/MessageResponse';

/*  Styles */
import './App.css';

class App extends Component {
  componentDidMount() {
    const { getLanguageStart, getSessionStart } = this.props;
    getLanguageStart();
    getSessionStart();
    // firebase.auth().onAuthStateChanged(user => {
    // 	console.log({user});
    // 	setUser(user)
    // })
    // this.props.getAssingRoles()
  }
  render() {
    const {
      isLoginUser,
      isAppLoading,
      withoutSession,
      message: { name, message },
      userIdBeneficiaryData,
      userIdVerifierData,
      dataUserRoles,
      location,
      withSession,
      children,
    } = this.props;
    return (
      <Fragment>
        {isAppLoading && <Loader />}
        <header>
          {!isLoginUser ? (
            <Nav />
          ) : (
            <NavLogin
              location={location}
              dataUserRoles={dataUserRoles}
              userIdBeneficiaryData={userIdBeneficiaryData}
              userIdVerifierData={userIdVerifierData}
            />
          )}
        </header>
        {isLoginUser ? withSession : withoutSession}

        {name === 'withError' && (
          <MessageResponse bgAlert="red" isActived message={message} />
        )}
        {children}
        {name === 'success' && <MessageResponse isActived message={message} />}
      </Fragment>
    );
  }
}

App.defaultProps = {
  children: null,
};

App.propTypes = {
  children: PropTypes.node,
  //   autorizeUser: PropTypes.func.isRequired,
  getLanguageStart: PropTypes.func.isRequired,
  isLoginUser: PropTypes.any.isRequired,
  isAppLoading: PropTypes.bool.isRequired,
  message: PropTypes.any.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    setUser: (value) => dispatch(setUser(value)),
    getLanguageStart: () => dispatch(getLanguage()),
    getAssingRoles: (user) => {
      dispatch(getAssingRoles(user));
    },
    setModalTerms: (active) => dispatch(setModalTerms(active)),
    getSessionStart: () => dispatch(getSession()),
  };
}

function mapStateToProps(store) {
  return {
    isLoginUser: store.app.isLoginUser,
    isAppLoading: store.app.isAppLoading,
    message: store.app.message,
    userIdBeneficiaryData: store.app.userIdBeneficiaryData,
    userIdVerifierData: store.app.userIdVerifierData,
    dataUserRoles: store.app.dataUserRoles,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
