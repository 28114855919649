// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
// i18n
import { t } from "../../i18n";
// const routes
import { routes } from "../../routes";
// Components
import Message from "../../components/Message";
import Button from "../../components/Button";
// Styles
import { Description, ButtonRight } from "./styles";
// Assets
import img from "../../assets/images/male-user.png";
/* Depencies */
import {
	getDraftLocaleStoregeBySlug,
	deleteLocaltoregeByMessage,
} from "../../redux/messages/actions";
import moment from "moment";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { Facebook } from "react-content-loader";
import HTMLtoDOCX from "html-to-docx";

class SeeMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageWritten: "",
		};
	}
	componentDidMount() {
		const {
			match: {
				params: { slug },
			},
			getDraftLocaleStoregeBySlug,
		} = this.props;
		getDraftLocaleStoregeBySlug(slug);
	}

	componentDidUpdate(prevProps) {
		if (this.props.messagesBeneficiary !== prevProps.messagesBeneficiary) {
			this.getFile();
		}
	}

	getFile = async () => {
		try {
			const dataMessage = this.props.messagesBeneficiary.find((data) => {
				const slugId = this.props.match.params.slug;
				return data.messageId === parseInt(slugId);
			});

			if (!dataMessage && !dataMessage.message) {
				return;
			}

			try {
				let url = "https://proxy.cors.sh/";

				// let url = 'https://cors-anywhere.herokuapp.com/';
				const { data } = await axios({
					method: "GET", //you can set what request you want to be
					url:
						url +
						dataMessage.message.messageFiles.find((el) => el.ext === ".html")
							.fileUrl,
					headers: {
						"x-cors-api-key": "temp_366c37f9d20ece510130f18dcb137cd1",
					},
				});
				this.setState({
					messageWritten: data,
				});
			} catch (e) {
				console.log(e);
			}
		} catch (e) {
			console.log(e);
		}
	};

	getRole = () => parseInt(localStorage["role"]);

	ImageDownloader = async (messageFiles, code) => {
		if (code === "img" && messageFiles.length > 0) {
			const zip = new JSZip();

			// Itera sobre las URL de las imágenes y agrega cada una al archivo zip
			await Promise.all(
				messageFiles.map(async (url, index) => {
					const urlCors = "https://cors-proxy.fringe.zone/";
					const response = await fetch(urlCors + url.fileUrl, {
						headers: {
							"x-cors-api-key": "temp_366c37f9d20ece510130f18dcb137cd1",
						},
					});

					const blob = await response.blob();
					zip.file(`image${index + 1}.jpg`, blob, { binary: true });
				}),
			);

			// Genera el archivo zip
			const content = await zip.generateAsync({ type: "blob" });

			// Descarga el archivo zip utilizando file-saver
			saveAs(content, "images.zip");
		}
	};

	handleDownloadPDF = async (url) => {
		const urlCors = "https://proxy.cors.sh/";
		const response = await fetch(urlCors + url, {
			headers: {
				"x-cors-api-key": "temp_366c37f9d20ece510130f18dcb137cd1",
			},
		});
		const html = await response.text();

		const data = await HTMLtoDOCX(html);
		saveAs(data, "mensajeTexto.docx");
	};

	render() {
		const { messagesBeneficiary } = this.props;
		const dataMessage = messagesBeneficiary.find((data) => {
			const slugId = this.props.match.params.slug;
			return data.messageId === parseInt(slugId);
		});

		const draftLocaleStorage = dataMessage && dataMessage.message;

		return (
			<Message record titles={this.getRole()}>
				{dataMessage !== undefined ? (
					<div>
						<div
							className='buttons'
							style={{ display: "flex", marginLeft: 10 }}
						>
							<Link to={routes.HOME}>
								<Button bgColorHover='#892B6E'>{t("buttons.return")}</Button>
							</Link>
							<ButtonRight>
								{((dataMessage &&
									dataMessage.message.messageType.code === "img") ||
									dataMessage.message.messageType.code === "written") && (
									<Button style={{ marginLeft: 10, background: "#ff8400" }}>
										{dataMessage.message.messageType.code === "img" ? (
											<>
												<a
													style={{ color: "white" }}
													onClick={() =>
														this.ImageDownloader(
															dataMessage.message.messageFiles,
															dataMessage.message.messageType.code,
														)
													}
												>
													{t("buttons.downloadMessage")}
												</a>
											</>
										) : (
											<a
												style={{ color: "white" }}
												onClick={() =>
													this.handleDownloadPDF(
														dataMessage.message.messageFiles.length
															? draftLocaleStorage.messageFiles.find(
																	(el) => el.ext === ".html",
															  ).fileUrl
															: "",
													)
												}
												href='#'
											>
												{t("buttons.downloadMessage")}
											</a>
										)}
									</Button>
								)}
							</ButtonRight>
						</div>
						<Description>
							<div className='imgUser'>
								<div className='circleImg'>
									<img
										className='imgContent'
										src={dataMessage.message.myUser.imageUrl}
										alt='user-profile'
									/>
								</div>
								<div className='msmInfo'>
									<div>
										<h2>
											{`${dataMessage.message.myUser.firstName} ${dataMessage.message.myUser.lastName}`}
										</h2>
										<h3>
											<em>{dataMessage.message.title}</em>
										</h3>
									</div>
									<div className='infoSpace'>
										<br />
										<span className='text-left'>
											{t("seeDraft.creationDate")}:{" "}
											{moment(dataMessage.message.createdAt).format("L")}
											<br />
											{t("seeDraft.displayDate")}:{" "}
											{moment(dataMessage.message.updatedAt).format("L")}
										</span>
									</div>
								</div>
							</div>
							<div className='txt'>
								<div className='topBlue' />
								{((draftLocaleStorage.messageType &&
									draftLocaleStorage.messageType.code === "img") ||
									(draftLocaleStorage.messageFiles &&
										draftLocaleStorage.messageFiles.length > 0 &&
										draftLocaleStorage.messageFiles[0].fileTypeId === 3)) &&
									draftLocaleStorage.messageFiles.map((item) => (
										<img
											style={{
												display: "block",
												margin: "0 auto",
												marginTop: "20px",
												maxWidth: "479px",
											}}
											src={item.fileUrl}
											alt='beneficiary'
										/>
									))}
								{((draftLocaleStorage.messageType &&
									draftLocaleStorage.messageType.code === "written") ||
									(draftLocaleStorage.messageFiles &&
										draftLocaleStorage.messageFiles.length > 0 &&
										(draftLocaleStorage.messageFiles[0].fileTypeId === 1 ||
											draftLocaleStorage.messageFiles[0].fileTypeId === 2) &&
										(draftLocaleStorage.messageType.code === "event" ||
											draftLocaleStorage.messageType.code === "advice"))) && (
									<>
										{draftLocaleStorage.messageFiles.length > 1 && (
											<a
												href={
													draftLocaleStorage.messageFiles.find(
														(el) => el.ext !== ".html",
													).fileUrl
												}
												download={
													draftLocaleStorage.messageFiles.find(
														(el) => el.ext !== ".html",
													).fileUrl
												}
											>
												<Button bgColorHover='#ff8400'>
													{draftLocaleStorage.messageFiles.find(
														(el) => el.ext !== ".html",
													).name || t("messagesResponse.attachedFile")}{" "}
													<i className='fas fa-cloud-download-alt' />
												</Button>
											</a>
										)}

										{draftLocaleStorage.messageFiles.find(
											(el) => el.ext === ".html" || ".txt",
										) ? (
											this.state.messageWritten ? (
												<div
													className='description'
													dangerouslySetInnerHTML={{
														__html: this.state.messageWritten,
													}}
												/>
											) : (
												<Facebook
													style={{ marginTop: "20px" }}
													height={280}
													width={280}
													speed={2.5}
													primaryColor='
								rgba(118, 35, 106, .8)
								'
													secondaryColor='rgba(251, 133, 25, .8)'
												/>
											)
										) : (
											<></>
										)}
									</>
								)}
								{((draftLocaleStorage.messageType &&
									draftLocaleStorage.messageType.code === "video") ||
									(draftLocaleStorage.messageFiles &&
										draftLocaleStorage.messageFiles.length > 0 &&
										(draftLocaleStorage.messageFiles[0].fileTypeId === 5 ||
											draftLocaleStorage.messageFiles[0].fileTypeId ===
												6))) && (
									<video
										controls
										style={{
											display: "block",
											margin: "0 auto",
											marginTop: "20px",
											maxWidth: "337px",
										}}
										src={
											draftLocaleStorage.messageFiles[0].fileUrl &&
											draftLocaleStorage.messageFiles[0].fileUrl
										}
										alt='beneficiary'
									/>
								)}
								{((draftLocaleStorage.messageType &&
									draftLocaleStorage.messageType.code === "audio") ||
									(draftLocaleStorage.messageFiles &&
										draftLocaleStorage.messageFiles.length > 0 &&
										draftLocaleStorage.messageFiles[0].fileTypeId === 4 &&
										(draftLocaleStorage.messageType.code === "event" ||
											draftLocaleStorage.messageType.code === "advice"))) && (
									<div>
										<audio controls>
											<source
												src={draftLocaleStorage.messageFiles[0].fileUrl}
												type='audio/wav'
											/>
										</audio>
									</div>
								)}
								<div className='separatorbottom' />
							</div>
						</Description>
					</div>
				) : (
					<div className='buttons'>
						<Link to={routes.HOME}>
							<Button bgColorHover='red'>{t("buttons.sorry")}</Button>
						</Link>
					</div>
				)}
			</Message>
		);
	}
}

function mapStateToProps(store) {
	return {
		about: store.home.homes[0] ? store.home.homes[0].about : [],
		draftLocaleStorage: store.messages.draftLocaleStorage || [],
		user: store.user.user || [],
		messagesBeneficiary: store.user.messagesBeneficiary.length
			? store.user.messagesBeneficiary
			: [],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getDraftLocaleStoregeBySlug: (slug) =>
			dispatch(getDraftLocaleStoregeBySlug(slug)),
		deleteLocaltoregeByMessage: () => dispatch(deleteLocaltoregeByMessage()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeMessage);
