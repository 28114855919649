// Dependencies
import styled from 'styled-components'

export const Container = styled.div`	
	position: relative;  	
	.title {
		color: #fff;
		font-size: 21px;
		line-height: 24px;
	}
	img {
		width: 100%;
	}
	.btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 42%;
	}
	.btn {
		font-size: 30px;
		width: 26px;
		height: 70px;
		line-height: 70px;		
		background-color: rgba(33, 18, 97, 0.75);
		text-align: center;
		font-weight: bold;
		color: #fff;
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
		transition: all 0.3s ease;
		&:hover {
			cursor: pointer;
			transform: scale(1.2, 1.2);
		}
	}

	.left {
		left: 2%;
		top: calc(50% - 70px);
	}

	.right {
		right: 2%;
		top: calc(50% - 70px);
	}
	.bxCaption {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;		
		background: rgba(33, 18, 97, 0.75);		
		p {
			font-size: 16px;
			color: inherit;
			font-style: inherit;
			font-family: inherit;
			span {
				color: #fff;
				font-family: Arial;
				display: block;
				font-size: 0.85em;
				padding: 10px;
				min-height: 1px;
				transition: all 1s ease;
			}
		}
	}
  .bxPager {
    text-align: center;
    font-size: 0.85em;
    font-family: Arial;
    font-weight: 700;
    color: rgb(102, 102, 102);
    padding-top: 20px;
		position: absolute;
    bottom: -30px;
    span {
			background: rgb(255, 132, 0);
    }
  }
  
`
