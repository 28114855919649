import request from '../../libs/request';
import { filterByLanguage, getLanguageById } from '../../i18n';
import getFormDataObject from '../../utils/getFormatData';

const endpoints = {
  messages: '/messages',
  messageType: '/message-types',
  messagesFiles: 'message-files/create-with-file',
  deleteFile: '/delete-files-of-messages',
  updateWithFile: '/update-with-file',
  messageFile: '/message-files',
  eventTypes: '/event-types',
  adviceTypes: '/advice-types',
  fileTypes: '/file-types',
  messageBeneficary: '/message-beneficiaries',
  count: '/count',
  recommendationEvents: 'recommendation-events',
  recommendations: '/recommendations',
  adviceRecommendationsTypes: '/advice-recommendations-types',
  adviceRecommendations: '/advice-recommendations',
};
class MessageService {
  static setMessage(message) {
    return request.post(endpoints.messages, message);
  }
  static updateMessage(messageId, message) {
    return request.patch(`${endpoints.messages}/${messageId}`, message);
  }
  static deleteFiles(messageId, files) {
    return request.delete(
      `${endpoints.messageFile}/${messageId}${endpoints.deleteFile}`,
      {
        data: { files },
      }
    );
  }
  static getMessageType() {
    return request.get(`${endpoints.messageType}`);
  }
  static getEventTypes() {
    const filter = JSON.stringify({
      where: {
        languageId: getLanguageById(),
      },
      include: ['events', 'recommendations'],
    });
    return request.get(`${endpoints.recommendationEvents}?filter=${filter}`);
  }

  static getAllEventTypes() {
    const filter = JSON.stringify({
      where: {
        languageId: getLanguageById(),
      },
    });
    return request.get(`${endpoints.recommendations}?filter=${filter}`);
  }
  static getAdviceTypes() {
    const filter = JSON.stringify({
      where: {
        languageId: getLanguageById(),
      },
      include: ['advices', 'recommendations'],
    });
    return request.get(
      `${endpoints.adviceRecommendationsTypes}?filter=${filter}`
    );
  }

  static getAllAdviceTypes() {
    // const filter = JSON.stringify({
    // 		where: {
    // 			languageId: getLanguageById()
    // 		}
    // 	})
    return request.get(
      `${endpoints.adviceRecommendations}?filter=${filterByLanguage}`
    );
  }
  static getfileTypes() {
    return request.get(endpoints.fileTypes);
  }
  static createWithFile(messageWithFile) {
    return request.post(
      endpoints.messagesFiles,
      getFormDataObject(messageWithFile)
    );
  }

  static createWithFileFormData(messageWithFile) {
    return request.post(endpoints.messagesFiles, messageWithFile);
  }

  static updateWithFile(messageId, messageWithFile) {
    const { messageFile, updateWithFile } = endpoints;
    return request.patch(
      `${messageFile}/${messageId}${updateWithFile}`,
      getFormDataObject(messageWithFile)
    );
  }
  static updateFile(messageId, messageWithFile) {
    const { messageFile, updateWithFile } = endpoints;
    return request.patch(
      `${messageFile}/${messageId}${updateWithFile}`,
      getFormDataObject(messageWithFile)
    );
  }

  static setMessageBeneficary(message) {
    return request.post(endpoints.messageBeneficary, message);
  }
  static updateBeneficiares(messageId, beneficiares) {
    return request.patch(
      `${endpoints.messageBeneficary}/${messageId}/update-beneficirares`,
      beneficiares
    );
  }
  static getMessageByUser(userId) {
    const filter = JSON.stringify({
      where: { and: [{ userId }, { messageTypeId: { between: [1, 6] } }] },
      order: 'id DESC',
      include: [
        { relation: 'messageFiles', scope: { fields: ['fileUrl', 'size'] } },
        { relation: 'messageType', scope: { fields: ['code'] } },
        {
          relation: 'messageBeneficiaries',
          scope: {
            fields: ['beneficiaryId'],
            include: {
              relation: 'beneficiary',
              scope: {
                fields: ['firstName'],
              },
            },
          },
        },
      ],
    });
    return request.get(`${endpoints.messages}?filter=${filter}`);
  }
  static getMessageEventsByUser(userId) {
    const filter = JSON.stringify({
      where: { and: [{ userId }, { messageTypeId: { between: [7, 8] } }] },
      order: 'id DESC',
      include: [
        { relation: 'messageFiles', scope: { fields: ['fileUrl', 'size'] } },
        { relation: 'messageType', scope: { fields: ['code'] } },
        {
          relation: 'messageBeneficiaries',
          scope: {
            fields: ['beneficiaryId'],
            include: {
              relation: 'beneficiary',
              scope: {
                fields: ['firstName'],
              },
            },
          },
        },
      ],
    });
    return request.get(`${endpoints.messages}?filter=${filter}`);
  }

  static getMessageAdviceByUser(userId) {
    const filter = JSON.stringify({
      where: { and: [{ userId }, { messageTypeId: { between: [9, 10] } }] },
      order: 'id DESC',
      include: [
        { relation: 'messageFiles', scope: { fields: ['fileUrl', 'size'] } },
        { relation: 'messageType', scope: { fields: ['code'] } },
        {
          relation: 'messageBeneficiaries',
          scope: {
            fields: ['beneficiaryId'],
            include: {
              relation: 'beneficiary',
              scope: {
                fields: ['firstName'],
              },
            },
          },
        },
      ],
    });
    return request.get(`${endpoints.messages}?filter=${filter}`);
  }

  static deleteMessageFiles(messageId) {
    return request.delete(`/messages/${messageId}/messageFiles`);
  }
  static deleteMessageBeneficiaries(messageId) {
    return request.delete(`/messages/${messageId}/messageBeneficiaries`);
  }
  static deleteMessage(messageId) {
    return request.delete(`/messages/${messageId}/delete-message`);
  }
}

export default MessageService;
