import styled from 'styled-components';
import icon from '../../assets/images/sprite-icons.svg';
import bell from '../../assets/images/bell.png';

export const Container = styled.div`
  top: 20px;
  right: 60px; 
  span {
    display: flex;
    .numNotification {
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 4px;
      background-color: #df382f;
      color: #FFF;
      position: relative;
      margin-right: 10px;
      text-align: center;
      span {
        width: 5px;
        height: 8px;
        background: url(${icon}) -50px -50px/550px 80px;
        position:absolute;
        top: 9px;
        right: -4px;
      }
    }
    .bellNotification {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      background-image: url(${bell});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  }
`;
