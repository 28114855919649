// Dependencies
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	margin: 0 15px;
	margin-bottom: 30px;
	.titleAndDate {
		display: grid;
		grid-template-columns: 1.8fr 1fr 1fr;
		grid-gap: 15px;
	}
	.absence {
		> input {
			cursor: pointer;
		}
	}
	.calendar {
		display: none;
		width: 242px;
		height: 293px;
		margin-left: -12px;
		position: absolute;
		z-index: 9;
		${ifProp(
			'isActived',
			css`
				display: block;
			`
		)}
	}
	.actionMessage {
		margin: 0 12%;
		margin-top: 20px;
		display: flex;
	}
	.containerUpload {
		margin-top: 60px;
		margin-left: 8.33333%;
		width: 83.3333%;
		animation: fadeInDown 0.4s ease-in-out;
		display: none;
		> label {
			display: inline-block;
			max-width: 100%;
			margin-bottom: 5px;
			font-weight: 700;
		}
		> button {
			width: 140px;
			float: right;
		}
	}
	${ifProp(
		'isActivedUpload',
		css`
			.containerUpload {
				display: block;
			}
			.selectPhotos {
				margin-top: 65px;
			}
		`
	)}
	${media.lessThan('medium')`
	.actionMessage { 
		display: inline-block;
	}
	.titleAndDate {
		grid-template-columns: 1fr;
	}	
`}
`
export const ModalAudio = styled.div`
	margin: 0 12%;
	width: 50%;
	height: 80px;
	padding: 10px;
	text-align: center;
`