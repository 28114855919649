/* Dependencies */
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validate from 'validate.js'
/* Actions */
import {
	loginVerifier	
} from '../../redux/user/actions'

import { t } from '../../i18n'

/* Validations */
const constrains = {
	email: {
		email: { message: t('validations.login.email') },
		presence: { message: t('validations.login.required') }
	},
	password: {
		presence: { message: t('validations.login.required') },
		length: {
			minimum: 6,
			message: t('validations.login.minimun')
		}
	}
}

const mapStateToProps = ({ user: { infoRelationBeneficiare } }) => {
	let values = {}
	if (infoRelationBeneficiare.user) {
		const { user } = infoRelationBeneficiare
		values = {
			userMainName: user.userName,
			userMainUrl: user.imageUrl
		}
	}
	return {
		...values
	}
}

const mapDispachtToProps = dispatch =>
	bindActionCreators(
		{			
			onSubmit: loginVerifier
		},
		dispatch
	)

// const margeProps = (stateProps, dispatchProps, ownProps) => ({
// 	...stateProps,
// 	...dispatchProps,
// 	...ownProps,
// 	getInfoRelationBeneficare: () =>
// 		dispatchProps.getInfoRelationBeneficare()
// })

export default compose(
	connect(
		mapStateToProps,
		mapDispachtToProps		
	),
	reduxForm({
		form: 'LoginVerifier',
		validate: values => validate(values, constrains)
	})
)
