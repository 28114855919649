/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
/* Actions */
import { setRegisterBeneficiare } from '../../redux/register/actions';

import { t } from '../../i18n';

/* Validations */
const constrains = {
  code: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  password: {
    presence: {
      message: t('validationsInputs.required'),
    },
    length: {
      minimum: 6,
      tooShort: t('validationsInputs.minimun'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
  'repeat-password': {
    presence: {
      message: t('validationsInputs.required'),
    },
    equality: {
      attribute: 'password',
      message: t('validationsInputs.noMatch'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
};

const mapStateToProps = ({ register: { dateRegisterBeneficiare } }) => {
  let initialValues = {};
  if (dateRegisterBeneficiare && dateRegisterBeneficiare.beneficiary) {
    initialValues = {
      beneficiareName: dateRegisterBeneficiare.beneficiary.userName,
      userMainName: dateRegisterBeneficiare.user.userName,
      userMainUrl: dateRegisterBeneficiare.user.imageUrl,
      email: dateRegisterBeneficiare.beneficiary.email,
      beneficiareId: dateRegisterBeneficiare.beneficiary.id,
    };
  }
  return {
    initialValues,
    ...initialValues,
  };
};

const mapDispachtToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterBeneficiare,
    },
    dispatch
  );

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (values) => {
    const {
      initialValues: { email, beneficiareId },
    } = stateProps;
    dispatchProps.setRegisterBeneficiare({
      ...values,
      email,
      beneficiareId,
      temporalToken: ownProps.params.relationId,
    });
  },
});

export default compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'RegisterBeneficiare',
    validate: (values) => validate(values, constrains),
  })
);
