// Dependencies
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// const routes
import { routes } from '../../routes'
// Styled component
import { Conatiner } from './styles'

class TutorialItem extends Component {
  render () {
    const { bgBorder, titleItem, path } = this.props
    return (
      <Conatiner bgBorder={bgBorder}>
        <Link to={`${routes.TUTORIALS}/${path}`}>
          <div className='box-tutorial-icon'>
            <div className='circle' />
          </div>
          <div className='box-tutorial-text'>
            <h4>
              { titleItem }
            </h4>
          </div>
        </Link>
      </Conatiner>
    )
  }
}

export default TutorialItem
