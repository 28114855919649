// Dependencies
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';

export const Container = styled.div`
	margin-top: 20px !important;
	display: none;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	animation: fadeInDown 0.23s ease-in-out;
	${media.lessThan('large')`
  grid-template-columns: 1fr;	
  `}
	${ifProp(
		'isActived',
		css`
			display: grid;
		`,
	)}
	.button {
		display: none;
		${ifProp(
			'isActivedButtonBeneficiaries',
			css`
				display: block;
				grid-column: 2 / 3;
				${media.lessThan('large')`
  		grid-column: 1;
  `}
			`,
		)}
	}
	.selectGenderStylesContainer {
		display: flex;
	}
`;
export const ContainerFormArrayBeneficiaries = styled.div`
	margin-top: 20px !important;
	display: none;
	grid-column-gap: 30px;
	animation: fadeInDown 0.23s ease-in-out;
	${media.lessThan('large')`
  grid-template-columns: 1fr;	
  `}
	${ifProp(
		'isActived',
		css`
			display: grid;
		`,
	)}
	.button {
		display: none;
		${ifProp(
			'isActivedButtonBeneficiaries',
			css`
				display: block;
				grid-column: 1 / 3;
				${media.lessThan('large')`
  		grid-column: 1;
  `}
			`,
		)}
	}
`;
export const ContainerBeneficiaries = styled.div`
	display: grid;
	grid-template-columns: 1;
	grid-gap: 30px;
	.nameStyle {
		grid-column: 2 / 1;
	}
	.genderStyle {
		margin-right: 53px;
	}
	.selectGenderStyle {
		display: flex;
		justify-content: flex-end;
	}
	.emailStyle {
		grid-column: 2 / 1;
	}
	.documentStyle {
		grid-column: 2 / 2;
	}
	.containerButtonRemove {
		grid-column: 2;
		display: flex;
		justify-content: flex-end;
	}
`;
