// Dependencies
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import media from 'styled-media-query'
// Assets
import icon from '../../assets/images/sprite-icons.svg'

// Styleds Components
export const Container = styled.div`
	top: ${prop('top', '56px')};
	right: ${prop('right', '334px;')};
	position: absolute;
	display: none;
	animation: fadeInDown 0.5s;
	${ifProp(
		'responsive',
		css`
			${media.lessThan('medium')`
			top: 303px;
			left: 3px;			
			box-shadow: -1px 22px 42px 0px rgba(0,0,0,0.75);
}
			`}
		`
	)}
	.dropdownNotifications {
		padding: 0;
		min-height: 100px;
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 45px;
		position: relative;
		list-style: none;
	}
	.colorTopbar {
		border-top: 4px solid #3fa8e0;
		height: 90px;
		padding: 10px;
		display: table;
		background: #fff;
	}
	.lifeGuideToolTip {
		padding: 0;
		li {
			list-style: none;
		}
		li,
		span {
			height: 38px;
			display: block;
			padding: 10px 20px;
		}		
	}
	.caret {
		display: inline-block;
		width: 0px;
		height: 0px;
		margin-left: 2px;
		vertical-align: middle;
		border-top: 4px dashed;
		border-right: 4px solid transparent;
		border-left: 4px solid transparent;
		transform: rotate(-90deg);
	}
	${ifProp(
		'isActive',
		css`
			display: block;
		`
	)}
	label {
		cursor: pointer;
	}
	.arrowButton {
		margin-left: 0.6rem;
	}
`

export const Overlay = styled.div`
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	display: none;
	animation-name: fadeIn 2s;
	${ifProp(
		'isActive',
		css`
			display: block;
		`
	)}
`

export const ArrowDropDown = styled.div`
	width: 15px;
	height: 8px;
	left: ${prop('left', '213px')};
	background: url(${icon}) -60px -45px/550px 80px;
	position: absolute;
	z-index: 10;
`

export const HeaderDropDown = styled.ul`
	width: ${prop('width', '268px')};
	margin-top: 8px;
	padding: 0 !important;
	border-radius: 4px;
	position: absolute;
	z-index: 10;
	list-style: none;
	margin-bottom: 0;
	padding: 0;
	opacity: 0.95;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: 0 0 9px 0 rgba(155, 155, 155, 0.5);
	text-transform: uppercase;
	font-size: 14px;
	color: #342769;
	font-weight: bold;	
	.titleNotification {
		height: 45px;
		background: #e6e6e6;
		padding: 3px 0 0;
		position: absolute;
		width: 100%;
		z-index: 10;
		border-radius: 4px 4px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;		
		.title {
			color: #211261;
		}
	}

	li:first-child {
		border-radius: 4px 4px 0 0;
	}
	li {
		width: 100%;
		a,
		span {
			width: 268px;
			height: 38px;
			padding: 10px 20px;
			display: block;
			color: #342769;
			text-decoration: none;
			cursor: pointer;
		}
		a:hover,
		span:hover {
			/*background: #892b6e;*/
			color: #fb8519;
		}
	}
`
