// Dependecies
import React, { Fragment } from 'react';
// Components
import FormUserType from '../FormUserType';
// Styleds Components
import { Container } from './styles';

function DropDownUser(props) {
  const {
    title,
    description,
    toggle,
    isActived,
    count,
    genders,
    relationships,
  } = props;
  return (
    <Fragment>
      <Container onClick={toggle}>
        <h2 className="title">{title}</h2>
        <p>{description}</p>
        <span className="caret" />
      </Container>
      <FormUserType
        count={count}
        isActived={isActived}
        relationships={relationships}
        genders={genders}
      />
    </Fragment>
  );
}

export default DropDownUser;
