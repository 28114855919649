// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
//Redux
import {
	setFormBeneficiaries,
	setFormVerifiers,
	getSlidersCheckers,
} from '../../redux/home/actions';
import { setFormBuy } from '../../redux/app/actions';
// i18n
import { t } from '../../i18n';
// Components
import Button from '../Button';
import Base from '../Base';
import Carrousel from '../Carrousel';

// Styleds Components
import { Container, Card } from './styles';
//Form
import Absence from '../../form/Absence';
import { setModal } from '../../redux/app/actions';
import { setAbsenceUserSelected } from '../../redux/user/actions';
import img from '../../assets/images/male-user.png';

class HomeVerifier extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardMessage: false,
			cardHistory: false,
			cardLife: false,
		};
	}
	componentDidMount() {
		this.props.getSlidersCheckers();
	}
	toggleCard = (card) =>
		this.setState({
			cardMessage: false,
			cardHistory: false,
			cardLife: false,
			[card]: !this.state[card],
		});

	toggleformBeneficiaries = () =>
		this.props.setFormBeneficiaries(!this.props.formBeneficiaries);

	toggleformVerifiers = () =>
		this.props.setFormVerifiers(!this.props.formVerifiers);

	render() {
		const { cardMessage } = this.state;
		const {
			modal,
			dataVerifierUserMain,
			isAbsenceForm,
			sliderCheckers,
			verifierImageUrl,
		} = this.props;
		return (
			<>
				<Base imgBackground={verifierImageUrl || img}>
					<span className='text-separator-top' />
					<Container>
						<Card isActivedButtons={cardMessage}>
							<h2>{t('home.homeVerifier.youVerifier')}</h2>
							<div className='content'>
								{dataVerifierUserMain.map((item, index) => (
									<div key={index} className='container-button'>
										<Button
											type='button'
											border
											borderColor='rgb(33,18,97)'
											bgColorHover='#ff8400'
											style={{ color: '#342573' }}
											onClick={() => isAbsenceForm(item)}
										>
											{t('home.homeVerifier.checkAbsence')}
											<br />
											<b>
												{item.mainUser && item.mainUser.firstName}{' '}
												{item.mainUser &&
													item.mainUser.lastName &&
													item.mainUser.lastName}
											</b>
											<br />
										</Button>
										{/* <Modal
										fullScreen
										active={modal ===  'confirmingAbsence'}
										toggle={() => closeModal('')}>
										<ForgotPasswordModal>
											<ValidateUserMainVerifier itemTest={item.mainUser.firstName} idUserMain={item.mainUser.id} />
										</ForgotPasswordModal>
									</Modal> */}
									</div>
								))}
							</div>
						</Card>
						{/* <CarrouselVerifier title={'Edison'} lifeGuides={'Edison'} link='/event-message' /> */}
						{sliderCheckers.length ? (
							<Carrousel lifeGuides={sliderCheckers} />
						) : (
							''
						)}
					</Container>
					{/* <Abasence active toggle={()=> this.props.toggleModal('')} />			 */}
				</Base>
				{modal === 'confirmingAbsence' && (
					<Absence active toggle={() => this.props.toggleModal('')} />
				)}
			</>
		);
	}
}

function mapStateToProps(store) {
	return {
		formBuy: store.app.formBuy,
		formBeneficiaries: store.home.formBeneficiaries,
		formVerifiers: store.home.formVerifiers,
		message: store.app.message,
		sliderCheckers: store.home.sliderCheckers,
		modal: store.app.modal,
		verifierImageUrl: store.home.homes[0]
			? store.home.homes[0].verifierImageUrl
			: '',
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setFormBuy: (value) => dispatch(setFormBuy(value)),
		setFormBeneficiaries: (value) => dispatch(setFormBeneficiaries(value)),
		setFormVerifiers: (value) => dispatch(setFormVerifiers(value)),
		closeModal: (value) => dispatch(setModal(value)),
		toggleModal: (value) => dispatch(setModal(value)),
		setAbsenceUserSelected: (value) => dispatch(setAbsenceUserSelected(value)),
		getSlidersCheckers: () => dispatch(getSlidersCheckers()),
	};
}

function margeProps(stateProps, dispatchProps, ownProps) {
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		isAbsenceForm: (value) => {
			dispatchProps.toggleModal('confirmingAbsence');
			dispatchProps.setAbsenceUserSelected(value);
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	margeProps,
)(HomeVerifier);
