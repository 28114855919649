// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'
import { prop } from 'styled-tools'
import lineTitle from '../../assets/images/line-title.svg'
// // Assets
// import bgImage from '../../assets/images/1.png'

export const Container = styled.section`
	background: url(${prop('bgImage')}) center;
	background-size: cover;
	background-position: center top;
	.Wrapper {
		padding-top: 91px;
		min-height: calc(100vh - 98px) !important;
	}
`
export const Content = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1.1fr;
	grid-gap: 15px;
	
	${media.lessThan('medium')`
  grid-template-columns:  1fr; 
    `}
	.questions {		
		h2 {
			color: #fff;
			text-align: center;
    }    
    }
		.text-separator-top {
			width: 180px;
			height: 25px;
			background: url(${lineTitle}) no-repeat 0 0 / 180px 25px;
			margin: 0 auto 20px;
			display: block;
			left: 50%;
    }
    .panel-group {
			margin-bottom: 20px;
			padding: 15px;
    }
		.question {			
			width: 100%;
			background-color: transparent;
 		  border: none!important;						  			
			text-align: left;
			margin-bottom: 15px;
		}
		> div {
			padding: 0 15px;
			.txt {
				padding: 11px 0;
				width: auto;
				display: inline-block;					
			}
		}
}	
`

export const ContainerFormRegister = styled.div`
	width: 100%;
	min-height: calc(100vh - 98px) !important;

	padding: 25px 0;
	margin: 0 auto;
	margin-bottom: 15px;	
	opacity: 0.74;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 10px 14px 0 rgba(155, 155, 155, 0.5);
	form {
		padding-right: 15px;
		padding-left: 15px;
		.title {
			color: #342573;
			font-weight: bold;
			h2 {
				margin-top: 0 !important;
			}
		}
		input,
		textarea {
			width: 100%;
		}
		.btnSubmit {
			margin-top: 15px;			
		}
	}
`
