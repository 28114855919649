import styled from 'styled-components'

export const Container = styled.div`
	overflow-x: auto;
	color: #898989;
	border-radius: 4px;
	border: 1px solid #211261;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 20px;
	background-color: transparent;
	border-spacing: 0;
	
	min-width: 320px;
	.top {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		background-color: #211261;
		padding-left: 16px;
		p {
			text-align: left;
			color: #fff;
		}
	}
`

export const Item = styled.div`
	padding-left: 16px;
	border-top: 1px solid #ddd;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
`
