/* Dependencies */
import styled from 'styled-components'

export const Form = styled.form`
	select, input {
		width: 100%;
		height: 50px;
		padding: 6px 20px;
		font-size: 13px;
		line-height: 1.42857143;
		color: '#999';
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 25px;
		border: 1px solid #342573;
	}
	.rdw-emoji-wrapper {
		display: none;
	}
	.options {
		display: flex !important;
    justify-content: center;
		button {
			width: auto !important;
		}
	}
`
