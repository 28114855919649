import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`  
  min-height: calc(100vh - 159px)!important;
  h1 {
    color : #4A4A4A!important;
    text-shadow: none!important;    
  }
  .box{
    display: flex;
    justify-content: flex-end;
  }
`

export const ContainerVideo = styled.div`
  position: relative;
  width: 900px;
  height: 450px;
  margin: 0 auto;  
  margin-bottom: 60px;
  overflow-y: auto;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    height: 100%;
    z-index: 11;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
      min-height: 350px;        
    `}
  }
`
