/* Dependenceies */
import React from 'react';
import PropTypes from 'prop-types';
/* Componenets */
import Modal from '../Modal';
import Button from '../Button';
/* i18n */
import { t } from '../../i18n';
/* Styles */
import { Content } from './styles';
/* Assets */

function ModalFeedBack({ toggle, user, actionConfirm, title, description }) {
  return (
    <Modal active toggle={toggle} heigth="10px" width="fit-content" confirm>
      <Content>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="btnConfirm">
          <Button width="auto" bgColor="#ff8400" onClick={toggle}>
            {t('home.terms-and-conditions.button')}
          </Button>
        </div>
      </Content>
    </Modal>
  );
}

ModalFeedBack.propTypes = {
  toggle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  actionConfirm: PropTypes.object.isRequired,
};

export default ModalFeedBack;
