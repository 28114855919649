/* Dependencies */
import React from 'react';
//libreria causante que no funciona en explorer
import { ReactMic } from 'react-mic';
/* Components */
import Button from '../Button';
/* Styles */
import { Container } from './styles';

class Record extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      recordedBlob: null,
    };
  }

  recording = () =>
    this.setState((state) => ({
      record: !state.record,
    }));

  onStop = (recordedBlob) => this.props.recordBlob(recordedBlob.blob);

  render() {
    const { record } = this.state;
    return (
      <Container>
        <ReactMic
          record={this.state.record}
          className="sound-wave"
          onStop={this.onStop}
          strokeColor="#000000"
          backgroundColor="#9b9b9b"
        />
        <Button isTransparent onClick={this.recording} type="button">
          <i className={`fas fa-${!record ? 'play' : 'pause'}`} />
        </Button>
        {this.state.recordedBlob && this.state.recordedBlob.blobURL && (
          <audio touchstart controls ref={this.myRef} name="media">
            <source
              src={this.state.recordedBlob && this.state.recordedBlob.blobURL}
              type="audio/webm"
            />
          </audio>
        )}
      </Container>
    );
  }
}

export default Record;
