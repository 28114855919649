// Dependencies
import styled from 'styled-components'
// Assets
import bgBlur from '../../assets/images/bg-blur2.jpg'

export const Container = styled.div`
	width: 280px;
	height: 150px;
	margin: 0 auto;
	display: inline-block;
	background: url(${bgBlur});
	margin-right: -3px;
  cursor: pointer;
  position: relative;
  i {
    margin-right: 5px;
  }
  h3 {
    text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  }
  &:hover .imgContent  {
    width: 80%;
    height: 110px;
    border-radius: 4px;
  }
  .action {
    width: 100%;
    height: 70px;
    position: relative;
      z-index: 10;
    margin-top: 40px;
    text-align: center;
    font-size: 32px;
    color: #fff;
}
.imgContent {
  position: absolute;
    top: -100%;
    right: 0;
    bottom: -100%;
    left: 0;
    margin: auto;
    width: 100%;
    height: 150px;
    border-radius: 0px;
    transition: all .2s ease-in-out;
}
  
`
