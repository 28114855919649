/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
/* Actions */
import {
  setRegisterVerifier,
  setModalRegisterVerifier,
} from '../../redux/register/actions';
import { setModalTerms } from '../../redux/app/actions';

import { t } from '../../i18n';

/* Validations */
const constrains = {
  code: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  password: {
    presence: {
      message: t('validationsInputs.required'),
    },
    length: {
      minimum: 6,
      tooShort: t('validationsInputs.minimun'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
  'repeat-password': {
    presence: {
      message: t('validationsInputs.required'),
    },
    equality: {
      attribute: 'password',
      message: t('validationsInputs.noMatch'),
    },
    format: {
      pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/,
      message: t('validationsInputs.password'),
    },
  },
};

const mapStateToProps = ({ register: { dateRegisterVerifier } }) => {
  let initialValues = {};
  if (dateRegisterVerifier.cheker) {
    initialValues = {
      beneficiareName: dateRegisterVerifier.cheker.userName,
      userMainName: dateRegisterVerifier.user.userName,
      userMainUrl: dateRegisterVerifier.user.imageUrl,
      email: dateRegisterVerifier.cheker.email,
      beneficiareId: dateRegisterVerifier.cheker.id,
    };
  }
  return {
    ...initialValues,
  };
};

const mapDispachtToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterVerifier,
      setModalTerms,
      toggle: () => setModalRegisterVerifier(false),
    },
    dispatch
  );

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (values) => {
    const { email, beneficiareId } = stateProps;
    dispatchProps.setRegisterVerifier(
      {
        ...values,
        email,
        beneficiareId,
        temporalToken: ownProps.params.relationVerifierId,
      },
      () => ownProps.history.push('/')
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'RegisterBeneficiare',
    validate: (values) => validate(values, constrains),
  })
);
