// Dependencies
import React from 'react';
// import { Link } from 'react-router-dom'
// Styled component
import { Container } from './styles';
import moment from 'moment';
import { t } from '../../i18n';

function PreviewMessage({ item, title, iconType }) {
	return (
		<Container>
			{/* <a href='#'> */}
			<div className='boxMessage'>
				<div className='messageType'>
					<i className={iconType} />
				</div>
				<div className='messageTitle'>
					<h4>
						{title === undefined ? item.message.title : title}
						{/* <small>Draft</small> */}
					</h4>
					<b>
						{item.message &&
							item.message.messageFiles.length &&
							(
								item.message.messageFiles.reduce((acc, cur) => {
									return acc + cur.size;
								}, 0) / 1000000
							).toFixed(2)}{' '}
						MB
					</b>
					<p>
						{t('previewMessage.visible')} :{' '}
						{item.message &&
						item.message.visibleData !== null &&
						!item.message.byAbsence
							? moment(item.message && item.message.visibleData).format('L')
							: ''}
						{(item.message && item.message.visibleData === null) ||
						(item.message.visibleData && item.message.byAbsence)
							? t('previewMessage.visibleTrue')
							: ''}
						<br />
						{t('previewMessage.of')}:{' '}
						<b>
							{item.message &&
								item.message.myUser &&
								item.message.myUser.firstName}
						</b>
					</p>
				</div>
			</div>
			{/* </a> */}
		</Container>
	);
}

PreviewMessage.defaultProps = {
	iconType: 'far fa-file-word',
};

export default PreviewMessage;
