/* Dependencies */
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const Container = styled.div`  
  padding: 15px;
  /*width: 25em;*/
  position: fixed;
  z-index: 12;
  top: 40px;
  right: 20px;
  width: 300px;
  border-radius: 7px;
  box-shadow: 0 0 12px #999;  
  opacity: .8;
  background-color: ${prop('bgColor', 'green')};
  text-align: center;
  animation: fadeInRight .3s ease-in-out;
  cursor: pointer;  
  transition: .4s all;
  display: none;
  p {
    color: #fff!important;
    font-weight: bold;
    font-size: 20px;
    text-transform: initial;
  }
  :hover {
    opacity: 1;
  } 
  ${ifProp('isActived', css`
    display: block;  
  `)}
`

export default Container
