/* Dependencies */
import React from "react";
import { Field, reduxForm } from "redux-form";
/* i18n */
import { t } from "../../i18n";
/* Components */
import Button from "../../components/Button/index";
import InputRedux from "../../fields/Input/index";
/* Validations */
import validate from "validate.js";
/* Styles */
import { Form } from "./styles";

const constrains = {
	firstName: {
		presence: {
			message: t("validationsInputs.required"),
		},
	},
	lastName: {
		presence: {
			message: t("validationsInputs.required"),
		},
	},
	relationshipId: {
		presence: {
			message: t("validationsInputs.required"),
		},
	},
	email: {
		email: {
			message: t("validationsInputs.email"),
		},
		presence: {
			message: t("validationsInputs.required"),
		},
	},
};

function CreateBeneficiaryAndVerifier({
	handleSubmit,
	isActivedmodalCreateBeneficiare,
	relationships,
}) {
	return (
		<Form className='formBeneficiare' onSubmit={handleSubmit}>
			<h3>
				{t("menu.create")} /{" "}
				{t(
					`menu.${
						isActivedmodalCreateBeneficiare ? "beneficiaries" : "verifiers"
					}`,
				)}
			</h3>
			<p>
				{t(
					`homeLogin.${
						isActivedmodalCreateBeneficiare ? "addBeneficiare" : "addVerifier"
					}`,
				)}
			</p>
			<Field
				type='text'
				name='firstName'
				component={InputRedux}
				placeholder={t("contact.fields.name")}
			/>
			<Field
				type='text'
				name='lastName'
				component={InputRedux}
				placeholder={t("contact.fields.lastName")}
			/>
			<div className='flex'>
				{isActivedmodalCreateBeneficiare ? (
					<Field name='relationshipId' component='select'>
						<option disabled=''>{t("fields.typeRelation")}</option>
						{relationships.map((relationship, index) => (
							<option key={index} value={relationship.id}>
								{relationship.name}
							</option>
						))}
					</Field>
				) : (
					<Field
						name='phone'
						component={InputRedux}
						type='number'
						placeholder={t("fields.mobile")}
					/>
				)}
			</div>

			<Field
				type='email'
				name='email'
				component={InputRedux}
				placeholder={t("contact.fields.email")}
			/>
			<div>
				<Button
					className='buttonBeneficiary'
					bgColor='#ff8400'
					bgColorHover='#211261'
				>
					{t("home.modal.forgetPassword.buttonAdd")}
				</Button>
			</div>
		</Form>
	);
}

export default reduxForm({
	form: "createBeneficiaryAndVerifier",
	validate: (values) => validate(values, constrains),
})(CreateBeneficiaryAndVerifier);
