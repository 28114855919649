export default {
  LOADING: 'LOADING',
  LOADING_OFF: 'LOADING_OFF',
  USER_LOGIN: 'USER_LOGIN',
  FORM_BUY: 'FORM_BUY',
  ARROW: 'ARROW',
  AUTHORIZER_USER: 'AUTORIZER_USER',
  ERROR: 'ERROR',
  EVENTS_PAGE: 'EVENTS_PAGE',
  MODAL_TERMS_AND_CONDITIONS: 'MODAL_TERMS_AND_CONDITIONS',
  MESSAGE: 'MESSAGE',
  IS_COMPONENT_LOADING: 'IS_COMPONENT_LOADING',
  LANGUAGES: 'LANGUAGES',
  LIFE_GUIDES: 'LIFE_GUIDES',
  MODAL: 'MODAL',
  USER_BENEFICIARY_DATA: 'USER_BENEFICIARY_DATA',
  USER_VERIFIER_DATA: 'USER_VERIFIER_DATA',
  DATA_VERIFIER_USER_MAIN: 'DATA_VERIFIER_USER_MAIN',
  DATA_ASSIGIN_ROLE: 'DATA_ASSIGIN_ROLE',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
  RECOMMENDATION_SELECTED: 'RECOMMENDATION_SELECTED',
  RECOMMENDATION_EVENT_SELECTED: 'RECOMMENDATION_EVENT_SELECTED',
  ADVICE_RECOMMENDATIONS: 'ADVICE_RECOMMENDATIONS',
  ADVICE_RECOMMENDATIONS_FILTER: 'ADVICE_RECOMMENDATIONS_FILTER',
  JOIN_USER_PRINCIPAL: 'JOIN_USER_PRINCIPAL',
  MODAL_VIDEO: 'MODAL_VIDEO',
  ADVICE_RECOMMENDATION_SELECTED: 'ADVICE_RECOMMENDATION_SELECTED'
}
