/* Dependencies */
import React, { Fragment } from "react";
import { connect } from "react-redux";
/* i18n */
import { t } from "../../i18n";
/* Components */
import FilesMessage from "../../components/FilesMessage";
import MessageResponse from "../../components/MessageResponse";

import AudioMessageForm from "../../form/AudioMessage";

function AudioMessage({ message, history, user }) {
	return (
		<Fragment>
			<FilesMessage
				withHoutRecomendations
				title={t("audioMessage.title")}
				notBox
			>
				<AudioMessageForm push={history.push} availableSpace={user[0] && user[0].avaliableSpace} />
			</FilesMessage>
			{message === "setMessageAudioSuccess" && <MessageResponse isActived />}
			{message === "setMessageAudioError" && (
				<MessageResponse
					isActived
					bgAlert='red'
					message={t("messagesResponse.notSpace")}
				/>
			)}
		</Fragment>
	);
}

function mapStateToProps(store) {
	return {
		message: store.app.message,
		user: store.user.user,
	};
}

export default connect(mapStateToProps)(AudioMessage);
