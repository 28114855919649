// Dependencieds
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from '../../i18n';
// const routes
import { routes } from '../../routes';
// Component

import Message from '../../components/Message';
import Button from '../../components/Button';
import Input from '../../components/Input';
import PreviewMessage from '../../components/PreviewMessage';
import DraftMessage from '../../components/DraftMessage';

// Styled
import { Container, SeeMessage } from './styles';
/* Actions */
import {
	getDraftLocaleStorege,
	getMessageAdviceByUser,
} from '../../redux/messages/actions';
/* Redux */
import { getMenssageBeneficiary } from '../../redux/user/actions';
import moment from 'moment';

class HistoryAdvice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: false,
			arrFilter: [],
			arrFilterBeneficiary: [],
		};
	}

	search = (e) => {
		const { messages, messagesBeneficiary } = this.props;
		const search = e.target.value;
		const arrFilter = messages.filter((item) =>
			item.title.toLowerCase().includes(search.toLowerCase()),
		);
		const arrFilterBeneficiary = messagesBeneficiary.filter((item) =>
			item.message.title.toLowerCase().includes(search.toLowerCase()),
		);
		if (arrFilter.length) {
			this.setState({
				arrFilter,
			});
		} else if (arrFilterBeneficiary.length) {
			this.setState({
				arrFilterBeneficiary,
			});
		} else {
			console.log('e');
			//validar el error
			// this.props.setMessage({
			// 	name: 'withError',
			// 	message: `${t('messagesResponse.notFoundMessages')} ${search}`
			// })
			// setTimeout(() => setMessage(''), 3000)
		}
	};

	_getTypeIcon = (messageTypeId) => {
		switch (messageTypeId) {
			case 4:
				return 'fas fa-volume-up';
			case 3:
				return 'fas fa-images';
			case 6:
				return 'fas fa-video';
			case 5:
				return 'fas fa-video';
			case 2:
				return 'far fa-file-word';
			default:
				return 'far fa-file-word';
		}
	};
	componentDidMount() {
		this.props.getDraftLocaleStorege();
		this.props.getMenssageBeneficiary();
		this.props.getMessageAdviceByUser();
	}
	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user && this.props.user) {
			this.props.getMessageAdviceByUser();
		}
	}

	toggleState = (value) => this.setState({ [value]: !this.state[value] });

	render() {
		const { message, arrFilter, arrFilterBeneficiary } = this.state;
		const { draftLocaleStorage, titles, messages, messagesBeneficiary } =
			this.props;
		const arrMessages = arrFilter.length ? arrFilter : messages;
		const arrMessagesBeneficiary = arrFilterBeneficiary.length
			? arrFilterBeneficiary
			: messagesBeneficiary;
		const date = new Date();
		const dateActual = moment(date).format('L');
		return (
			<div>
				{titles === 1 ? (
					<Message record titles={titles}>
						<Fragment>
							<Container>
								<div className='buttonsRecord'>
									<h1 className='textCenter'>{t('record.newMessages')}</h1>
								</div>
								<div>
									<Input
										type='search'
										placeholder={t('record.search')}
										onChange={this.search}
									/>
									{arrMessagesBeneficiary.length <= 0 ? (
										<div className='txtInfo'>
											{t('record.notMessagesBeneficiary')}
										</div>
									) : (
										''
									)}
								</div>
								{/* <SelectInput className='select' /> */}
							</Container>
						</Fragment>
						<SeeMessage>
							{arrMessagesBeneficiary.length &&
								arrMessagesBeneficiary.map((item, key) => (
									<div>
										{moment(item.message.visibleData).format('L') <=
											dateActual || item.message.visibleData === null ? (
											<Link to={`${routes.MESSAGE}/${item.message.id}`}>
												<PreviewMessage item={item} key={key} />
											</Link>
										) : (
											<div>
												<PreviewMessage
													icontrolstem={item}
													key={key}
													title={t('record.notContent')}
												/>
											</div>
										)}
									</div>
								))}
						</SeeMessage>
					</Message>
				) : (
					<Message record>
						<Fragment>
							<Container>
								<div className='buttonsRecord'>
									<Link to={routes.HOME}>
										<Button bgColor='#211261' bgColorHover='#892B6E'>
											{t('buttons.createMessage')}
										</Button>
									</Link>
								</div>
								<div>
									<Input
										type='search'
										placeholder={t('record.search')}
										onChange={this.search}
									/>
									{arrMessages.length <= 0 ? (
										<div className='txtInfo'>{t('record.notMessages')}</div>
									) : (
										''
									)}
									{!messages && (
										<div className='txtInfo'>{t('record.notMessages')}</div>
									)}
								</div>
								{/* <SelectInput className='select' /> */}
							</Container>
						</Fragment>
						{message && (
							<SeeMessage>
								<DraftMessage isDraft />
								{draftLocaleStorage.map((draft, key) => (
									<Link key={key} to={`${routes.RECORD}/${draft.title}`}>
										<DraftMessage
											isDraft
											title={draft.title}
											key={key}
											size={
												message.messageFiles.length &&
												(
													message.messageFiles.reduce((acc, cur) => {
														return acc + cur.size;
													}, 0) / 1000000
												).toFixed(2)
											}
										/>
									</Link>
								))}
							</SeeMessage>
						)}
						<SeeMessage>
							{!message &&
								arrMessages.map((itemMessage, index) => (
									<Link key={index} to={`${routes.RECORD}/${itemMessage.id}`}>
										<DraftMessage
											title={itemMessage.title}
											key={index}
											iconType={this._getTypeIcon(
												itemMessage.messageFiles.length > 0
													? itemMessage.messageFiles[0].fileTypeId
													: 1,
											)}
											visibleData={itemMessage.visibleData}
											size={
												itemMessage.messageFiles.length &&
												(
													itemMessage.messageFiles.reduce((acc, cur) => {
														return acc + cur.size;
													}, 0) / 1000000
												).toFixed(2)
											}
											beneficiaryName={itemMessage.messageBeneficiaries}
										/>
									</Link>
								))}
						</SeeMessage>
					</Message>
				)}
			</div>
		);
	}
}

function mapStateToProps(store) {
	const messages = store.messages;
	return {
		draftLocaleStorage: messages.draftLocaleStorage || [],
		messages: messages.messageAdviceByUser.data || [],
		messagesBeneficiary: store.user.messagesBeneficiary.length
			? store.user.messagesBeneficiary
			: [],
		user: store.user.user[0],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getDraftLocaleStorege: () => dispatch(getDraftLocaleStorege()),
		getMenssageBeneficiary: (user) => {
			dispatch(getMenssageBeneficiary(user));
		},
		getMessageAdviceByUser: () => dispatch(getMessageAdviceByUser()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryAdvice);
