/* Dependencies */
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { forgotPassword } from '../../redux/user/actions';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import MessageResponse from '../../components/MessageResponse';

// Validate
import validate from 'validate.js';

const constrains = {
  email: {
    presence: true,
  },
};

class JoinUserMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      error: true,
    };
  }
  renderError = () => {
    const messageData = this.props.message;
    let message = '';
    if (messageData) {
      switch (messageData) {
        case 'auth/wrong-password':
          message = 'algo salio mal';
          break;
        default:
          message = 'valida tus datos';
          break;
      }
      return (
        <MessageResponse
          bgAlert="red"
          message={message}
          isActived={this.state.error}
          visible={this.visibleMessage}
        />
      );
    }
  };
  visibleMessage = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 2000);
  };
  render() {
    const { handleSubmit, message, toggle, actionConfirm } = this.props;

    const { visible } = this.state;
    return (
      <div>
        {visible}
        <form onSubmit={handleSubmit} renderError={this.renderError()}>
          <h2>{t('joinMainModal.joinMainUser')}</h2>
          <div
            style={{
              padding: '10px',
            }}
          >
            <Button
              bgColor="#ff8400"
              bgColorHover="#211261"
              onClick={() => toggle()}
            >
              No
            </Button>
          </div>
          <div
            style={{
              padding: '10px',
              marginBottom: '13px',
            }}
          >
            <Button
              bgColor="#ff8400"
              bgColorHover="#211261"
              onClick={() => actionConfirm()}
            >
              {t('joinMainModal.yes')}
            </Button>
          </div>

          <MessageResponse
            isActived={message === 'succesSendMailRecoverPassword'}
            message="¡Se envio un correo de recuperacion!"
          />
          <MessageResponse
            isActived={message === 'errorSendMailRecoverPassword'}
            message="El correo no se encuentra registrado"
            bgAlert="red"
          />
          <MessageResponse
            isActived={message === 'errorSendManyMails'}
            message="Has restablecido la contraseña demasiadas veces Inténtalo de nuevo más tarde."
            bgAlert="red"
          />
        </form>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    message: store.app.message,
  };
};

const mapDispachtToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(forgotPassword(values)),
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps),
  reduxForm({
    form: 'forgotPassword',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(JoinUserMain);
