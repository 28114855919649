// Dependencies
import React from 'react'
import Wrapper from '../Wrapper'
import { connect } from 'react-redux'
// Styled Components
import { Container, Body } from './styles'
// Components
import Footer from '../Footer'

function Base (props) {
  const { isLoginUser, imgBackground, children, noMargin, about, homeLogin, withComponentFullScreen } = props
  return (
    <Body active={isLoginUser} imgBackground={imgBackground} >
      <div>
      
      <Wrapper height={isLoginUser ? '263px' : '199px'} noMargin={noMargin}>
      
        <Container active={isLoginUser} homeLogin={homeLogin} about={about}>
        {withComponentFullScreen && withComponentFullScreen}
          {!about && children}
          {about && <div className='card'>{children}</div>}
        </Container>
        </Wrapper>
      </div>
      
      
      <div className='text'>
        <Footer />
      </div>
    </Body>
  )
}

function mapStateToProps (store) {
  return {
    isLoginUser: store.app.isLoginUser
  }
}

export default connect(mapStateToProps)(Base)
