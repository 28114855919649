// Componets
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { prop, ifProp } from 'styled-tools';
// Assets
import BackgroundImg from '../../assets/images/4.jpg';
import lineTitle from '../../assets/images/separator 2.png';
import lineTitle2 from '../../assets/images/line-title-2.svg';
import spriteIcon from '../../assets/images/sprite-icons.svg';

export const Body = styled.div`
  background-image: url(${prop('imgBackground', BackgroundImg)});
  background-repeat: no-repeat;
  background-size: cover;
  /*background-position: center;*/
  /*margin-top: 58px;*/
  padding-top: 40px;
  ${ifProp(
    'active',
    css`
      margin-top: 47px;
    `
  )}
`;

export const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 58px;
  .card {
    grid-column: 2/ 3;
    min-height: 496px;
    max-width: 100%;
    opacity: 0.74;
    border-radius: 20px;
    background-color: #34276996;
    padding-top: 50px;
    box-shadow: 0 10px 14px 0 rgba(155, 155, 155, 0.5);
  }
  .title {
    text-align: center;
    font-size: 26px;
    line-height: 26px;
    margin: 0 0 10px;
    color: #fff;
  }
  .text-separator-black {
    width: 180px;
    height: 25px;
    background: url(${lineTitle2}) no-repeat 0 0 / 180px 25px;
    margin: 0 auto 50px;
    display: block;
    left: 50%;
  }
  .paragraph {
    p,
    span {
      color: #fff !important;
      text-align: center !important;
      font-size: 13.5px !important;
      line-height: 22px !important;
      text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em blue !important;
    }

    &:last-child {
      margin-bottom: 10px !important;
    }
  }
  .text-separator-bottom {
    width: 180px;
    height: 10px;
    background: url(${spriteIcon}) -210px -70px/550px 80px no-repeat;
    margin: 20px auto 60px;
    display: block;
  }

  ${ifProp(
    'about',
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .card {
        grid-column: 2/ 3;
      }
      .title {
        color: white;
      }
      .text-separator-top {
        background: url(${lineTitle});
        background-repeat: no-repeat;
        background-size: cover;
        height: 42px;
        width: 147px;
        margin: 0 auto;
        display: block;
      }
    `
  )}
  ${ifProp(
    'homeLogin',
    css`
      .title {
        text-align: left;
        font-size: 30px;
        line-height: 26px;
        color: #fff;
        margin-left: -130px;
      }
      ${media.lessThan('medium')`	
			.title {
				margin-left: 0;
			}						
			`}
    `
  )}
	${ifProp(
    'active',
    css`
      margin-top: 96px;
    `
  )}	  
	${media.greaterThan('large')`
  width: 970px;
    `}
	${media.lessThan('medium')`
	display: block;
    `}
`;
