// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'
import { prop } from 'styled-tools'
// Assets
import lineTitle from '../../assets/images/line-title-2.svg'

export const Container = styled.div`	
	text-align: center;
	margin-top: ${prop('marginTop', '100px')};
	display: grid;
	grid-template-areas: 1fr;
	.loader-container {
		height: 200px;
		width: 80%;				
		margin: 0 auto;		
		padding-left: 10%;
	}
	.text-separator-top {
		width: 180px;
		height: 25px;
		background: url(${lineTitle}) no-repeat 0 0 / 180px 25px;
		margin: 0 auto 20px;
		display: inline-block;
		left: 50%;
	}
	.container-tutorials {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
		${media.lessThan('medium')`
    grid-template-columns: 1fr;      
    `}
	}
`
