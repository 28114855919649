// Dependencies
import React, { Component, Fragment } from "react";

// Styled compoents
import { Container } from "./styles";
import InputRedux from "../../fields/Input";
import { Field } from "redux-form";
import { t } from "../../i18n";

const emailRequire = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? t("register.invalidEmail")
		: undefined;

const characters = (value) =>
	value && !/^[A-Za-zÁÉÍÓÚñáéíóúÑ _]*[ñA-Za-z][ñA-Za-z _]*$/i.test(value)
		? t("register.onlyLetters")
		: undefined;
const minLength = (min) => (value) =>
	value && value.length < min ? t("register.MustThan") : undefined;

class FormUserTypeVerifiers extends Component {
	render() {
		const { isActived, count } = this.props;
		return (
			<Fragment>
				{count.map((item, index) => (
					<Container key={index} isActived={isActived}>
						<Field
							component={InputRedux}
							name={`NameUserVerifier` + index}
							type='text'
							validate={[characters]}
							placeholder={t("register.fields.NameVerifiers")}
						/>
						<Field
							component={InputRedux}
							name={`LastNameUserVerifier` + index}
							type='text'
							validate={[characters]}
							placeholder={t("register.fields.LastNameVerifiers")}
						/>
						{/* <Gender id={item} index={index+1} genders={genders} /> */}
						<Field
							component={InputRedux}
							name={`emailVerifiers` + index}
							validate={emailRequire}
							type='email'
							required
							placeholder={t("register.fields.EmailVerifiers")}
						/>
						<Field
							component={InputRedux}
							name={`cellPhone` + index}
							type='number'
							validate={minLength(8)}
							placeholder={t("register.fields.cellPhone")}
						/>
					</Container>
				))}
			</Fragment>
		);
	}
}

export default FormUserTypeVerifiers;
