/* Dependencies */
import styled from 'styled-components'

export const Form = styled.form`
  select {
    width: 50%;
    height: 50px;
    padding: 6px 20px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #999;
    background-color: #fff;
    background-image: none;
    border: 1px solid #CCC;
    border-radius: 25px;
    border: 1px solid #342573;
    margin-bottom: 10px;
  }  	
}



`
