/* Dependencies */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
/* Actions */
import {
	setModal
} from '../../redux/app/actions'

const mapDispachtToProps = dispatch =>
	bindActionCreators(
		{
			loginBeneficiary: () => setModal('loginBeneficiary'),
			loginVerifier: () => setModal('loginVerifier')
		},
		dispatch
	)

export default connect(
	null,
	mapDispachtToProps
)
