/* Dependencies */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { forgotPassword } from '../../redux/user/actions';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import InputRedux from '../../fields/Input';
import MessageResponse from '../../components/MessageResponse';

// Validate
import validate from 'validate.js';

const constrains = {
  email: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
};

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      error: true,
    };
  }
  renderError = () => {
    const messageData = this.props.message;
    let message = '';
    if (messageData) {
      switch (messageData) {
        case 'auth/wrong-password':
          message = 'algo salio mal';
          break;
        default:
          message = 'valida tus datos';
          break;
      }
      return (
        <MessageResponse
          bgAlert="red"
          message={message}
          isActived={this.state.error}
          visible={this.visibleMessage}
        />
      );
    }
  };
  visibleMessage = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 2000);
  };
  render() {
    const { handleSubmit, message } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit} rendererror={this.renderError()}>
          <h3>{t('home.modal.forgetPassword.title')}</h3>
          <p>
            {t('home.modal.forgetPassword.description')}
            <span> {t('home.modal.forgetPassword.fluie')}</span>.
          </p>
          <Field
            type="email"
            name="email"
            component={InputRedux}
            placeholder="E-mail"
          />
          <Button bgColor="#ff8400" bgColorHover="#211261">
            {t('home.modal.forgetPassword.button')}
          </Button>
          <MessageResponse
            isActived={message === 'succesSendMailRecoverPassword'}
            message="¡Se envio un correo de recuperacion!"
          />
          <MessageResponse
            isActived={message === 'errorSendMailRecoverPassword'}
            message="El correo no se encuentra registrado"
            bgAlert="red"
          />
          <MessageResponse
            isActived={message === 'errorSendManyMails'}
            message="Has restablecido la contraseña demasiadas veces Inténtalo de nuevo más tarde."
            bgAlert="red"
          />
        </form>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    message: store.app.message,
  };
};

const mapDispachtToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(forgotPassword(values)),
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps),
  reduxForm({
    form: 'forgotPassword',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(ForgotPasswordForm);
