// @ts-check
// Dependencies
import React from 'react';
import { connect } from 'react-redux';

// Components
import Base from '../../components/Base';

import { ContainerVideo } from './styles';

function HowDoWeDoIt({ imgBackground, videoUrl }) {
  return (
    <Base imgBackground={imgBackground} withComponentFullScreen>
      <ContainerVideo>
        {videoUrl && (
          <iframe
            className="video"
            src={videoUrl + '?rel=0'}
            title="video home"
          />
        )}
      </ContainerVideo>
      {/* <h1 className='title'>{t('menu.howDo')}</h1>
			
			<span className='text-separator-top' />
			<p className='paragraph'>
				<div dangerouslySetInnerHTML={{ __html: `${howDoesItWork}` }} />
			</p>
			<div className='text-separator-bottom' /> */}
    </Base>
  );
}

function mapStateToProps(store) {
  let values = {};
  if (store.home.homes.length) {
    values = {
      imgBackground: store.home.homes[0].verifierImageUr,
      videoUrl: store.home.homes[0].videoUrl,
    };
  }
  return {
    ...values,
  };
}

export default connect(mapStateToProps)(HowDoWeDoIt);
