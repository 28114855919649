// Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// i18n
import { t } from '../../i18n';
//Redux
import {
  setActivedModalChangePass,
  getOrders,
} from '../../redux/profile/actions';
import { getSlidersBeneficiares } from '../../redux/home/actions';
import { getCountries } from '../../redux/register/actions';
import { getUser, updateUser } from '../../redux/user/actions';
import UpdateProfile from '../../form/UpdateProfile';
import { getProfile } from '../../redux/app/actions';

// const routes
import { routes } from '../../routes';
// Components

import Wrapper from '../../components/Wrapper';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import History from '../../components/History';
import MessageResponse from '../../components/MessageResponse';
import Carrousel from '../../components/Carrousel';

// Styles
import { Container } from './styles';
import img from '../../assets/images/user-min.png';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPost: [],
    };
  }
  componentDidMount() {
    const { getCountries, getSlidersBeneficiares, getOrders } = this.props;
    getUser();
    getCountries();
    getSlidersBeneficiares();
    getOrders();
    getProfile();
  }
  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user && this.props.user) {
      this.props.getOrders();
    }
  }
  toggleSetActivedModalChangePass = () =>
    this.props.setActivedModalChangePass(!this.props.changePassword);
  render() {
    const {
      user,
      updateUserProps,
      message,
      countries,
      userIdBeneficiaryData,
      userIdVerifierData,
      sliderBeneficiares,
      orders,
    } = this.props;
    const beneficiaryProfile = userIdBeneficiaryData;
    const verifierProfile = userIdVerifierData;
    return (
      <Fragment>
        <Wrapper>
          <Container>
            <div className="infoUser">
              <h1 className="textCenter">{t('profile.myInformation')}</h1>
              <span className="topBlue" />
              <UpdateProfile
                initialValues={user}
                imageUrl={
                  user && user.imageUrl && user.imageUrl.split('/').length > 1
                    ? user.imageUrl
                    : img
                }
                imageDefault={img}
                onSubmit={updateUserProps}
                countries={countries}
                beneficiaryProfile={beneficiaryProfile}
                verifierProfile={verifierProfile}
                toggleSetActivedModalChangePass={
                  this.toggleSetActivedModalChangePass
                }
              />
            </div>
            {beneficiaryProfile === 1 && sliderBeneficiares.length > 0 ? (
              <Carrousel lifeGuides={sliderBeneficiares} />
            ) : (
              ''
            )}
            {beneficiaryProfile === 1 ? (
              <>
                <div className="whatDoYou">
                  <img src={img} alt="" style={{ width: '100%' }} />
                </div>
              </>
            ) : verifierProfile === 2 ? (
              <div className="wrapper">
                {/* <img src={img} alt='' style={{ width: '100%' }}/> */}
              </div>
            ) : (
              <>
                <div className="whatDoYou">
                  <div className="wrapper">
                    <h2>{t('homeLogin.whatDoYou')}</h2>
                    <div className="buttons">
                      <Link to={routes.WRITTEN_MESSAGE}>
                        <Button bgColorHover="#892B6E">
                          {' '}
                          {t('buttons.written')}
                        </Button>
                      </Link>
                      <Link to={routes.VIDEO_MESSAGE}>
                        <Button bgColorHover="#892B6E">
                          {t('buttons.video')}
                        </Button>
                      </Link>
                      <Link to={routes.AUDIO_MESSAGE}>
                        <Button bgColorHover="#892B6E">
                          {t('buttons.audio')}
                        </Button>
                      </Link>
                      <Link to={routes.PICTURE_MESSAGE}>
                        <Button bgColorHover="#892B6E">
                          {t('buttons.pictures')}
                        </Button>
                      </Link>
                      <Link to={routes.RECORD}>
                        <Button bgColorHover="#892B6E">
                          {t('buttons.seeMessage')}
                        </Button>
                      </Link>
                    </div>
                    <h2>{t('history.myHistory')}</h2>
                    <History
                      orders={orders}
                      field_1={t('history.date')}
                      field_2={t('history.reference')}
                      field_3={t('history.space')}
                      field_4={t('history.price')}
                    />
                  </div>
                </div>
              </>
            )}
            <MessageResponse
              isActived={message === 'updateUserSuccess'}
              message={t('messagesResponse.updateSucces')}
            />
          </Container>
        </Wrapper>
        <Footer />
      </Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    changePassword: store.profile.isActivedModalChangePass,
    user: store.user.user[0],
    message: store.app.message,
    sliderBeneficiares: store.home.sliderBeneficiares,
    countries: store.register.countries || [],
    userIdBeneficiaryData: store.app.userIdBeneficiaryData,
    userIdVerifierData: store.app.userIdVerifierData,
    orders: store.profile.orders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActivedModalChangePass: (value) =>
      dispatch(setActivedModalChangePass(value)),
    getUser: () => dispatch(getUser()),
    updateUserProps: (user) => {
      dispatch(updateUser(user));
    },
    getCountries: () => dispatch(getCountries()),
    getProfile: (user) => {
      dispatch(getProfile(user));
    },
    getSlidersBeneficiares: () => dispatch(getSlidersBeneficiares()),
    getOrders: () => dispatch(getOrders()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
