/* Dependencies */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { t } from "../../i18n";
import { getBasicMessageInformation } from "../../redux/messages/actions";
/* Comoponenets */
import Message from "../../components/Message";
import MessageResponse from "../../components/MessageResponse";

/* Styles */
import { Container } from "./styles";
/* Form */
import WrittenMessageForm from "../../form/WrittenMessage";

class WrittenMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
			isActived: false,
		};
	}

	componentDidMount() {
		this.props.getBasicMessageInformation();
	}

	toggleIsActived = () => this.setState({ isActived: !this.state.isActived });

	onChange = (date) =>
		this.setState({ date, isActived: !this.state.isActived });

	render() {
		const { isActived } = this.state;
		const { message } = this.props;

		return (
			<Fragment>
				<Message
					withHoutRecomendations
					titleTypeMessage={t("writtenMessage.title")}
					eraser
					notButton
				>
					<Container isActived={isActived}>
						<WrittenMessageForm
							toggleModal={this.toggleIsActived}
							history={this.props.history}
						/>
					</Container>
				</Message>
				{message === "setMessageUserSucces" && <MessageResponse isActived />}
				{message === "setMessageUserError" && (
					<MessageResponse
						isActived
						bgAlert='red'
						message={t("messagesResponse.notSpace")}
					/>
				)}
			</Fragment>
		);
	}
}

WrittenMessage.propTypes = {
	getBasicMessageInformation: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
};

function mapStateToProps(store) {
	return {
		message: store.app.message,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WrittenMessage);
