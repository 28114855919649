// Dependencies
import styled, { css } from "styled-components";
import { ifProp, ifNotProp } from "styled-tools";
import media from "styled-media-query";
// Assets
import lineTitle from "../../assets/images/line-title-2.svg";

const inputBorder = css`
	width: 100%;
	height: ${({ styleSelect }) => (styleSelect ? "auto" : "50px")};
	padding: 6px 20px;
	margin-bottom: 15px;
	font-size: 14.5px;
	line-height: 1.42857143;
	color: #999;
	background-color: #fff;
	border-radius: 25px;
	font-size: 18px;
	color: #000;
	border: 1px solid #4c3f83;
	:focus {
		border-color: #66afe9;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px rgba(102, 175, 233, 0.6);
	}
`;
export const ContainerSearch = styled.div`
	.beneficiaries-select {
		.select__control {
			border-radius: 20px;
			${inputBorder}
		}
		.select__value-container {
			min-height: 30px;
		}
		.select__placeholder {
			font-size: 12px;
		}
		input {
			display: none;
		}
	}
	text-transform: capitalize;
	input {
		margin: 0 !important;
		padding: 0 !important;
		height: 0;
	}
`;

export const ContainerTitleNav = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Container = styled.div`
	margin-top: 159px;
	input {
		${inputBorder}
	}

	.wrapperLateralMenu {
		width: 320px;
		overflow-y: auto;
		padding-bottom: 280px;
		height: 100%;
		position: fixed;
		border-radius: 0;
		display: inline-block;
		background-color: #f7f7f7;
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.15);
		> div {
			padding-bottom: 80px;
		}
		${media.lessThan("medium")`
    	display: none;
		.right {
			width: 100%;
		}		
  `}
	}
	.modal {
		text-align: left !important;
	}
	.buttonModal {
		font-size: 40px;
		display: none;
	}
	.rigth {
		margin-top: -95px;
		right: 0;
		width: calc(100% - 321px);
		height: 300px;
		position: fixed;
		background: #fff;
		padding: 15px;
		padding-top: 30px;
		animation-fill-mode: backwards;
		display: none;
		input {
			width: 100%;
		}
		h4 {
			margin: 10px 0;
			color: #342573;
			font-size: 20px;
			font-weight: 900;
			line-height: 34px;
		}
		p {
			color: #898989;
			font-size: 15px;
		}
		input {
			margin-top: 13px;
		}
		.close {
			display: none;
		}
		${media.lessThan("medium")`
			width: 100%;
			z-index: 10;
			.close {
			display: block
			font-size: 26px;   
    	right: 0;
    	float: right;
    	color: gray;
		}
  `}
	}
	${ifProp(
		"isActived",
		css`
			.rigth {
				display: block;
				animation: fadeInRight 0.4s;
			}
		`,
	)}

	.showBenef {
		width: 165px;
		height: 33px;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
	}
	.showBenefResponsive {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 6% 0;
		cursor: pointer;
		flex-direction: row;
		justify-content: space-between;
		${ifProp(
			"isShow",
			css`
				display: none;
			`,
		)}
		@media (min-width: 992px) {
			display: none;
		}
	}
	.titleResposive {
		margin: 10px 0;
		color: #342573;
		font-size: 20px;
		font-weight: 900;
		line-height: 34px;
	}
	.icon {
		font-size: 29px;
		color: gray;
	}
	@media (min-width: 992px) {
		.rigth {
			width: 260px;
			height: 100%;
		}
	}
	${media.lessThan("medium")`
	.circleImage {    
    right: -38px!important;    
    top: 60px!important;
	}
	.buttonModal {
		display: flex;
		
	}
	.rigth {
		display: block;
		${ifProp(
			"isActived",
			css`
				display: none;
				animation: fadeInRight 0.4s;
			`,
		)}
	}
  `}
`;
export const CreateMessage = styled.div`
	padding-top: 20px;
	position: absolute;
	width: calc(100% - 320px);
	margin-left: 321px;
	z-index: 0;
	transition: all 0.5s;
	${ifProp(
		"isActived",
		css`
			padding-left: 0;
			width: calc(100% - 320px);
		`,
	)}
	@media (min-width: 992px) {
		width: calc(100% - 320px);
		${ifNotProp(
			"record",
			css`
				${ifProp(
					"isActived",
					css`
						padding-left: 0;
						width: calc(100% - 580px);
					`,
				)}
			`,
		)}
	}
	${media.lessThan("medium")`
		width: 100%;
		margin: 0;
		${ifProp(
			"isActived",
			css`
				width: 100%;
			`,
		)}
	`}
	.input {
		margin: 0 auto;
		margin-top: 20px;
		width: 62%;
		text-align: center;
		margin-bottom: 30px;
		> h4 {
			color: #211261;
		}
		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.center {
		margin-bottom: 8px;
	}
	.options {
		margin: 0 auto;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 8px;
	}

	footer {
		position: relative;
	}
	.ContainerCreateMessage {
		min-height: calc(100vh - 194px);
		@media (max-width: 992px) {
			margin-top: 10%;
		}
	}
	.typeMessage {
		color: #211261;
		text-align: center;
	}
	.separator {
		width: 180px;
		height: 25px;
		background: url(${lineTitle}) no-repeat 0 0 / 180px 25px;
		margin: 0 auto 20px -90px;
		display: inline-block;
		position: relative;
		left: 50%;
	}
	.DraftEditor-root {
		height: 500px !important;
		border: 1px solid gray !important;
		overflow-y: hidden;
	}
	${ifProp(
		"eraser",
		css`
			.options {
				margin: 0 auto;
				width: 100%;
				display: block;
				grid-template-columns: none;
				grid-gap: none;
				> button {
					width: 201px;
				}
			}
		`,
	)}
	${media.lessThan("medium")`
	.input {
		width: 100%;
	}
	.options {
		grid-template-columns: 1fr;
		button {
			width: 206px;
    	margin: 0 auto;
		}
	}
	.ContainerCreateMessage {
		display: flex;
    flex-direction: column;
    align-items: center;
	}
`}
`;
