/* Dependencies */
import React from "react";
import { Field, reduxForm } from "redux-form";
/* i18n */
import { t } from "../../i18n";
/* Components */
import Button from "../../components/Button";
import InputRedux from "../../fields/Input";
import InputPassword from "../../fields/InputPassword";
import SelectRedux from "../../fields/SelectInput";
import { ContainerFile } from "../../pages/Register/styles";
// Validate
import validate from "validate.js";
import DropDownUser from "../../components/DropDownUser";
import DropDownUserVerifiers from "../../components/DropDownUserVerifiers";
import InputFileButton from "../../fields/InputFileButton";

const constrains = {
	number: {
		presence: { message: t("validations.login.required") },
	},
	email: {
		email: { message: t("validations.login.email") },
		presence: { message: t("validations.login.required") },
	},
	password: {
		presence: { message: t("validations.login.required") },
		length: {
			minimun: 8,
			tooShort: "sdsfsfsa",
			wrongLength: "debe ser mayor a 8 digirtos",
		},
	},
	repeatPassword: {
		presence: { message: t("validations.login.required") },
		length: {
			minimun: 8,
			tooShort: "sdsfsfsa",
			wrongLength: "needs to have %{count} words or more",
		},
		equality: {
			attribute: "password",
			message: t("validations.login.passwordsDoMotMatch"),
		},
	},
};

const password = (value) =>
	!/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,32}$/.test(value)
		? t("validationsInputs.password")
		: false;

const required = (value) => (value ? undefined : t("register.required"));
const minLength = (min) => (value) =>
	value && value.length < min ? t("register.MustThan") : undefined;
const minValue13 = minLength(8);
const emailRequire = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? t("register.invalidEmail")
		: undefined;

const phoneNumber = (value) =>
	value && value.length < 10 ? t("register.minimun") : undefined;
const maxLength = (max) => (value) =>
	value && value.length > max ? t("register.maximumCharacters") : undefined;
const maxLength30 = maxLength(30);
const characters = (value) =>
	value && !/^[A-Za-zÁÉÍÓÚñáéíóúÑ _]*[ñA-Za-z][ñA-Za-z _]*$/i.test(value)
		? t("register.onlyLetters")
		: undefined;

function CreateNewUser({
	handleSubmit,
	onSubmit,
	isActivedmodalCreateBeneficiare,
	verifiersA,
	beneficiaresA,
	toggleBeneficiares,
	toggleVerifiers,
	toggleModalTerms,
	beneficiares,
	verifiers,
	genders,
	identificationTypes,
	relationships,
	renderError,
	visible,
	countries,
	code,
	onSubmitBeneficiary,
	fileName,
	uplodadName,
}) {
	function onChange(data) {
		const stale = data.name !== undefined ? data.name : "No seleccionado";
		uplodadName(stale);
	}
	return (
		<form
			className='formBeneficiare'
			onSubmit={handleSubmit((value) =>
				code
					? onSubmitBeneficiary({
							email: value.primaryEmail,
							optionalEmail: value.optionalEmail,
							firstName: value.name,
							lastName: value.lastName,
							identificationNumber: value.numberDocument,
							phone: value.cellPhone,
							mobile: value.cellPhone,
							addres: value.addres,
							imageUrl: value.imageUrl,
							active: true,
							avaliableSpace: 0,
							countryId: value.country,
							genderId: 1,
							identificationTypeId: value.documentType,
							password: value.password,
							code: code,
					  })
					: onSubmit([
							{
								email: value.primaryEmail,
								optionalEmail: value.optionalEmail,
								firstName: value.name,
								lastName: value.lastName,
								identificationNumber: value.numberDocument,
								phone: value.cellPhone,
								mobile: value.cellPhone,
								addres: value.addres,
								active: true,
								avaliableSpace: 0,
								countryId: value.country,
								genderId: 1,
								identificationTypeId: value.documentType,
								password: value.password,
							},
							{
								beneficiaries: value.beneficiaries,
							},
							{
								beneficiaries2: [
									{
										emailBeneficiary: value.emailBeneficiary,
										nameUserBeneficiary: value.nameUserBeneficiary,
										lastNameUserBeneficiary: value.lastNameUserBeneficiary,
										documentTypeBeneficiary: value.documentTypeBeneficiary,
										countryId: value.country,
									},
								],
								verifieries: [
									{
										emailVerifiers: value.emailVerifiers0,
										NameUserVerifier: value.NameUserVerifier0,
										LastNameUserVerifier: value.LastNameUserVerifier0,
										cellPhone: value.cellPhone0,
										countryId: value.country,
									},
									{
										emailVerifiers: value.emailVerifiers1,
										NameUserVerifier: value.NameUserVerifier1,
										LastNameUserVerifier: value.LastNameUserVerifier1,
										cellPhone: value.cellPhone1,
									},
									{
										emailVerifiers: value.emailVerifiers2,
										NameUserVerifier: value.NameUserVerifier2,
										LastNameUserVerifier: value.LastNameUserVerifier2,
										cellPhone: value.cellPhone2,
									},
								],
							},
							{
								imageUrl: value.imageUrl,
							},
					  ]),
			)}
		>
			<h1 className='title'>{t("register.name")}</h1>
			<span className='text-separator-top' />
			<p>{t("register.information")}:</p>
			<div className='inputs'>
				<Field
					name='name'
					component={InputRedux}
					type='text'
					placeholder={t("register.fields.name")}
					validate={[maxLength30, characters, required]}
				/>
				<Field
					component={InputRedux}
					name='lastName'
					type='text'
					placeholder={t("register.fields.lastName")}
					validate={[maxLength30, characters, required]}
				/>
				<Field
					name='documentType'
					component={SelectRedux}
					validate={required}
					isRegister
				>
					<option disabled='' value=''>
						{t("register.fields.SelectDocumentType")}*
					</option>
					{identificationTypes.map((data, index) => (
						<option key={index} value={data.id}>
							{data.name}
						</option>
					))}
				</Field>
				<Field
					component={InputRedux}
					name='numberDocument'
					type='number'
					placeholder='ID'
					validate={(minLength(8), required)}
				/>
				<Field
					name='country'
					component={SelectRedux}
					isRegister
					validate={required}
				>
					<option disabled=''>{t("register.fields.SelectCountry")}</option>
					{countries.map((data, index) => (
						<option key={index} value={data.id}>
							{data.name}
						</option>
					))}
				</Field>
				<Field
					component={InputRedux}
					name='cellPhone'
					type='number'
					validate={[required, phoneNumber]}
					placeholder={t("register.fields.cellPhone")}
				/>
			</div>
			<Field
				component={InputRedux}
				name='addres'
				type='text'
				validate={required}
				placeholder={t("register.fields.addres")}
			/>
			<label>{t("register.fields.attachPicture")}*:</label>
			<ContainerFile>
				{/* <div className='fileCaption'>
					<div className='file-caption-name' >{fileName !== undefined ? fileName : 'No seleccionado'}
					</div>
				</div>
				<div className='btn'>
					<i className='far fa-folder-open' />
					<Field
						data-show-preview='false'
						data-show-remove='false'
						data-show-upload='false'
						id='fileUpload'
						onChange={data => onChange(data)}
						component={FileInput}
						name='imageUrl'
						type='file'
					/>
				
					<span className='span' style={{ width: '92px' }}>{t('buttons.browse') + '...'}</span>
				
				</div> */}
			</ContainerFile>
			<Field
				data-show-preview='false'
				data-show-remove='false'
				data-show-upload='false'
				id='fileUpload'
				nameFile={fileName}
				name='imageUrl'
				// nameFile={fileName}
				onChange={onChange}
				component={InputFileButton}
			/>
			<Field
				component={InputRedux}
				name='primaryEmail'
				type='email'
				validate={[required, emailRequire]}
				placeholder={t("register.fields.primaryEmail")}
			/>
			<Field
				component={InputRedux}
				name='optionalEmail'
				type='email'
				placeholder={t("register.fields.optionalEmail")}
			/>
			<div>
				<div className='inputs'>
					<Field
						component={InputPassword}
						name='password'
						placeholder={t("register.fields.password")}
						validate={[required, minValue13, password]}
					/>
					<Field
						component={InputPassword}
						name='repeatPassword'
						placeholder={t("register.fields.repeatPassword")}
						validate={[required, minValue13, password]}
					/>
				</div>
			</div>
			{code ? (
				""
			) : (
				<div>
					<div>
						<button className='typeUser' type='button'>
							<DropDownUser
								title={t("register.beneficiares.name")}
								count={beneficiaresA}
								description={t("register.beneficiares.description")}
								isActived={beneficiares}
								toggle={toggleBeneficiares}
								genders={genders}
								relationships={relationships}
							/>
						</button>
					</div>
					<button className='typeUser' type='button'>
						<DropDownUserVerifiers
							title={t("register.verifiers.name")}
							count={verifiersA}
							description={t("register.verifiers.description")}
							isActived={verifiers}
							toggle={toggleVerifiers}
							genders={genders}
							relationships={relationships}
						/>
					</button>
				</div>
			)}

			<div className='submit'>
				<Field
					name='authorizeData'
					type='checkbox'
					validate={required}
					component={InputRedux}
					placeholder={t("register.accept")}
				/>
				<div className='text-accept' onClick={toggleModalTerms}>
					{t("register.accept")}
					<span>{" " + t("menu.terms")}</span>
				</div>
				<Button bgColor='#211261' bgColorHover='#892b6e'>
					{t("buttons.submit")}
				</Button>
			</div>
		</form>
	);
}

export default reduxForm({
	form: "createNewUser",
	validate: (values) => validate(values, constrains),
})(CreateNewUser);
