//  Dependencies
import React from "react";
// Styles
import { Container, Item } from "./styles";
import { t } from "../../i18n";

function History({ field_1, field_2, field_3, field_4, orders }) {
	return (
		<Container>
			{!orders.length ? (
				<div>
					<div className='top'>
						<p>{field_1}</p>
						<p>{field_2}</p>
						<p>{field_3}</p>
						<p>{field_4}</p>
					</div>
					<p>{t("history.NotPayu")}</p>
				</div>
			) : (
				<div>
					<div className='top'>
						<p>{field_1}</p>
						<p>{field_2}</p>
						<p>{field_3}</p>
						<p>{field_4}</p>
					</div>
					{orders.map((order, index) => (
						<Item key={index}>
							<p>{order.expirationDate}</p>
							<p>{order.space && order.space.code}</p>
							<p>{order.space && order.space.space} megabytes</p>
							<p>$ {order.space && order.space.price}</p>
						</Item>
					))}
				</div>
			)}
		</Container>
	);
}

export default History;
