import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 25px;
	margin-right: -130px;
	margin-left: -130px;
	margin-bottom: 60px;
	margin-top: 29px;		
	.buttonBeneficiary {
		float: right;
		width: 90px;
		margin-bottom: 20px;
	}
	.packageAlert {
		background: #df382f;
		color: #fff;
		font-size: 14px;
		padding: 10px;
		margin: 0 auto;
	}
	.btnBuy {
		width: 140px !important;
		padding: 0 !important;
		margin: 0 29%;
	}
	.txtBlue {
		margin-left: 10px;
		color: #211261;
	}
	${media.lessThan('large')`    
  margin-right: -5px;
	margin-left: -5px;		
  `}
	${media.lessThan('medium')`    
    grid-template-columns: 1fr;
  `}
`
export const BtnRight = styled.div`
	width: 55px;
	height: 155px;
	position: fixed;
	right: -5px;
	top: 170px;
	z-index: 10;
	${media.greaterThan('medium')`    
    top: 267px;
  `}
`
export const ProfilesModal = styled.div`
  margin: 63px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  form {
    > h3,
    span {
      color: #211261;
    }
    img {
    }
    > h3 {
      font-size: 19px;
      line-height: 21px;
      margin: 10px 0;
      font-family: inherit;
      font-weight: bold;
    }
    > p {
      color: #333;
      font-size: 13px;
    }
    > input {
      margin: 30px 0;
      width: 100%;
    }
    > div {
      margin-right: 19px;
    }
  }
`