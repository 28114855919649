import request from '../../libs/request'

class ContactService {
	endpoints = {
		faq: '/faqs',
		contact: '/contacts/new-contact'
	}

	getFaq(filter = {}) {
		return request.get(`${this.endpoints.faq}?filter=${JSON.stringify(filter)}`)
	}

	setContacts({ name, email, phone, comment, authorizeData, language }) {
		return request.post(this.endpoints.contact, {
			name,
			email,
			phone,
			comment,
      authorizeData,
      language
		})
	}
}

export default new ContactService()
