// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-column-gap: 20px;
  text-align: center;  
  margin-right: -130px;
	margin-left: -130px;    
  margin-bottom: 25px;
  .link {
    margin-top: 20px;
    button {
      width: auto;
    }
  }
  .container-slider {
    margin: 48px auto;
    width: 750px;
    img {
      width: 750px;
      height: 400px;
    }
  }
  ${media.lessThan('large')`    
  margin-right: -5px;
	margin-left: -5px;		
  `}
  ${media.lessThan('medium')`
  grid-template-columns: 1fr;
  
  `}
`
