import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 18%;
    right: 22px;
    left: 1;
    color: #342573;
    font-size: 1.6rem;
    cursor: pointer;
  }
`