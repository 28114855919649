// Dependencies
import React from 'react'
import { connect } from 'react-redux'
// Redux
import { setQuestion } from '../../redux/contact/actions'
// Styled compoents
import { Container } from './styles'

class Question extends React.Component {
  render () {
    const { titleQuestion, contentQuestion, isActived, ko } = this.props

    return (
      <Container isActived={isActived} ko={ko}>
        <span className='block-question'>{titleQuestion}</span>
        <div className='collapsel'>
          <p>{contentQuestion}</p>
        </div>
      </Container>
    )
  }
}

function mapStateToProps (store) {
  return {
    isActivedQuestion: store.contact.isActivedQuestion
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleQuestion: action => dispatch(setQuestion(action))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Question)
