import { reset } from 'redux-form'
import { QUESTION, QUESTIONS } from './const'
import ContactService from './ContactService'
import { getLanguage } from '../../i18n';

import { setMessage, setLoading } from '../app/actions'

const timeHideMessage = 3000

export const setQuestion = value => ({ type: QUESTION, value })
export const setQuestions = value => ({ type: QUESTIONS, value })

export const setContacts = values => async dispatch => {
	try {
    dispatch(setLoading(true))
    const rr = getLanguage()
    console.log('rr', rr)
		await ContactService.setContacts({...values, language: getLanguage() })
		dispatch(setMessage('setContactSuccess'))
		dispatch(reset('contact'))
	} catch (e) {
		dispatch(setMessage('setContactError'))
		console.log(e)		
	} finally {
		dispatch(setLoading(false))
		setTimeout(() => dispatch(setMessage('')), timeHideMessage)		
	}
}
