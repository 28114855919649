import {
	MESSAGE_TYPES,
	EVENT_TYPES,
	EVENT_TYPES_ALL,
	MESSAGE_PREVIEW,
	SAVE_DRAFT,
	DRAFT_LOCALE_STOREGE,
	FILE_TYPES,
	SELECTED_BENEFICIARIES,
	SET_MESSAGE_BY_USER,
	SET_MESSAGE_EVENTS_BY_USER,
	SET_MESSAGE_ADVICE_BY_USER,
	DRAFT_LOCALE_STORAGE_DETAIL,
	ADVICE_TYPES,
	ADVICE_TYPES_ALL,
} from "./const";
import MessageService from "./MessageService";

import { setMessage, setLoading } from "../app/actions";
import { getUser } from "../user/actions";

import { t } from "../../i18n";

import moment from "moment";

const timeHideMessage = 3000;
const getLocalStorage = (keyName) =>
	JSON.parse(window.localStorage.getItem(keyName));
const setLocalStorage = (keyName, el) =>
	window.localStorage.setItem(keyName, JSON.stringify(el));

export const setMessageTypes = (value) => ({ type: MESSAGE_TYPES, value });

export const setMessagePreview = (value) => ({ type: MESSAGE_PREVIEW, value });

export const setSelectedBeneficiaries = (value) => ({
	type: SELECTED_BENEFICIARIES,
	value,
});

export const setEventTypes = (value) => ({ type: EVENT_TYPES, value });
export const setEventTypesAll = (value) => ({ type: EVENT_TYPES_ALL, value });

export const setAdviceTypes = (value) => ({ type: ADVICE_TYPES, value });
export const setAdviceTypesAll = (value) => ({ type: ADVICE_TYPES_ALL, value });

export const setDraftLocaleStorege = (value) => ({
	type: DRAFT_LOCALE_STOREGE,
	value,
});

export const setDraftLocaleStorageDetail = (value) => ({
	type: DRAFT_LOCALE_STORAGE_DETAIL,
	value,
});

export const setFileTypes = (value) => ({ type: FILE_TYPES, value });

export const setMessageByUser = (value) => ({
	type: SET_MESSAGE_BY_USER,
	value,
});

export const setMessageEventsByUser = (value) => ({
	type: SET_MESSAGE_EVENTS_BY_USER,
	value,
});

export const setMessageAdviceByUser = (value) => ({
	type: SET_MESSAGE_ADVICE_BY_USER,
	value,
});

export const getEventTypes = () => async (dispatch) => {
	try {
		const [eventTypes, eventTypesAll] = await Promise.all([
			MessageService.getEventTypes(),
			MessageService.getAllEventTypes(),
		]);

		dispatch(setEventTypes(eventTypes.data));
		dispatch(setEventTypesAll(eventTypesAll.data));
	} catch (e) {
		console.log(e);
	}
};

export const getAdviceTypes = () => async (dispatch) => {
	try {
		const [eventTypes, adviceTypesAll] = await Promise.all([
			MessageService.getAdviceTypes(),
			MessageService.getAllAdviceTypes(),
		]);
		dispatch(setAdviceTypes(eventTypes.data));
		dispatch(setAdviceTypesAll(adviceTypesAll.data));
	} catch (e) {
		console.log(e);
	}
};

export const getMessageTypes = () => async (dispatch) => {
	try {
		const messageType = await MessageService.getMessageType();
		dispatch(setMessageTypes(messageType.data));
	} catch (e) {
		console.log(e);
	}
};

export const getBasicMessageInformation = () => (dispatch) => {
	try {
		dispatch(getMessageTypes());
		dispatch(getEventTypes());
		dispatch(getAdviceTypes());
	} catch (e) {
		console.log(e);
	}
};
// original
// export const setMessageUser2 =
// 	(message, next) => async (dispatch, getStore) => {
// 		try {
// 			if (message.body.length <= 8) {
// 				message.body = "<p><p></p</p>";
// 			}
// 			const fileUrl = new Blob([message.body], { type: "text/html" });
// 			delete message.body;
// 			dispatch(setLoading(true));
// 			const store = getStore();
// 			const {
// 				user: { user },
// 				messages: { selectedBeneficiaries },
// 			} = store;
// 			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
// 				return dispatch(setMessage("beneficiaryRequired"));
// 			message.userId = user[0].id;
// 			console.log("	message,", message);

// 			const response = await MessageService.setMessage({
// 				...message,
// 				visibleData:
// 					message.evenType === "date"
// 						? moment(message.visibleData, "YYYY-MM-DD")
// 						: null,
// 				byAbsence: message.evenType === "absence" ? true : null,
// 			});

// 			const formData = new FormData();
// 			if (message.attached) {
// 				formData.append("fileUrl", message.attached, message.attached.type);
// 			} else {
// 				formData.append("fileUrl", fileUrl, "message.html");
// 			}
// 			formData.append("fileTypeId", 1);
// 			formData.append("messageId", response.data.id);
// 			formData.append("visibleData", moment(message.visibleData, "YYYY-MM-DD"));
// 			let formDataAttached = null;
// 			if (message.attached) {
// 				formDataAttached = true;
// 			}
// 			dispatch(setMessagePreview(response.data));
// 			await Promise.all([
// 				MessageService.createWithFileFormData(formData),
// 				formDataAttached &&
// 					MessageService.createWithFile({
// 						fileUrl: message.attached,
// 						fileTypeId: 1,
// 						messageId: response.data.id,
// 						visibleData: moment(message.visibleData, "YYYY-MM-DD"),
// 					}),
// 				...selectedBeneficiaries.map((selectedBeneficiary) =>
// 					MessageService.setMessageBeneficary({
// 						beneficiaryId: selectedBeneficiary.value,
// 						messageId: response.data.id,
// 					}),
// 				),
// 			]);
// 			dispatch(
// 				setMessage({
// 					name: "success",
// 					message: t("messagesResponse.messageCreated"),
// 				}),
// 			);
// 			dispatch(getMessageByUser());
// 			next();
// 		} catch (e) {
// 			console.log("setMessageUser", e);

// 			dispatch(
// 				setMessage({
// 					name: "withError",
// 					message: t("messagesResponse.notSpace"),
// 				}),
// 			);
// 		} finally {
// 			dispatch(setLoading(false));
// 			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
// 		}
// 	};
export const setMessageUser = (message, next) => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user, checkers },
			messages: { selectedBeneficiaries },
		} = store;
		console.log("---store", checkers.length);
		if (checkers.length === 0) {
			return dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notVerified"),
				}),
			);
		}
		console.log("-----USE", user);
		if (message.body.length <= 8 && message.attached) {
			delete message.body;
		}
		if (user[0].avaliableSpace === 0)
			return dispatch(setMessage("setMessageUserError"));
		if (!selectedBeneficiaries || !selectedBeneficiaries.length) {
			return dispatch(setMessage("beneficiaryRequired"));
		}
		message.userId = user[0].id;

		const response = await MessageService.setMessage({
			...message,
			visibleData:
				message.evenType === "date"
					? moment(message.visibleData, "YYYY-MM-DD")
					: null,
			byAbsence: message.evenType === "absence" ? true : null,
		});

		const promises = [];
		// Verificar si hay un archivo adjunto (PDF) o un mensaje HTML para subir los dos
		if (message.attached && message.body) {
			// Subir mensaje en formato HTML

			const body = message.body;

			const fileUrl = new Blob([body], { type: "text/html" });

			const htmlFormData = new FormData();
			htmlFormData.append("fileUrl", fileUrl, "message.html");
			htmlFormData.append("fileTypeId", 1);
			htmlFormData.append("messageId", response.data.id);

			htmlFormData.append(
				"visibleData",
				moment(message.visibleData, "YYYY-MM-DD"),
			);
			// dispatch(setMessagePreview(response.data));

			promises.push(MessageService.createWithFileFormData(htmlFormData));
			message.attached &&
				promises.push(
					MessageService.createWithFile({
						fileUrl: message.attached,
						fileTypeId: 1,
						messageId: response.data.id,
						visibleData: moment(message.visibleData, "YYYY-MM-DD"),
					}),
				);
		} else if (message.attached) {
			// dispatch(setMessagePreview(response.data));
			promises.push(
				MessageService.createWithFile({
					fileUrl: message.attached,
					fileTypeId: 1,
					messageId: response.data.id,
					userId: user[0].id,
					visibleData: moment(message.visibleData, "YYYY-MM-DD"),
				}),
			);
		} else {
			if (message.body.length <= 8) {
				message.body = "<p><p></p</p>";
			}
			// Subir mensaje en formato HTML
			const fileUrl = new Blob([message.body], { type: "text/html" });
			delete message.body;
			delete message.attached;

			const htmlFormData = new FormData();
			htmlFormData.append("fileUrl", fileUrl, "message.html");
			htmlFormData.append("fileTypeId", 1);
			htmlFormData.append("messageId", response.data.id);
			htmlFormData.append(
				"visibleData",
				moment(message.visibleData, "YYYY-MM-DD"),
			);
			dispatch(setMessagePreview(response.data));
			promises.push(MessageService.createWithFileFormData(htmlFormData));
		}
		await promises.push(
			...selectedBeneficiaries.map((selectedBeneficiary) =>
				MessageService.setMessageBeneficary({
					beneficiaryId: selectedBeneficiary.value,
					messageId: response.data.id,
				}),
			),
		);

		dispatch(
			setMessage({
				name: "success",
				message: t("messagesResponse.messageCreated"),
			}),
		);

		dispatch(getMessageByUser());
		next();
	} catch (e) {
		console.log("setMessageUser", e);

		dispatch(setMessage("setMessageUserError"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const setMessageWrittenEdit =
	(message, cb, isLocal, slug) => async (dispatch, getStore) => {
		try {
			const {
				messages: { selectedBeneficiaries },
				user: { user },
			} = getStore();
			console.log("ENTRO EDITAR TEXTOP");

			if (isLocal) {
				const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`);
				const newLocal = saveDraft.filter((el) => el.id !== slug);
				setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, newLocal);
				dispatch(setDraftLocaleStorege(newLocal));
				return dispatch(setMessageUser(message, cb));
			}

			dispatch(setLoading(true));

			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
				return dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.benficiaryRequired"),
					}),
				);

			const beneficiares = selectedBeneficiaries.map((el) => el.value);

			if (message.attached && message.idText !== "" && message.idFile !== "") {
				// Subir mensaje en formato HTML

				const body = message.body;

				const blob = new Blob([body], { type: "text/html" });
				const fileUrl = new File([blob], "message.html");

				body &&
					(await MessageService.updateFile(message.idText, {
						fileUrl,
					}));
				message.attached &&
					(await MessageService.updateFile(message.idFile, {
						fileUrl: message.attached,
					}));
			} else if (message.attached) {
				await MessageService.updateFile(
					message.idFile !== "" ? message.idFile : message.fileTypeText,
					{
						fileUrl: message.attached,
					},
				);
			} else {
				const blob = new Blob([message.body], { type: "text/html" });
				const fileUrl = new File([blob], "message.html");
				await MessageService.updateFile(
					message.idText !== "" ? message.idText : message.fileTypeText,
					{ fileUrl },
				);
			}
			await Promise.all([
				MessageService.updateMessage(message.messageId, {
					title: message.title,
					visibleData:
						message.evenType === "date"
							? moment(message.visibleData, "YYYY-MM-DD")
							: null,
					byAbsence: message.evenType === "absence" ? true : null,
				}),
				MessageService.updateBeneficiares(message.messageId, {
					beneficiares,
				}),
			]);
			dispatch(getMessageByUser());
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.updateSucces"),
				}),
			);
			cb();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notSpace"),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const getDraftLocaleStorege = () => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`);
		dispatch(setDraftLocaleStorege(saveDraft));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const getSelectMessage = (slug) => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const {
			messages: {
				messageByUser: { data },
				messageEventByUser,
				messageAdviceByUser,
			},
		} = getStore();
		const selectedMessage = data
			.concat(messageEventByUser.data)
			.concat(messageAdviceByUser.data)
			.find(({ id }) => id === slug);

		dispatch(setDraftLocaleStorege(selectedMessage));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const getDraftLocaleStoregeBySlug = (slug) => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		// const store = getStore();
		// const {
		//   user: { user },
		// } = store;
		// const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`).find(
		// 	item => item.title === slug
		// )
		// dispatch(setDraftLocaleStorege(saveDraft))
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const getDraftLocaleStorageById = (id) => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`).find(
			(item) => item.id === parseInt(id),
		);
		console.log(saveDraft);
		dispatch(setDraftLocaleStorageDetail(saveDraft));
	} catch (e) {
		console.log("getDraftLocaleStorageById ", e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const deleteLocaltoregeByMessage = () => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
			messages: { draftLocaleStorage },
		} = store;
		const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`).filter(
			(item) => item.id !== draftLocaleStorage.id,
		);
		setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, saveDraft);
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const saveDraftLocaleStorege = (message) => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		message.userId = user[0].id;
		const saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`);

		if (Array.isArray(saveDraft)) {
			saveDraft.push({ ...message, id: saveDraft.length + 1 });
			setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, saveDraft);
		} else {
			setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, [{ ...message, id: 1 }]);
		}
		dispatch(setMessagePreview(message));
		dispatch(setMessage("setMessageUserSucces"));

		window.location.assign("/see-draft");
	} catch (e) {
		console.log(e);
		dispatch(setMessage("setMessageUserError"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const saveEditDraftLocaleStorege = (message) => (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		message.userId = user[0].id;
		let saveDraft = getLocalStorage(`${SAVE_DRAFT}${user[0].id}`);
		for (let index = 0; index < saveDraft.length; index++) {
			if (saveDraft[index].id === message.id) {
				saveDraft[index] = message;
				break;
			}
		}
		// console.log('saveEditDraftLocaleStorege', saveDraft)
		setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, saveDraft);
		// if (Array.isArray(saveDraft)) {
		// 	saveDraft.push({ ...message, id: saveDraft.length + 1 })
		// 	setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, saveDraft)
		// } else {
		// 	setLocalStorage(`${SAVE_DRAFT}${user[0].id}`, [{...message, id: 1}])
		// }
		dispatch(setMessagePreview(message));
		dispatch(setMessage("setMessageUserSucces"));

		window.location.assign("/see-draft");
	} catch (e) {
		console.log(e);
		dispatch(setMessage("setMessageUserError"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const setMessageAudio =
	(
		{
			messageTypeId,
			eventTypeId,
			title,
			fileUrl,
			fileTypeId,
			visibleData,
			evenType,
		},
		cb,
	) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user, checkers },
				messages: { selectedBeneficiaries },
			} = store;
			if (checkers.length === 0) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.notVerified"),
					}),
				);
			}
			if (selectedBeneficiaries.length === 0)
				return dispatch(setMessage("beneficiaryRequired"));
			if (!fileUrl) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.requiredFileVideo"),
					}),
				);
			}
			const message = {
				userId: user[0].id,
				messageTypeId,
				eventTypeId,
				title,
				visibleData:
					evenType === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
				byAbsence: evenType === "absence" ? true : null,
			};
			const response = await MessageService.setMessage(message);
			const messageWithFile = {
				fileUrl,
				fileTypeId,
				messageId: response.data.id,
				visibleData: moment(visibleData).format(),
			};
			await Promise.all([
				MessageService.createWithFile(messageWithFile),
				...selectedBeneficiaries.map((selectedBeneficiary) =>
					MessageService.setMessageBeneficary({
						beneficiaryId: selectedBeneficiary.value,
						messageId: response.data.id,
					}),
				),
			]);
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.messageCreated"),
				}),
			);
			dispatch(getMessageByUser());
			// if (dataUrlOrigin === dataUrlOrigin) {
			// 	window.location.assign('/ver-borradores')
			// }
			// if (dataUrlOrigin === dataUrlOrigin) {
			// 	window.location.assign('/see-draft')
			// }
			cb();
		} catch (e) {
			console.log(e);
			dispatch(setMessage("setMessageAudioError"));
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setMessageVideo =
	(
		{
			messageTypeId,
			eventTypeId,
			title,
			fileUrl,
			fileTypeId,
			visibleData,
			evenType,
		},
		next,
	) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user, checkers },
				messages: { selectedBeneficiaries },
			} = store;
			if (checkers.length === 0) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.notVerified"),
					}),
				);
			}
			if (!fileUrl) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.requiredFileVideo"),
					}),
				);
			}
			if (selectedBeneficiaries.length === 0)
				return dispatch(setMessage("beneficiaryRequired"));
			const message = {
				userId: user[0].id,
				messageTypeId,
				eventTypeId,
				title,
				visibleData:
					evenType === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
				byAbsence: evenType === "absence" ? true : null,
			};
			const response = await MessageService.setMessage(message);
			const messageWithFile = {
				fileUrl,
				fileTypeId,
				messageId: response.data.id,
			};
			await Promise.all([
				MessageService.createWithFile(messageWithFile),
				...selectedBeneficiaries.map((selectedBeneficiary) =>
					MessageService.setMessageBeneficary({
						beneficiaryId: selectedBeneficiary.value,
						messageId: response.data.id,
					}),
				),
			]);
			dispatch(setMessage("setMessageVideoSuccess"));
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.messageCreated"),
				}),
			);
			dispatch(getMessageByUser());
			next();
		} catch (e) {
			console.log(e);
			dispatch(setMessage("setMessageVideoError"));
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setMessageImage =
	(
		{
			messageTypeId,
			eventTypeId,
			title,
			fileUrl,
			fileTypeId,
			visibleData,
			evenType,
		},
		cb,
	) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user, checkers },
				messages: { selectedBeneficiaries },
			} = store;
			if (checkers.length === 0) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.notVerified"),
					}),
				);
			}
			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
				return dispatch(setMessage("beneficiaryRequired"));
			if (!fileUrl.length) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.requiredFileVideo"),
					}),
				);
			}
			const message = {
				userId: user[0].id,
				messageTypeId,
				eventTypeId,
				title,
				visibleData:
					evenType === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
				byAbsence: evenType === "absence" ? true : null,
			};
			const response = await MessageService.setMessage(message);
			const messageWithFile = {
				fileUrl,
				fileTypeId,
				messageId: response.data.id,
			};
			await Promise.all([
				...selectedBeneficiaries.map((selectedBeneficiary) =>
					MessageService.setMessageBeneficary({
						beneficiaryId: selectedBeneficiary.value,
						messageId: response.data.id,
					}),
				),
				...fileUrl.map((image) =>
					MessageService.createWithFile({
						...messageWithFile,
						fileUrl: image.data,
					}),
				),
			]);
			dispatch(setMessage("setMessageImageSuccess"));
			dispatch(getMessageByUser());
			cb();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notSpace"),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setUploadFile =
	({ title, fileUrl, visibleData, messageId, messageFileId, type }, redirect) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const {
				messages: { selectedBeneficiaries },
			} = getStore();
			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
				return dispatch(setMessage("beneficiaryRequired"));
			const beneficiares = selectedBeneficiaries.map((el) => el.value);
			await Promise.all([
				MessageService.updateMessage(messageId, {
					title,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				}),
				MessageService.updateBeneficiares(messageId, {
					beneficiares,
				}),
				fileUrl && MessageService.updateFile(messageFileId, { fileUrl }),
			]);
			dispatch(getMessageByUser());
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.updateSucces"),
				}),
			);
			redirect();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notSpace"),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setMessageEdit =
	(
		{
			title,
			fileUrl,
			visibleData,
			filesToDelte,
			messageId,
			messageTypeId,
			type,
		},
		redirect,
	) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const {
				messages: { selectedBeneficiaries },
			} = getStore();
			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
				return dispatch(setMessage("beneficiaryRequired"));
			const beneficiares = selectedBeneficiaries.map((el) => el.value);
			const files = fileUrl.filter((el) => !el.messageId);
			await Promise.all([
				MessageService.updateMessage(messageId, {
					title,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				}),
				MessageService.updateBeneficiares(messageId, {
					beneficiares,
				}),
				...files.map(({ data: fileUrl }) =>
					MessageService.createWithFile({
						messageTypeId,
						messageId,
						fileUrl,
					}),
				),
				filesToDelte.length &&
					MessageService.deleteFiles(messageId, filesToDelte),
			]);
			dispatch(getMessageByUser());
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.updateSucces"),
				}),
			);
			redirect();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notSpace"),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setVideoEdit =
	(
		{
			title,
			fileUrl,
			visibleData,
			filesToDelte,
			messageId,
			messageTypeId,
			type,
		},
		redirect,
	) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const {
				messages: { selectedBeneficiaries },
			} = getStore();
			if (!selectedBeneficiaries || !selectedBeneficiaries.length)
				return dispatch(setMessage("beneficiaryRequired"));
			const beneficiares = selectedBeneficiaries.map((el) => el.value);
			const files = fileUrl.filter((el) => !el.messageId);
			await Promise.all([
				MessageService.updateMessage(messageId, {
					title,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				}),
				MessageService.updateBeneficiares(messageId, {
					beneficiares,
				}),
				...files.map(({ data: fileUrl }) =>
					MessageService.createWithFile({
						messageTypeId,
						messageId,
						fileUrl,
					}),
				),
				filesToDelte.length &&
					MessageService.deleteFiles(messageId, filesToDelte),
			]);
			dispatch(getMessageByUser());
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.updateSucces"),
				}),
			);
			redirect();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notSpace"),
				}),
			);
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setMessageEvents =
	(values, redirect) => async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));

			const store = getStore();
			const {
				user: { user, checkers },
				messages: { messageTypes },
			} = store;
			if (checkers.length === 0) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.notVerified"),
					}),
				);
			}
			const messageTypeId = messageTypes.find(
				(messageType) => messageType.code === "event",
			).id;

			const fileTypeId = messageTypes.find(
				(fileType) => fileType.code === values.messageTypeId,
			).id;

			const { eventTypeId, title, fileUrl, beneficiaryId, type, visibleData } =
				values;

			let { body } = values;
			let eventTypeIdValid = eventTypeId;
			if (!parseInt(eventTypeId)) {
				eventTypeIdValid = null;
			}
			if (!beneficiaryId)
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.benficiaryRequired"),
					}),
				);

			let fileUrlAtt = null;
			let formData = new FormData();

			console.log("----fileTypeId", fileTypeId);
			if (fileTypeId === 1 || fileTypeId === 2) {
				if (body.length <= 8) {
					// eslint-disable-next-line
					body = "<p><p></p</p>";
				}
				console.log("----ENTRO ESCRITOP", fileTypeId);

				const blob = new Blob([body], { type: "text/html" });
				fileUrlAtt = new File([blob], "message.html");

				const response = await MessageService.setMessage({
					...body,
					title,
					userId: user[0].id,
					messageTypeId,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				});

				formData.append("fileUrl", blob, "message.html");
				formData.append("fileTypeId", 1);
				formData.append("messageId", response.data.id);
				formData.append("visibleData", moment(visibleData, "YYYY-MM-DD"));
				let formDataAttached = null;

				if (body.attached) {
					formDataAttached = true;
				}
				if (formDataAttached) {
					await MessageService.setMessageBeneficary({
						beneficiaryId,
						messageId: response.data.id,
					});
					await MessageService.createWithFile({
						fileUrl: body.attached,
						fileTypeId: 1,
						messageId: response.data.id,
						visibleData: moment(visibleData, "YYYY-MM-DD"),
					});
				}
				await MessageService.setMessageBeneficary({
					beneficiaryId,
					messageId: response.data.id,
				});
				await MessageService.createWithFileFormData(formData);

				dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.messageCreated"),
					}),
				);
			} else {
				console.log("----fileUrlAtt", fileUrlAtt);
				console.log("----fileUrl", fileUrl);

				const message = {
					userId: user[0].id,
					messageTypeId,
					eventTypeId: eventTypeIdValid,
					title,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				};

				const response = await MessageService.setMessage(message);
				await MessageService.setMessageBeneficary({
					beneficiaryId,
					messageId: response.data.id,
				});
				if (fileUrl) {
					const messageWithFile = {
						fileUrl,
						fileTypeId,
						messageId: response.data.id,
					};
					if (Array.isArray(fileUrl))
						await Promise.all([
							...fileUrl.map((data) =>
								MessageService.createWithFile({
									...messageWithFile,
									fileUrl: data.data || data,
								}),
							),
						]);
					else await MessageService.createWithFile(messageWithFile);
				}

				dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.messageCreated"),
					}),
				);
			}

			redirect();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.error"),
				}),
			);
			dispatch(setLoading(false));
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const setMessageAdvice =
	(values, redirect) => async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user, checkers },
				messages: { messageTypes },
			} = store;
			if (checkers.length === 0) {
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.notVerified"),
					}),
				);
			}
			const messageTypeId = messageTypes.find(
				(messageType) => messageType.code === "advice",
			).id;
			const fileTypeId = messageTypes.find(
				(fileType) => fileType.code === values.messageTypeId,
			).id;
			const { eventTypeId, title, fileUrl, beneficiaryId, type, visibleData } =
				values;

			let { body } = values;
			if (!beneficiaryId)
				return dispatch(
					setMessage({
						name: "withError",
						message: t("messagesResponse.benficiaryRequired"),
					}),
				);

			// let fileUrlAtt = null;
			let formData = new FormData();

			console.log("----fileTypeId consejos", fileTypeId);
			if (fileTypeId === 1 || fileTypeId === 2) {
				if (body.length <= 8) {
					// eslint-disable-next-line
					body = "<p><p></p</p>";
				}
				console.log("----ENTRO ESCRITO consejo", fileTypeId);

				const blob = new Blob([body], { type: "text/html" });
				// fileUrlAtt = new File([blob], 'message.html');

				const response = await MessageService.setMessage({
					...body,
					title,
					userId: user[0].id,
					messageTypeId,
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				});

				formData.append("fileUrl", blob, "message.html");
				formData.append("fileTypeId", 1);
				formData.append("messageId", response.data.id);
				formData.append("visibleData", moment(visibleData, "YYYY-MM-DD"));
				let formDataAttached = null;

				if (body.attached) {
					formDataAttached = true;
				}
				if (formDataAttached) {
					await MessageService.setMessageBeneficary({
						beneficiaryId,
						messageId: response.data.id,
					});
					await MessageService.createWithFile({
						fileUrl: body.attached,
						fileTypeId: 1,
						messageId: response.data.id,
						visibleData: moment(visibleData, "YYYY-MM-DD"),
					});
				}
				await MessageService.setMessageBeneficary({
					beneficiaryId,
					messageId: response.data.id,
				});
				await MessageService.createWithFileFormData(formData);

				dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.messageCreated"),
					}),
				);
			} else {
				const message = {
					userId: user[0].id,
					messageTypeId,
					eventTypeId,
					title,
					body: "",
					visibleData:
						type === "date" ? moment(visibleData, "YYYY-MM-DD") : null,
					byAbsence: type === "absence" ? true : null,
				};

				const response = await MessageService.setMessage(message);
				await MessageService.setMessageBeneficary({
					beneficiaryId,
					messageId: response.data.id,
				});
				if (fileUrl) {
					const messageWithFile = {
						fileUrl,
						fileTypeId,
						messageId: response.data.id,
					};
					if (Array.isArray(fileUrl))
						await Promise.all([
							...fileUrl.map((data) =>
								MessageService.createWithFile({
									...messageWithFile,
									fileUrl: data.data || data,
								}),
							),
						]);
					else await MessageService.createWithFile(messageWithFile);
				}

				dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.messageCreated"),
					}),
				);
			}
			redirect();
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.error"),
				}),
			);
			dispatch(setLoading(false));
		} finally {
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
			dispatch(setLoading(false));
		}
	};

export const getFileTypes = () => async (dispatch) => {
	try {
		const fileTypes = await MessageService.getfileTypes();
		dispatch(setFileTypes(fileTypes.data));
	} catch (e) {
		console.log(e);
	}
};

export const getMessageByUser = () => async (dispatch, getStore) => {
	try {
		const store = getStore();
		const {
			user: { user },
		} = store;
		const messageByUser = await MessageService.getMessageByUser(user[0].id);
		const { data } = messageByUser;
		dispatch(setMessageByUser({ data, countWritten: data.length }));
		dispatch(getUser());
	} catch (e) {
		console.log(e);
	}
};

export const getMessageEventByUser = () => async (dispatch, getStore) => {
	try {
		const store = getStore();
		const {
			user: { user },
		} = store;
		const messageByUser = await MessageService.getMessageEventsByUser(
			user[0].id,
		);
		const { data } = messageByUser;
		dispatch(setMessageEventsByUser({ data, countWritten: data.length }));
	} catch (e) {
		console.log(e);
	}
};

export const getMessageAdviceByUser = () => async (dispatch, getStore) => {
	try {
		const store = getStore();
		const {
			user: { user },
		} = store;
		const messageByUser = await MessageService.getMessageAdviceByUser(
			user[0].id,
		);
		const { data } = messageByUser;
		dispatch(setMessageAdviceByUser({ data, countWritten: data.length }));
	} catch (e) {
		console.log(e);
	}
};

export const deleteMessage = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const {
			messages: {
				draftLocaleStorage: { id: messageId },
			},
		} = getStore();

		await MessageService.deleteMessage(messageId);

		dispatch(getMessageByUser());
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(getMessageByUser());
		dispatch(setLoading(false));
	}
};
