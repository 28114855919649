/* Dependencies */
import React, { Component } from 'react';
import { connect } from 'react-redux';
/* i18n */
import { t } from '../../i18n';
/* Comoponenet */
import Message from '../../components/Message';

/* Styled Component */
import { Container } from './styles';
/* form */
import EventMessageForm from '../../form/EventMessage';

class EventMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActived: false,
      isActivedArea: false,
      isActivedImg: false,
      isActivedAudio: false,
      isActivedVideo: false,
    };
  }
  toggleIsActivedUpload = (state) =>
    this.setState({
      isActivedArea: false,
      isActivedImg: false,
      isActivedAudio: false,
      isActivedVideo: false,
      [state]: !this.state[state],
    });

  toggleIsActived = () => this.setState({ isActived: !this.state.isActived });

  onChange = (date) =>
    this.setState({ date, isActived: !this.state.isActived });

  toggleIsActivedFile = () =>
    this.setState({ isActivedUpload: !this.state.isActivedUpload });

  render() {
    const {
      isActivedUpload,
      isActived,
      isActivedImg,
      isActivedAudio,
      isActivedVideo,
      isActivedArea,
    } = this.state;
    return (
      <Message
        titleTypeMessage={t('eventMessage.title')}
        eraser
        notButton
        withHoutListBeneficiares
      >
        <Container
          isActived={isActived}
          isActivedUpload={isActivedUpload}
          isActivedImg={isActivedImg}
          isActivedAudio={isActivedAudio}
          isActivedVideo={isActivedVideo}
          isActivedArea={isActivedArea}
        >
          <EventMessageForm history={this.props.history} />
        </Container>
      </Message>
    );
  }
}

function mapStateToProps(store) {
  return {
    eventsPage: store.app.eventsPage,
  };
}

export default connect(mapStateToProps)(EventMessage);
