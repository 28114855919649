// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'
import icon from '../../assets/images/sprite-icons.svg'

export const Modal = styled.div`
	animation: fadeOutLeftBig 3s;
	.circleUser {
		height: 70px;
		margin: 0 auto;
		width: 70px;
		border-radius: 35px;
		background: #ccc;
		overflow: hidden;
	}
	.boxCircleUser {
		height: 70px;
		margin: 0 auto;
		width: 70px;
		position: relative;
	}
	.center {
		text-align: center;
	}
	.boxPhrase {
		width: 80%;
		max-width: 330px;
		margin: 0 auto;
		margin-top: -44px;
		text-align: center;
		font-weight: bold;
		p:first-child {
    	font-weight: bold;
    	color: #fff;
    	font-size: 1.6rem;
			margin-bottom: 0;
		}
	}
	.description {
		color: #fff;
		font-weight: normal !important;
	}
	.circleQuotes {
		width: 40px;
		height: 40px;
		border-radius: 103px;
		background: #fff;
		position: relative;
		top: -55px;
		right: -38px;
		margin: 0 auto;
		span {
			width: 27px;
			height: 11px;
			background: url(${icon}) -400px -68px/550px 80px;
			position: absolute;
			margin: 15px 6px;
		}
	}
	.textSeparatorBottom {
		width: 180px;
		height: 10px;
		background: url(${icon}) -210px -70px/550px 80px no-repeat;
		margin: 20px auto;
		display: block;
	}
`

export const Container = styled.div`	
	position: relative;  	
	.title {
		color: #fff;
		font-size: 21px;
		line-height: 24px;
	}
	img {
		width: 100%;
	}
	.btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 42%;
	}
	.btn {
		font-size: 30px;
		width: 26px;
		height: 70px;
		line-height: 70px;		
		background-color: rgba(33, 18, 97, 0.75);
		text-align: center;
		font-weight: bold;
		color: #fff;
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
		transition: all 0.3s ease;
		&:hover {
			cursor: pointer;
			transform: scale(1.2, 1.2);
		}
	}

	.left {
		left: 2%;
		top: calc(50% - 70px);
	}

	.right {
		right: 2%;
		top: calc(50% - 70px);
	}
	.bxCaption {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;		
		background: rgba(33, 18, 97, 0.75);		
		p {
			font-size: 16px;
			color: inherit;
			font-style: inherit;
			font-family: inherit;
			span {
				color: #fff;
				font-family: Arial;
				display: block;
				font-size: 0.85em;
				padding: 10px;
				min-height: 1px;
				transition: all 1s ease;
			}
		}
	}
  .bxPager {
    text-align: center;
    font-size: 0.85em;
    font-family: Arial;
    font-weight: 700;
    color: rgb(102, 102, 102);
    padding-top: 20px;
		position: absolute;
    bottom: -30px;
    span {
			background: rgb(255, 132, 0);
    }
  }
  
`

export const ContainerVideo = styled.div`
  position: relative;
  max-width: 300px;
  height: 150px;
  margin: 0 auto;  
  margin-bottom: 60px;
  overflow-y: hidden;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    z-index: 11;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
      min-height: 350px;        
    `}
  }
`
