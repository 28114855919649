/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
import moment from 'moment';
/* Actions */
import {
  setUploadFile,
  getSelectMessage,
  setSelectedBeneficiaries,
  setMessageVideo,
  setMessagePreview,
} from '../../redux/messages/actions';
import { setModal } from '../../redux/app/actions';

/* Validations */
const constrains = {
  title: {
    presence: true,
  },
  eventType: {
    presence: true,
  },
};

const mapStateToProps = ({ messages: { draftLocaleStorage } }) => {
  let initialValues = {};
  let uri = '';
  if (draftLocaleStorage) {
    initialValues = {
      title: draftLocaleStorage.title,
      visibleData: moment(draftLocaleStorage.visibleData).format('YYYY-MM-DD'),
      type: draftLocaleStorage.visibleData ? 'date' : 'absence',
      messageId: draftLocaleStorage.id,
      messageTypeId: draftLocaleStorage.messageTypeId,
      messageFileId:
        draftLocaleStorage.messageFiles &&
        draftLocaleStorage.messageFiles[0].id,
    };
    uri =
      draftLocaleStorage.messageFiles &&
      draftLocaleStorage.messageFiles[0].fileUrl;
  }
  return {
    draftLocaleStorage,
    initialValues,
    uri,
  };
};

const mapDispachtToProps = (dispatch) =>
  bindActionCreators(
    {
      setUploadFile,
      getSelectMessage,
      setSelectedBeneficiaries,
      setMessageVideo,
      setModal,
      setMessagePreview,
    },
    dispatch
  );

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (values) =>
    dispatchProps.setUploadFile(values, () => ownProps.push('/')),
});

export default compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'videoMessageEdit',
    validate: (values) => validate(values, constrains),
  })
);
