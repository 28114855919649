export default {
	messageTypes: [],
  eventTypes: [],
  eventTypesAll: [],
  adviceTypes: [],
  adviceTypesAll: [],
	messagePreview: {},
	draftLocaleStorage: [],
	fileTypes: [],
	selectedBeneficiaries: [],
	messageByUser: {
		data: [],
		countWritten: 0
	},
	messageEventByUser: {
		data: [],
		countWritten: 0
	},
	messageAdviceByUser: {
		data: [],
		countWritten: 0
  },
  draftLocalStorageDetail: {}
}
