import initialState from './initialState'
import {
  ACTIVED_FORM_BENEFICIARES,
  REGISTER,
  GENDERS,
  IDENTIFICATIONTYPE,
  RELATIONSHIP,
  MESSAGE,
  COUNTRIES,
  FREQUENT_QUESTIONS,
  DATA_REGISTER_BENEFICIARE,
  MODAL_REGISTER_BENEFICIARE,
  DATA_REGISTER_VERIFIER,
  MODAL_REGISTER_VERIFIER
} from './const'

export default function tutorials (state = initialState, action) {
  switch (action.type) {
    case ACTIVED_FORM_BENEFICIARES:
      return Object.assign(
        {},
        {
          ...state,
          isActivedFormBeneficiaries: action.value
        }
      )
      //send data
    case REGISTER:
        return Object.assing(
          {},
          {
            ...state,
            createRegisterUser: action.value
          }
        )
    case GENDERS:
        return Object.assign(
          {},
          {
            ...state,
            genders: action.value
          }
        )
    case IDENTIFICATIONTYPE:
        return Object.assign(
          {},
          {
            ...state,
            identificationTypes: action.value
          }
        )
    case RELATIONSHIP:
        return Object.assign(
          {},
          {
            ...state,
            relationships: action.value
          }
        )
    case MESSAGE:
        return Object.assign(
          {},
          {
            ...state,
            message: action.value
          }
        )
    case COUNTRIES:
          return Object.assign(
            {},
            {
              ...state,
              countries: action.value
            }
          )
    case FREQUENT_QUESTIONS:
          return Object.assign(
            {},
            {
              ...state,
              frequentQuestions: action.value
            }
          )
    case DATA_REGISTER_BENEFICIARE:
          return Object.assign(
            {},
            {
              ...state,
              dateRegisterBeneficiare: action.value              
            }
          )

    case DATA_REGISTER_VERIFIER: 
          return Object.assign(
            {},
            {
              ...state,
              dateRegisterVerifier: action.value              
            }
          )
    case MODAL_REGISTER_BENEFICIARE:
          return Object.assign(
            {},
            {
              ...state,
              modalRegisterBeneficiare: action.value
            }
          )
    case MODAL_REGISTER_VERIFIER:
      return Object.assign(
        {},
        {
          ...state,
          modalRegisterVerifier: action.value
        }
      )            
    default:
      return state
  }
}
