import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  padding: 0.7rem 1.8rem;
  background-color: #f8f8f8;
  opacity: 0.8;
  height: 58px;
  margin-bottom: 58px;
  border: 1px solid transparent;
  justify-content: space-between;
  .logo {
    width: 123px;
    height: 48px;
  }
  ${ifProp(
    'absolute',
    css`
      position: absolute;
      width: 100%;
      height: 90px;
      left: 0;
      top: 0;
      right: 0;
      z-index: 9;
      opacity: 1;
    `
  )}
`;
export const Anav = styled.div`
  margin-left: 51px;
  font-size: 18px;
  line-height: 20px;
  color: #342769;
  position: relative;
  img {
    max-width: 100%;
    max-height: 50px;
    border: 0;
    padding: 0;
  }
  ${media.lessThan('medium')`
    margin-left: 0;    
  `}
`;
export const Ul = styled.ul`
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  width: 65%;   
  .links {
    display: flex;
    width: 75%;
    align-items: center;
    justify-content: flex-end;
  }
  .links Link, a, span {
    font-size: 13px;
    color: #342769;
    text-decoration: none;
    margin: 0rem 0.6rem;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    &:last-child {
      margin: 0;
    }
  }
  .links Link,a:hover,span:hover {
    color: #ff8400 !important;
  }
  > div {
    height: 30px;
    margin: 0 0.3rem;
  }  
  .language {
    margin-right: -10px;
    margin-left: 15px;
  }

  ${media.lessThan('large')`    
    width: 70%;
    .links a, span {            
    font-size: 14px;               
    &:last-child{
      margin: 0;
    }    
    }
  `}
  ${media.lessThan('medium')`    
    position: absolute;
    width: 100%;
    height: 0;
    top: 100%;        
    box-sizing: border-box;
    left: 0;
    padding: 0;
    border-top: thin solid #ddd;
    background-color: #f8f8f8;
    display: block;
    overflow: hidden;            
    ${ifProp(
      'actived',
      css`
        overflow: visible;
        height: 200px;
      `
    )};
    .links {
      display: block;      
      width: 100%;
      height: 118px;
      margin: 0
    }
    .links a, span {
      width: 100%;      
      display: block;
      padding: 10px 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px; 
      box-sizing: border-box;
      max-width: 100%;
      margin: 0;
      cursor: pointer;
    }
    .mediaButton {
      display: none;            
    }
    .language {            
      width: 100%;
      margin: 36px 0 0 0;
      border: 0;            
      padding-left:10px;      
      box-sizing: border-box;
      left: -10px;        
    }        
  `}
  .mediaButton {      
      &:hover {
      animation: pulse 1s;
    }
`;

export const ContainerVideo = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    z-index: 11;
    min-height: 550px;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
      min-height: 350px;        
    `}
  }
`;
