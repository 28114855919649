/* Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
/* Styled Components */
import Container from './styles';
/* i18n */
import { t } from '../../i18n';

function MessageResponse({ bgAlert, message, isActived }) {
  return (
    <Container bgColor={bgAlert} isActived={isActived}>
      <p>{message}</p>
    </Container>
  );
}

MessageResponse.defaultProps = {
  message: t('messagesResponse.success'),
};

MessageResponse.propTypes = {
  bgAlert: PropTypes.string,
  message: PropTypes.string,
  isActived: PropTypes.bool.isRequired,
};

export default MessageResponse;
