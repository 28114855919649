// Dependencies
import React, { Component } from 'react';
// Components
import TutorialItem from '../TutorialItem';
// Styled compoents
import { Container } from './styles';

class TutorialBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: ['#3fa8e0', '#892b6e', '#211261', '#ff8400'],
    };
  }

  render() {
    const { colors } = this.state;
    const tutorials = this.props.tutorials.reduce((acc, item) => {
      const lastItem = acc[acc.length - 1];
      const colorIndex = lastItem ? colors.indexOf(lastItem.color) + 1 : 0;
      const color =
        colorIndex > colors.length - 1 ? colors[0] : colors[colorIndex];
      return acc.concat({ ...item, color });
    }, []);

    return (
      <Container>
        <div className="header">
          <h3>{this.props.title}</h3>
        </div>
        <div>
          {tutorials.map((tutorial, index) => (
            <TutorialItem
              key={index}
              path={tutorial.slug}
              bgBorder={tutorial.color}
              titleItem={tutorial.title}
            />
          ))}
        </div>
      </Container>
    );
  }
}

export default TutorialBox;
