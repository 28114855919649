// Dependencies
import React from 'react';
// import { Link } from 'react-router-dom'
// Styled component
import { Container } from './styles';
import { t } from '../../i18n';
import moment from 'moment';

function DraftMessage({
	title,
	beneficiaryName,
	size,
	isDraft,
	visibleData,
	iconType,
}) {
	return (
		<Container>
			<div className='boxMessage'>
				<div className='messageType'>
					<i className={iconType} />
				</div>
				<div className='messageTitle'>
					<h4>
						{title}
						{/* <small>Draft</small> */}
					</h4>
					<b>{size} MB</b>
					<p
						style={{
							padding: '0px 28px 0px 0px',
						}}
					>
						{t('previewMessage.visible')} :{' '}
						{visibleData !== null
							? moment(visibleData).format('L')
							: t('fields.absances')}{' '}
						<br />
						{t('previewMessage.for')}{' '}
						{!isDraft &&
							beneficiaryName.map((item, index) => (
								<b key={index}>{`${
									item.beneficiary ? item.beneficiary.firstName : ''
								} `}</b>
							))}
					</p>
				</div>
			</div>
		</Container>
	);
}

DraftMessage.defaultProps = {
	iconType: 'far fa-file-word',
};

export default DraftMessage;
