import request from '../../libs/request'
import { filterByLanguage } from '../../i18n'

const endpoints = {
	tutorial: '/tutorials',
	tutorialTypes: '/tutorial-types'
}

class TutorialService {	
	static getTutorials() {				
		return request.get(`${endpoints.tutorial}?filter=${filterByLanguage}`)
	}
	static getTutorialsBySlug(slug) {
		const filter = JSON.stringify({
			where: {
				slug
      },
      include: 'tutorialSliders'
		})
		return request.get(`${endpoints.tutorial}?filter=${filter}`)
	}

	static getTutorialTypes(filter = {}) {		
		return request.get(
			`${endpoints.tutorialTypes}?filter=${JSON.stringify(filter)}`
		)
	}
}

export default TutorialService
