/* Dependencies */
import React, { Fragment } from 'react'
import Area from '../../components/TextArea'
/* Styles */
import { SpanError } from './styles'
const TextArea = ({
	input,
	placeholder,
	type,
	meta: { touched, error },
	rows
}) => (
	<Fragment>
		<Area rows={rows} {...input} type={type} placeholder={placeholder} />
		{touched && (error && <SpanError>{error}</SpanError>)}
	</Fragment>
)

export default TextArea
