/* Dependencies */
import React, { Fragment, Component } from "react";
import { Field } from "redux-form";
import axios from "axios";

import PropTypes from "prop-types";
import {
	EditorState,
	convertToRaw,
	ContentState,
	convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import InputFileButton from "../../fields/InputFileButton";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
/* i18n */
import { t, getLanguage } from "../../i18n";
/* Actions */

/* Components */
import Button from "../../components/Button";
import InputRedux from "../../fields/Input";
import SelectRedux from "../../fields/SelectInput";

// Validate

import { Form } from "./styles";

import withStore from "./store";

class WrittenMessage extends Component {
	constructor(props) {
		super(props);
		this.SAVE_DRAFT = "saveDraft";
		this.state = {
			editorState: EditorState.createEmpty(),
			type: "",
			fileName: "",
			file: "",
			nameFile: "",
			idFile: "",
			idText: "",
			draftLocalStorageDetail: null,
		};
		this.getFile = this.getFile.bind(this);
	}

	async getFile() {
		try {
			console.log(
				"this.props.draftLocaleStorage.messageFiles",
				this.props.draftLocaleStorage,
			);
			if (this.props.draftLocaleStorage.messageFiles.length > 1) {
				this.setState({
					nameFile: t("messagesResponse.attachedFile"),
				});
			}

			// let url = 'https://cors-anywhere.herokuapp.com/'
			let url = "https://proxy.cors.sh/";
			const body = this.props.draftLocaleStorage.messageFiles.find(
				(el) => el.ext === ".html",
			);
			console.log("body", body);

			const nameFileNew =
				(this.props.draftLocaleStorage.messageFiles.length > 1 &&
					this.props.draftLocaleStorage.messageFiles.find(
						(el) => el.ext !== ".html",
					).name) ||
				this.props.draftLocaleStorage.messageFiles.find(
					(el) => el.ext === ".html",
				).name;
			console.log("nameFileNew", nameFileNew);

			const idTextUser = this.props.draftLocaleStorage.messageFiles.find(
				(el) => el.ext === ".html",
			).id;
			const idFileUser =
				(this.props.draftLocaleStorage.messageFiles.length > 1 &&
					this.props.draftLocaleStorage.messageFiles.find(
						(el) => el.ext !== ".html",
					).id) ||
				this.props.draftLocaleStorage.messageFiles.find(
					(el) => el.ext === ".html",
				).id;
			console.log("idTextUser", idTextUser);
			console.log("idFileUser", idFileUser);

			this.setState({
				fileName: nameFileNew,
				idFile: idFileUser,
				idText: idTextUser,
			});
			const { data } = await axios({
				method: "GET", //you can set what request you want to be
				url: url + body.fileUrl,
				headers: {
					"x-cors-api-key": "temp_366c37f9d20ece510130f18dcb137cd1",
				},
			});

			const blocksFromHTML = convertFromHTML(data);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap,
			);

			this.setState({
				editorState: EditorState.createWithContent(state),
			});
		} catch (e) {
			console.log(e);
		}
	}
	onChangeFile = (file) => {
		if (!file) {
			return;
		}
		this.setState({
			fileName: file.name,
			file,
		});
	};

	getMessageLocalStorage = ({ draftId }) => {
		const user = this.props.user;
		if (!user.length) {
			return;
		}
		const draftLocalStorageDetail = JSON.parse(
			localStorage.getItem(`${this.SAVE_DRAFT}${user[0].id}`),
		).find((item) => item.id === parseInt(draftId));
		this.setState({
			draftLocalStorageDetail,
		});
		const blocksFromHTML = convertFromHTML(draftLocalStorageDetail.body);
		const body = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap,
		);

		if (draftLocalStorageDetail.type === "date") {
			this.setState({
				type: "date",
			});
		}

		this.setState({
			editorState: EditorState.createWithContent(body),
			draftLocalStorageDetail,
		});
	};

	componentDidMount() {
		const { slug, draftLocaleStorage, setSelectedBeneficiaries, isLocal } =
			this.props;

		if (isLocal) {
			this.getMessageLocalStorage({ draftId: slug });
			console.log("---ENTRO 1");
		} else {
			console.log("---ENTRO 2");

			if (this.props.initialValues.type === "date") {
				this.setState({
					type: "date",
				});
			}
			this.getFile();
			if (draftLocaleStorage) {
				console.log("---ENTRO 3");

				if (Array.isArray(draftLocaleStorage.messageBeneficiaries)) {
					const beneficiaries =
						draftLocaleStorage.messageBeneficiaries &&
						draftLocaleStorage.messageBeneficiaries.map((el) => ({
							label: el.beneficiary ? el.beneficiary.firstName : "",
							value: el.beneficiary ? el.beneficiary.id : "",
						}));
					setSelectedBeneficiaries(beneficiaries);
				}
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { slug, isLocal, user } = this.props;
		if (user !== prevProps.user && user && isLocal) {
			this.getMessageLocalStorage({ draftId: slug });
		}
	}

	// componentDidUpdate(prevProps) {
	// 	if (
	// 		this.props.draftLocaleStorage !== prevProps.draftLocaleStorage &&
	// 		this.props.draftLocaleStorage
	// 	) {

	// 		this.props.getSelectMessage(parseInt(this.props.slug))
	// 		const { draftLocaleStorage, setSelectedBeneficiaries } = this.props
	// 		console.log('method')
	// 		if (draftLocaleStorage) {
	// 			if(Array.isArray(draftLocaleStorage.messageBeneficiaries)){
	// 				const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
	// 					el => ({
	// 						label: el.beneficiary.firstName,
	// 						value: el.beneficiary.id
	// 					})
	// 				)
	// 				setSelectedBeneficiaries(beneficiaries)
	// 			}
	// 			this.getFile()
	// 		}
	// 	}
	// }

	onChange = (data) => data && this.setState({ type: data.target.value });
	onEditorStateChange = (editorState) => this.setState({ editorState });
	render() {
		const { editorState, type, draftLocalStorageDetail } = this.state;
		const {
			handleSubmit,
			messageTypes,
			onSubmit,
			saveEditDraftLocaleStorege,
			editorSateLocal,
		} = this.props;
		const messageType = messageTypes.find(
			(messageType) => messageType.code === "written",
		);
		const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		console.log("------draftLocalStorageDetail", draftLocalStorageDetail);
		console.log("this.props", this.props.draftLocaleStorage, this.state.file);
		return (
			<Fragment>
				<Form
					onSubmit={handleSubmit((values) =>
						onSubmit({
							...values,
							attached: this.state.file,
							messageTypeId: messageType === undefined ? 1 : messageType.id,
							body,
							idText: this.state.idText,
							idFile: this.state.idFile,
						}),
					)}
				>
					<div className='titleAndDate'>
						<Field
							type='text'
							name='title'
							component={InputRedux}
							placeholder={t("writtenMessage.fields.title")}
						/>

						<Field
							onChange={this.onChange}
							name='evenType'
							component={SelectRedux}
						>
							<option>{t("fields.selectEvents")}</option>
							<option value='absence'>{t("fields.absances")}</option>
							<option value='date'>{t("fields.dates")}</option>
						</Field>

						{type === "date" && (
							<Field
								type='date'
								name='visibleData'
								component={InputRedux}
								placeholder={t("writtenMessage.fields.title")}
							/>
						)}
					</div>
					<Editor
						editorState={editorSateLocal || editorState}
						wrapperClassName='rdw-storybook-wrapper'
						editorClassName='rdw-storybook-editor'
						onEditorStateChange={this.onEditorStateChange}
						emoji={false}
						localization={{
							locale: getLanguage(),
						}}
					/>
					{console.log("this.state.nameFile", this.state.nameFile)}
					{this.state.nameFile && (
						<div className='input'>
							<p className='center'>{t("writtenMessage.attachFile")}:</p>
							<Field
								data-show-preview='false'
								data-show-remove='false'
								data-show-upload='false'
								name='attached'
								nameFile={this.state.fileName || this.state.nameFile}
								onChange={this.onChangeFile}
								component={InputFileButton}
								accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'
							/>
						</div>
					)}
					<div className='input'>
						{/* <h4>
							{`${t('writtenMessage.previewMessage')}`}{' '}
							<span onClick={() => setModal('isActivedMessagePreview')}>{`${t(
								'writtenMessage.clickhere'
							)}`}</span>
						</h4> */}

						<div className='options'>
							{this.props.isLocal && (
								<Button
									type='button'
									onClick={handleSubmit((values) =>
										saveEditDraftLocaleStorege({
											...draftLocalStorageDetail,
											...values,
											messageTypeId:
												messageType === undefined ? 1 : messageType.id,
											body,
										}),
									)}
									bgColorHover='#892B6E'
								>
									<i className='far fa-clock' /> {t("buttons.saveDraft")}
								</Button>
							)}

							<Button bgColor='#DF382F' bgColorHover='#892B6E'>
								<i className='far fa-clock' /> {t("buttons.scheduleMessage")}
							</Button>
						</div>
					</div>
				</Form>
			</Fragment>
		);
	}
}
WrittenMessage.defaultProps = {
	saveDraftLocaleStorege: () => {},
};

WrittenMessage.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	saveDraftLocaleStorege: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	eventTypes: PropTypes.array.isRequired,
	messageTypes: PropTypes.array.isRequired,
};

export default withStore(WrittenMessage);
