// Dependencies
import React from 'react'
// Styled Component
import Container from './styles'

function Overlay (props) {
  return <Container {...props} onClick={props.toggle} />
}

export default Overlay
