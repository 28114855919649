import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = styled.button`
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: #342769;
  width: 70px;
  height: 32px;
  position: relative;
  outline: none;
  cursor: pointer;
  ${ifProp(
    'isActive',
    css`
      background-color: #342769;
    `
  )}
  label {
    color: #fff;
    cursor: pointer;
  }
  .arrowButton {
    margin-left: 0.6rem;
  }
`
export const ItemsContainer = styled.div`
  top: 138%;
  position: absolute;
  left: -10px;
  display: none;
  margin-top: 1px;
  
  ${ifProp(
    'isActive',
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `
  )}
`
export const Item = styled.a`
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: #342769;
  width: 70px;
  height: 32px;  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:0.18rem;
  color: #fff!important;
`
