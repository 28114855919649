// Dependeccies
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin-bottom: 15px;
  >.fileCaption {            
    width: 100%;
    margin-bottom: 0;
    height: 37px;
    padding: 6px 20px;    
    line-height: 1.42857143;
    color: #999;
    background-color: #fff;    
    border: 1px solid #CCC;
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .file-caption-name {            
      overflow: hidden;
      max-height: 20px;
      padding-right: 10px;
      word-break: break-all;
    }
  }
  >.btn {            
    width: 100%;
    background-color: #DF382F;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff;     
    transition: background .5s linear 0s;
    cursor: pointer!important;
    padding-left: 8px;
    &:hover {
      background-color: #892b6e;
    }    
    ${media.lessThan('medium')`
      font-size: 11px;
    `}
    >input[type=file] {
      position: absolute;                                    
      filter: alpha(opacity=0);
      max-width: 80px;      
      height: 37px;
      opacity: 0;
      background: none repeat scroll 0 0 transparent;      
      display: block;      
      box-sizing: border-box;
      cursor: pointer;
    }
  }

`
