import 'react-app-polyfill/ie11';

//Dependencies
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
//Redux
import HomeService from '../../redux/home/HomeService';
import { setHomes } from '../../redux/home/actions';
import { setModalTerms, setModalVideo } from '../../redux/app/actions';
//i18n
import {
  t,
  languages,
  setLanguage,
  getLanguage,
  getLanguageById,
} from '../../i18n';
// const routes
import { routes } from '../../routes';
//Components

import DropDown from '../DropDown';
import MenuButton from '../MenuButton';
import Modal from '../Modal';
import TermsAndConditions from '../TermsAndConditions';
import ModalVideoYoutube from '../ModalVideoYoutube';

//Styled
import { Nav, Anav, Ul } from './styles';
//Assets
import logo from '../../assets/images/logo-1.png';

const langs = languages.map((item) => ({
  label: item.description,
  value: item.name,
  id: item.id,
}));
const currentLang = langs.find((item) => item.value === getLanguage());
class NavMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuVisible: false,
      activeModalTerms: this.props.termsActive,
    };
  }

  async componentDidMount() {
    const homes = await HomeService.getHomes({
      where: { languageId: getLanguageById() },
    });
    await this.props.setHomes(homes.data);
    if (this.props.location.pathname.split('/')[2] === 'terms-and-conditions') {
      this.props.setModalTerms(!this.props.isActivedModalTerms);
    }
  }

  toggleMenu = () =>
    this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible });

  toggleModalTerms = () => {
    this.props.setModalTerms(!this.props.isActivedModalTerms);
  };

  render() {
    const {
      termsConditions,
      logoUrl,
      isActivedModalTerms,
      location,
      videoUrl,
      setModalVideo,
      modalVideo,
    } = this.props;
    return (
      <Fragment>
        <Nav absolute>
          <Anav>
            <Link to="/">
              <img
                src={logoUrl === '' || logoUrl === 'null' ? logo : logoUrl}
                alt="Logo Flüie"
                className="logo"
              />
            </Link>
          </Anav>
          <Ul actived={this.state.mobileMenuVisible} onClick={this.toggleMenu}>
            <div className="links">
              <Link to={routes.ABOUT}>{t('menu.about')}</Link>
              <span onClick={() => setModalVideo(true)}>{t('menu.howDo')}</span>
              <span onClick={this.toggleModalTerms}>{t('menu.terms')}</span>
              <Link to={routes.CONTACT}>{t('menu.contactUs')}</Link>
            </div>
            <div className="language">
              <DropDown
                choices={langs}
                defaultValue={currentLang}
                onChange={(value) => {
                  setLanguage(value.value, value.id, location.pathname);
                }}
              />
            </div>
          </Ul>
          <MenuButton action={this.toggleMenu} />
        </Nav>
        <Modal
          fullScreen
          txtAlign="none"
          active={isActivedModalTerms}
          toggle={this.toggleModalTerms}
        >
          <TermsAndConditions>
            <div style={{ textAlign: 'center' }}>
              <h3>{t('home.terms-and-conditions.title')}</h3>
            </div>

            <div dangerouslySetInnerHTML={{ __html: `${termsConditions}` }} />
            <div style={{ textAlign: 'center' }}>
              <button onClick={this.toggleModalTerms}>
                {t('home.terms-and-conditions.button')}
              </button>
            </div>
          </TermsAndConditions>
        </Modal>
        {/* <Modal
					fullScreen
					active={modalVideo}
					toggle={() => setModalVideo(false)}
				>
					<ContainerVideo>
				{
					videoUrl && <iframe
					className='video'
					src={videoUrl + '?rel=0' }
					title='video home'
				/>
				}
			</ContainerVideo>	
				</Modal> */}
        <ModalVideoYoutube
          active={modalVideo}
          videoUrl={videoUrl}
          toggle={() => setModalVideo(false)}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    termsConditions: store.home.homes[0]
      ? store.home.homes[0].termsConditions
      : [],
    videoUrl: store.home.homes[0] ? store.home.homes[0].videoUrl : '',
    logoUrl: store.home.homes[0] ? store.home.homes[0].logoUrl : '',
    isActivedModalTerms: store.app.modalTermsAndConditions,
    modalVideo: store.app.modalVideo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHomes: (homes) => dispatch(setHomes(homes)),
    setModalTerms: (active) => dispatch(setModalTerms(active)),
    setModalVideo: (value) => dispatch(setModalVideo(value)),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(NavMain);
