// Dependencies
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	margin: 0 15px;
	margin-bottom: 30px;
	.titleAndDate {
		display: grid;
		grid-template-columns: 1.8fr 1fr 1fr;
		grid-gap: 15px;
	}
	.absence {
		> input {
			cursor: pointer;
		}
	}
	.calendar {
		display: none;
		width: 242px;
		height: 293px;
		margin-left: -12px;
		position: absolute;
    z-index: 9;
		${ifProp(
    'isActived',
    css`
				display: block;
			`
  )}
	}
	.input {		
		margin: 0 auto;
		margin-top: 20px;
		width: 62%;
		text-align: center;
		> h4 {
		color: #211261;
		}	
	}	
	${media.lessThan('medium')`
	.titleAndDate {
		grid-template-columns: 1fr;
	}
	.input {
		width: 100%;
	}
`}
`
