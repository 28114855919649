import styled from 'styled-components'
import media from 'styled-media-query'

export const Menu = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1.5rem;
  color: #342573;
  display: none;
  outline: none;
  ${media.lessThan('medium')`
    display: block!important;        
  `}
`
