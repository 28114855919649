// Dependencies
import React from 'react'
// Styled Component
import { Container } from './styles'

function ButtonModal (props) {
  const { toggle } = props
  return (
    <Container onClick={toggle}>
      <i className='fas fa-plus' />
    </Container>
  )
}

export default ButtonModal
