export default {
  user: [],
  beneficiaries: [],
  checkers: [],
  beneficiarySelected: {},
  checkerSelected: {},
  beneficiariesIdName: [],
  messagesBeneficiary: [],
  notifications: [],
  notificationSelected: {},
  infoRelationBeneficiare: {},
  infoRelationVerifier: {},
  absenceUserSelected: {},
  isUserMain: false,
  dataOrderPayment: {},
  dataSignature: {},
};
