// Dependencies
import styled from 'styled-components';
import media from 'styled-media-query';
import { prop } from 'styled-tools';
// Assets
import lineTitle2 from '../../assets/images/line-title-2.svg';
import lineTitle from '../../assets/images/line-title.svg';

export const Container = styled.section`
  background: url(${prop('bgImage')}) center;
  background-size: cover;
  background-position: center top;
  .Wrapper {
    margin-top: 58px;
    min-height: calc(100vh - 98px) !important;
  }
`;
export const Content = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	.questions {
		margin-top: 100px;
		h1 {
			color: #fff;
			text-align: center;
    }    
    }
		.text-separator-top {
			width: 180px;
			height: 25px;
			background: url(${lineTitle}) no-repeat 0 0 / 180px 25px;
			margin: 0 auto 20px;
			display: block;
			left: 50%;
    }
    .panel-group {
			margin-bottom: 20px;
			padding: 15px;
    }
		> button {			
			width: 100%;
			background-color: transparent;
 		  border: none!important;						  			
			text-align: left;
			margin-bottom: 30px;
		}
		.typeUser {			
				width: 100%;
				background-color: transparent;
				border: none!important;	
				padding: 0!important;		
				text-align: left;		
				margin-bottom: 20px;	  			
			}			
		}
		> div {
			padding: 0 15px;
			.txt {
				padding: 11px 0;
				width: auto;
				display: inline-block;					
			}
		}
		${media.lessThan('medium')`
  grid-template-columns:  1fr;  
	.questions{
		display: none;
	}
    `}
`;

export const ContainerFormRegister = styled.div`
  width: 100%;
  max-height: calc(100vh - 98px) !important;
  margin-bottom: 0 !important;
  background: rgba(255, 255, 255, 0.4);
  padding: 48px 0;
  margin: 0 auto;
  margin-bottom: 15px;
  overflow-y: scroll;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  form {
    padding-right: 15px;
    padding-left: 15px;
    .title {
      text-align: center;
    }
    .text-separator-top {
      width: 180px;
      height: 25px;
      background: url(${lineTitle2}) no-repeat 0 0 / 180px 25px;
      margin: 0 auto 20px;
      display: block;
      left: 50%;
    }
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      ${media.lessThan('medium')`
 				 grid-template-columns:  1fr ;  
				grid-row-gap: 13px;
				margin-bottom: 13px;
				input {
					margin: 0;					
				}				
    `}
    }
    label {
      font-weight: 300;
      color: #555;
      margin-left: 20px;
    }
    input {
      width: 100%;
    }

    .submit {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 0.5fr 3fr 1fr;
      align-items: center;
      .text-accept {
        margin-left: 7px;
        color: #892b6e;
        cursor: pointer;
      }
      ${media.lessThan('medium')`
 				 grid-template-columns:  .5fr 2fr ;  
					button {
						margin-top: 10px;
						grid-column: 1 / 3;
					}
    `}
    }
  }
`;
export const SelectStyle = {
  width: '100%',
  height: '50px',
  padding: '6px 20px',
  fontSize: '13px',
  lineHeight: '1.42857143',
  color: '#999',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  borderRadius: '25px',
  border: '1px solid #342573',
};

export const ContainerFile = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin-bottom: 15px;
  > .fileCaption {
    width: 100%;
    margin-bottom: 0;
    height: 37px;
    padding: 6px 20px;
    line-height: 1.42857143;
    color: #999;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .file-caption-name {
      overflow: hidden;
      max-height: 20px;
      padding-right: 10px;
      word-break: break-all;
    }
  }
  > .btn {
    width: 100%;
    background-color: #df382f;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff;
    transition: background 0.5s linear 0s;
    cursor: pointer !important;
    padding-left: 8px;
    &:hover {
      background-color: #892b6e;
    }
    ${media.lessThan('medium')`
      font-size: 11px;
		`}
    span {
      position: relative;
    }
    > input[type='file'] {
      position: relative;
      filter: alpha(opacity=0);
      max-width: 80px;
      height: 37px;
      opacity: 0;
      background: none repeat scroll 0 0 transparent;
      display: block;
      box-sizing: border-box;
      cursor: pointer;
      width: 88px;
    }
  }
`;

export const ContainerVideo = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    z-index: 11;
    min-height: 550px;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
       min-height: 350px;        
    `}
  }
`;
