//Dependencies
import React, { Component } from 'react';
//Styled component
import { Modal, ContainerVideo } from './styles';

class Carrousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgs: this.props.data,
      index: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.nextProperty, 8000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextProperty = () => {
    const newIndex = this.state.index + 1;
    if (newIndex >= this.props.data.length) {
      return this.setState({
        index: 0,
      });
    }
    this.setState({
      index: newIndex,
    });
  };
  prevProperty = () => {
    const prevIndex = this.state.index - 1;
    this.setState({
      index: prevIndex <= 0 ? 0 : prevIndex,
    });
  };

  render() {
    const { index } = this.state;
    // console.log('imgs 2', imgs)

    // console.log('frequentQuestions 2', this.props.data)
    const data = this.props.data;
    return (
      <Modal>
        {/* <div className='btns'>
						<div className='left btn' onClick={this.prevProperty}>
							<i className='fas fa-chevron-left' />
						</div>
						<div className='right btn' onClick={this.nextProperty}>
							<i className='fas fa-chevron-right' />
						</div>
					</div> */}
        <span className="text-separator-top" />
        {data.length && !data[index].videoUrl ? (
          <>
            <div className="circleUser">
              <div className="boxCircleUser">
                <img
                  alt="imageUser"
                  src={data.length ? data[index].imageUrl : 'algo salio mal'}
                  style={{
                    height: '71.19px',
                    width: '64px',
                  }}
                />
              </div>
            </div>
            <div className="circleQuotes">
              <span />
            </div>{' '}
          </>
        ) : (
          <ContainerVideo>
            <iframe
              className="video"
              src={
                data.length &&
                data[index].videoUrl.replace('watch?v=', 'embed/')
              }
              title="video home"
            />
          </ContainerVideo>
        )}

        <div className="boxPhrase">
          <p>{data.length ? data[index].name : 'algo salio mal'}</p>
          <p className="description">
            {data.length ? data[index].description : 'algo salio mal'}
          </p>
        </div>
        <div className="textSeparatorBottom" />
      </Modal>
    );
  }
}

export default Carrousel;
