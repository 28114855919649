/* eslint-disable jsx-a11y/anchor-has-content */
// Dependecies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//Redux
import { setModalTerms } from '../../redux/app/actions';
// i18n
import { t } from '../../i18n/index';
// routes
import { routes } from '../../routes';
// Styleds Components
import { Container } from './styles';

const date = new Date();

class Footer extends Component {
  toggleModalTerms = () =>
    this.props.setModalTerms(!this.props.isActivedModalTerms);

  render() {
    const { phone, email, urlFacebook, urlYoutube, urlInstagram } = this.props;
    return (
      <Container>
        <div className="footerText">
          <span>{phone} &nbsp; -</span>
          <span>&nbsp; {email} &nbsp; | &nbsp;</span>
          <Link to={routes.TUTORIALS}>{t('menu.tutorials')}</Link>
          &nbsp; | &nbsp;
          <span onClick={this.toggleModalTerms}>{t('menu.terms')}</span>
          &nbsp; | &nbsp;
          <Link to={routes.CONTACT}>{t('menu.contactUs')}</Link>
        </div>

        <div className="redesSociales">
          <span className="min-text left">{t('menu.followUs')}:</span>
          {urlFacebook && (
            <a
              href={urlFacebook}
              target="_blank"
              rel="noopener noreferrer"
              className="fab fa-facebook-f"
            />
          )}
          {urlYoutube && (
            <a
              href={urlYoutube}
              target="_blank"
              rel="noopener noreferrer"
              className="fab fa-youtube"
            />
          )}
          {urlInstagram && (
            <a
              href={urlInstagram}
              target="_blank"
              rel="noopener noreferrer"
              className="fab fa-instagram"
            />
          )}
        </div>
        <div className="foot">
          <div className="reserved">
            &copy; {date.getFullYear()} {t('menu.copy')}
          </div>
          <div className="imaginamos">
            <a
              className="text-gray"
              href="http://www.imaginamos.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span> {t('menu.web')}: </span>
              <div className="ahorranito" />
            </a>
          </div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(store) {
  return {
    phone: store.home.homes[0] ? store.home.homes[0].phone : [],
    email: store.home.homes[0] ? store.home.homes[0].email : [],
    urlFacebook: store.home.homes[0] ? store.home.homes[0].facebook : null,
    urlYoutube: store.home.homes[0] ? store.home.homes[0].youtube : null,
    urlInstagram: store.home.homes[0] ? store.home.homes[0].instagram : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalTerms: (actived) => dispatch(setModalTerms(actived)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
