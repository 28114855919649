export const ACTIVED_FORM_BENEFICIARES = 'ACTIVED_FORM_BENEFICIARES'
export const GENDERS = 'GENDERS'
export const REGISTER = 'REGISTER'
export const IDENTIFICATIONTYPE = 'IDENTIFICATIONTYPE'
export const RELATIONSHIP = 'RELATIONSHIP'
export const MESSAGE = 'MESSAGE'
export const COUNTRIES = 'COUNTRIES'
export const FREQUENT_QUESTIONS = 'FREQUENT_QUESTIONS'
export const DATA_REGISTER_BENEFICIARE = 'DATA_REGISTER_BENEFICIARE'
export const MODAL_REGISTER_BENEFICIARE = 'MODAL_REGISTER_BENEFICIARE'
export const MODAL_REGISTER_VERIFIER = 'MODAL_REGISTER_VERIFIER'
export const DATA_REGISTER_VERIFIER = 'DATA_REGISTER_VERIFIER'