import request from '../../libs/request'
import { filterByLanguage } from '../../i18n'

const endpoints = {
	payment: '/payments',
	paypal: '/paypal',
	spaces: '/spaces'
}

class PaymentService {
	static getSpaces() {
		return request.get(`${endpoints.spaces}?filter=${filterByLanguage}`)
	}
	static setPaymentPaypal(space){
		const { payment, paypal } = endpoints
		return request.post(`${payment}${paypal}`, space)
	}
}

export default PaymentService
