/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
import moment from 'moment';
/* Actions */
import {
  setUploadFile,
  getSelectMessage,
  setSelectedBeneficiaries,
} from '../../redux/messages/actions';

import { t } from '../../i18n';

/* Validations */
const constrains = {
  title: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  eventType: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
};

const mapStateToProps = ({ messages: { draftLocaleStorage } }) => {
  let initialValues = {};
  let uri = '';
  if (draftLocaleStorage) {
    initialValues = {
      title: draftLocaleStorage.title,
      visibleData: moment(draftLocaleStorage.visibleData).format('YYYY-MM-DD'),
      type: draftLocaleStorage.visibleData ? 'date' : 'absence',
      messageId: draftLocaleStorage.id,
      messageTypeId: draftLocaleStorage.messageTypeId,
      messageFileId:
        draftLocaleStorage.messageFiles &&
        draftLocaleStorage.messageFiles[0].id,
    };
    uri =
      draftLocaleStorage.messageFiles &&
      draftLocaleStorage.messageFiles[0].fileUrl;
  }
  return {
    draftLocaleStorage,
    initialValues,
    uri,
  };
};

const mapDispachtToProps = (dispatch) =>
  bindActionCreators(
    {
      setUploadFile,
      getSelectMessage,
      setSelectedBeneficiaries,
    },
    dispatch
  );

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (values) => {
    dispatchProps.setUploadFile(
      { ...values, messageFileId: stateProps.initialValues.messageFileId },
      () => ownProps.push('/')
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'AudioMessageEdit',
    validate: (values) => validate(values, constrains),
  })
);
