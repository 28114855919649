import CONST from './const';

import Service from './service';

export const setActivedModalChangePass = (value) => ({
  type: CONST.ACTIVE_MODAL_CHANGE_PASSWORD,
  value,
});

export const setActivedModalCreateVerifier = (value) => ({
  type: CONST.ACTIVE_MODAL_CREATE_VEREFIER,
  value,
});

export const setActivedModalCreateBeneficiare = (value) => ({
  type: CONST.ACTIVE_MODAL_CREATE_BENEFICIARE,
  value,
});

export const setActivedModalEditVerifier = (value) => ({
  type: CONST.ACTIVE_MODAL_EDIT_VEREFIER,
  value,
});

export const setActivedModalEditBeneficiare = (value) => ({
  type: CONST.ACTIVE_MODAL_EDIT_BENEFICIARE,
  value,
});
export const setOrders = (value) => ({
  type: CONST.ORDERS,
  value,
});

export const getOrders = () => async (dispatch, getStore) => {
  try {
    const {
      user: { user },
    } = getStore();
    if (!user[0].id) {
      return;
    }
    const response = await Service.getOrders(user[0].id);
    dispatch(setOrders(response.data));
  } catch (e) {
    console.log(e);
  }
};
