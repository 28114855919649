import React, { Component } from 'react'

import RecoverPassword from '../../form/RecoverPassword';

import widthStore from './store';

class RestorePassword extends Component {

  componentDidMount(){    
    this.props.handleLogout()
  }

  render(){
    const code = this.props.location.search.split('=').pop()    
    return (
      <RecoverPassword code={code} history={this.props.history}/>
    )
  }
}

export default widthStore(RestorePassword)