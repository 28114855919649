// Dependencies
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

const Container = styled.div`
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	display: none;  
	animation-name: fadeIn 2s;   
  background: rgba(130, 130, 130, .3);
	${ifProp(
    'isActive',
    css`
			display: block;
		`
  )}
`

export default Container
