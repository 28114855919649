/* Dependencies */
import styled from 'styled-components';
import media from 'styled-media-query';
/* Assets */

export const ContentMain = styled.div`
	/* width: 320px; */
	padding: 10px;
	display: flex;
	justify-content: space-between;
  margin: 2% 0;
  border-bottom: 1px solid;
	/* max-width: 80%;
	${media.lessThan('medium')`		
				max-width: 80%;
		`}; */
`;

export const ContentChild = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const ContentImage = styled.div`
	margin: 0 -19%;
`;

export const ContentCenter = styled.div`
	width: 48%;
	word-wrap: break-word;
	padding: 2%;
`;

export const ContentLeft = styled.div`
	width: 28%;
`;

export const ContentRight = styled.div`
	width: 22%;
`;

export const Buttons = styled.button`
	background: transparent;
	border: none;
	color: orange;
	margin: 0;
	padding: 0 !important;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: all 0.3s;
	:hover {
		color: black;
	}
`;

export const isActive = styled.p``;

export const isTitle = styled.h4`
	font-size: 14px;
`;
