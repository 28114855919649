export default {
  isActivedFormBeneficiaries: false,
  isActivedFormVerifiers: false,
  idForm: {
    beneficiare: true,
    verifier: true
  },
  createRegisterUser: {
    
  },
  genders: [],
  identificationTypes: [],
  relationships: [],
  message: '',
  countries: [],
  frequentQuestions: [],
  dateRegisterBeneficiare: {},
  dateRegisterVerifier: {},
  modalRegisterBeneficiare: false,
  modalRegisterVerifier: false
}
