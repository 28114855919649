/* Dependencies */
import styled from 'styled-components'
import media from 'styled-media-query'

export const Content = styled.div`
  width: 464px;  
  padding: 10px;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 24px;
    color: rgb(52,39,105);
  }
  p {
    font-size: 15px
  }
  .btnConfirm {
    display: flex;
    justify-content: center;
    button:first-child {
      margin-right: 10px;      
    }
  }
  ${media.lessThan('medium')`
  
    width: auto;
  `}
`