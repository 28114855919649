import React, { Component } from 'react';
import { Field } from 'redux-form';

import { t } from '../../i18n';

import InputRedux from '../../fields/Input';
import InputPassword from '../../fields/InputPassword';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import logo from '../../assets/images/logo-1.png';

import imgeDefault from '../../assets/images/male-user.png';

import { Content } from './styles';

import withStore from './store';

class RegisterBeneficiare extends Component {
	render() {
		const {
			active,
			toggle,
			beneficiareName,
			userMainName,
			userMainUrl,
			handleSubmit,
		} = this.props;
		return (
			<Modal width='40%' fullScreen active={active} toggle={toggle}>
				<Content>
					<form onSubmit={handleSubmit}>
						<img src={logo} alt='logo' />
						<h3>{t('registerBeneficiare.userBeneficare')}</h3>
						<p className='description'>
							{t('registerBeneficiare.welcome')} <b>{beneficiareName}</b>{' '}
							{t('registerBeneficiare.description')}
						</p>
						<p>
							{t('registerBeneficiare.youAre')}{' '}
							<b> {t('registerBeneficiare.beneficiare')}</b>{' '}
							{t('registerBeneficiare.the')}
						</p>
						<div className='content-form'>
							<figure className='img-user'>
								<img
									src={
										(userMainUrl !== undefined &&
											userMainUrl !== 'null' &&
											userMainUrl) ||
										imgeDefault
									}
									alt='user'
								/>
								<figcaption>{userMainName}</figcaption>
							</figure>
							<div className='container-input'>
								<Field
									name='code'
									component={InputRedux}
									placeholder={t('registerBeneficiare.description')}
								/>
								<Field
									name='password'
									component={InputPassword}
									placeholder={t('home.modal.changePassword.newPassword')}
								/>
								<Field
									name='repeat-password'
									component={InputPassword}
									placeholder={t('home.modal.changePassword.confirmPassword')}
								/>
								<Button
									bgColor='#ff8400'
									bgColorHover='#211261'
									style={{ height: '50px' }}
								>
									{t('buttons.validBeneficiare')}
								</Button>
							</div>
						</div>
					</form>
				</Content>
			</Modal>
		);
	}
}

export default withStore(RegisterBeneficiare);
