import React, { Component } from 'react';
import { Container, ItemsContainer, Item } from './styles';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      currentItem: {},
    };
    this.toggle = this.toggle.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }
  componentDidMount() {
    this.setState({
      currentItem: this.props.defaultValue || {},
    });
  }

  toggle() {
    this.setState({
      isActive: !this.state.isActive,
    });
  }
  selectItem(value) {
    this.props.onChange(value);
    this.setState({
      currentItem: value,
    });
  }

  render() {
    return (
      <Container isActive={this.state.isActive} onClick={this.toggle}>
        <label>
          {this.state.currentItem.label}
          <i className="fas fa-angle-down arrowButton" />
        </label>
        <ItemsContainer isActive={this.state.isActive}>
          {this.props.choices.map((item) => (
            <Item onClick={() => this.selectItem(item)} key={item.value}>
              {item.label}
            </Item>
          ))}
        </ItemsContainer>
      </Container>
    );
  }
}

export default DropDown;
