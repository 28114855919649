import request from "../../libs/request";
import { getLanguageById } from "../../i18n";

class AppService {
	endpoint = {
		languages: "/languages",
		lifeGuides: "/guide-types",
		profileBeneficiary: "beneficiaries-users",
		profileVerifier: "verifiers-users",
		assingRoleMain: "assigned-roles",
		recommendations: "recommendations",
		adviceRecommendation: "/advice-recommendations",
	};
	getLanguages() {
		return request.get(this.endpoint.languages);
	}
	getLifeGuides() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
			include: ["lifeGuides"],
		});
		return request.get(`${this.endpoint.lifeGuides}?filter=${filter}`);
	}
	getRecommendations() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
		});
		return request.get(`${this.endpoint.recommendations}?filter=${filter}`);
	}
	getAdviceRecommendations() {
		const filter = JSON.stringify({
			where: {
				languageId: getLanguageById(),
			},
			// include: {
			//   // relation: 'adviceTypeId',
			//   scope: { fields: ['relationshipsId'] },
			// },
		});
		return request.get(
			`${this.endpoint.adviceRecommendation}?filter=${filter}`,
		);
	}
	getProfiles(beneficiaryId) {
		const filter = JSON.stringify({
			where: {
				beneficiaryId: beneficiaryId,
			},
		});
		return request.get(`${this.endpoint.profileBeneficiary}?filter=${filter}`);
	}
	getProfileVerifier(verifierId) {
		const filter = JSON.stringify({
			where: {
				verifierId: verifierId,
			},
			include: ["mainUser"],
		});
		return request.get(`${this.endpoint.profileVerifier}?filter=${filter}`);
	}
	getAssingRole(userId) {
		const filter = JSON.stringify({
			where: {
				userId,
			},
		});
		return request.get(`${this.endpoint.assingRoleMain}?filter=${filter}`);
	}
	setUserMainRole(userId) {
		return request.post(this.endpoint.assingRoleMain, {
			userId,
			roleId: 1,
		});
	}
	getAssingRoleProfile(userId) {
		const filter = JSON.stringify({
			where: {
				userId,
			},
		});
		return request.get(`${this.endpoint.assingRoleMain}?filter=${filter}`);
	}
	getRoleUserPrincipal(userId) {
		const filter = JSON.stringify({
			where: { and: [{ userId }, { roleId: 1 }] },
		});
		return request.get(`${this.endpoint.assingRoleMain}?filter=${filter}`);
	}
}

export default new AppService();
