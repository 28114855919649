// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Redux
import { setFormBuy, getProfile } from "../../redux/app/actions";
import {
	setFormBeneficiaries,
	setFormVerifiers,
} from "../../redux/home/actions";
// COSNT ROUTES
import { routes } from "../../routes";
// Components
import Button from "../Button";

import FormRight from "../FormRight";
// i18n
import { t } from "../../i18n";
// Styles Components
import { Container, UserConsumption } from "./styles";
// Assets
import img from "../../assets/images/male-user.png";
import payu from "../../assets/images/payu.jpg";

const convertBytesToMega = (num) => (num / 1000000).toFixed(2);

export const getPercentageSpacioConsumed = (total, percentage) => {
	const difference = total - percentage;
	const number = ((difference / total) * 100).toFixed(2);
	return !isNaN(number) ? number + "%" : 0 + "%";
};

class InfoUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActived: false,
		};
	}
	componentDidMount() {
		const { getProfile } = this.props;
		getProfile();
	}
	toggleIsActived = () => this.setState({ isActived: !this.state.isActived });

	toggleformBeneficiariesOff = () => {
		this.props.setFormBeneficiaries(false);
		this.props.setFormVerifiers(false);
	};

	render() {
		const {
			setFormBuy,
			formBuy,
			emailUser,
			userName,
			imageUrl,
			withBuySpace,
			spaceTotal,
			avaliableSpace,
			withRole,
		} = this.props;
		console.log("withRole", withRole);
		const { isActived } = this.state;
		return (
			<Container isActived={!isActived} withRole={withRole}>
				<div className='infoUser'>
					<Link
						// className='infoUser'
						to={routes.PROFILE}
						onClick={this.toggleformBeneficiariesOff}
					>
						<div className='imgUser'>
							<img
								src={
									imageUrl && imageUrl.split("/").length > 1 ? imageUrl : img
								}
								alt='user'
							/>
						</div>
						<div className='container-description'>
							<div className='textInfoUser'>
								<h4>{userName}</h4>
								<p>{emailUser}</p>
							</div>
							<span
								onClick={() => {
									if (withRole && withRole.length > 1) {
										return this.props.setModalChangeRole();
									}
								}}
							>
								{withRole ? "" : t("infoUser.main")} {withRole}
							</span>
						</div>
					</Link>
				</div>

				{withBuySpace && (
					<UserConsumption
						isActived={isActived}
						used={getPercentageSpacioConsumed(spaceTotal, avaliableSpace)}
					>
						<div className='space'>
							<div className='blockBarSpace'>
								<div className='textUsed'>
									<div>
										<h2>{t("infoUser.classifiedConsumption")}</h2>
										<div>
											<span className='highlight'>
												{convertBytesToMega(spaceTotal - avaliableSpace)} MB
											</span>{" "}
											{t("infoUser.of")} {convertBytesToMega(spaceTotal)} MB
										</div>
									</div>
									<div className='buySpace'>
										<Button
											onClick={() => setFormBuy(!formBuy)}
											border
											borderColor='#211164'
											txtColor='#211164'
											bgColorHover='#892B6E'
											type='button'
											className='txt'
										>
											{/* <span className='iconSpace' /> */}
											{t("infoUser.buySpace")}
										</Button>
									</div>
								</div>
								<div className='barSpace'>
									<div className='barUsed' data-used='0' />
								</div>
							</div>
						</div>
						<div className='arrow' onClick={this.toggleIsActived} />
					</UserConsumption>
				)}

				{/* )} */}

				<FormRight
					isActive={formBuy}
					toggle={() => this.props.setFormBeneficiaries(true)}
					onClick={() => console.log("method seconds")}
					titleFormRight={t("homeLogin.packages")}
				>
					<div className='packageAlert'>{t("homeLogin.limitSpace")}.</div>
					{/* {spaces.map(space => (
						<ItemBuyForm />
					))} */}
					<h2 className='txtBlue'>{t("homeLogin.paymentMethod")}</h2>
					<Link to='/'>
						<Button border className='btnBuy' borderColor='#CCC'>
							<img src={payu} alt='Payu' />
						</Button>
					</Link>
				</FormRight>
			</Container>
		);
	}
}

function mapStateToProps(store) {
	return {
		formBuy: store.app.formBuy,
		emailUser: store.app.isLoginUser.email,
		userIdBeneficiaryData: store.app.userIdBeneficiaryData,
		userIdVerifierData: store.app.userIdVerifierData,
		spaces: store.payment.spaces || [],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setFormBuy: (value) => dispatch(setFormBuy(value)),
		setFormBeneficiaries: (value) => dispatch(setFormBeneficiaries(value)),
		setFormVerifiers: (value) => dispatch(setFormVerifiers(value)),
		getProfile: (user) => {
			dispatch(getProfile(user));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoUser);
