import { SPACES, SPACE_SELECTED } from './const';
import PaymentService from './PaymentService';

import { setMessage, setLoading } from '../app/actions';
import { t } from '../../i18n';

export const setSpaces = (value) => ({ type: SPACES, value });

export const setSpaceSelected = (value) => ({ type: SPACE_SELECTED, value });

/* Async Actions */

export const getSpaces = () => async (dispatch) => {
  try {
    const spaces = await PaymentService.getSpaces();
    dispatch(setSpaces(spaces.data));
  } catch (e) {
    console.log(e);
  }
};

export const setPaymentPaypal = () => async (dispatch, getStore) => {
  dispatch(setLoading(true));
  try {
    const store = getStore();
    const {
      user: { user },
      payment: { spaceSelected },
    } = store;
    if (!spaceSelected) {
      return dispatch(
        setMessage({
          name: 'withError',
          message: t('messagesResponse.requiredSpace'),
        })
      );
    }
    const response = await PaymentService.setPaymentPaypal({
      spaceSelected,
      userId: user[0].id,
    });
    window.location.href = response.data.approvalUrl;
  } catch (e) {
    console.log(e);
  } finally {
    setTimeout(() => dispatch(setMessage('')), 3000);
    dispatch(setLoading(false));
  }
};
