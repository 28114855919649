// Dependencies
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
// Component
import createRootReducer from './rootReducer';

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default (history) => {
  const store = createStore(
    createRootReducer(history),
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  return store;
};
