// Dependencies
import React from 'react';
import { connect } from 'react-redux';
// i18n
import { t } from '../../i18n';
// Components
import Base from '../../components/Base';
function About({ about, imgBackground }) {
  return (
    <Base imgBackground={imgBackground} about={about}>
      <h1 className="title">{t('menu.about')}</h1>
      <span className="text-separator-top" />
      <div className="paragraph">
        <div dangerouslySetInnerHTML={{ __html: `${about}` }} />
      </div>
      <div className="text-separator-bottom" />
    </Base>
  );
}

function mapStateToProps(store) {
  const home = store.home.homes[0];
  return {
    about: home ? home.about : [],
    imgBackground: home ? home.beneficiaryImageUrl : [],
  };
}

export default connect(mapStateToProps)(About);
