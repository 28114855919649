export const USER = 'USER';
export const BENEFICIARIES = 'BENEFICIARIES';
export const BENEFICIARIES_ID_NAME = 'BENEFICIARIES_ID_NAME';
export const BENEFICIARY_SELECTED = 'BENEFICIARY_SELECTED';
export const CHECKER_SELECTED = 'CHECKER_SELECTED';
export const CHECKERS = 'CHECKERS';
export const MESSAGE_BENEFICIARY = 'MESSAGE_BENEFICIARY';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const INFO_RELATION_BENEFICIARE = 'INFO_RELATION_BENEFICIARE';
export const NOTIFICATION_SELECETED = 'NOTIFICATION_SELECETED';
export const INFO_RELATION_VERIFIER = 'INFO_RELATION_VERIFIER';
export const ABSENCE_USER_SELECTED = 'ABSENCE_USER_SELECTED';
export const USER_MAIN = 'USER_MAIN';
export const DATA_ORDER_PAYMENT = 'DATA_ORDER_PAYMENT';
export const DATA_SIGNATURE = 'DATA_SIGNATURE';
