const getFormDataObject = data => {
	const formData = new window.FormData()
	for (let [key, value] of Object.entries(data)) {
		if (value && value.rawFile) {
			formData.append(key, value.rawFile)
		} else {
			formData.append(key, value)
		}
	}
	return formData
}

export default getFormDataObject
