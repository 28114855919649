import { getLanguage } from '../i18n';

export const routes = {
  en: {
    HOME: '/home',
    PROFILE: '/my-profile',
    SEE_DRAFT: '/see-draft/:slug',
    RECORD: '/see-draft',
    MESSAGE: '/message',
    SEE_MESSAGE: '/message/:slug',
    WHAT_IS_IT: '/what-is-it',
    TIPS_MESSAGE: '/tips-message',
    EVENT_MESSAGE: '/event-message',
    VIDEO_MESSAGE: '/video-message',
    AUDIO_MESSAGE: '/audio-message',
    AUDIO_MESSAGE_EDIT: '/audio-message/:messageId',
    PICTURE_MESSAGE: '/picture-message',
    PICTURE_MESSAGE_EDIT: '/picture-message/:messageId',
    VIDEO_MESSAGE_EDIT: '/video-message/:messageId',
    WRITTEN_MESSAGE_EDIT: '/written-message/:messageId',
    WRITTEN_MESSAGE: '/written-message',
    REGISTER: '/register',
    REGISTER_NO_MAIN_USERS: '/register/beneficiare/:relationBeneneficiareId',
    REGISTER_VERIFIER: '/register/verifier/:relationVerifierId',
    CONTACT: '/contact',
    TUTORIALS: '/tutorials',
    TUTORIAL_DETAIL: '/tutorials/:pageTutorial',
    ABOUT: '/about',
    HOW_DO_WE_DO_IT: '/how-do-we-do-it',
    REGISTERBENEFICIARY: '/register/:slug',
    HISTORY_EVENTOS: '/history-events',
    HISTORY_ADVICE: '/history-advice',
    SEE_DRAFT_LOCAL_BASE: '/see-draft-local',
    SEE_DRAFT_LOCAL: '/see-draft-local/:slug',
  },
  es: {
    HOME: '/inicio',
    PROFILE: '/mi-perfil',
    SEE_DRAFT: '/ver-borradores/:slug',
    RECORD: '/ver-borradores',
    MESSAGE: '/mensajes',
    SEE_MESSAGE: '/mensajes/:slug',
    WHAT_IS_IT: '/que-es',
    TIPS_MESSAGE: '/consejos-para-mensajes',
    EVENT_MESSAGE: '/mensajes-de-eventos',
    VIDEO_MESSAGE: '/mensajes-de-video',
    AUDIO_MESSAGE: '/mensajes-de-audio',
    AUDIO_MESSAGE_EDIT: '/mensajes-de-audio/:messageId',
    PICTURE_MESSAGE: '/mensajes-de-imagen',
    PICTURE_MESSAGE_EDIT: '/mensajes-de-imagen/:messageId',
    VIDEO_MESSAGE_EDIT: '/mensajes-de-video/:messageId',
    WRITTEN_MESSAGE_EDIT: '/mensajes-escritos/:messageId',
    WRITTEN_MESSAGE: '/mensajes-escritos',
    REGISTER: '/registro',
    REGISTER_NO_MAIN_USERS: '/register/beneficiare/:relationBeneneficiareId',
    REGISTER_VERIFIER: '/register/verifier/:relationVerifierId',
    CONTACT: '/contactenos',
    TUTORIALS: '/tutorials',
    TUTORIAL_DETAIL: '/tutorials/:pageTutorial',
    ABOUT: '/nosotros',
    HOW_DO_WE_DO_IT: '/como-lo-hacemos',
    REGISTERBENEFICIARY: '/registro/:slug',
    HISTORY_EVENTOS: '/historial-eventos',
    HISTORY_ADVICE: '/historial-consejos',
    SEE_DRAFT_LOCAL_BASE: '/ver-borrador-local',
    SEE_DRAFT_LOCAL: '/ver-borrador-local/:slug',
  },
};

export default routes[getLanguage()];
