// Dependencies
import styled from 'styled-components';
import media from 'styled-media-query';
// Assets
import lineTitle from '../../assets/images/line-title-2.svg';
import spriteIcon from '../../assets/images/sprite-icons.svg';

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
	margin-top: 199px;
	margin-bottom: 50px;
	.modal {
		max-width: 500px;
	}
	.textCenter {
		text-align: center;
	}
	.infoUser {
		border-right: 1px solid #ccc;
		display: flex;
		flex-direction: column;
	}
	.topBlue {
		width: 180px;
		height: 25px;
		background: url(${lineTitle}) 0 0/180px 25px no-repeat;
		margin: 0 auto;
		display: inline-block;
	}
	.topForm {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
	.circleImg {
		width: 160px;
		height: 160px;
		margin: 0 auto;
		border-radius: 100px;
		background: #ccc;
		overflow: hidden;
		margin-top: 20px;
	}
	.imgContent {
		width: 100%;
		height: 100%;
		position: relative;
		margin: auto;
		vertical-align: middle;
	}
	.btnEditPhoto {
		width: 10%;
		height: 30px;
		background: url(${spriteIcon}) -235px -40px/550px 80px;
		margin: 10px auto 0;
		position: absolute;
		cursor: pointer;
	}
	.uploadEdit {
		width: 101px;
		height: 45px;
		overflow: hidden;
		position: absolute;
		top: 0;
		right: -33px;
		input {
			display: block !important;
			width: 101px !important;
			height: 45px !important;
			opacity: 0 !important;
			overflow: hidden !important;
			cursor: pointer;
		}
	}
	.cyan {
		width: 179px !important;
		color: #3fa8e0;
		font-size: 11px;
		line-height: 18px;
		text-align: center;
		cursor: pointer;
	}
	.formRight {
		width: 85%;
		margin: 0 auto;
		margin-top: 20px;
		select {
			margin-bottom: 10px;
		}
	}
	.formBottom {
		width: 95%;
	}
	.changePassword {
		margin: 0 10px 20px 20px;
		position: relative;
		top: 11px;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.saveChange {
		width: 163px;
		margin: 0 auto;
	}
	.buttons {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 3px;
		button {
			margin-right: 10px;
			line-height: normal;
		}
		button:last-child {
			margin-right: 0;
			width: 133px;
			font-size: 14px;
		}
	}
	/*-----------Right------------*/
	.whatDoYou {
		img {
			display: none;
		}
		h2 {
			color: #211261;
		}
	}

	/*-----------Movil------------*/

	${media.lessThan('large')`  
		width: 100%;  
    grid-template-columns: 1fr;
		.infoUser {
			padding-bottom: 10px;
			border-right: none;			
			border-bottom: 1px solid #ccc;			
		}
		.topForm {
			grid-template-columns: 1fr;
		}
    .formRight {
			margin-top: 75px;
		}
  `}
	${media.lessThan('medium')`
		.wrapper {
			 
			display: flex;
			flex-direction: column;    
			align-items: center;
		}
		.whatDoYou {
			margin: 0 auto;
		}
		.buttons {
			margin: 0 auto;
			grid-template-columns: repeat(2, 1fr);
		}	
		.formBottom {
			width: 84%;
    	margin: 0 auto;
		}

	
	`}
`;
