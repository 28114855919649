/* Dependencies */
import React, { Component } from 'react';
/* Fields */
import Input from '../Input';
/* Styles */
import { Container } from './styles';

class InputPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const { showPassword } = this.state;
    return (
      <Container>
        <Input type={!showPassword ? 'password' : 'text'} {...this.props} />
        <i
          className={`fas fa-eye${!showPassword ? '-slash' : ''}`}
          onClick={() =>
            this.setState((state) => ({
              showPassword: !state.showPassword,
            }))
          }
        />
      </Container>
    );
  }
}

export default InputPassword;
