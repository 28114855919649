/* Dependencies */
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import VideoRecorder from 'react-video-recorder';
/* i18n */
import { t } from '../../i18n';
/* Actions */
import {
  setMessageVideo,
  getBasicMessageInformation,
  getFileTypes,
  setMessagePreview,
} from '../../redux/messages/actions';
import { setModal } from '../../redux/app/actions';
/* Components */
import TypeFileUpload from '../../components/TypeFileUpload';
import Button from '../../components/Button';
import InputRedux from '../../fields/Input';
import SelectRedux from '../../fields/SelectInput';
import FileInput from '../../fields/FileInput';
/* Styles */
import { ModalAudio, Form, ContainerVideo, Container } from './styles';
/* Assets */
import rec from '../../assets/images/rec-video.jpg';
import upload from '../../assets/images/upload-video.jpg';

const constrains = {
  title: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  evenType: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
  fileUrl: {
    presence: {
      message: t('validationsInputs.required'),
    },
  },
};
class VideoMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: [],
      isSubmit: true,
      type: '',
      nameFile: '',
    };
  }

  componentDidMount() {
    const { getFileTypes, getBasicMessageInformation } = this.props;
    getBasicMessageInformation();
    getFileTypes();
  }

  onChangeFile = (e) => {
    const data = new File([e], 'video-record.webm');

    this.setState({
      nameFile: e.name,
      video: [{ data, url: URL.createObjectURL(e) }],
      isSubmit: true,
    });
  };

  onChangeAtt = (e) => {
    this.setState({
      nameFile: e.name,
      video: [{ data: e, url: URL.createObjectURL(e) }],
      isSubmit: true,
    });
  };

  toggleItem = (item) =>
    this.setState((state) => ({
      isActivedItem: item !== state.isActivedItem ? item : '',
    }));
  onChange = (data) =>
    data &&
    this.setState({
      nameFile: data.name,
      type: data,
    });
  onChangeDate = (data) => data && this.setState({ type: data.target.value });

  render() {
    const { handleSubmit, onSubmit, messageTypes, availableSpace } = this.props;
    const { video, isActivedItem, isSubmit, type, nameFile } = this.state;
    
    const messageType = messageTypes.find(
      (messageType) => messageType.code === 'video'
    );
    const fileTypeId = 5;

    const obtenerPesoArchivoEnMB = (size) => {
      const megabytes = size / (1024 * 1024);
      return megabytes.toFixed(2);
    };

    return (
      <Fragment>
        <Form
          onSubmit={
            isSubmit && video.length
              ? handleSubmit((values) =>
                  onSubmit({
                    ...values,
                    fileUrl: video[0].data,
                    messageTypeId:
                      messageType === undefined ? 6 : messageType.id,
                    fileTypeId,
                  })
                )
              : (e) => e.preventDefault()
          }
        >
          <div className="titleAndDate">
            <Field
              type="text"
              name="title"
              component={InputRedux}
              placeholder={t('writtenMessage.fields.title')}
            />
            {/* <Field name='eventTypeId' component='select'>
							<option>Selecionar tipo de evento</option>
							{eventTypes.map((eventType, key) => (
								<option key={key} value={eventType.id}>
									{eventType.name}
								</option>
							))}
						</Field> */}
            <Field
              onChange={this.onChangeDate}
              name="evenType"
              component={SelectRedux}
            >
              <option>{t('fields.selectDate')}</option>
              <option value="absence">{t('fields.absances')}</option>
              <option value="date">{t('fields.dates')}</option>
            </Field>
            {type === 'date' && (
              <Field
                type="date"
                name="visibleData"
                component={InputRedux}
                placeholder={t('writtenMessage.fields.title')}
              />
            )}
          </div>
          <div className="actionMessage">
            <TypeFileUpload
              title={t('videoMessage.recording')}
              icon="fas fa-video"
              bgImg={rec}
              toggle={() => this.toggleItem('audio')}
            />
            <TypeFileUpload
              title={t('videoMessage.attach')}
              icon="fas fa-play"
              bgImg={upload}
              toggle={() => this.toggleItem('file')}
            />
          </div>
          {isActivedItem === 'audio' && (
            <ModalAudio bottom="300px" width="100%">
              <ContainerVideo>
                <VideoRecorder
                  onTurnOnCamera={() => this.setState({ isSubmit: false })}
                  onStopReplaying={() => this.setState({ isSubmit: false })}
                  onStartRecording={() => this.setState({ isSubmit: false })}
                  onRecordingComplete={this.onChangeFile}
                />
              </ContainerVideo>
            </ModalAudio>
          )}
          {isActivedItem === 'file' && (
            <ModalAudio>
              <label>{t('videoMessage.format')}</label>
              <Field
                nameFile={nameFile}
                component={FileInput}
                onChange={this.onChangeAtt}
                name="fileUrl"
                type="file"
                accept=".mp4,.ogg, .webm .mkv"
              />
            </ModalAudio>
          )}
          {video[0] && video[0].data.size > availableSpace && (
            <Container bgColor='red' isActived>
              <p>{t('messagesResponse.fileBigSize').replace('availableSpace', obtenerPesoArchivoEnMB(availableSpace)).replace('fileSize', obtenerPesoArchivoEnMB(video[0] && video[0].data.size))}</p>
            </Container>
          )}
          {/* <div className='input'>
						<h4>
							{`${t('writtenMessage.previewMessage')}`}{' '}
							<span
								onClick={handleSubmit(values => {
									setMessagePreview({
										...values,
										fileUrl: video[0] ? video[0].url : '',
										messageTypeId: messageType === undefined ? 6 :  messageType.id,
										fileTypeId: fileType.id
									})
									setModal('isActivedMessagePreview')
								})}>{`${t('writtenMessage.clickhere')}`}</span>
						</h4>
					</div> */}

          <div className="containerBtnSubmit">
            <Button bgColor="#DF382F" bgColorHover="#892B6E" width="50%" disabled={video[0] && video[0].data.size > availableSpace}>
              <i className="far fa-clock" /> {t('buttons.scheduleMessage')}
            </Button>
          </div>
        </Form>
      </Fragment>
    );
  }
}

VideoMessage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  const message = store.messages;
  return {
    eventTypes: message.eventTypes || [],
    messageTypes: message.messageTypes || [],
    fileTypes: message.fileTypes || [],
    values: store.form,
  };
}

function mapDispachtToProps(dispatch) {
  return {
    onSubmit: (messageWithFile, next) =>
      dispatch(setMessageVideo(messageWithFile, next)),
    getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
    getFileTypes: () => dispatch(getFileTypes()),
    setModal: (modal) => dispatch(setModal(modal)),
    setMessagePreview: (messagePreview) =>
      dispatch(setMessagePreview(messagePreview)),
  };
}

function margeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (value) =>
      dispatchProps.onSubmit(value, () => ownProps.push('/')),
  };
}

const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'videoMessage',
    validate: (values) => validate(values, constrains),
  })
);

export default enhance(VideoMessage);
