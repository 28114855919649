/* Dependencies */
import styled, { css } from "styled-components";
import media from "styled-media-query";
import { ifProp } from "styled-tools";

export const Container = styled.div`
	width: 100%;
	height: 45px;
	position: relative;
	right: 10px;
	margin-bottom: 10px;
	float: left;
	cursor: pointer;
	z-index: 11;
	.txt {
		color: #342573;
		line-height: 35px;
		text-align: center;
		float: left;
		position: absolute;
		background: #f5f5f5;
		border-radius: 20px;
		opacity: 0;
		transition: all 0.33s ease-in-out;
		font-size: 17px;
		width: auto;
		padding: 0 10px;
		left: 85px;
		top: -4px;
	}
	.circleImage {
		border-radius: 30px;
		/*padding: 5px;
      background: #F5F5F5;
      /*box-shadow: 0 0 2px 2px rgba(0, 0, 0, .1);*/
		float: right;
		position: relative;
		@media (max-width: 992px) {
			display: none;
		}
		width: 55px;
		height: 55px;
		top: 5px;
		right: 0px;
		transition: 0.3s all;
		${ifProp(
			"font",
			css`
				font-size: 25px;
				background-color: transparent;
				box-shadow: none;
				color: gray;
				top: 0;
			`,
		)}
		img {
			width: 100%;
		}
	}
	.circleImage:hover {
		width: 42px;
		height: 42px;
	}
	&:hover .txt {
		opacity: 1;
		left: -55px;
		${ifProp(
			"small",
			css`
				left: -100px;
			`,
		)}
	}
	/* ${media.lessThan("medium")`
    z-index: 10;
    .circleImage {    
      right: -38px!important;    
      top: 60px!important;
      z-index: 11;
    `} */
`;
