/* Dependencies */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '../../i18n';
import { getBasicMessageInformation } from '../../redux/messages/actions';
/* Components */
import Message from '../../components/Message';
import MessageResponse from '../../components/MessageResponse';
/* Styles */
import { Container } from './styles';
/* Form */
import WrittenMessageFormEdit from '../../form/WrittenMessageEdit';

class WrittenMessageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isActived: false,
    };
  }

  componentDidMount() {
    this.props.getBasicMessageInformation();
  }

  toggleIsActived = () => this.setState({ isActived: !this.state.isActived });

  onChange = (date) =>
    this.setState({ date, isActived: !this.state.isActived });

  render() {
    const { isActived } = this.state;
    const { message } = this.props;
    const isLocal = this.props.location.search === '?local=true';
    return (
      <Fragment>
        <Message
          withHoutRecomendations
          titleTypeMessage={t('writtenMessage.title')}
          eraser
          notButton
        >
          <Container isActived={isActived}>
            <WrittenMessageFormEdit
              history={this.props.history}
              slug={this.props.match.params.messageId}
              toggleModal={this.toggleIsActived}
              isLocal={isLocal}
            />
          </Container>
        </Message>
        <MessageResponse isActived={message === 'setMessageUserSucces'} />
        {/* <MessageResponse
					isActived={message === 'setMessageUserError'}
					message='Error'
					withError
				/>
				<MessageResponse
					isActived={message === 'setMessageUserError'}
					message='Error'
					withError
				/> */}
      </Fragment>
    );
  }
}

WrittenMessageEdit.propTypes = {
  getBasicMessageInformation: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
};

function mapStateToProps(store) {
  return {
    message: store.app.message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WrittenMessageEdit);
