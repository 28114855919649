import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const Wrapper = styled.div`
	top: 59px;		
  margin: 0 auto;
	min-height: calc(100vh - ${prop('height', '199px')});
	width: 100%;
	max-width: 1366px;
	margin: 0 auto;
  @media (min-width: 768px) {
		width: 750px;
  }
  @media (min-width: 992px) {
		width: 970px;
	}
	@media (min-width: 1200px) {
		width: 1199px;
	}	
	${ifProp('noMargin', css`
	
		width: 100%;
	`)}
`

export default Wrapper
