// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 40px;
	grid-row-gap: 12px;
	padding: 30px;
	.buttonsRecord {
		height: 37px;
		display: flex;
		a {
			height: 37px;
			margin-right: 5px;
		}	
		> button, a {
			width: 50%;
		}	
	}
	.txtInfo {
		text-align: center;
	}
	@media screen and (max-width: 1299px) {
		.buttonsRecord {
			> button {
				font-size: 13px;
			}
		}
	}
	/*----------tablet-------------------*/
	${media.lessThan('large')`
  grid-template-columns: 1fr;
  .buttonsRecord {
    width: 80%;
    margin: 0 auto;
  }
  `}
	${media.lessThan('medium')`
  width: 100%;
  padding: 8px;
  .buttonsRecord {    
    width: 100%;
    a {
      width: 100%;
    }
  }
  `}
`

export const SeeMessage = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding-left: 19px;
	${media.lessThan('medium')`
		display: block;
    width: 100%;
  `}
`
