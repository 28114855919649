// Dependencies
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	margin: 0 15px;
	margin-bottom: 30px;
	.titleAndDate {
		display: grid;
		grid-template-columns: 1.2fr 1fr;
		grid-gap: 15px;
	}
	.absence {
		> input {
			cursor: pointer;
		}
	}
	.calendar {
		display: none;
		width: 242px;
		height: 293px;
		margin-left: -12px;
		position: absolute;
		z-index: 9;
		${ifProp(
    'isActived',
    css`
				display: block;
			`
  )}
	}	
	
	.date {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		.absence {
			> input {
				float: right;
			}			
		}
	}
	.buttons {
		transition: all .1s ease-in-out;		
		margin-top: 40px;
		display: flex;	
		justify-content: space-between;
		> div {
			width: 24%;
		}
		button {
			width: 100%;
			display: flex;	
			align-items: center;		
    	justify-content: center;
		}	
	}
	.photo, .audio, .video, .txtresponsive, .area {
		display: none;
	}
	.area {
		width: 83.33333333%;		
		margin: 0 auto;
		margin-top: 40px;
		transition: all .3s ease-in-out;
		p { 
			text-align: center;
		}		
	}
	.blue {
			color: #211261;
			text-align: center;
		}
		
		${ifProp(
    'isActivedArea',
    css`
				.area {
					display: block;
				}
			`
  )}
	${ifProp(
    'isActivedImg',
    css`
			.photo {
				display: block;
			}
		`
  )}
	${ifProp(
    'isActivedAudio',
    css`
			.audio {
				display: block;
			}
		`
  )}		
	${ifProp(
    'isActivedVideo',
    css`
			.video {
				display: block;
			}
		`
  )}				
	${ifProp(
    'isActivedUpload',
    css`
			.containerUpload {
				display: block;
			}
			.selectPhotos {
				margin-top: 65px;
			}
		`
  )}		
	${media.lessThan('large')`		
	.titleAndDate {
		input {
			margin-bottom: 9px;
		}
		div {
			margin-bottom: 9px;
		}
	}
	.title {
		grid-column: 1/3;
	}
		.buttons {									
				> div {		
					min-width: 1px;			
					margin: 0 auto;
					margin-bottom: 7px;
					text-align: center;
				}		
				button {
					width: 97%;
				}
			}			
			${ifProp(
    'arrowActived',
    css`
					.txt {
						display: none;
					}
					.txtresponsive {
						display: block;
					}
				`
  )}
	`}


	${media.lessThan('medium')`
	.titleAndDate {
		/* grid-template-columns: 1fr;
    grid-auto-rows: 46px; 
    background-color: red;    */
    display: block;
    select {
      margin-bottom: 10px;
    }
    .date {
      display: block;
    }
	}	
			.txt {
	display: block;
}
			.txtresponsive {
				display: none;
			}
	.buttons {
		display: flex;
		flex-direction: column;
		> div {
			width: 95%;
			margin: 0 auto;
			margin-bottom: 7px;
			text-align: center;
			transition: all .2s ease-in-out;
		}		
	}
	}
`}		
	
`
