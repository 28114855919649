//Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// i18n
import { t } from '../../i18n';
import { setModal } from '../../redux/app/actions';
//Components
import FilesMessage from '../../components/FilesMessage';
import ModalFeedBack from '../../components/ModalFeedBack';

import VideoMessageForm from '../../form/VideoMessageEdit';

class VideoMessageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivedUpload: false,
      isActivedVideoRecorder: false,
      isShowModal: true,
    };
  }

  toggleIsActived = (keyName) =>
    this.setState((state) => ({ [keyName]: !state[keyName] }));
  render() {
    const {
      match: {
        params: { messageId },
      },
      history: { push },
    } = this.props;
    return (
      <Fragment>
        <FilesMessage
          withHoutRecomendations
          title={t('videoMessage.title')}
          notBox
        >
          <VideoMessageForm slug={messageId} push={push} />
        </FilesMessage>
        {/* {modal === 'modalPreview' && (
					// <ModalPreview
					// 	style={{ width: '100%' }}
					// 	user={user[0]}
					// 	messagePreview={messagePreview}
					// 	toggle={() => setModal('')}>
					// 	<video
					// 		style={{ width: '100%' }}
					// 		src={messagePreview.fileUrl}
					// 		controls
					// 	/>
					// </ModalPreview>
					<ModalVideo toggle={() => setModal('')} />
				)} */}
        {this.state.isShowModal && (
          <ModalFeedBack
            toggle={() =>
              this.setState({
                isShowModal: false,
              })
            }
            title={t('home.modal.confirm.confirm')}
            description={t('home.modal.confirm.messageVideo')}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.user.user || [],
    messagePreview: store.messages.messagePreview || [],
    modal: store.app.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModal: (modal) => dispatch(setModal(modal)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoMessageEdit);
