/* Dependencies */
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validate from 'validate.js'
/* Actions */
import {
	setMessageEvents,
	setMessageAdvice,
	getBasicMessageInformation,
	getFileTypes
} from '../../redux/messages/actions'
import {
	getAllBeneficiaryOnlyId,
	getAllBeneficiary,
	
} from '../../redux/user/actions'
import { getRecommendationSelected, getAdviceByRelation, getAdviceRecommendations, getAdviceRecommendationSelected } from '../../redux/app/actions'




import { t } from '../../i18n'

const constrains = {
	title: {
		presence: {
			message: t('validationsInputs.required')
		}
	},
	eventType: {
		presence: {
			message: t('validationsInputs.required')
		}
	}
}


function cleanDuplicateKeys({array, key}){
  if(!array.length){
    return []
  } 
  return array.reduce((acc,item)=>{
    if(!acc.find(el => el[key] === item[key])){
      acc.push(item)
    }
    return acc;
  },[])
}

const mapStateToProps = ({
	messages: { eventTypes, messageTypes, fileTypes, adviceTypes },
	user: { beneficiariesIdName },
	app: { recommendationEventSelected, adviceRecommendations }
}) => {
	let initialValues = {}
	if (recommendationEventSelected.title) {
		initialValues = {
			title: recommendationEventSelected.title,
			description: recommendationEventSelected.description
		}
	}
	return {
		eventTypes: cleanDuplicateKeys({array: eventTypes, key: 'eventTypeId'}),
		messageTypes,
		fileTypes,
    beneficiariesIdName,
    adviceRecommendations,
    adviceTypes: cleanDuplicateKeys({array: adviceTypes, key: 'adviceTypeId'}),
		...initialValues
	}
}

const mapDispachtToProps = dispatch =>
	bindActionCreators(
		{
			onSubmit: setMessageEvents,
			onSubmitAdvice: setMessageAdvice,
			getBasicMessageInformation,
			getFileTypes,
			getAllBeneficiary,
			getAllBeneficiaryOnlyId,
      getRecommendationSelected,
      getAdviceRecommendationSelected,
      getAdviceByRelation,
      getAdviceRecommendations
		},
		dispatch
	)

const margeProps = (stateProps, dispatchProps, ownProps) => ({
	...stateProps,
	...dispatchProps,
	...ownProps,
	onSubmit: values => dispatchProps.onSubmit(values, () =>  ownProps.history.push('/')),
	onSubmitAdvice: values => dispatchProps.onSubmitAdvice(values,() =>  ownProps.history.push('/'))

})
	

export default compose(
	connect(
		mapStateToProps,
		mapDispachtToProps,
		margeProps		
	),
	reduxForm({
		form: 'eventMessage',
		validate: values => validate(values, constrains)
	})
)
