import React, { Component } from 'react';
import { Field } from 'redux-form';

import { t } from '../../i18n';

import TextArea from '../../fields/TextArea';
import InputFileButton from '../../fields/InputFileButton';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import logo from '../../assets/images/logo-1.png';

import img from '../../assets/images/male-user.png';

import { Content } from './styles';

import withStore from './store';

class Abasence extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fileName: '',
		};
		this._toggleFileName = this._toggleFileName.bind(this);
	}
	_toggleFileName(value) {
		if (value) {
			this.setState({
				fileName: value.name,
			});
		}
	}
	render() {
		const { fileName } = this.state;
		const { active, toggle, imageUrl, firstName, handleSubmit } = this.props;
		return (
			<Modal
				width='fit-content'
				padding='0 25px'
				fullScreen
				active={active}
				toggle={toggle}
				zIndex={10}
			>
				<Content>
					<form onSubmit={handleSubmit}>
						<img src={logo} alt='logo' />
						<p>{t('userVerifier.confirming')}</p>
						<div className='content-form'>
							<figure className='img-user'>
								<img
									src={
										imageUrl && imageUrl.split('/').length > 1 ? imageUrl : img
									}
									alt='user'
								/>
								<figcaption>{firstName}</figcaption>
							</figure>
							<div className='container-buttons'>
								<Field
									nameFile={fileName}
									name='evidence'
									onChange={this._toggleFileName}
									component={InputFileButton}
								/>
								<Field
									name='descripcion'
									rows='7'
									component={TextArea}
									placeholder={t('fields.absenceDescription')}
								/>
								<Button bgColor='#ff8400' bgColorHover='#211261'>
									{t('buttons.submit')}
								</Button>
							</div>
						</div>
					</form>
				</Content>
			</Modal>
		);
	}
}

export default withStore(Abasence);
