import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	position: relative;
  transition: background .3s linear 0s;
	img {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 10px;
	}
	h4,
	p {
		margin: 0;
		padding: 0;
		text-transform: initial;
	}
	p {
		font-size: 10px;
		font-weight: 400;
	}
	h4 {
		font-size: .9rem;
	}
	.time {
		bottom: 0;
		right: 19px;
		position: absolute;
		font-size: 9px;
	}
	button {
		position: absolute;
    top: 0;
    right: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
		:hover {
			transform: scale(1.1);
		}
	}
	${ifProp(
		'withAction',
		css`
			cursor: pointer;
      :hover {
        background-color: #ef7800;        
      }
		`
	)}
`
