// Dependecies
import styled from 'styled-components'
import { prop } from 'styled-tools'
export const Conatiner = styled.div`
	
	border-top: 4px solid ${prop('bgBorder', '#3FA8E0')};
	cursor: pointer;
	link,
	a {
		width: 100%;
		height: 100%;
		text-decoration: none;
		display: grid;
		grid-template-columns: 1fr 6fr;
		&:hover {
			animation: test 0.6s forwards;			
		}
		.box-tutorial-icon {
			width: 90px;
			height: 80px;
			display: flex;
			align-items: center;
			align-content: center;
			.circle {
				width: 50px;
				height: 50px;
				top: 15px;
				margin-left: 15px;
				padding: 0px;
				border-radius: 30px;
				background: ${prop('bgBorder', '#3FA8E0')};
			}
		}
	}
	.box-tutorial-text {
		width: calc(100% - 90px);
		display: flex;
		align-items: center;
		h4 {
			color: #898989 !important;
			text-align: justify !important;
		}
	}
`
