/* Dependencies */
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validate from 'validate.js';
/* Actions */
import { setAbsenceUserMain } from '../../redux/user/actions';

import { t } from '../../i18n';

/* Validations */
const constrains = {
	evidence: {
		presence: {
			message: t('validationsInputs.required'),
		},
	},
	descripcion: {
		presence: {
			message: t('validationsInputs.required'),
		},
	},
};

const mapStateToProps = ({ user: { absenceUserSelected, user } }) => {
	let values = {};
	const [userVerifier] = user;
	const { id: verifierId } = userVerifier;
	if (absenceUserSelected.mainUser) {
		const {
			mainUser: { imageUrl, firstName, id: userId },
		} = absenceUserSelected;
		values = {
			imageUrl,
			firstName,
			userId,
		};
	}
	return {
		...values,
		verifierId,
	};
};

const mapDispachtToProps = (dispatch) =>
	bindActionCreators(
		{
			setAbsenceUserMain,
		},
		dispatch,
	);

const margeProps = (stateProps, dispatchProps, ownProps) => ({
	...stateProps,
	...dispatchProps,
	...ownProps,
	onSubmit: (values) => {
		const { userId, verifierId } = stateProps;
		dispatchProps.setAbsenceUserMain({
			...values,
			userId,
			verifierId,
		});
	},
});

export default compose(
	connect(mapStateToProps, mapDispachtToProps, margeProps),
	reduxForm({
		form: 'absence',
		validate: (values) => validate(values, constrains),
	}),
);
