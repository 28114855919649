import firebase from "firebase/app";
import AppService from "./AppService";
import CONST from "./const";
import { setIsUserMain } from "../user/actions";
import UserService from "../user/UserService";
import { t } from "../../i18n";
import "firebase/auth";

const timeHideMessage = 3000;

export const setLanguage = (value) => ({ type: CONST.LANGUAGES, value });
export const setMessage = (value) => ({ type: CONST.MESSAGE, value });
export const setModal = (value) => ({ type: CONST.MODAL, value });
export const setLoading = (value) => ({ type: CONST.LOADING, value });
export const setComponentLoading = (value) => ({
	type: CONST.IS_COMPONENT_LOADING,
	value,
});
export const offLoading = (value) => ({ type: CONST.LOADING_OFF, value });
export const setUserLogin = (value) => ({ type: CONST.USER_LOGIN, value });
export const setFormBuy = (value) => ({ type: CONST.FORM_BUY, value });
export const setArrow = (value) => ({ type: CONST.ARROW, value });
export const setError = (value) => ({ type: CONST.ERROR, value });
export const setEventPage = (value) => ({ type: CONST.EVENTS_PAGE, value });
export const setModalTerms = (value) => ({
	type: CONST.MODAL_TERMS_AND_CONDITIONS,
	value,
});

export const setModalVideo = (value) => ({
	type: CONST.MODAL_VIDEO,
	value,
});

export const setBeneficiaryId = (value) => ({
	type: CONST.USER_BENEFICIARY_DATA,
	value,
});

export const setVerifierId = (value) => ({
	type: CONST.USER_VERIFIER_DATA,
	value,
});

export const setDataUserMainVerifier = (value) => ({
	type: CONST.DATA_VERIFIER_USER_MAIN,
	value,
});

export const AssingRoleProfile = (value) => ({
	type: CONST.DATA_ASSIGIN_ROLE,
	value,
});

export const setRecommendation = (value) => ({
	type: CONST.RECOMMENDATIONS,
	value,
});

export const setAdviceRecommendation = (value) => ({
	type: CONST.ADVICE_RECOMMENDATIONS,
	value,
});

export const setAdviceRecommendationFilter = (value) => ({
	type: CONST.ADVICE_RECOMMENDATIONS_FILTER,
	value,
});

export const setJoinUserPrincipal = (value) => ({
	type: CONST.JOIN_USER_PRINCIPAL,
	value,
});

export const setRecommendationEventSelected = (value) => ({
	type: CONST.RECOMMENDATION_EVENT_SELECTED,
	value,
});

export const setRecommendationSelected = (value) => ({
	type: CONST.RECOMMENDATION_SELECTED,
	value,
});

export const setAdviceRecommendationSelected = (value) => ({
	type: CONST.ADVICE_RECOMMENDATION_SELECTED,
	value,
});

export const getRecommendation = () => async (dispatch) => {
	try {
		dispatch(setComponentLoading(true));
		// const recommendations = await AppService.getRecommendations()
		// dispatch(setRecommendation(recommendations.data))
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};
export const getAdviceRecommendations = () => async (dispatch) => {
	try {
		dispatch(setComponentLoading(true));
		const { data } = await AppService.getAdviceRecommendations();
		dispatch(setAdviceRecommendation(data));
		// const [eventTypes, eventTypesAll]= await Promise.all([
		//   MessageService.getEventTypes(),
		//   MessageService.getAllEventTypes()
		// ])

		// dispatch(setEventTypes(eventTypes.data))
		// dispatch(setEventTypesAll(eventTypesAll.data))
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const getAdviceByRelation = (value) => (dispatch, getStore) => {
	const {
		app: { adviceRecommendations },
		user: { beneficiaries },
	} = getStore();
	if (!Array.isArray(beneficiaries)) {
		return;
	}
	const beneficiary = beneficiaries.find(
		(el) => el.beneficiaryId === value.value,
	);

	const recommendations = adviceRecommendations.filter(
		(el) =>
			el.adviceTypeId &&
			beneficiary.relationshipId &&
			el.adviceTypeId.relationshipsId === beneficiary.relationshipId,
	);

	dispatch(setAdviceRecommendationFilter(recommendations));
};

export const getRoleUserPrincipal = () => async (dispatch, getStore) => {
	try {
		const {
			user: { user },
		} = getStore();
		const userId = user[0].id;
		const { data } = await AppService.getRoleUserPrincipal(userId);
		if (!data.length) {
			dispatch(setJoinUserPrincipal(true));
		}
	} catch (e) {
		console.log(e);
	}
};

export const getRecommendationSelected =
	({ target }) =>
	(dispatch, getStore) => {
		try {
			dispatch(setRecommendationSelected([]));

			const eventTypeId = parseInt(target.value);
			const {
				messages: { eventTypes },
			} = getStore();
			if (isNaN(eventTypeId)) {
				return dispatch(setRecommendationSelected([]));
			}

			const recommendationsSelected = eventTypes.filter(
				(recomendation) =>
					recomendation.recommendations &&
					recomendation.eventTypeId === eventTypeId,
			);

			if (recommendationsSelected.length) {
				dispatch(setRecommendationSelected(recommendationsSelected));
			} else {
				dispatch(setRecommendationSelected(eventTypes));
			}
		} catch (e) {
			console.log(e);
		}
	};

export const getAdviceRecommendationSelected =
	({ target }) =>
	(dispatch, getStore) => {
		try {
			dispatch(setAdviceRecommendationSelected([]));

			const adviceTypeId = parseInt(target.value);
			const {
				messages: { adviceTypes },
			} = getStore();
			if (isNaN(adviceTypeId)) {
				return dispatch(setAdviceRecommendationSelected([]));
			}

			const recommendationsSelected = adviceTypes.filter(
				(recomendation) =>
					recomendation.recommendations &&
					recomendation.adviceTypeId === adviceTypeId,
			);

			if (recommendationsSelected.length) {
				dispatch(setAdviceRecommendationSelected(recommendationsSelected));
			} else {
				dispatch(setAdviceRecommendationSelected(adviceTypes));
			}
		} catch (e) {
			console.log(e);
		}
	};

export const getAssingRoles = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));

		const store = await getStore();
		const {
			app: {
				isLoginUser: { uid },
			},
		} = store;
		const user = await UserService.getUser(uid);
		const userId = user.data[0].id;
		await AppService.getAssingRoleProfile(userId);
		dispatch(AssingRoleProfile(3));
		// if(rolesAssing.data[1].roleId === 1){
		// 	console.log('rolesAssing.data[1].roleId === 1')
		// } else  {

		// }
		// const result = await AppService.getProfiles(userId)
		// const countBeneficiary = result.data.length
		// const trueBeneficiary = countBeneficiary === 1 ? 1 : 0
		// if(trueBeneficiary === 0){
		// 	dispatch(setLoading(true))

		const resultVerifier = await AppService.getProfileVerifier(userId);

		dispatch(setDataUserMainVerifier(resultVerifier.data));
		// 	const countVerifier = resultVerifier.data.length
		// 	const trueVerifier = countVerifier === 1 ? 2 : 0

		// 	dispatch(setVerifierId(trueVerifier))
		// } else if (trueBeneficiary === 1){

		// 	dispatch(setBeneficiaryId(trueBeneficiary))
		// // }
	} catch (error) {
		console.log("MODAL", error);
	} finally {
		dispatch(setLoading(false));
	}
};
export const getProfile = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));

		const store = await getStore();
		const {
			app: {
				isLoginUser: { uid },
			},
		} = store;
		const user = await UserService.getUser(uid);
		const userId = user.data[0].id;
		await AppService.getProfiles(userId);
		dispatch(setLoading(true));
		if (localStorage["role"] === "2") {
			dispatch(setLoading(true));
			const resultVerifier = await AppService.getProfileVerifier(userId);
			dispatch(setDataUserMainVerifier(resultVerifier.data));
			dispatch(setVerifierId(2));
		}
		// } else if (trueBeneficiary === 1){
		// 	dispatch(setBeneficiaryId(trueBeneficiary))
		// }
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const getVerifierData = () => async (dispatch, getStore) => {
	try {
		const {
			user: { user },
		} = getStore();
		const resultVerifier = await AppService.getProfileVerifier(user.user[0].id);
		dispatch(setDataUserMainVerifier(resultVerifier.data));
	} catch (e) {
		console.log(e);
	}
};

export const setUserMain = () => async (dispatch, getStore) => {
	try {
		dispatch(setModal(""));
		dispatch(setLoading(true));
		const store = await getStore();
		const {
			app: { isLoginUser },
		} = store;
		delete localStorage["role"];
		let newUid =
			isLoginUser.length > 0 ? isLoginUser[0].uidAuth : isLoginUser.uid;

		const user = await UserService.getUser(newUid);

		const userId = user.data[0].id;
		const result = await AppService.getAssingRole(userId);

		if (result.data[0].roleId === 1) {
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.errorJoinMain"),
				}),
			);
		} else {
			await AppService.setUserMainRole(userId);
			dispatch(
				setMessage({
					name: "success",
					message: t("messagesResponse.successJoinMain"),
				}),
			);
			dispatch(setBeneficiaryId(null));
			dispatch(setVerifierId(null));
		}
		dispatch(setIsUserMain(true));
		window.location.assign("/inicio");
	} catch (e) {
		console.log(e);
		dispatch(setMessage("errorJoinMain"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};
export const getLifeGuide = () => async (dispatch) => {
	try {
		dispatch(setComponentLoading(true));
		const lifeGuides = await AppService.getLifeGuides();
		dispatch(setLifeGuide(lifeGuides.data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const setLifeGuide = (value) => ({ type: CONST.LIFE_GUIDES, value });

export const getLanguage = () => async (dispatch) => {
	try {
		const language = await AppService.getLanguages();
		dispatch(setLanguage(language.data));
	} catch (e) {
		console.log(e);
	}
};

export const handleLogout = () => (dispatch) => {
	delete localStorage["token"];
	delete localStorage["role"];
	delete localStorage["session"];
	delete localStorage["firebaseToken"];
	firebase
		.auth()
		.signOut()
		.then((_) => {
			dispatch(autorizeUser(false));
			dispatch(setVerifierId(null));
			dispatch(setBeneficiaryId(null));
			dispatch(setIsUserMain(false));
		})
		.catch((error) => {
			console.log(`error ${error.code} ${error.message}`);
		});
};

export const autorizeUser = (value) => ({
	type: CONST.AUTHORIZER_USER,
	value: value,
});

export const setUser = (value) => (dispatch) => {
	try {
		const role = parseInt(localStorage.getItem("role"));
		if (role === 1) {
			dispatch(setBeneficiaryId(1));
		} else if (role === 2) {
			dispatch(setVerifierId(2));
		}
		dispatch(autorizeUser(value));
	} catch (e) {
		console.log(e);
	}
};
