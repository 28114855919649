export default {
  isAppLoading: false,
  isComponentLoading: false,
  isLoginUser: false,
  formBuy: false,
  arrow: true,
  eventsPage: false,
  error: '',
  modalTermsAndConditions: false,
  modalVideo: false,
  message: '',
  language: [],
  lifeGuides: [],
  modal: '',
  userIdBeneficiaryData: '',
  userIdVerifierData: '',
  dataVerifierUserMain: [],
  dataUserRoles: [],
  dataRecommendations: [],
  adviceRecommendations: [],
  adviceRecommendationsFilter: [],
  adviceRecommendationsSelected: [],
  recommendationsSelected: [],
  recommendationEventSelected: [],
  recommendationAdviceSelected: [],
  joinUserPrincipal: false,
};
