export const MESSAGE_TYPES = 'MESSAGE_TYPES'
export const EVENT_TYPES = 'EVENT_TYPES'
export const EVENT_TYPES_ALL = 'EVENT_TYPES_ALL'
export const MESSAGE_PREVIEW = 'EVENT_TYPES'
export const SAVE_DRAFT = 'saveDraft'
export const DRAFT_LOCALE_STOREGE = 'DRAFT_LOCALE_STOREGE'
export const DRAFT_LOCALE_STORAGE_DETAIL = 'DRAFT_LOCALE_STORAGE_DETAIL'
export const FILE_TYPES = 'FILE_TYPES'
export const SELECTED_BENEFICIARIES = 'SELECTED_BENEFICIARIES'
export const SET_MESSAGE_BY_USER = 'SET_MESSAGE_BY_USER'
export const SET_MESSAGE_EVENTS_BY_USER = 'SET_MESSAGE_EVENTS_BY_USER'
export const SET_MESSAGE_ADVICE_BY_USER = 'SET_MESSAGE_ADVICE_BY_USER'
export const ADVICE_TYPES = 'ADVICE_TYPES'
export const ADVICE_TYPES_ALL = 'ADVICE_TYPES_ALL'


