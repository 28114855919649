/*Dependencies */
import React, { Component, Fragment } from "react";
/* i18n */
import { t } from "../../i18n";
/* Components*/
import FilesMessage from "../../components/FilesMessage";
import MessageResponse from "../../components/MessageResponse";

/* Form */
import PictureMessageForm from "../../form/ImageMessage";

class PictureMessageEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActivedUpload: false,
			isActived: false,
		};
	}

	toggleIsActived = () =>
		this.setState({ isActivedUpload: !this.state.isActivedUpload });
	render() {
		const { message } = this.props;
		return (
			<Fragment>
				<FilesMessage
					withHoutRecomendations
					title={t("pictureMessage.title")}
					notBox
				>
					<PictureMessageForm history={this.props.history} />
				</FilesMessage>
				{message === "setMessageUserSucces" && <MessageResponse isActived />}
				{message === "setMessageUserError" && (
					<MessageResponse
						isActived
						bgAlert='red'
						message={t("messagesResponse.notSpace")}
					/>
				)}
			</Fragment>
		);
	}
}

export default PictureMessageEdit;
