
 const configFirebase = {
  apiKey: 'AIzaSyBcBpg5k3cwZGJkbQpw0mC_Gj49NnStP8A',
  authDomain: 'astral-reef-233515.firebaseapp.com',
  databaseURL: 'https://astral-reef-233515.firebaseio.com',
  projectId: 'astral-reef-233515',
  storageBucket: 'astral-reef-233515.appspot.com',
  messagingSenderId: '619945873981'
}


export default configFirebase