//Dependencies
import React, { Component } from 'react';
//Styled component
import { Container } from './styles';
// Assets
// import img from '../../assets/images/1.png';

class Carrousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imgs: props.lifeGuides,
			index: 0,
		};
	}

	componentDidMount() {
		this.interval = setInterval(this.nextProperty, 5000);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	nextProperty = () => {
		const newIndex = this.state.index + 1;
		this.setState({
			index: newIndex > this.state.imgs.length - 1 ? 0 : newIndex,
		});
	};
	prevProperty = () => {
		const prevIndex = this.state.index - 1;
		this.setState({
			index: prevIndex <= 0 ? 0 : prevIndex,
		});
	};

	render() {
		const { title } = this.props;
		const { imgs, index } = this.state;
		return (
			<Container>
				<div>
					{title && <h2 className='title'>{title}</h2>}

					<div className='btns'>
						<div className='left btn' onClick={this.prevProperty}>
							<i className='fas fa-chevron-left' />
						</div>
						<div className='right btn' onClick={this.nextProperty}>
							<i className='fas fa-chevron-right' />
						</div>
					</div>
					<img src={imgs.length ? imgs[index].imageUrl : ''} alt='' />
					<div className='bxCaption'>
						<p>
							<span
								dangerouslySetInnerHTML={{
									__html: imgs.length ? imgs[index].description : '',
								}}
							/>
						</p>
					</div>
				</div>
				<div className='bxPager'>
					<span />
				</div>
				<div className='link' />
			</Container>
		);
	}
}

export default Carrousel;
