import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	margin-right: -130px;
	margin-left: -130px;
	margin-bottom: 60px;
	margin-top: 29px;		
	.buttonBeneficiary {
		float: right;
		width: 90px;
		margin-bottom: 20px;
	}
	.packageAlert {
		background: #df382f;
		color: #fff;
		font-size: 14px;
		padding: 10px;
		margin: 0 auto;
	}
	.btnBuy {
		width: 140px !important;
		padding: 0 !important;
		margin: 0 29%;
	}
	.txtBlue {
		margin-left: 10px;
		color: #211261;
	}
	${media.lessThan('large')`    
  margin-right: -5px;
	margin-left: -5px;		
  `}
	${media.lessThan('medium')`    
    grid-template-columns: 1fr;
  `}
`
export const BtnRight = styled.div`
	width: 130px;
	height: 155px;
	position: fixed;
	right: -5px;
	top: 170px;
	z-index: 10;
	${media.greaterThan('medium')`    
    top: 267px;
  `}
`
export const ForgotPasswordModal = styled.div`
  margin: 63px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  form {
    > h3,
    span {
      color: #211261;
    }
    img {
    }
    > h3 {
      font-size: 19px;
      line-height: 21px;
      margin: 10px 0;
      font-family: inherit;
      font-weight: bold;
    }
    > p {
      color: #333;
      font-size: 13px;
    }
    > input {
      margin: 30px 0;
      width: 100%;
    }
    > div {
      margin-right: 19px;
    }
  }
`

export  const Card = styled.div`
background: rgba(255, 255, 255, 0.75);
	text-align: center;
	padding: 33px 20px;
	text-align: center;
	border-radius: 12px;
	max-height: 313px;
	display: flex;
	flex-direction: column;
	transition: all 0.5s ease-in-out;
	position: relative;
	overflow-y: auto;
	h2 {
		color: #342573;
		font-size: 30px;
		font-weight: 900;
		line-height: 34px;
		text-align: center;
		margin-bottom: 0;
		cursor: pointer;
    transition: all .4s;
    margin-top: 0;
    margin-bottom: 22px;
		:hover {
			font-size: 31px;			
		}
  }
  .content {
    width: 100%;
		height: 100%;
		padding-right: 10px;
    overflow-x: hidden!important;
  }
  .container-button {
    margin-top: 10px;
  }

`