// Dependecies
import media, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = media.div`  
    width: 100%;
    margin-bottom: 0;
    border-radius: 4px;
    background-color: #211261;
    cursor: pointer;           
    &:hover {
        background-color: #892b6e;
    }
    transition: background .4s linear 0s;
    .block-question {
      width: 100%;      
      color: #FFF;
      display: inline-block;
      padding: 10px 15px;
      font-size: 17px;      
      cursor: pointer;            
    }
    .collapsel {      
      background-color: #fff;
      padding: 15px;
      display: none; 
      white-space: pre-wrap; 
      p {
        font-size: 17px;    
      }        
      ${ifProp(
				'isActived',
				css`
					display: block;
					animation-name: fadeOut 3s;
				`
			)}
    }            
  }
  
`
