import styled from 'styled-components'
import media from 'styled-media-query' 

export const ContainerVideo = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  color: #eee;
  font-size: 0.8rem;
  .video {
    width: 100%;
    z-index: 11;
    min-height: 550px;
    ${media.lessThan('small')`                 
      min-height: 195px;        
    `}
    ${media.lessThan('medium')`                 
  min-height: 350px;        
    `}
  }
`