// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
// i18n
import { t } from "../../i18n";
// const routes
import { routes } from "../../routes";
// Components
import Message from "../../components/Message";
import Button from "../../components/Button";

// Styles
import { Container, Description, ButtonRight, ButtonsSeeDraft } from "./styles";
// Assets
import img from "../../assets/images/male-user.png";
import {
	getDraftLocaleStoregeBySlug,
	deleteMessage,
	getSelectMessage,
} from "../../redux/messages/actions";
import moment from "moment";
import { Facebook } from "react-content-loader";

class SeeDraft extends Component {
	constructor(props) {
		super(props);
		this.state = {
			textMessage: "",
			body: "",
		};
	}
	async componentDidMount() {
		const {
			match: {
				params: { slug },
			},
			getDraftLocaleStoregeBySlug,
			getSelectMessage,
		} = this.props;
		if (parseInt(slug)) {
			getSelectMessage(parseInt(slug));
		} else {
			getDraftLocaleStoregeBySlug(slug);
		}
	}
	async componentDidUpdate(prevProps) {
		if (
			this.props.draftLocaleStorage !== prevProps.draftLocaleStorage &&
			this.props.draftLocaleStorage &&
			this.props.draftLocaleStorage.messageType &&
			(this.props.draftLocaleStorage.messageType.code === "written" ||
				(this.props.draftLocaleStorage.messageFiles[0] &&
					(this.props.draftLocaleStorage.messageFiles[0].fileTypeId === 1 ||
						this.props.draftLocaleStorage.messageFiles[0].fileTypeId === 2)))
		) {
			try {
				const fileHtml = this.props.draftLocaleStorage.messageFiles.find(
					(el) => el.ext === ".html",
				);
				const body = this.props.draftLocaleStorage.messageFiles.find(
					(el) => el.ext !== ".html",
				);
				this.setState({
					body,
				});
				if (!fileHtml) {
					return;
				}
				let url = "https://proxy.cors.sh/";
				const { data } = await axios({
					method: "GET", //you can set what request you want to be
					url: url + fileHtml.fileUrl,
					headers: {
						"x-cors-api-key": "temp_366c37f9d20ece510130f18dcb137cd1",
					},
				});
				this.setState({
					textMessage: data,
				});
			} catch (e) {
				console.log(e);
			}
		}
	}

	typeRedirec = () => {
		let route = "";

		if (
			(this.props.draftLocaleStorage.messageType.code === "event" &&
				this.props.draftLocaleStorage &&
				this.props.draftLocaleStorage.messageFiles &&
				this.props.draftLocaleStorage.messageFiles.length) ||
			(this.props.draftLocaleStorage.messageType.code === "advice" &&
				this.props.draftLocaleStorage &&
				this.props.draftLocaleStorage.messageFiles &&
				this.props.draftLocaleStorage.messageFiles.length)
		) {
			if (!this.props.draftLocaleStorage.messageFiles.length) {
				return route;
			}

			switch (this.props.draftLocaleStorage.messageFiles[0].fileTypeId) {
				case 3:
					route = "PICTURE_MESSAGE";
					break;
				case 5:
					route = "VIDEO_MESSAGE";
					break;
				case 6:
					route = "VIDEO_MESSAGE";
					break;
				case 4:
					route = "AUDIO_MESSAGE";
					break;
				case 1:
					route = "WRITTEN_MESSAGE";
					break;
				case 2:
					route = "WRITTEN_MESSAGE";
					break;
				default:
					route = "PICTURE_MESSAGE";
					break;
			}
			return route;
		}
		if (
			this.props.draftLocaleStorage.messageType &&
			this.props.draftLocaleStorage.messageType.code
		) {
			switch (this.props.draftLocaleStorage.messageType.code) {
				case "img":
					route = "PICTURE_MESSAGE";
					break;
				case "video":
					route = "VIDEO_MESSAGE";
					break;
				case "audio":
					route = "AUDIO_MESSAGE";
					break;
				case "written":
					route = "WRITTEN_MESSAGE";
					break;
				default:
					route = "PICTURE_MESSAGE";
					break;
			}
			return route;
		}
	};
	redirectToTarget = () =>
		this.props.history.push(
			`${routes[this.typeRedirec()]}/${this.props.draftLocaleStorage.id}`,
		);

	render() {
		const { draftLocaleStorage, user, deleteMessage } = this.props;
		return (
			<Message record>
				<Container>
					<ButtonsSeeDraft>
						<Link to={routes.RECORD}>
							<Button onClick={deleteMessage} bgColorHover='#892B6E'>
								{t("buttons.deleteMessage")}
							</Button>
						</Link>

						<Link to={routes.RECORD}>
							<Button
								border
								borderColor='red'
								txtColor='red'
								bgBorderHover='gray'
							>
								{t("buttons.return")}
							</Button>
						</Link>
						<a href='#nouse' onClick={this.redirectToTarget}>
							<Button
								border
								borderColor='red'
								txtColor='red'
								bgBorderHover='gray'
							>
								{t("buttons.editMessage")}
							</Button>
						</a>
					</ButtonsSeeDraft>
				</Container>
				<Description>
					<div className='imgUser'>
						<div className='circleImg'>
							<img className='imgContent' src={img} alt='user-profile' />
						</div>
						<div className='msmInfo'>
							<div>
								<h2>
									{user.length > 0 &&
										`${user[0].firstName} ${user[0].lastName}`}
								</h2>
								<h3>
									<em>{draftLocaleStorage.title}</em>
								</h3>
							</div>
							<div className='infoSpace'>
								<h3>
									{t("seeDraft.usedSpace")}:{" "}
									{draftLocaleStorage.messageFiles !== undefined &&
									draftLocaleStorage.messageFiles.length > 0
										? `${(
												draftLocaleStorage.messageFiles.reduce((acc, cur) => {
													return acc + cur.size;
												}, 0) / 1000000
										  ).toFixed(2)} MB`
										: "1 MB"}
								</h3>
								<br />
								<span className='text-left'>
									{t("seeDraft.creationDate")}:{" "}
									{moment(draftLocaleStorage.createdAt).format("L")}
									<br />
								</span>
								<span className='text-left'>
									{t("seeDraft.displayDate")}:{" "}
									{draftLocaleStorage.byAbsence
										? t("fields.absances")
										: moment(draftLocaleStorage.visibleData).format("L")}
									<br />
								</span>
							</div>
						</div>
					</div>

					<div className='txt'>
						<div className='topBlue' />
						{((draftLocaleStorage.messageType &&
							draftLocaleStorage.messageType.code === "img") ||
							(draftLocaleStorage.messageFiles &&
								draftLocaleStorage.messageFiles.length > 0 &&
								draftLocaleStorage.messageFiles[0].fileTypeId === 3)) &&
							draftLocaleStorage.messageFiles.map((item, index) => {
								if (item.fileUrl) {
									return (
										<img
											key={index}
											className='container-media'
											src={item.fileUrl}
											alt='beneficiary'
										/>
									);
								} else {
									return <span>{t("messagesResponse.uploadingImage")}</span>;
								}
							})}

						{((draftLocaleStorage.messageType &&
							draftLocaleStorage.messageType.code === "written") ||
							(draftLocaleStorage.messageFiles &&
								draftLocaleStorage.messageFiles.length > 0 &&
								(draftLocaleStorage.messageFiles[0].fileTypeId === 1 ||
									draftLocaleStorage.messageFiles[0].fileTypeId === 2) &&
								(draftLocaleStorage.messageType.code === "event" ||
									draftLocaleStorage.messageType.code === "advice"))) && (
							<>
								{draftLocaleStorage.messageFiles && this.state.body && (
									<a
										href={
											this.state.body
												? this.state.body.fileUrl
												: "https://fluie.com/"
										}
										download={
											this.state.body
												? this.state.body.fileUrl
												: "https://fluie.com/"
										}
									>
										<Button bgColorHover='#ff8400'>
											{(this.state.body && this.state.body.name) ||
												t("messagesResponse.attachedFile")}
											<i className='fas fa-cloud-download-alt' />
										</Button>
									</a>
								)}
								{draftLocaleStorage.messageFiles.find(
									(el) => el.ext === ".html",
								) ? (
									this.state.textMessage ? (
										<div
											className='description'
											dangerouslySetInnerHTML={{
												__html: this.state.textMessage,
											}}
										/>
									) : (
										<Facebook
											style={{ marginTop: "20px" }}
											height={80}
											width={280}
											speed={2.5}
											primaryColor='
						rgba(118, 35, 106, .8)
						'
											secondaryColor='rgba(251, 133, 25, .8)'
										/>
									)
								) : (
									t("messagesResponse.uploadingImage")
								)}
							</>
						)}
						{((draftLocaleStorage.messageType &&
							draftLocaleStorage.messageType.code === "video") ||
							(draftLocaleStorage.messageFiles &&
								draftLocaleStorage.messageFiles.length > 0 &&
								(draftLocaleStorage.messageFiles[0].fileTypeId === 5 ||
									draftLocaleStorage.messageFiles[0].fileTypeId === 6))) && (
							<>
								{draftLocaleStorage.messageFiles[0] &&
								draftLocaleStorage.messageFiles[0].fileUrl ? (
									<video
										controls
										className='container-media'
										src={
											draftLocaleStorage.messageFiles[0] &&
											draftLocaleStorage.messageFiles[0].fileUrl
										}
										alt='beneficiary'
									/>
								) : (
									t("messagesResponse.uploadingImage")
								)}
							</>
						)}
						{((draftLocaleStorage.messageType &&
							draftLocaleStorage.messageFiles.length > 0 &&
							draftLocaleStorage.messageType.code === "audio") ||
							(draftLocaleStorage.messageFiles &&
								draftLocaleStorage.messageFiles.length > 0 &&
								draftLocaleStorage.messageFiles[0].fileTypeId === 4 &&
								(draftLocaleStorage.messageType.code === "event" ||
									draftLocaleStorage.messageType.code === "advice"))) && (
							<div>
								{draftLocaleStorage.messageFiles[0].fileUrl ? (
									<audio controls>
										<source
											src={draftLocaleStorage.messageFiles[0].fileUrl}
											type='audio/wav'
										/>
									</audio>
								) : (
									t("messagesResponse.uploadingImage")
								)}
							</div>
						)}
						<div className='separatorbottom' />
					</div>
				</Description>
				<ButtonRight>
					{/* <Button bgColorHover='#892B6E'>{t('buttons.downloadMessage')}</Button> */}
				</ButtonRight>
			</Message>
		);
	}
}

function mapStateToProps(store) {
	const home = store.homes;
	const messages = store.messages;
	return {
		about: home ? home[0].about : [],
		draftLocaleStorage: messages.draftLocaleStorage || [],
		user: store.user.user || [],
		messagesByUser: messages.messageByUser.data || null,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getDraftLocaleStoregeBySlug: (slug) =>
			dispatch(getDraftLocaleStoregeBySlug(slug)),
		deleteMessage: () => dispatch(deleteMessage()),
		getSelectMessage: (slug) => dispatch(getSelectMessage(slug)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeDraft);
