// Dependencies
import React from 'react';
// Styleds components
import { Container } from './styles';

function ItemBuyForm({ name, price, isSelected, isActived, dateFinish }) {
  return (
    <>
      <Container onClick={isSelected} isActived={isActived}>
        <h4>{name}</h4>
        <b>USD {price}</b>
      </Container>
      <div>
        <b>{dateFinish}</b>
      </div>
    </>
  );
}

export default ItemBuyForm;
