/* Dependencies */
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
/* Actions */
import { changeAbsenceUserMain } from '../../redux/user/actions';

const mapStateToProps = ({ user: { absenceUserSelected, user }, app }) => {
	let values = {};
	const [userVerifier] = user;
	console.log('----user', user);
	const userId = user[0].id;

	const { id: verifierId } = userVerifier;
	let getRoleLogin = () => parseInt(localStorage['role']);

	if (absenceUserSelected.mainUser) {
		const {
			mainUser: { firstName, id: userId },
		} = absenceUserSelected;
		values = {
			firstName,
			userId,
			role: getRoleLogin(),
			approved: false,
		};
	}
	return {
		...values,
		firstName:
			app.dataVerifierUserMain.length > 0
				? app.dataVerifierUserMain[0].mainUser.firstName
				: 'Usuario Principal',
		userId: userId ? userId : 'Usuario Principal',
		verifierId,
		role: getRoleLogin(),
		approved: false,
	};
};

const mapDispachtToProps = (dispatch) => {
	return {
		onSubmit: (userId, verifierId, approved, role) =>
			dispatch(changeAbsenceUserMain(userId, verifierId, approved, role)),
	};
};

export default compose(
	connect(mapStateToProps, mapDispachtToProps),
	reduxForm({
		form: 'changeAbsence',
	}),
);
