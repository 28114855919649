import React from 'react';
import { Menu } from './styles';
function MenuButton(props) {
  return (
    <Menu onClick={props.action}>
      <i className="fas fa-bars" />
    </Menu>
  );
}

export default MenuButton;
