import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// routes
import { routes } from '../../routes';
// Component
import Base from '../../components/Base';
import Carrousel from '../../components/Carrousel';
import Button from '../../components/Button';

// Styled component
import { Container } from './styles';
// Assets
import img from '../../assets/images/1.png';
//i18n
import { t } from '../../i18n';
import AppService from '../../redux/app/AppService';

function WhatIsIt() {
	const [lifeGuides, setLifeGuides] = useState([]);
	const [isComponentLoading, setIsComponentLoading] = useState(false);

	const _getData = async () => {
		setIsComponentLoading(true);
		try {
			const response = await AppService.getLifeGuides();
			setLifeGuides(response.data);
			setIsComponentLoading(false);
		} catch (e) {
			setIsComponentLoading(false);

			console.log('e', e);
		}
	};

	useEffect(() => {
		_getData();
	}, []);

	return (
		<Base imgBackground={img} noMargin>
			<h1 className='title'>{t('whatIsIt.Title')}</h1>
			<span className='text-separator-top' />
			{isComponentLoading === true ? (
				<div className='loader-container'>
					{/* <Facebook
              speed={2.5}
              primaryColor='#a3a09e'
              secondaryColor='#6d6965'
            /> */}
				</div>
			) : (
				<Container>
					{/* {
      lifeGuides.length && <div className='container-slider'>
              <Carrousel lifeGuides={lifeGuides} link='/event-message' />
              <Link to={routes.EVENT_MESSAGE} className='link'>
                <Button>{t('whatIsIt.CreateEventButton')}</Button>
              </Link>
            </div>
      } */}
					{lifeGuides.length > 0 &&
						lifeGuides.map((lifeGuide, index) => (
							<div key={index}>
								<Carrousel
									title={lifeGuide.name}
									lifeGuides={lifeGuide.lifeGuides}
									link='/event-message'
								/>
								<Link
									to={!index ? routes.EVENT_MESSAGE : routes.TIPS_MESSAGE}
									className='link'
								>
									<Button>
										{!index
											? t('whatIsIt.CreateEventButton')
											: t('whatIsIt.CreateAdvice')}
									</Button>
								</Link>
							</div>
						))}
				</Container>
			)}
		</Base>
	);
}

export default WhatIsIt;
