import initialState from './initialState'
import { SET_TUTORIALS, TUTORIALS_BY_SLUG } from './const'

export default function tutorials(state = initialState, action) {
	switch (action.type) {
		case TUTORIALS_BY_SLUG:
			return Object.assign(
				{},
				{
					...state,
					tutorialsBySlug: action.value
				}
			)
		case SET_TUTORIALS:
			return Object.assign(
				{},
				{
					...state,
					tutorials: action.value
				}
			)
		default:
			return state
	}
}
