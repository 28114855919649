// Dependencies
import styled from 'styled-components'
import media from 'styled-media-query'
// assets
import ahorranito from '../../assets/images/imaginamos.png'

export const Container = styled.footer`
	width: 100%;
	bottom: 0;	
	padding: 0 8px;	
	position: relative;
	z-index: 10;
	background-color: #ddd;
	display: grid;
	grid-template-columns: 4fr 1fr;
	span {
		cursor: pointer;
	}
	@media only screen and (max-height: 568px) {
		bottom: -74px;
	}
	${media.lessThan('medium')`
  grid-template-columns:  1fr;  
    `}

	.footerText {
		width: 100%;
		height: auto;
		padding: 9px 0;
		font-size: 14px;

		a:hover, span:hover {
			color: #211261;
		}
	}
	.redesSociales {
		width: 155px;
		line-height: 25px;
		display: inline-block;
		margin-bottom: 10px;
		margin: auto;
		margin-right: 0;
		display: flex;
		align-items: center;
		height: 38px;
		span {
			height: 25px;
			line-height: 25px;
			font-size: 11px;
		}

		> a {
			color: #ff8400;
			margin-left: 10px;
			font-size: 24px;
			&:hover {
				color: #892b6e;
			}
		}
		${media.lessThan('medium')`  
  grid-column: 1;
    `}
	}
	a,
	link {
		color: #333;
		text-decoration: none;
	}
	.foot {
		overflow: hidden;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 0.3rem;
		background: #342573;				
		margin: 0;
		margin: 0px -8px;
		grid-column: 1 / 3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;		
		${media.lessThan('medium')`
  grid-template-columns:  auto;  
  padding-bottom: 1rem;
    `}
	}

	.reserved {
		color: #fff;		
		font-size: 14px;
		font-weight: 700;		
	}

	.imaginamos {
		margin-top: 9px;
		font-size: 14px;
		font-weight: 500;		
		text-align: center;
		span {
			color: #fff;
		}
		a {
			display: flex;
			text-align: center;
		}
		.ahorranito {
			background-image: url(${ahorranito});
			background-size: cover;
			height: 19px;
			width: 105px;
			background-repeat: no-repeat;
			margin: 0 auto;
		}
	}
`
