/* Dependencies */
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import media from 'styled-media-query'

export const Container = styled.div`  	
  width: 456px;	
  opacity: 0.85;	
  border-radius: 20px;	
  background-color: #FFFFFF;	
  box-shadow: 0 10px 14px 0 rgba(155,155,155,0.5);
  display: flex;  
  position: absolute;
  z-index: 8;
  top: 123px;  
  right: 120px;    
  text-align: center;  
  box-sizing: border-box;  
  animation: fadeInRight 1s ease-out;        	
  }  
  ${ifProp(
		'isActiveLogin',
		css`
			display: flex;
		`
	)}
	${media.lessThan('medium')`                 
		width: 95%;
		left: 2.5%;
  `}
`

export const BoxLogin = styled.div`
	width: 94%;
	overflow-y: auto;
	margin: 0 auto;
	/*background: rgba(255, 255, 255, 0.4);
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);*/
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-content: center;
	.message {
		display: none;
		${ifProp(
			'visible',
			css`
				display: block;
			`
		)}
	}
	.containerClose {
		width: 100%;
		height: 20px;
		margin-top: 7px;
		background-color: transparent;
		border: transparent;
		display: block;
		display: none;
		> button {
			width: 16px;
			height: 16px;
			float: left;
			margin-left: 10px;
			color: black;
			font-size: 1.4rem;
			background-color: transparent;
			border: 1px solid transparent;
			outline: none;
			cursor: pointer;
		}
	}
	.containerform {
		width: 90%;
		max-width: 320px;
		margin: 0 auto;
		.text {
			width: 100%;
			text-align: center;			
			> h1 {
				margin-top: 20px;
				color: #342573;
				font-size: 30px;
				font-weight: 1200;
				line-height: 34px;
			}
			> p {
				font-size: 18px;
				color: #55535e;
				line-height: 21px;
			}
		}
	}
	.form {
		margin-top: 46px;
		width: 90%;
		margin: auto;
		.formInput {
			margin-top: 45px;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
		.enterForm {
			display: block;
			/*justify-content: space-between;*/
			color: #fff;
			font-size: 13px;
			width: 100%;
			> span {
				cursor: pointer;
				margin-top: 10px;
				text-decoration: underline;
				color: red;
			}
			button {
				width: 63%;
				height: 50px;
				background-color: #f84e06;
				margin-bottom: 10px;
			}			
		}
		.button-full {
			width: 100%!important;
    	font-size: 14px;
		}
	}
	.findUs {		
		p {
			color: #55535e;
			font-size: 18px;
			line-height: 21px;
			margin: 0;
		}
	}
	.play {
		height: 68px;
		width: 68px;
	}
`
export const ContainerButtonsRegister = styled.div`	
	display: flex;
	flex-direction: column;	
	> a,
	button {		
		padding: 12px 0;
		border-radius: 35px;
		line-height: 14px;
		.min-text {
			font-size: 11px;
		}
	}
`
