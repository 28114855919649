// Dependencies
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ifProp } from 'styled-tools'
// Assets
import arrow from '../../assets/images/arrow.png'
import verifiers from '../../assets/images/verifiers.png'

export const Container = styled.div`
	top: 80px;
	width: 100%;
	height: calc(100% - 80px);
	position: fixed;
	right: 0;
	overflow-x: hidden;
	background: #fff;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
	padding: 0 10px;
	padding-bottom: 15px;
	display: none;
	animation: fadeInRight 0.4s;
	z-index: 11 !important;
	button {
		width: 92%;
		margin: 0 auto;
		padding: 13.5px 7px;
		font-size: 13px;
		font-weight: bold;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		> img {
			margin-left: 5px;
		}
	}
	${ifProp(
		'isActive',
		css`
			display: block;
		`
	)}
	.titleFormRight {
		height: 40px;
		margin: 20px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.iconForm {
			height: 55px;
			width: 55px;
			background-image: url(${verifiers});
			background-size: contain;
		}
		h3 {			
			color: #76236a;
		}
	}
	.close {
		width: 10%;		
		cursor: pointer;
		span {
			width: 15px;
			height: 15px;
			float: right;
			background: url(${arrow});
			background-repeat: no-repeat;
		}
	}
	${media.greaterThan('small')`
    max-width: 340px;
    `}
`
