import { routes } from '../routes/const'

export const languages = [
	{
		name: 'es',
		description: 'ES',
		id: 1
	},
	{
		name: 'en',
		description: 'ENG',
		id: 2
	}
]

export const langKey = 'lang'
export const defaultLanguage = 'en'
export const langId = 'id'
export function t(path) {
	const currentLanguage = localStorage[langKey] || defaultLanguage
	const translation = require(`./${currentLanguage}.json`)
	return pathToValue(path, translation)
}
function pathToValue(path = '', obj) {
	const arr = path.split('.') || []
	return arr.reduce((r, p) => r[p], obj)
}
export const getLanguageById = () => localStorage[langId] || 2

export const filterByLanguage = JSON.stringify({
	where: {
		languageId: getLanguageById()
	}
})

export const getLanguage = () => localStorage[langKey] || defaultLanguage
export const setLanguage = (lang, id, path) => {
	if (lang === getLanguage()) return
	const previosLang = routes[getLanguage()]
	const route = Object.keys(previosLang).find(key => previosLang[key] === path)
	localStorage[langKey] = lang
	localStorage['id'] = parseInt(id)
	window.location = routes[lang][route] || '/'
}
