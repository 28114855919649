/* Dependencies */
import React, { Component } from 'react';

/* i18n */ import { t } from '../../i18n';
/* Components */
import FilesMessage from '../../components/FilesMessage';
import ModalFeedBack from '../../components/ModalFeedBack';

import AudioMessageForm from '../../form/AudioMessageEdit';

class AudioMessageEdit extends Component {
  state = {
    isShowModal: true,
  };
  render() {
    const {
      match: {
        params: { messageId },
      },
      history: { push },
    } = this.props;
    return (
      <>
        <FilesMessage
          withHoutRecomendations
          title={t('audioMessage.title')}
          notBox
        >
          <AudioMessageForm slug={messageId} push={push} />
        </FilesMessage>
        {this.state.isShowModal && (
          <ModalFeedBack
            toggle={() =>
              this.setState({
                isShowModal: false,
              })
            }
            title={t('home.modal.confirm.confirm')}
            description={t('home.modal.confirm.messageAudio')}
          />
        )}
      </>
    );
  }
}

export default AudioMessageEdit;
