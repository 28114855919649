import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	width: 100%;
	padding: 5px 0;
	margin: 0 auto;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	justify-items: center;
	h4,
	b {
		color: #898989;
		margin: 0;
	}
	b {
		font-size: 16px;
		line-height: 18px;
		font-weight: 700;
		margin: auto;
	}
	${ifProp(
		'isActived',
		css`
			h4,
			b {
				color: #fb8519;
			}
		`
	)}
`
