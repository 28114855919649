/* Dependencies */
import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import VideoRecorder from 'react-video-recorder';
/* i18n */
import { t } from '../../i18n';
/* Components */
import Button from '../../components/Button';
import TypeFileUpload from '../../components/TypeFileUpload';

import InputRedux from '../../fields/Input';
import SelectRedux from '../../fields/SelectInput';
import FileInput from '../../fields/FileInput';
/* Styles */
import { ModalAudio, Form, ContainerVideo } from './styles';
/* Assets */
import rec from '../../assets/images/rec-video.jpg';
import upload from '../../assets/images/upload-video.jpg';

import withStore from './store';

class VideoMessageEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			video: [],
			isSubmit: true,
			type: '',
			nameFile: '',
		};
	}

	componentDidMount() {
		const {
			slug,
			getSelectMessage,
			draftLocaleStorage,
			setSelectedBeneficiaries,
		} = this.props;
		if (this.props.initialValues.type === 'date') {
			this.setState({
				type: 'date',
			});
		}
		getSelectMessage(parseInt(slug));
		if (draftLocaleStorage && Array.isArray(draftLocaleStorage.messageFiles)) {
			const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
				(el) => ({
					label: el.beneficiary ? el.beneficiary.firstName : '',
					value: el.beneficiary ? el.beneficiary.id : '',
				}),
			);
			setSelectedBeneficiaries(beneficiaries);
		}
	}
	componentDidUpdate(prevProps) {
		const { draftLocaleStorage, setSelectedBeneficiaries } = this.props;
		if (draftLocaleStorage !== prevProps.draftLocaleStorage) {
			const { slug, getSelectMessage } = this.props;
			getSelectMessage(parseInt(slug));
			if (
				draftLocaleStorage &&
				Array.isArray(draftLocaleStorage.messageFiles)
			) {
				const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
					(el) => ({
						label: el.beneficiary ? el.beneficiary.firstName : '',
						value: el.beneficiary ? el.beneficiary.id : '',
					}),
				);
				setSelectedBeneficiaries(beneficiaries);
			}
		}
	}

	onChangeFile = (e) =>
		this.setState({
			nameFile: e.name,
			video: [{ data: e, url: URL.createObjectURL(e) }],
			isSubmit: true,
		});

	toggleItem = (item) =>
		this.setState((state) => ({
			isActivedItem: item !== state.isActivedItem ? item : '',
		}));
	onChangeAtt = (e) => {
		this.setState({
			nameFile: e.name,
			video: [{ data: e, url: URL.createObjectURL(e) }],
			isSubmit: true,
		});
	};
	onChange = (data) =>
		data &&
		this.setState({
			nameFile: data.name,
			type: data,
		});

	onChangeDate = (data) => data && this.setState({ type: data.target.value });

	render() {
		const { handleSubmit, onSubmit, uri } = this.props;
		const { video, isActivedItem, isSubmit, type, nameFile } = this.state;
		return (
			<Fragment>
				<Form
					onSubmit={
						isSubmit
							? handleSubmit((values) =>
									onSubmit({
										...values,
										fileUrl: video.length ? video[0].data : null,
									}),
							  )
							: (e) => e.preventDefault()
					}
				>
					<div className='titleAndDate'>
						<Field
							type='text'
							name='title'
							component={InputRedux}
							placeholder={t('writtenMessage.fields.title')}
						/>
						{/* <Field name='eventTypeId' component='select'>
							<option>Selecionar tipo de evento</option>
							{eventTypes.map((eventType, key) => (
								<option key={key} value={eventType.id}>
									{eventType.name}
								</option>
							))}
						</Field> */}

						<Field
							onChange={this.onChangeDate}
							name='type'
							component={SelectRedux}
							defaultValue={this.props.initialValues.type}
						>
							<option>{t('fields.selectEvents')}</option>
							<option value='absence'>{t('fields.absances')}</option>
							<option value='date'>{t('fields.dates')}</option>
						</Field>
						{type === 'date' && (
							<Field
								type='date'
								name='visibleData'
								component={InputRedux}
								placeholder={t('writtenMessage.fields.title')}
							/>
						)}
					</div>
					<div className='actionMessage'>
						<TypeFileUpload
							title={t('videoMessage.recording')}
							icon='fas fa-video'
							bgImg={rec}
							toggle={() => this.toggleItem('audio')}
						/>
						<TypeFileUpload
							title={t('videoMessage.attach')}
							icon='fas fa-play'
							bgImg={upload}
							toggle={() => this.toggleItem('file')}
						/>
					</div>
					{isActivedItem === 'audio' && (
						<ModalAudio bottom='300px' width='100%'>
							<ContainerVideo>
								<VideoRecorder
									onTurnOnCamera={() => this.setState({ isSubmit: false })}
									onStopReplaying={() => this.setState({ isSubmit: false })}
									onStartRecording={() => this.setState({ isSubmit: false })}
									onRecordingComplete={this.onChangeFile}
								/>
							</ContainerVideo>
						</ModalAudio>
					)}
					{isActivedItem === 'file' && (
						<ModalAudio>
							<label>{t('videoMessage.format')}</label>
							<Field
								nameFile={nameFile}
								component={FileInput}
								onChange={this.onChangeAtt}
								name='fileUrl'
								type='file'
								accept='.mp4,.ogg, .webm .mkv'
							/>
						</ModalAudio>
					)}
					{/* <div className='input'>
						<h4>
							{`${t('writtenMessage.previewMessage')}`}{' '}
							<span
								onClick={handleSubmit(values => {
									setMessagePreview({
										...values,
										fileUrl: video[0] ? video[0].url : ''
									})
									setModal('modalPreview')
								})}>{`${t('writtenMessage.clickhere')}`}</span>
						</h4>
					</div> */}
					<div className='container-audio-preview'>
						<video controls style={{ maxWidth: '350px' }}>
							<source src={video.url || uri} />
						</video>
					</div>
					<div className='containerBtnSubmit'>
						<Button bgColor='#DF382F' bgColorHover='#892B6E' width='50%'>
							<i className='far fa-clock' /> {t('buttons.scheduleMessage')}
						</Button>
					</div>
				</Form>
			</Fragment>
		);
	}
}

VideoMessageEdit.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default withStore(VideoMessageEdit);
