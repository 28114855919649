import React from 'react';
import { Field } from 'redux-form';

import { t } from '../../i18n';

import InputRedux from '../../fields/Input';
import InputPassword from '../../fields/InputPassword';

import Modal from '../../components/Modal';
import Button from '../../components/Button';
import logo from '../../assets/images/logo-1.png';

import img from '../../assets/images/male-user.png';

import { Content } from './styles';

import withStore from './store';

function RegisterVerifier({
	active,
	toggle,
	beneficiareName,
	userMainName,
	userMainUrl,
	handleSubmit,
	setModalTerms,
}) {
	return (
		<Modal width='40%' fullScreen active={active} toggle={toggle} zIndex={10}>
			<Content>
				<form onSubmit={handleSubmit}>
					<img src={logo} alt='logo' />
					<p>
						{t('registerVerifier.youAre')}{' '}
						<b> {t('registerVerifier.verifier')}</b> {t('registerVerifier.the')}
					</p>
					<div className='content-form'>
						<figure className='img-user'>
							<img
								src={
									(userMainUrl !== undefined &&
										userMainUrl !== 'null' &&
										userMainUrl) ||
									img
								}
								alt='user'
							/>
							<figcaption>{userMainName}</figcaption>
						</figure>
						<div className='container-input'>
							<Field
								name='code'
								component={InputRedux}
								placeholder={t('registerVerifier.code')}
							/>
							<p className='description'>
								{t('registerBeneficiare.welcome')} <b>{beneficiareName}</b>{' '}
								{t('registerVerifier.description')}
							</p>
							<Field
								type='number'
								name='phone'
								component={InputRedux}
								placeholder={t('fields.phone')}
							/>
							<Field
								type='number'
								name='mobile'
								component={InputRedux}
								placeholder={t('fields.mobile')}
							/>
							<Field
								name='optionalEmail'
								component={InputRedux}
								placeholder={t('fields.optionalEmailShort')}
							/>
							<Field
								name='password'
								component={InputPassword}
								placeholder={t('home.modal.changePassword.newPassword')}
							/>
							<Field
								name='repeat-password'
								component={InputPassword}
								placeholder={t('home.modal.changePassword.confirmPassword')}
							/>
							<div className='container-terms'>
								<Field type='checkbox' name='terms' component={InputRedux} />
								<div className='text-accept'>
									{t('register.accept')}
									<span onClick={() => setModalTerms(true)}>
										{' ' + t('menu.terms')}
									</span>
								</div>
							</div>

							<Button bgColor='#ff8400' bgColorHover='#211261'>
								{t('buttons.validVerifier')}
							</Button>
						</div>
					</div>
				</form>
			</Content>
		</Modal>
	);
}

export default withStore(RegisterVerifier);
