import INITIAL_STATE from './initialState';
import {
  USER,
  BENEFICIARIES,
  BENEFICIARY_SELECTED,
  CHECKER_SELECTED,
  CHECKERS,
  BENEFICIARIES_ID_NAME,
  MESSAGE_BENEFICIARY,
  NOTIFICATIONS,
  NOTIFICATION_SELECETED,
  INFO_RELATION_BENEFICIARE,
  INFO_RELATION_VERIFIER,
  ABSENCE_USER_SELECTED,
  USER_MAIN,
  DATA_ORDER_PAYMENT,
  DATA_SIGNATURE,
} from './const';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER:
      return Object.assign(
        {},
        {
          ...state,
          user: action.value,
        }
      );
    case BENEFICIARIES:
      return Object.assign(
        {},
        {
          ...state,
          beneficiaries: action.value,
        }
      );
    case CHECKERS:
      return Object.assign(
        {},
        {
          ...state,
          checkers: action.value,
        }
      );
    case CHECKER_SELECTED:
      return Object.assign(
        {},
        {
          ...state,
          checkerSelected: action.value,
        }
      );
    case BENEFICIARY_SELECTED:
      return Object.assign(
        {},
        {
          ...state,
          beneficiarySelected: action.value,
        }
      );
    case BENEFICIARIES_ID_NAME:
      return Object.assign(
        {},
        {
          ...state,
          beneficiariesIdName: action.value,
        }
      );
    case MESSAGE_BENEFICIARY:
      return Object.assign(
        {},
        {
          ...state,
          messagesBeneficiary: action.value,
        }
      );
    case NOTIFICATIONS:
      return Object.assign(
        {},
        {
          ...state,
          notifications: action.value,
        }
      );
    case INFO_RELATION_BENEFICIARE:
      return Object.assign(
        {},
        {
          ...state,
          infoRelationBeneficiare: action.value,
        }
      );
    case INFO_RELATION_VERIFIER:
      return Object.assign(
        {},
        {
          ...state,
          infoRelationVerifier: action.value,
        }
      );
    case NOTIFICATION_SELECETED:
      return Object.assign(
        {},
        {
          ...state,
          notificationSelected: action.value,
        }
      );
    case ABSENCE_USER_SELECTED:
      return Object.assign(
        {},
        {
          ...state,
          absenceUserSelected: action.value,
        }
      );

    case USER_MAIN:
      return Object.assign(
        {},
        {
          ...state,
          isUserMain: action.value,
        }
      );
    case DATA_ORDER_PAYMENT:
      return Object.assign(
        {},
        {
          ...state,
          dataOrderPayment: action.value,
        }
      );
    case DATA_SIGNATURE:
      return Object.assign(
        {},
        {
          ...state,
          dataSignature: action.value,
        }
      );
    default:
      return state;
  }
};
