// Dependecies
import media from 'styled-components'
// Assets
import bgPuerple from '../../assets/images/bg-purple.jpg'

export const Container = media.div`  
    width: 100%;
    border: 1px solid #CCC;
    border-radius: 4px;    
    margin-bottom: 50px;      
  .header {
    width: 100%;
    height: 80px;
    border-radius: 2px 2px 0 0;
    background: url(${bgPuerple}) center/cover;     
    padding: 18px;   
    > h3 {
    text-align: justify;
    color : #fff;
  }
  }
  
`
