import React, { Component } from 'react';
import { Field } from 'redux-form';

import { t } from '../../i18n';

import InputRedux from '../../fields/Input';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import logo from '../../assets/images/logo-1.png';

import img from '../../assets/images/male-user1.png';

import { Content } from './styles';

import withStore from './store';

class AcceptVerifier extends Component {
  componentDidMount() {
    this.props.getInfoRelationVerifier();
  }
  render() {
    const { active, toggle, userMainName, userMainUrl, handleSubmit } =
      this.props;

    return (
      <Modal width="376px" fullScreen active={active} toggle={toggle}>
        <Content>
          <form onSubmit={handleSubmit}>
            <img src={logo} alt="logo" />
            <p>
              {t('registerVerifier.youAre')}{' '}
              <b> {t('registerVerifier.verifier')}</b>{' '}
              {t('registerVerifier.the')}
            </p>
            <div className="content-form">
              <figure className="img-user">
                <img src={userMainUrl || img} alt="user" />
                <figcaption>{userMainName}</figcaption>
              </figure>
              <div className="container-input">
                <Field
                  name="code"
                  component={InputRedux}
                  placeholder={t('registerVerifier.code')}
                />
                <p>
                  {/* Recuerda que al ser beneficiario de{' '}
									<strong>{userMainName}</strong>, se sincronizan los mensajes
									junto con tus otros contactos. */}
                </p>
                <Button bgColor="#ff8400" bgColorHover="#211261">
                  {t('buttons.validBeneficiare')}
                </Button>
              </div>
            </div>
          </form>
        </Content>
      </Modal>
    );
  }
}

export default withStore(AcceptVerifier);
