/* Dependecies */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
/* Pages */
import App from '../App';
import Home from '../pages/Home';
import Tutorials from '../pages/Tutorials';
import Register from '../pages/Register';
import TutorialDetail from '../pages/TutorialDetail';
import HowDoWeDoIt from '../pages/HowDoWeDoIt';
import RestorePassword from '../pages/RestorePassword';
import About from '../pages/About';
import Contact from '../pages/Contact';

import SeeDraft from '../pages/SeeDraft';
import SeeMessage from '../pages/SeeMessage';
import Record from '../pages/Record';
import WhatIsIt from '../pages/WhatIsIt';
//libreria en EventMessage/Recorder react-mic
import TipsMessage from '../pages/TipsMessage';
import EventMessage from '../pages/EventMessage';
import VideoMessage from '../pages/VideoMessage';
//libreria en EventMessage/Recorder react-mic
import AudioMessage from '../pages/AudioMessage';

import PictureMessage from '../pages/PictureMessage';
import PictureMessageEdit from '../pages/PictureMessageEdit';
//libreria en AudioMessageEdit/Recorder react-mic

import AudioMessageEdit from '../pages/AudioMessageEdit';

import VideoMessageEdit from '../pages/VideoMessageEdit';
import WrittenMessage from '../pages/WrittenMessage';
import WrittenMessageEdit from '../pages/WrittenMessageEdit';
import SeeMessageDraf from '../pages/SeeMessageDraf';
import HistoryEvents from '../pages/HistoryEvents';
import HistoryAdvice from '../pages/HistoryAdvice';
import Profile from '../pages/Profile';

import routes from './const';

class AppRoutes extends Component {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <App
          withSession={
            <Switch>
              <Route exact path={routes.PROFILE} component={Profile} />
              <Route exact path={routes.SEE_DRAFT} component={SeeDraft} />
              <Route exact path={routes.MESSAGE} component={SeeMessage} />
              <Route exact path={routes.SEE_MESSAGE} component={SeeMessage} />
              <Route exact path={routes.RECORD} component={Record} />
              <Route exact path={routes.WHAT_IS_IT} component={WhatIsIt} />
              <Route exact path={routes.TIPS_MESSAGE} component={TipsMessage} />
              <Route
                exact
                path={routes.EVENT_MESSAGE}
                component={EventMessage}
              />
              <Route
                exact
                path={routes.VIDEO_MESSAGE}
                component={VideoMessage}
              />
              <Route
                exact
                path={routes.AUDIO_MESSAGE}
                component={AudioMessage}
              />
              <Route
                exact
                path={routes.PICTURE_MESSAGE}
                component={PictureMessage}
              />
              <Route
                exact
                path={routes.PICTURE_MESSAGE_EDIT}
                component={PictureMessageEdit}
              />
              <Route
                exact
                path={routes.AUDIO_MESSAGE_EDIT}
                component={AudioMessageEdit}
              />
              <Route
                exact
                path={routes.VIDEO_MESSAGE_EDIT}
                component={VideoMessageEdit}
              />
              <Route
                exact
                path={routes.WRITTEN_MESSAGE}
                component={WrittenMessage}
              />
              <Route
                // exact
                path={routes.WRITTEN_MESSAGE_EDIT}
                component={WrittenMessageEdit}
              />
              <Route
                exact
                path={routes.SEE_DRAFT_LOCAL}
                component={SeeMessageDraf}
              />

              <Route
                exact
                path={routes.REGISTERBENEFICIARY}
                component={Register}
              />
              <Route exact path={routes.CONTACT} component={Contact} />
              <Route exact path={routes.TUTORIALS} component={Tutorials} />
              <Route
                exact
                path={routes.HISTORY_EVENTOS}
                component={HistoryEvents}
              />
              <Route
                exact
                path={routes.HISTORY_ADVICE}
                component={HistoryAdvice}
              />
              <Route
                exact
                path={routes.TUTORIAL_DETAIL}
                component={TutorialDetail}
              />
              <Route exact path={routes.ABOUT} component={About} />
              <Route
                exact
                path={routes.HOW_DO_WE_DO_IT}
                component={HowDoWeDoIt}
              />
              <Route path={routes.REGISTER_NO_MAIN_USERS} component={Home} />
              <Route path={routes.REGISTER_VERIFIER} component={Home} />
              <Route exact path={routes.REGISTER} component={Register} />
              <Route component={Home} />
              <Route path="/reset-password" component={RestorePassword} />
            </Switch>
          }
          withoutSession={
            <Switch>
              <Route exact path={routes.TUTORIALS} component={Tutorials} />
              <Route exact path={routes.REGISTER} component={Register} />
              <Route
                exact
                path={routes.TUTORIAL_DETAIL}
                component={TutorialDetail}
              />
              <Route exact path={routes.ABOUT} component={About} />
              <Route
                exact
                path={routes.HOW_DO_WE_DO_IT}
                component={HowDoWeDoIt}
              />
              <Route exact path={routes.CONTACT} component={Contact} />
              <Route path={routes.REGISTER_NO_MAIN_USERS} component={Home} />
              <Route path={routes.REGISTER_VERIFIER} component={Home} />
              <Route path="/reset-password" component={RestorePassword} />
              <Route component={Home} />
            </Switch>
          }
        />
      </ConnectedRouter>
    );
  }
}

export default AppRoutes;
