// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// i18n
import { t } from '../../i18n';
// const routes
import { routes } from '../../routes';
// Components
import Message from '../../components/Message';
import Button from '../../components/Button';
// Styles
import { Description } from './styles';
// Assets
// import img from '../../assets/images/male-user.png';
/* Depencies */
import {
	getDraftLocaleStoregeBySlug,
	deleteLocaltoregeByMessage,
	getDraftLocaleStorageById,
} from '../../redux/messages/actions';
// import moment from 'moment';

class SeeMessageDraft extends Component {
	constructor(props) {
		super(props);
		this.SAVE_DRAFT = 'saveDraft';
		this.state = {
			draftLocalStorageDetail: {},
		};
	}

	componentDidMount() {
		const {
			match: {
				params: { slug },
			},
		} = this.props;
		this.getMessageLocalStorage({ draftId: slug });
	}

	getMessageLocalStorage = ({ draftId }) => {
		const user = this.props.user;
		if (!user.length) {
			return;
		}
		const draftLocalStorageDetail = JSON.parse(
			localStorage.getItem(`${this.SAVE_DRAFT}${user[0].id}`),
		).find((item) => item.id === parseInt(draftId));
		this.setState({
			draftLocalStorageDetail,
		});
	};

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user && this.props.user) {
			this.getMessageLocalStorage({ draftId: this.props.match.params.slug });
		}
	}

	getRole = () => parseInt(localStorage['role']);

	render() {
		const { draftLocalStorageDetail } = this.state;
		const slugId = this.props.match.params.slug;

		return (
			<Message record titles={this.getRole()}>
				{draftLocalStorageDetail ? (
					<div>
						<div
							className='buttons'
							style={{ width: 'fit-content', paddingLeft: 14, display: 'flex' }}
						>
							<Link to={routes.HOME}>
								<Button bgColorHover='#892B6E'>{t('buttons.return')}</Button>
							</Link>
							<Link to={`${routes.WRITTEN_MESSAGE}/${slugId}?local=true`}>
								<Button
									border
									borderColor='red'
									txtColor='red'
									style={{ marginLeft: 14 }}
									bgBorderHover='gray'
								>
									{t('buttons.editMessage')}
								</Button>
							</Link>
						</div>
						<Description>
							<div className='imgUser'>
								<div className='circleImg'>
									<img
										className='imgContent'
										src={this.props.user[0] && this.props.user[0].imageUrl}
										alt='user-profile'
									/>
								</div>
								<div className='msmInfo'>
									<div>
										<h2>
											{this.props.user[0] && this.props.user[0].firstName}
										</h2>
										<h3>
											<em>{draftLocalStorageDetail.title}</em>
										</h3>
									</div>
									<div className='infoSpace'>
										<br />
										<span className='text-left'>
											{/* {t('seeDraft.creationDate')}: {moment(dataMessage.message.createdAt).format('L')}
										<br />
										{t('seeDraft.displayName')}: {moment(dataMessage.message.updatedAt).format('L')} */}
										</span>
									</div>
								</div>
							</div>

							<div className='txt'>
								<div className='topBlue' />
								<div
									dangerouslySetInnerHTML={{
										__html: draftLocalStorageDetail.body,
									}}
								/>

								<div className='separatorbottom' />
							</div>
						</Description>
						{/* <ButtonRight>			
						<Button bgColorHover='#892B6E'>	< a style={{ color: 'white' }} 
								download={dataMessage.message.messageFiles.length ?dataMessage.message.messageFiles[0].fileUrl : ''}
								href={`${dataMessage.message.messageFiles.length ?dataMessage.message.messageFiles[0].fileUrl : ''}`}>
								{t('buttons.downloadMessage')}
							</a></Button>
					</ButtonRight> */}
					</div>
				) : (
					<div className='buttons'>
						<Link to={routes.HOME}>
							<Button bgColorHover='red'>{t('buttons.sorry')}</Button>
						</Link>
					</div>
				)}
			</Message>
		);
	}
}

function mapStateToProps(store) {
	return {
		about: store.home.homes[0] ? store.home.homes[0].about : [],
		draftLocalStorageDetail: store.messages.draftLocalStorageDetail || {},
		user: store.user.user || [],
		messagesBeneficiary: store.user.messagesBeneficiary.length
			? store.user.messagesBeneficiary
			: [],
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getDraftLocaleStoregeBySlug: (slug) =>
			dispatch(getDraftLocaleStoregeBySlug(slug)),
		deleteLocaltoregeByMessage: () => dispatch(deleteLocaltoregeByMessage()),
		getDraftLocaleStorageById: (id) => dispatch(getDraftLocaleStorageById(id)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeMessageDraft);
