/* Dependencies */
import React, { Component } from 'react';
import { Field } from 'redux-form';

import PropTypes from 'prop-types';

/* i18n */
import { t } from '../../i18n';

/* Components */
import Button from '../../components/Button';
import Recorder from '../../components/Recorder';
import TypeFileUpload from '../../components/TypeFileUpload';

import InputRedux from '../../fields/Input';
import FileInput from '../../fields/FileInput';
/* Styles */
import { ModalAudio, Form } from './styles';
/* Validate */
import rec from '../../assets/images/rec-audio.jpg';
import upload from '../../assets/images/upload-audio.jpg';

import withStore from './store';
import SelectRedux from '../../fields/SelectInput';

class AudioMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			audioBlob: null,
			isActivedItem: '',
			type: '',
			nameFile: '',
		};
	}

	componentDidMount() {
		const {
			slug,
			getSelectMessage,
			draftLocaleStorage,
			setSelectedBeneficiaries,
		} = this.props;
		if (this.props.initialValues.type === 'date') {
			this.setState({
				type: 'date',
			});
		}
		getSelectMessage(parseInt(slug));
		if (draftLocaleStorage && Array.isArray(draftLocaleStorage.messageFiles)) {
			const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
				(el) => ({
					label: el.beneficiary ? el.beneficiary.firstName : '',
					value: el.beneficiary ? el.beneficiary.id : '',
				}),
			);
			setSelectedBeneficiaries(beneficiaries);
		}
	}

	componentDidUpdate(prevProps) {
		const { draftLocaleStorage, setSelectedBeneficiaries } = this.props;
		if (draftLocaleStorage !== prevProps.draftLocaleStorage) {
			const { slug, getSelectMessage } = this.props;
			getSelectMessage(parseInt(slug));
			if (
				draftLocaleStorage &&
				Array.isArray(draftLocaleStorage.messageFiles)
			) {
				this.setState({ images: draftLocaleStorage.messageFiles });
				const beneficiaries = draftLocaleStorage.messageBeneficiaries.map(
					(el) => ({
						label: el.beneficiary ? el.beneficiary.firstName : '',
						value: el.beneficiary ? el.beneficiary.id : '',
					}),
				);
				setSelectedBeneficiaries(beneficiaries);
			}
		}
	}

	toggleItem = (item) =>
		this.setState((state) => ({
			isActivedItem: item !== state.isActivedItem ? item : '',
		}));

	recordBlob = (audioBlob) =>
		this.setState({ audioBlob, uriPreview: URL.createObjectURL(audioBlob) });

	onChange = (data) => data && this.setState({ type: data.target.value });
	onChangeFile = (data) =>
		data &&
		this.setState({
			nameFile: data.name,
		});
	render() {
		const { nameFile, uriPreview } = this.state;
		const { handleSubmit, onSubmit, uri } = this.props;
		const { audioBlob, isActivedItem, type } = this.state;

		return (
			<Form
				onSubmit={handleSubmit((values) =>
					onSubmit({
						...values,
						fileUrl: audioBlob || values.fileUrl,
					}),
				)}
			>
				<div className='titleAndDate'>
					<Field
						type='text'
						name='title'
						component={InputRedux}
						placeholder={t('writtenMessage.fields.title')}
					/>
					<Field onChange={this.onChange} name='type' component={SelectRedux}>
						<option>{t('fields.selectEvents')}</option>
						<option value='absence'>{t('fields.absances')}</option>
						<option value='date'>{t('fields.dates')}</option>
					</Field>
					{type === 'date' && (
						<Field
							type='date'
							name='visibleData'
							component={InputRedux}
							placeholder={t('writtenMessage.fields.title')}
						/>
					)}
				</div>
				<div className='actionMessage'>
					<TypeFileUpload
						title={t('audioMessage.recording')}
						icon='fas fa-microphone'
						bgImg={rec}
						toggle={() => this.toggleItem('audio')}
					/>
					<TypeFileUpload
						title={t('audioMessage.attachAudio')}
						icon='fas fa-volume-up'
						bgImg={upload}
						toggle={() => this.toggleItem('file')}
					/>
				</div>
				{isActivedItem === 'audio' && (
					<ModalAudio>
						<Recorder recordBlob={this.recordBlob} />
					</ModalAudio>
				)}
				{isActivedItem === 'file' && (
					<ModalAudio>
						<label>{t('audioMessage.format')}</label>
						<Field
							nameFile={nameFile}
							component={FileInput}
							onChange={this.onChangeFile}
							name='fileUrl'
							type='file'
							accept='.mp3,.ogg, .wav'
						/>
					</ModalAudio>
				)}
				<div className='container-audio-preview'>
					<audio controls>
						<source src={uriPreview || uri} type='audio/wav' />
					</audio>
				</div>
				<div className='containerBtnSubmit'>
					<Button bgColor='#DF382F' bgColorHover='#892B6E' width='50%'>
						<i className='far fa-clock' /> {t('buttons.scheduleMessage')}
					</Button>
				</div>
			</Form>
		);
	}
}

AudioMessage.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isActivedItem: PropTypes.bool.isRequired,
};

export default withStore(AudioMessage);
