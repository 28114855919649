// Dependencies
import React, { Component, Fragment } from "react";
// Components
import PrimaryButton from "../PrimaryButton";
// Styled compoents
import {
	Container,
	ContainerFormArrayBeneficiaries,
	ContainerBeneficiaries,
} from "./styles";
import InputRedux from "../../fields/Input";
import SelectRedux from "../../fields/SelectInput";
import { Field, FieldArray } from "redux-form";
import { SelectStyle } from "../../pages/Register/styles";
import { t } from "../../i18n";

const emailRequire = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? t("register.invalidEmail")
		: undefined;
const maxLength = (max) => (value) =>
	value && value.length > max ? t("register.maximumCharacters") : undefined;
const maxLength15 = maxLength(15);

const characters = (value) =>
	value && !/^[A-Za-zÁÉÍÓÚñáéíóúÑ _]*[ñA-Za-z][ñA-Za-z _]*$/i.test(value)
		? t("register.onlyLetters")
		: undefined;

const renderBeneficiary = ({
	fields,
	relationships,
	genders,
	meta: { error, submitFailed },
}) => (
	<div>
		{fields.map((item, index) => (
			<ContainerBeneficiaries key={index}>
				<div className='containerButtonRemove'>
					<PrimaryButton
						title='Remove Member'
						isTransparent
						onClick={() => fields.remove(index)}
					>
						<i className='fas fa-times' />
					</PrimaryButton>
				</div>
				<Container isActived>
					<Field
						name={`${item}.nameUserBeneficiary`}
						type='`text`'
						component={InputRedux}
						validate={[maxLength15, characters]}
						placeholder={t("register.fields.NameBeneficiary")}
					/>
					{/* <div className="selectGenderStyle">
        {genders.map((item) => (
            <div className='genderStyle'>
              <Field
                name={`gender${index}`}
                component={InputRedux}
                type="radio"
                value={item.name}
              />{' '}
              {item.name}
            </div>
          ))}
        </div> */}
					<Field
						name={`${item}.emailBeneficiary`}
						type='email'
						component={InputRedux}
						validate={emailRequire}
						placeholder={t("register.fields.EmailBeneficiary")}
					/>
					<Field
						name={`${item}documentTypeBeneficiary`}
						component='select'
						style={SelectStyle}
					>
						<option disabled=''>
							{t("register.fields.selectTypeOfRelationship")}
						</option>
						{relationships.map((data, index) => (
							<option key={index} value={data.id}>
								{data.name}
							</option>
						))}
					</Field>
				</Container>
			</ContainerBeneficiaries>
		))}
		<PrimaryButton
			type='button'
			width='50%'
			marginTop='20px'
			marginLeft='50%'
			bgColor='#211261'
			bgColorHover='#892b6e'
			onClick={() => fields.push({})}
		>
			{t("register.fields.addNewBeneficiary")}
		</PrimaryButton>
		{submitFailed && error && <span>{error}</span>}
	</div>
);

class FormUserType extends Component {
	render() {
		const { isActived, count, genders, relationships } = this.props;
		return (
			<Fragment>
				{count.map((item, index) => (
					<div key={index}>
						<Container isActived={isActived}>
							<Field
								component={InputRedux}
								name='nameUserBeneficiary'
								type='text'
								placeholder={t("register.fields.NameBeneficiary")}
								validate={[characters]}
							/>
							<Field
								component={InputRedux}
								name='lastNameUserBeneficiary'
								type='text'
								placeholder={t("register.fields.LastNameBeneficiary")}
								validate={[characters]}
							/>
							{/* <div className="selectGenderStylesContainer">
                {genders.map((item) => (
                    <div className='genderStyle'>
                      <Field
                        name={`formGender${index}`}
                        component={InputRedux}
                        type="radio"
                        value={item.name}
                        validate={required}
                      />{' '}
                      {item.name}
                    </div>
                  ))}
              </div> */}
							<Field
								component={InputRedux}
								name='emailBeneficiary'
								type='email'
								validate={emailRequire}
								placeholder={t("register.fields.EmailBeneficiary")}
							/>
							<Field
								name='documentTypeBeneficiary'
								component={SelectRedux}
								isRegister
								positionIcon='calc(65% - 13px)'
							>
								<option disabled=''>
									{t("register.fields.selectTypeOfRelationship")}
								</option>
								{relationships.map((data, i) => (
									<option key={i} value={data.id}>
										{data.name}
									</option>
								))}
							</Field>
						</Container>
						<ContainerFormArrayBeneficiaries isActived={isActived}>
							<FieldArray
								name='beneficiaries'
								component={renderBeneficiary}
								relationships={relationships}
								genders={genders}
							/>
						</ContainerFormArrayBeneficiaries>
					</div>
				))}
			</Fragment>
		);
	}
}

export default FormUserType;
