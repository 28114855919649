/* Dependencies */
import React, { Component } from "react";
import { connect } from "react-redux";
import { change as changeValue } from "redux-form";
import Select from "react-select";
/* Redux */
import {
	setArrow,
	getRecommendation,
	getAdviceRecommendations,
} from "../../redux/app/actions";
import {
	getBasicMessageInformation,
	setSelectedBeneficiaries,
	getMessageEventByUser,
	getMessageAdviceByUser,
} from "../../redux/messages/actions";
import { setModal } from "../../redux/app/actions";
/* i18n */
import { t } from "../../i18n";
/* Components */

import LateralMenu from "../LateralMenu";
import MessageResponse from "../MessageResponse";
import TooltipRight from "../TooltipRight";
import Modal from "../Modal";
import ButtonModal from "../ButtonModal";
import OverLay from "../OverLay";
import Footer from "../Footer";
import Button from "../Button";
import ModalPreview from "../ModalPreview";
import Recomendation from "../Recomendation";

/* Styles Components */
import {
	Container,
	CreateMessage,
	ContainerSearch,
	ContainerTitleNav,
} from "./styles";
import { getMenssageBeneficiary } from "../../redux/user/actions";
import {
	setRecommendationEventSelected,
	setAdviceRecommendationSelected,
} from "../../redux/app/actions";
import { getDraftLocaleStorege } from "../../redux/messages/actions";

class Message extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			dataValue: null,
		};
	}
	componentDidMount() {
		const {
			getBasicMessageInformation,
			getDraftLocaleStorege,
			getMenssageBeneficiary,
			getRecommendation,
			getMessageEventByUser,
			getMessageAdviceByUser,
			getAdviceRecommendations,
		} = this.props;
		Promise.all([
			getBasicMessageInformation(),
			getDraftLocaleStorege(),
			getMenssageBeneficiary(),
			getRecommendation(),
			getAdviceRecommendations(),
			getMessageEventByUser(),
			getMessageAdviceByUser(),
		]);
	}

	toggleModal = () => this.setState({ modal: !this.state.modal });

	setDataMessageAdvices = (item) => {
		this.setState({
			dataValue: item,
		});
	};
	render() {
		// const { modal, isActivedMessagePreview } = this.state
		const {
			titleTypeMessage,
			children,
			setArrow,
			arrowActived,
			eraser,
			record,
			user,
			messagePreview,
			notButton,
			modal,
			setModal,
			titles,
			beneficiariesIdName,
			setSelectedBeneficiaries,
			message,
			countWritten,
			beneficiariesSelected,
			recommendations,
			setRecommendationEventSelected,
			setAdviceRecommendationSelected,
			recommendationEventSelected,
			messagesEvent,
			messagesAdvice,
			adviceRecommendations,
			withAdviceRecommendations,
			withFilterRecommendations,
			withFilterAdviceRecommendations,
		} = this.props;
		// const advice = messagesByUser.filter(item => item.messageType.code === "event" )
		const countMessageEvent = messagesEvent.length;
		const countMessageAdvice = messagesAdvice.length;

		const filterUsersSelect =
			(beneficiariesSelected &&
				beneficiariesSelected.length &&
				beneficiariesSelected.filter((i) => i.label !== "")) ||
			[];

		return (
			<Container isActived={arrowActived}>
				<div className='wrapperLateralMenu'>
					{titles ? (
						<LateralMenu titles={titles} />
					) : (
						<LateralMenu
							countMessages={countWritten}
							countMessageAdvice={countMessageAdvice}
							countMessageEvent={countMessageEvent}
						/>
					)}
				</div>

				<CreateMessage isActived={arrowActived} eraser={eraser} record={record}>
					<div className='ContainerCreateMessage'>
						{!record && (
							<div>
								<h1 className='typeMessage'>{titleTypeMessage}</h1>
								<span className='separator' />
								<div
									className='showBenefResponsive'
									isShow={arrowActived}
									onClick={() => setArrow(!arrowActived)}
								>
									<h4 className='titleResposive'>
										{!this.props.withHoutRecomendations
											? t("recommendations")
											: t("menu.beneficiaries")}
									</h4>
									<i className='icon far fa-arrow-alt-circle-right' />
								</div>
							</div>
						)}
						{children}
					</div>

					{!record && (
						<div className='input'>
							{!eraser && (
								<h4>
									{`${t("writtenMessage.previewMessage")}`}{" "}
									<span
										onClick={() => setModal("isActivedMessagePreview")}
									>{`${t("writtenMessage.clickhere")}`}</span>
								</h4>
							)}
							<div className='options'>
								{!eraser && (
									<Button bgColorHover='#892B6E'>
										<i className='far fa-clock' /> {t("buttons.saveDraft")}
									</Button>
								)}
								{!notButton && (
									<Button bgColor='#DF382F' bgColorHover='#892B6E'>
										<i className='far fa-clock' />{" "}
										{t("buttons.scheduleMessage")}
									</Button>
								)}
							</div>
						</div>
					)}
					<Footer />
				</CreateMessage>

				{!record && (
					<div>
						<div className='wrapperLateralMenu rigth'>
							<div>
								<i
									className='far fa-arrow-alt-circle-right close'
									onClick={() => setArrow(!arrowActived)}
								/>
								{!this.props.withHoutListBeneficiares && (
									<>
										<ContainerTitleNav>
											<h4>{t("menu.beneficiaries")}</h4>
											<div className='showBenef'>
												<TooltipRight
													font={
														!arrowActived
															? "far fa-arrow-alt-circle-left"
															: "far fa-arrow-alt-circle-right"
													}
													title={
														!arrowActived
															? this.props.withHoutRecomendations
																? t("menu.beneficiaries")
																: t("recommendations")
															: false
													}
													toggle={() => setArrow(!arrowActived)}
												/>
											</div>
										</ContainerTitleNav>
										<p>{t("infoUser.peopleYourMessage")}.</p>

										<ContainerSearch
											styleSelect={
												filterUsersSelect && filterUsersSelect.length
													? true
													: false
											}
										>
											<Select
												placeholder={t("buttons.beneficiaryName")}
												options={beneficiariesIdName.map((item) => ({
													value: item.id,
													label: item.value,
												}))}
												isMulti
												value={filterUsersSelect}
												isSearchable={false}
												blurInputOnSelect={false}
												isClearable={false}
												className='beneficiaries-select'
												classNamePrefix='select'
												noOptionsMessage={() => "No options"}
												onChange={(values) => setSelectedBeneficiaries(values)}
												style={{
													multiValue: {
														backgroundColor: "red",
													},
												}}
											/>
										</ContainerSearch>
									</>
								)}

								{!this.props.withHoutRecomendations && (
									<>
										<div>
											<h3>{t("recommendations")}</h3>
										</div>
										{/* <Slide> */}
										{/* {
									withAdviceRecommendations ?
								} */}
										{!withAdviceRecommendations &&
											recommendations.map((item, i) => {
												const row = withFilterRecommendations
													? item.recommendations
													: item;

												return (
													<Recomendation
														isSelected={
															recommendationEventSelected.id === row.id
														}
														key={i}
														onClick={() => setRecommendationEventSelected(row)}
														title={row.title || row.recommendations.title}
														description={
															row.description || row.recommendations.description
														}
													/>
												);
											})}

										{withAdviceRecommendations &&
											adviceRecommendations.map((item, i) => {
												const row = !withFilterAdviceRecommendations
													? item
													: item.recommendations;

												return (
													<Recomendation
														isSelected={
															recommendationEventSelected.id === row.id
														}
														key={i}
														onClick={() => setAdviceRecommendationSelected(row)}
														title={row.title || row.recommendations.title}
														description={
															row.description || row.recommendations.description
														}
													/>
												);
											})}
										{/* </Slide> */}
									</>
								)}
							</div>
						</div>

						<OverLay toggle={this.toggleModal} />
						<Modal toggle={this.toggleModal} txtAlign='left' top>
							<LateralMenu />
						</Modal>
						{modal === "modalPreview" && (
							<ModalPreview
								user={user[0]}
								messagePreview={messagePreview}
								toggle={() => setModal("")}
							/>
						)}
						<div className='buttonModal'>
							<ButtonModal toggle={this.toggleModal} />
						</div>
					</div>
				)}
				{message === "beneficiaryRequired" && (
					<MessageResponse
						isActived
						bgAlert='red'
						message={t("messagesResponse.benficiaryRequired")}
					/>
				)}
			</Container>
		);
	}
}

function mapStateToProps(store) {
	const user = store.user;
	return {
		arrowActived: store.app.arrow,
		beneficiaries: user.beneficiaries || [],
		beneficiariesIdName: user.beneficiariesIdName || [],
		beneficiariesSelected: store.messages.selectedBeneficiaries,
		user: user.user || [],
		messagePreview: store.messages.messagePreview || [],
		modal: store.app.modal,
		message: store.app.message,
		messagesEvent: store.messages.messageEventByUser.data || [],
		messagesAdvice: store.messages.messageAdviceByUser.data || [],
		messagesByUser: store.messages.messageByUser.data,
		countWritten: store.messages.messageByUser
			? store.messages.messageByUser.countWritten
			: 23,
		recommendations: store.app.recommendationsSelected.length
			? store.app.recommendationsSelected
			: store.messages.eventTypesAll,
		withFilterRecommendations: !!store.app.recommendationsSelected.length,
		adviceRecommendations: store.app.recommendationAdviceSelected.length
			? store.app.recommendationAdviceSelected
			: store.messages.adviceTypesAll,
		withFilterAdviceRecommendations:
			!!store.app.recommendationAdviceSelected.length,
		recommendationEventSelected: store.app.recommendationEventSelected,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setArrow: (arrow) => dispatch(setArrow(arrow)),
		getBasicMessageInformation: () => dispatch(getBasicMessageInformation()),
		setModal: (modal) => dispatch(setModal(modal)),
		setSelectedBeneficiaries: (value) =>
			dispatch(setSelectedBeneficiaries(value)),
		getMenssageBeneficiary: (user) => {
			dispatch(getMenssageBeneficiary(user));
		},
		getDraftLocaleStorege: () => dispatch(getDraftLocaleStorege()),
		getRecommendation: () => dispatch(getRecommendation()),
		getAdviceRecommendations: () => dispatch(getAdviceRecommendations()),
		setRecommendationEventSelected: (value) =>
			dispatch(setRecommendationEventSelected(value)),

		setAdviceRecommendationSelected: (value) =>
			dispatch(setAdviceRecommendationSelected(value)),

		changeValue: (form, field, value) =>
			dispatch(changeValue(form, field, value)),
		getMessageEventByUser: () => dispatch(getMessageEventByUser()),
		getMessageAdviceByUser: () => dispatch(getMessageAdviceByUser()),
	};
}

function margeProps(stateProps, dispatchProps, ownProps) {
	return {
		...stateProps,
		...dispatchProps,
		...ownProps,
		setRecommendationEventSelected: (value) => {
			dispatchProps.setRecommendationEventSelected(value);
			dispatchProps.changeValue("eventMessage", "title", value.title);
			dispatchProps.changeValue("eventMessage", "body", value.description);
		},
		setAdviceRecommendationSelected: (value) => {
			dispatchProps.setAdviceRecommendationSelected(value);
			dispatchProps.changeValue("eventMessage", "title", value.title);
			dispatchProps.changeValue("eventMessage", "body", value.description);
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	margeProps,
)(Message);
