import styled from 'styled-components'

export const Content = styled.div`
	width: 323px;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	text-align: center;	
	button {
		margin-bottom: 15px;
		height: 50px;
	}
	img {
		margin-bottom: 26px;
	}
`
