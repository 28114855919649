import { io } from "socket.io-client";

const SERVER = process.env.REACT_APP_API_URL.split("/api")[0];

export class SocketNotification {
	constructor() {
		this._socket = io(SERVER, {
			transports: ["websocket"],
			forceNew: true,
		});
	}

	connectNotification(onUpdateNotification, userId) {
		this._socket.on(`new-notification-${userId}`, (data) => {
			onUpdateNotification(data);
		});
	}

	close() {
		this._socket.close();
	}
}
