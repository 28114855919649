/* Dependecies */
import React from 'react'
/* Styles */
import { Container, Overlay } from './styles'

function Loader() {
	return (
		<Overlay>
			<Container>
				<div className='lds-ripple'>
					<div />
					<div />
				</div>
			</Container>
		</Overlay>
	)
}

export default Loader
