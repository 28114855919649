import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './app/reducer'
import user from './user/reducer'
import contact from './contact/reducer'
import tutorials from './tutorials/reducer'
import register from './register/reducer'
import home from './home/reducer'
import profile from './profile/reducer'
import messages from './messages/reducer'
import payment from './payment/reducer'
import { reducer as reduxFormReducer } from 'redux-form'

export default history =>
	combineReducers({
		form: reduxFormReducer,
		router: connectRouter(history),
		user,
		app,
		contact,
		tutorials,
		register,
		home,
		profile,
		messages,
		payment
	})
