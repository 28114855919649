import firebase from "firebase/app";
import "firebase/auth";

import { reset } from "redux-form";
import {
	USER,
	BENEFICIARIES,
	BENEFICIARY_SELECTED,
	CHECKERS,
	CHECKER_SELECTED,
	BENEFICIARIES_ID_NAME,
	MESSAGE_BENEFICIARY,
	NOTIFICATIONS,
	NOTIFICATION_SELECETED,
	INFO_RELATION_BENEFICIARE,
	INFO_RELATION_VERIFIER,
	ABSENCE_USER_SELECTED,
	USER_MAIN,
	DATA_ORDER_PAYMENT,
	DATA_SIGNATURE,
} from "./const";
import UserService from "./UserService";

import {
	setMessage,
	setLoading,
	autorizeUser,
	setError,
	setModal,
	setComponentLoading,
	setBeneficiaryId,
	setVerifierId,
	getVerifierData,
	handleLogout,
	setUser as setUserApp,
} from "../app/actions";

import { t, getLanguageById } from "../../i18n";

import getRandomString from "../../utils/getRandomString";

const timeHideMessage = 3000;

export const setAbsenceUserSelected = (value) => ({
	type: ABSENCE_USER_SELECTED,
	value,
});

export const setIsUserMain = (value) => ({
	type: USER_MAIN,
	value,
});

export const setNotifications = (value) => ({
	type: NOTIFICATIONS,
	value,
});

export const setNotificationSelected = (value) => ({
	type: NOTIFICATION_SELECETED,
	value,
});

export const setInfoRelationBeneficare = (value) => ({
	type: INFO_RELATION_BENEFICIARE,
	value,
});

export const setInfoRelationVerifier = (value) => ({
	type: INFO_RELATION_VERIFIER,
	value,
});

export const setUser = (value) => ({ type: USER, value });
export const setBeneficiaries = (value) => ({ type: BENEFICIARIES, value });
export const setBeneficiariesIdName = (value) => ({
	type: BENEFICIARIES_ID_NAME,
	value,
});
export const setCheckers = (value) => ({ type: CHECKERS, value });
export const setBeneficiarySelected = (value) => ({
	type: BENEFICIARY_SELECTED,
	value,
});

export const setCheckerSelected = (value) => ({
	type: CHECKER_SELECTED,
	value,
});
export const setMeessageBeneficiary = (value) => ({
	type: MESSAGE_BENEFICIARY,
	value,
});

export const setOrderPayment = (value) => ({
	type: DATA_ORDER_PAYMENT,
	value,
});

export const setSignature = (value) => ({
	type: DATA_SIGNATURE,
	value,
});

export const getMenssageBeneficiary =
	(filter) => async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));

			const store = await getStore();
			const {
				app: {
					isLoginUser: { uid },
				},
			} = store;
			const user = await UserService.getUser(uid);
			const userId = user.data[0].id;
			if (filter) {
				const dataMenssages = await UserService.getByBeneficiaryByUsers(
					userId,
					filter,
				);
				dispatch(setMeessageBeneficiary(dataMenssages.data));
			} else {
				const dataMenssages = await UserService.getByBeneficiaryByUsers(userId);
				dispatch(setMeessageBeneficiary(dataMenssages.data));
			}
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(setLoading(false));
		}
	};

export const getUser = () => async (dispatch, getStore) => {
	try {
		const {
			app: {
				isLoginUser: { uid },
			},
		} = getStore();
		if (!uid) {
			return;
		}

		const user = await UserService.getUser(uid);

		// if(!user.data[0].active){
		// 	dispatch(handleLogout())
		// 	return dispatch(setMessage({ name: 'withError', message: 'ErerrorAuthror' }))
		// }
		const { data } = user;
		data[0].imageUrl = `${data[0].imageUrl}?date=${Date.now()}`;
		dispatch(setUser(data));
	} catch (e) {
		console.log(e);
	}
};

export const getSession = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const role = localStorage.getItem("role");
		firebase.auth().onAuthStateChanged((user) => {
			if (!user) {
				return;
			}
			UserService.getUserInfo(user.uid)
				.then(({ data }) => {
					if (
						data[0].active &&
						data[0].currentSession === localStorage.getItem("session")
					) {
						dispatch(setUserApp(user));
						dispatch(setIsUserMain(!role));
					}
				})
				.catch((e) => console.log("GET_SESSION: ", e));
		});
	} catch (e) {
		console.log("GET_SESSION:", e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const handleAuth = (values) => async (dispath) => {
	try {
		dispath(setLoading(true));
		delete localStorage["role"];
		delete localStorage["session"];
		const { email, password } = values;
		const response = await firebase
			.auth()
			.signInWithEmailAndPassword(email, password);
		const { user } = response;
		localStorage["token"] = user.refreshToken;
		const { data: userData } = await UserService.getUserInfo(user.uid);
		if (!userData[0].active) {
			dispath(handleLogout());
			return dispath(
				setMessage({
					name: "withError",
					message: t("validations.login.userBlocked"),
				}),
			);
		}
		const { data: role } = await UserService.getRolBeneficiare(
			userData[0].id,
			1,
		);
		if (!role.length) {
			dispath(handleLogout());
			return dispath(setMessage({ name: "withError", message: "Error" }));
		}

		const currentSession = getRandomString();
		localStorage["session"] = currentSession;
		await UserService.updateSession({
			userId: userData[0].id,
			currentSession,
		});
		dispath(autorizeUser(user));
		dispath(setIsUserMain(true));
		dispath(getUser());
	} catch (e) {
		console.log(e);
		dispath(setError(e));
		if (e.code === "auth/user-not-found")
			return dispath(
				setMessage({
					name: "withError",
					message: t("messagesResponse.noRegisterEMail"),
				}),
			);
		dispath(setMessage("errorAuth"));
	} finally {
		setTimeout(() => dispath(setMessage("")), timeHideMessage);
		dispath(setLoading(false));
	}
};

export const loginBeneficiare =
	({ email, password }) =>
	async (dispath) => {
		try {
			dispath(setLoading(true));
			delete localStorage["role"];
			const response = await firebase
				.auth()
				.signInWithEmailAndPassword(email, password);
			const {
				user: { uid },
			} = response;
			const { data: user } = await UserService.getUserInfo(uid);
			if (!user[0].active) {
				dispath(handleLogout());
				return dispath(
					setMessage({
						name: "withError",
						message: t("validations.login.userBlocked"),
					}),
				);
			}
			const { data: role } = await UserService.getRolBeneficiare(user[0].id);
			if (!role.length) {
				dispath(handleLogout());
				return dispath(setMessage({ name: "withError", message: "Error" }));
			}
			dispath(setBeneficiaryId(1));
			localStorage["role"] = 1;
			dispath(setModal(""));
			dispath(setIsUserMain(false));
		} catch (e) {
			console.log(e);
			dispath(setError(e));
			dispath(
				setMessage({
					name: "withError",
					message: t("validations.login.try-again"),
				}),
			);
		} finally {
			dispath(setLoading(false));
			setTimeout(() => dispath(setMessage("")), timeHideMessage);
		}
	};

export const loginVerifier =
	({ email, password }) =>
	async (dispath) => {
		try {
			dispath(setLoading(true));
			delete localStorage["role"];

			const response = await firebase
				.auth()
				.signInWithEmailAndPassword(email, password);
			const {
				user: { uid },
			} = response;
			const { data: user } = await UserService.getUserInfo(uid);
			if (!user[0].active) {
				dispath(handleLogout());
				return dispath(
					setMessage({
						name: "withError",
						message: t("validations.login.userBlocked"),
					}),
				);
			}
			const { data: role } = await UserService.getRolVerifier(user[0].id);

			if (!role.length) {
				dispath(handleLogout());
				return dispath(setMessage({ name: "withError", message: "Error" }));
			}
			dispath(setVerifierId(2));
			localStorage["role"] = 2;
			dispath(setModal(""));

			dispath(autorizeUser(user));
			dispath(getVerifierData(user));
			dispath(setIsUserMain(false));
		} catch (e) {
			console.log(e);
			dispath(setError(e));
			dispath(
				setMessage({
					name: "withError",
					message: t("validations.login.try-again"),
				}),
			);
		} finally {
			dispath(setLoading(false));
			setTimeout(() => dispath(setMessage("")), timeHideMessage);
		}
	};

export const changePassword = (values) => async (dispatch) => {
	try {
		dispatch(setLoading(true));

		const { currentPassword, newPassword } = values;
		const user = firebase.auth().currentUser;
		const cred = firebase.auth.EmailAuthProvider.credential(
			user.email,
			currentPassword,
		);
		await user.reauthenticateWithCredential(cred);
		await user.updatePassword(newPassword);
		dispatch(
			setMessage({
				name: "success",
				message: t("messagesResponse.updatePassword"),
			}),
		);
		dispatch(reset("changePassword"));
	} catch (e) {
		if (e.code === "auth/wrong-password") {
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.notEquals"),
				}),
			);
		} else {
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.passwordNotUpdate"),
				}),
			);
		}
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const recoverPassword = (data, navigate) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		await UserService.recoverPassword({ ...data });
		dispatch(
			setMessage({
				name: "success",
				message: t("messagesResponse.updatePassword"),
			}),
		);
		navigate();
	} catch (error) {
		console.log("recoverPassword: ", error);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const updateUser = (data) => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		if (user === user[0]) return;
		// delete data.id
		const role = localStorage["role"];
		let object = {
			firstName: null,
			lastName: null,
			imageUrl: "null?date=1624483651679?date=1624483703992",
			countryId: null,
		};
		console.log("data", data);
		delete data.assignedRoles;
		delete data.beneficiaryUsers;
		delete data.lastMessageSent;
		switch (role) {
			case "1":
				object.firstName = data.firstName;
				object.lastName = data.lastName;
				object.imageUrl = data.imageUrl;
				object.countryId = data.countryId;
				if (typeof data.imageUrl == "string" || !data.imageUrl) {
					await UserService.upadateUserInfo(data.id, object);
				} else {
					await UserService.upadateUserInfo(data.id, object);
					await UserService.updateUser(data.id, object);
				}
				await dispatch(getUser());

				break;
			case "2":
				object.firstName = data.firstName;
				object.lastName = data.lastName;
				object.imageUrl = data.imageUrl;
				object.countryId = data.countryId;
				if (typeof object.imageUrl == "string" || !object.imageUrl) {
					await UserService.upadateUserInfo(data.id, object);
				} else {
					await UserService.upadateUserInfo(data.id, object);

					await UserService.updateUser(data.id, object);
				}
				await dispatch(getUser());

				break;

			default:
				if (typeof data.imageUrl == "string" || !data.imageUrl) {
					await UserService.upadateUserInfo(data.id, data);
				} else {
					await UserService.upadateUserInfo(data.id, data);

					await UserService.updateUser(data.id, data);
				}
				await dispatch(getUser());

				break;
		}

		dispatch(setMessage("updateUserSuccess"));
	} catch (e) {
		console.log(e);
		dispatch(setMessage("updateUserError"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const addBeneficiariesByUserId =
	(beneficiare, isAddCheker) => async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const { email } = beneficiare;
			const isMailExists = await UserService.getUserOneByEmail(email);
			const userId = getStore().user.user[0].id;
			const replyMessage = !isAddCheker
				? "addBeneficiariesSuccess"
				: "addBeneficiariesError";

			let newRelation = {
				userId,
				relationshipId: 1,
			};
			if (isMailExists.data.length > 0) {
				newRelation[!isAddCheker ? "beneficiaryId" : "verifierId"] =
					isMailExists.data[0].id;
			} else {
				const response = await UserService.setUser({ email });
				const {
					data: { id },
				} = response;
				newRelation[!isAddCheker ? "beneficiaryId" : "verifierId"] = id;
			}
			if (!isAddCheker) await UserService.addBeneficiariesByUserId(newRelation);
			else await UserService.addChekerByUserId(newRelation);
			dispatch(setMessage(replyMessage));
			dispatch(reset("createBeneficiaryAndVerifier"));
			dispatch(!isAddCheker ? getAllBeneficiary() : getAllChecker());
			dispatch(setModal(null));
		} catch (e) {
			console.log(e);
			const replyError = !isAddCheker
				? "addBeneficiariesError"
				: "addChekerError";
			dispatch(setMessage(replyError));
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
		}
	};

export const setBeneficiaryByUserId =
	(beneficiare) => async (dispatch, getStore) => {
		try {
			const { email, relationshipId } = beneficiare;
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user },
			} = store;
			// return
			const newRelation = {
				...beneficiare,
				relationshipId: parseInt(relationshipId),
				userId: user[0].id,
				email: [email],
			};
			await UserService.addBeneficiariesByUserId(newRelation);
			dispatch(setMessage("setRelationsSuccess"));
			dispatch(reset("createBeneficiaryAndVerifier"));
			dispatch(getAllBeneficiary());
			dispatch(setModal(null));
		} catch (e) {
			console.log(e);
			dispatch(setMessage("setRelationsErrorC"));
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
		}
	};

export const editBeneficiaryByUserId =
	(beneficiare) => async (dispatch, getStore) => {
		try {
			const { email, relationshipId } = beneficiare;
			console.log("ENTRO EDITAR BENE", beneficiare);
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: { user },
			} = store;
			const editRelation = {
				...beneficiare,
				relationshipId: parseInt(relationshipId),
				userId: user[0].id,
				emailBeneficiary: email,
			};
			await UserService.editBeneficiaryByUserId(editRelation);
			dispatch(setMessage("updateSuccess"));
			dispatch(reset("createBeneficiaryAndVerifier"));
			dispatch(getAllBeneficiary());
			dispatch(setModal(null));
		} catch (e) {
			console.log(e);
			dispatch(setMessage("setRelationsErrorC"));
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
		}
	};

export const setCheckerByUserId = (checker) => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const { email } = checker;
		const store = getStore();
		const {
			user: { user },
		} = store;
		const newRelation = {
			...checker,
			userId: user[0].id,
			verifierEmails: [email],
		};
		if (user.length > 0 && user[0].countryId === null) {
			return dispatch(
				setMessage({
					name: "withError",
					message: t("register.messageVerifiers"),
				}),
			);
		}
		await UserService.addCheckersByUserId(newRelation);
		dispatch(setMessage("setRelationsSuccess"));
		dispatch(reset("createBeneficiaryAndVerifier"));
		dispatch(getAllChecker());
		dispatch(setModal(null));
	} catch (e) {
		console.log(e);
		dispatch(setMessage("setRelationsError"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const editCheckerByUserId = (checker) => async (dispatch, getStore) => {
	try {
		const { email, id, phone, firstName, lastName } = checker;
		console.log("ENTRO VERI", checker);
		dispatch(setLoading(true));
		const store = getStore();
		const {
			user: { user },
		} = store;
		const newDataEdit = {
			id,
			proofStatusId: user[0].id,
			firstName,
			lastName,
			emailVerifier: email,
			phone,
		};
		console.log("ENTRO VERI2", newDataEdit);
		await UserService.editCheckersByUserId(newDataEdit);

		dispatch(setMessage("updateSuccess"));
		dispatch(reset("createBeneficiaryAndVerifier"));
		dispatch(getAllChecker());
		dispatch(setModal(null));
	} catch (e) {
		console.log(e);
		dispatch(setMessage("setRelationsErrorC"));
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const searchBeneficiareOrCheker = () => async (dispatch) => {
	try {
		await Promise.all([
			dispatch(getAllBeneficiary()),
			dispatch(getAllChecker()),
		]);
		dispatch(getAllBeneficiaryOnlyId());
	} catch (e) {
		console.log(e);
	}
};

export const getAllBeneficiary = () => async (dispatch, getStore) => {
	try {
		dispatch(setComponentLoading("beneficiaries"));
		const store = getStore();
		const {
			user: { user },
		} = store;
		const beneficiary = await UserService.getAllBeneficiary(user[0].id);
		console.log("----> beneficiary", beneficiary.data);
		dispatch(setBeneficiaries(beneficiary.data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setComponentLoading(false));
	}
};
export const getAllBeneficiaryOnlyId = () => async (dispatch, getStore) => {
	try {
		await dispatch(getAllBeneficiary());
		const store = await getStore();
		const {
			user: { beneficiaries },
		} = store;
		const arr = beneficiaries.map((item) => ({
			id: item.beneficiaryId,
			value: item.beneficiaryUser.firstName,
		}));
		dispatch(setBeneficiariesIdName(arr));
	} catch (e) {
		console.log(e);
	}
};

export const getAllChecker = () => async (dispatch, getStore) => {
	try {
		dispatch(setComponentLoading("checkers"));
		const store = getStore();
		const {
			user: { user },
		} = store;
		const checker = await UserService.getAllChecker(user[0].id);
		dispatch(setCheckers(checker.data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setComponentLoading(false));
	}
};

export const forgotPassword = (values) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const { email } = values;
		// firebase.auth().useDeviceLanguage(); // set with function
		// firebase.auth().languageCode = 'en'; // set with string
		// const user = firebase.auth()
		// user.useDeviceLanguage()
		// user.languageCode = getLanguage()
		await UserService.setResetPassword(email);
		dispatch(
			setMessage({
				name: "success",
				message: t("messagesResponse.recoverPasswordSuccess"),
			}),
		);
		dispatch(reset("forgotPassword"));
		setTimeout(() => dispatch(setModal("")), 1000);
	} catch (e) {
		// let errorMessage = ''
		console.log("ERROR", e.code);
		if (e.code === "auth/user-not-found")
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.noRegisterEMail"),
				}),
			);
		// errorMessage = 'errorSendMailRecoverPassword'
		if (e.code === "auth/too-many-requests") {
			dispatch(
				setMessage({
					name: "withError",
					message: t("messagesResponse.longInten"),
				}),
			);
		}

		dispatch(
			setMessage({
				name: "withError",
				message: t("validations.login.wrong-password"),
			}),
		);
		// dispatch(setMessage(errorMessage))
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const confirmingAbsence = (values) => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const store = getStore();
		const userVerfier = store.user.user[0].id;
		const { email, idUserMain } = values;
		const obj = {
			userId: idUserMain,
			userVerfier,
			descripcion: email,
		};
		await UserService.setAbsences(obj);
		await dispatch(setMessage("succesSend"));
		dispatch(reset("confirmingAbsence"));
		setTimeout(() => dispatch(setModal("")), 1000);
	} catch (e) {
		let errorMessage = "";
		if (e.code === "auth/user-not-found")
			errorMessage = "errorSendMailRecoverPassword";
		if (e.code === "auth/too-many-requests")
			errorMessage = "errorSendManyMails";
		dispatch(setMessage(errorMessage));
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), timeHideMessage);
	}
};

export const deleteBeneficiary =
	(isChecker = false) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			const store = getStore();
			const {
				user: {
					beneficiarySelected: { id },
				},
			} = store;
			!isChecker
				? await UserService.deleteBeneficiary(id)
				: await UserService.deleteCheker(id);

			dispatch(setModal(""));
			!isChecker ? dispatch(getAllBeneficiary()) : dispatch(getAllChecker());
			dispatch(setMessage("deleteBeneficiaryOrCheckerSuccess"));
		} catch (e) {
			console.log(e);
			dispatch(setMessage("deleteBeneficiaryOrCheckerError"));
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), timeHideMessage);
		}
	};

export const getNotifications = () => async (dispatch, getStore) => {
	try {
		const {
			user: { user },
		} = getStore();
		const { data } = await UserService.getNotifications(user[0].id);
		dispatch(setNotifications(data.reverse()));
	} catch (e) {
		console.log(e);
	}
};

export const updateNotificationSocket =
	(notification) => (dispatch, getStore) => {
		const {
			user: { notifications },
		} = getStore();
		const newNotifications = [
			notification[getLanguageById()],
			...notifications,
		];
		dispatch(setNotifications(newNotifications));
	};

export const updateNotification = (notificationId) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		await UserService.updateNotification(notificationId);
		dispatch(getNotifications());
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

/* acceptance of relationship between beneficiary */

export const getInfoRelationBeneficare = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const {
			user: {
				notificationSelected: { notification },
			},
		} = getStore();
		const { data } = await UserService.getInfoAcceptBeneficare(notification);
		dispatch(setInfoRelationBeneficare(data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const setAcceptRelationBeneficiare =
	({ code }) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			await UserService.setAcceptRelationBeneficiare(code);
			const {
				user: {
					notificationSelected: { id },
					user,
				},
			} = getStore();
			await Promise.all([
				UserService.updateNotifications(id),
				UserService.setRoleBeneficiare(user[0].id),
			]);
			dispatch(
				setMessage({
					name: "success",
					message: "Beneficiario aceptado",
				}),
			);
			dispatch(setModal(""));
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: "Codigo invalido",
				}),
			);
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), 3000);
		}
	};

/* acceptance of relationship between verifier */

export const getInfoRelationVerifier = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const {
			user: {
				notificationSelected: { notification },
			},
		} = getStore();
		const { data } = await UserService.getInfoAcceptVerifier(notification);
		dispatch(setInfoRelationVerifier(data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

export const setAcceptRelationVerifier =
	({ code }) =>
	async (dispatch, getStore) => {
		try {
			dispatch(setLoading(true));
			await UserService.setAcceptRelationVerifier(code);
			const {
				user: {
					notificationSelected: { id },
					user,
				},
			} = getStore();
			await Promise.all([
				UserService.updateNotifications(id),
				UserService.setRoleVerifer(user[0].id),
			]);
			dispatch(
				setMessage({
					name: "success",
					message: "Verificador aceptado",
				}),
			);
			dispatch(setModal(""));
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: "Codigo invalido",
				}),
			);
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), 3000);
		}
	};

/* report absence of primary user */

export const setAbsenceUserMain = (values) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		await UserService.setAbsenceUserMain(values);
		dispatch(setModal(""));
		dispatch(
			setMessage({
				name: "success",
				message: t("messagesResponse.adminAbsenece"),
			}),
		);
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
		setTimeout(() => dispatch(setMessage("")), 3000);
	}
};

/* change report absence of primary user */

export const changeAbsenceUserMain =
	({ userId, verifierId, approved, role }) =>
	async (dispatch) => {
		try {
			dispatch(setLoading(true));
			console.log("----role", role, userId);
			// get userId Main
			let getDataUserMain;
			let resultAbsence;
			if (role === 2) {
				getDataUserMain = await UserService.getDataUserMainOfVerifier(
					verifierId,
				);
				const { userId } = getDataUserMain.data[0];
				resultAbsence = await UserService.changeAbsenceUserMain(
					userId,
					verifierId,
					approved.stateAbsence,
					true,
				);
			} else {
				// getDataUserMain = await UserService.getDataUserMainOfVerifier(
				// 	verifierId,
				// );
				console.log("----getDataUserMain", getDataUserMain);

				resultAbsence = await UserService.changeAbsenceUserMain(
					userId,
					userId,
					approved.stateAbsence,
				);
			}
			console.log("----resultAbsence", resultAbsence);
			if (resultAbsence.data.ok) {
				dispatch(setModal(""));
				dispatch(
					setMessage({
						name: "success",
						message: t("messagesResponse.adminAbsenece"),
					}),
				);
			} else {
				dispatch(
					setMessage({
						name: "withError",
						message: "Error al cambiar la ausencia",
					}),
				);
			}
		} catch (e) {
			console.log(e);
			dispatch(
				setMessage({
					name: "withError",
					message: "Error al cambiar la ausencia",
				}),
			);
		} finally {
			dispatch(setLoading(false));
			setTimeout(() => dispatch(setMessage("")), 3000);
		}
	};

// create order for payment PAYU

export const getPaymentOrder = (dataNew) => async (dispatch, getStore) => {
	try {
		console.log("data1", dataNew);

		dispatch(setLoading(true));
		const {
			user: { user },
		} = getStore();
		const numberRandomId = Math.floor(Math.random() * 1000);
		const obj = {
			jsonOrder: { id: `PAYU-${numberRandomId}` },
			paid: false,
			userId: user[0].id,
			spaceId: dataNew.spaceId,
			processed: false,
			expirationDate: "2021-07-08T22:52:46.000Z",
		};
		console.log("DATA obj ", obj);

		const { data } = await UserService.getOrderPayment(obj);
		console.log("DATA RESPONSE ", data);
		dispatch(setOrderPayment(data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};

// get signature order

export const getSignatureOrder = () => async (dispatch, getStore) => {
	try {
		dispatch(setLoading(true));
		const {
			user: { dataOrderPayment },
		} = getStore();
		console.log("getSignatureOrder dataOrderPayment ", dataOrderPayment);

		const { data } = await UserService.getOrderSignature(dataOrderPayment.id);
		console.log("DATA RESPONSE ", data);
		dispatch(setSignature(data));
	} catch (e) {
		console.log(e);
	} finally {
		dispatch(setLoading(false));
	}
};
