import initialState from './initialState'
import { HOME, FORM_BENEFICIARIES, FORM_VERIFIERS, SLIDER_CHECKER, SLIDER_BENEFICIARES } from './const'



/** 
 *reduce from home
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case HOME:
      return Object.assign(
				{},
        {
          ...state,
          homes: action.value
        }
			)
    case FORM_BENEFICIARIES:
      return Object.assign(
				{},
        {
          ...state,
          formBeneficiaries: action.value
        }
			)
    case FORM_VERIFIERS:
      return Object.assign(
				{},
        {
          ...state,
          formVerifiers: action.value
        }
      )
    case SLIDER_CHECKER:
      return Object.assign(
        {},
        {
          ...state,
          sliderCheckers: action.value 
        }
      ) 
    case SLIDER_BENEFICIARES: 
        return Object.assign(
          {},
          {
            ...state,
            sliderBeneficiares: action.value
          }
        )
    default:
      return state
  }
}
