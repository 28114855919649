import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
	margin-top: 5px;
	width: 100%;
	min-height: 70px;
	height: fit-content;
	display: flex;
	background-color: #ff8400;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: background 0.4s linear 0s;
	border-radius: 12px;
    padding: 10px;
	:hover {
		background-color: rgba(118, 35, 106, 0.9);
	}
	/* padding: 0 5px; */
	div {
		height: 100%;
	}
	h4,
	p {
		margin: 0 !important;
		width: fit-content !important;
		max-width: 170px;
		padding: 0;
		line-height: 1.2;
	}
	h4 {
		margin-bottom: 5px !important;
		font-size: 16px !important;
		color: white !important;
		font-weight: 500;
	}
	p {
		font-size: 14px !important;
		color: white !important;
		font-weight: 300;
	}
	span {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: white;
		transition: background 0.4s linear 0s;
		display: flex;
		justify-content: center;
		align-items: center;
		i {
			color: #fff;
			font-size: 1.5rem;
		}
		${ifProp(
			'isSelected',
			css`
				background-color: rgba(118, 35, 106, 0.9);
			`
		)}
	}
`
