// Dependecies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// i18n
import { t } from '../../i18n';
// Components
import Button from '../Button';
import SingnIn from '../../container/VisibleSingIn';
// import MessageTemporal from '../MessageTemporal'
// Styleds compontes
import { Container, BoxLogin, ContainerButtonsRegister } from './styles';
//Assets
import play from '../../assets/images/play.png';
import apple from '../../assets/images/apple.png';
import facebook from '../../assets/images/facebook.png';
import { setModal } from '../../redux/app/actions';
import { routes } from '../../routes';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      error: true,
    };
  }
  // renderError = () => {
  // 	const { errors } = this.props
  // 	let message = ''
  // 	if (errors) {
  // 		switch (errors.code) {
  // 			case 'auth/wrong-password':
  // 				message = t('validations.login.wrong-password')
  // 				break
  // 			case 'auth/user-not-found':
  // 				message = t('validations.login.user-not-found')
  // 			// eslint-disable-next-line no-fallthrough
  // 			default:
  // 				message = t('validations.login.try-again')
  // 				break
  // 		}
  // 		return (
  // 			<MessageTemporal
  // 				bgAlert='red'
  // 				message={message}
  // 				isActived={this.state.error}
  // 				visible={this.visibleMessage}
  // 			/>
  // 		)
  // 	}
  // }

  visibleMessage = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 2000);
  };

  render() {
    const {
      descriptionUser,
      // descriptionBeneficiary,
      // descriptionChecker,
      isActiveLogin,
      toggleLogin,
      urlFacebook,
      // toggleModalRBeneficiray,
      // toggleModalVerifier,
      // toggleModalForgotPassword
    } = this.props;
    return (
      <Container isActiveLogin={isActiveLogin}>
        <BoxLogin>
          <div className="containerClose">
            <button onClick={toggleLogin}>
              <i className="fas fa-times-circle" />
            </button>
          </div>
          <div className="containerform">
            <div className="text">
              <h1>{t('home.login.title')}</h1>
              <p>{t('home.login.description')}</p>
            </div>
            <div className="form">
              <SingnIn />
            </div>
            <a
              href="#nothing"
              className="min-tex"
              onClick={() => this.props.toggleModal('forgotPassword')}
              style={{ cursor: 'pointer' }}
            >
              {t('home.login.forgot-password')}
            </a>
            <ContainerButtonsRegister>
              <Link to={routes.REGISTER}>
                <Button border bgColorHover="#f9692c" bgColor="#342769">
                  {t('home.login.user-principal')}
                  <br />
                  <span className="min-text">{descriptionUser}</span>
                </Button>
              </Link>
            </ContainerButtonsRegister>
            {/* <ContainerButtonsRegister>
									<Link to='/register'>
										<Button border bgColorHover='#ff8400'>
											{t('home.login.user-principal')}
											<br />
											<span className='min-text'>{descriptionUser}</span>
										</Button>
									</Link>
									<Button
										type='button'
										border
										bgColorHover='#ff8400'
										onClick={toggleModalRBeneficiray}>
										{t('home.login.beneficiary')}
										<br />
										<span className='min-text'>{descriptionBeneficiary}</span>
									</Button>
									<Button
										border
										bgColorHover='#ff8400'
										onClick={toggleModalVerifier}>
										{t('home.login.verifier')}
										<br />
										<span className='min-text'>{descriptionChecker}</span>
									</Button>
								</ContainerButtonsRegister> */}
            <div className="findUs">
              <p>{t('home.login.findUs')}</p>
              <div>
                <a href="#nothing" target="_blank">
                  <img src={play} alt="google-play" className="play" />
                </a>
                <a href="#nothing" target="_blank">
                  <img src={apple} alt="app-store" className="play" />
                </a>
                {urlFacebook && (
                  <a
                    href={urlFacebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="google-play" className="play" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </BoxLogin>
      </Container>
    );
  }
}

function mapStateToProps(store) {
  return {
    descriptionUser: store.home.homes[0]
      ? store.home.homes[0].descriptionUser
      : [],
    descriptionBeneficiary: store.home.homes[0]
      ? store.home.homes[0].descriptionBeneficiary
      : [],
    descriptionChecker: store.home.homes[0]
      ? store.home.homes[0].descriptionChecker
      : [],
    errors: store.app.error,
    urlFacebook: store.home.homes[0] ? store.home.homes[0].facebook : null,
  };
}

function mapDispachtToProps(dispatch) {
  return {
    toggleModal: (value) => dispatch(setModal(value)),
  };
}

export default connect(mapStateToProps, mapDispachtToProps)(Login);
