// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// i18n
import { t } from '../../i18n';
// Redux
import { getTutorialsBySlug } from '../../redux/tutorials/actions';
// CONST ROUTES
import { routes } from '../../routes';
// Components
import Base from '../../components/Base';
import Button from '../../components/Button';
import Carrousel from '../../components/Carrousel';
// Styled componets
import { Container, ContainerVideo } from './styles';

class TutorialDetail extends Component {
  componentDidMount() {
    const {
      match: {
        params: { pageTutorial },
      },
    } = this.props;
    this.props.getTutorialsBySlug(pageTutorial);
  }

  render() {
    const { tutorialsBySlug, isComponentLoading } = this.props;
    console.log(
      'validations',
      tutorialsBySlug.videoUrl && tutorialsBySlug.videoUrl.split('/').length
    );
    return (
      <Base imgBackground="none">
        <Container>
          {isComponentLoading ? (
            <></>
          ) : (
            <div>
              <div className="box">
                <Link to={routes.TUTORIALS}>
                  <Button
                    border
                    borderColor="red"
                    txtColor="red"
                    bgBorderHover="gray"
                  >
                    {t('buttons.return')}
                  </Button>
                </Link>
              </div>
              <h1 className="title">{tutorialsBySlug.title}</h1>
              <span className="text-separator-black" />
              <div
                dangerouslySetInnerHTML={{
                  __html: `${tutorialsBySlug.description || ''}`,
                }}
              />
            </div>
          )}
          {tutorialsBySlug.videoUrl &&
          tutorialsBySlug.videoUrl.split('/').length > 1 ? (
            <ContainerVideo>
              <iframe
                className="video"
                src={tutorialsBySlug.videoUrl.replace('watch?v=', 'embed/')}
                title="video home"
              />
            </ContainerVideo>
          ) : tutorialsBySlug.tutorialSliders &&
            tutorialsBySlug.tutorialSliders.length ? (
            <Carrousel lifeGuides={tutorialsBySlug.tutorialSliders} />
          ) : (
            <></>
          )}
        </Container>
        {tutorialsBySlug.link && tutorialsBySlug.link.split().length > 1 && (
          <Link to={`routes.TUTORIALS/${tutorialsBySlug.link}`}>
            <Button
              style={{
                marginTop: 10,
              }}
              border
              borderColor="red"
              txtColor="red"
              bgBorderHover="gray"
            >
              {t('buttons.otherTutorial')}
            </Button>
          </Link>
        )}
      </Base>
    );
  }
}

function mapStateToProps(store) {
  return {
    isComponentLoading: store.app.isComponentLoading,
    tutorialsBySlug: store.tutorials.tutorialsBySlug,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTutorialsBySlug: (slug) => dispatch(getTutorialsBySlug(slug)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialDetail);
