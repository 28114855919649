// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
// i18n
import { t } from '../../i18n';
import {
  createUserMain,
  updateUserBeneficiary,
  setGenders,
  getGender,
  getIdentificationTypes,
  setIdentificationType,
  getRelationships,
  setRelationship,
  setCountries,
  getCountries,
  getFrequentQuestions,
} from '../../redux/register/actions';

import { setModalTerms } from '../../redux/app/actions';

// Components
import Footer from '../../components/Footer';
import Wrapper from '../../components/Wrapper';
import CarrouselQuestions from '../../components/CarrouselQuestions';

import { reduxForm, reset } from 'redux-form';
import { compose } from 'redux';
// Styled components
import { Container, Content, ContainerFormRegister } from './styles';
import CreateNewUser from '../../form/CreateRegister';
import MessageResponse from '../../components/MessageResponse';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiares: false,
      verifiers: false,
      visible: true,
      error: true,
      fileName: '',
    };
  }

  async componentDidMount() {
    this.props.getGender();
    this.props.getIdentificationTypes();
    this.props.getRelationships();
    this.props.getCountries();
    this.props.getFrequentQuestions();
  }
  toggleBeneficiares = () =>
    this.setState({ beneficiares: !this.state.beneficiares });

  toggleVerifiers = () => this.setState({ verifiers: !this.state.verifiers });

  toggleModalTerms = () => this.props.setModalTerms();

  renderError = () => {
    const error = this.props;
    let message = '';
    if (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          message = 'algo salio mal';
          break;
        default:
          message = 'valida tus datos';
          break;
      }
      return (
        <MessageResponse
          bgAlert="red"
          message={message}
          isActived={this.state.error}
          visible={this.visibleMessage}
        />
      );
    }
  };
  getFileName = (fileName) => {
    this.setState({
      fileName: fileName,
    });
  };
  visibleMessage = () => {
    this.setState({
      error: true,
    });
    setTimeout(() => {
      this.setState({
        error: false,
      });
    }, 2000);
  };
  render() {
    const verifiersA = [1, 2, 3];
    const beneficiariesA = [7];
    const {
      genders,
      createUserMain,
      updateUserBeneficiary,
      identificationTypes,
      relationships,
      message,
      countries,
      frequentQuestions,
      registerUrl,
    } = this.props;
    const { fileName } = this.state;
    const code = this.props.match.params.slug;
    // this.props.history.push('/')
    return (
      <Container bgImage={registerUrl}>
        <Wrapper className="Wrapper">
          <Content>
            <div className="questions">
              <h1>{t('testimonies')}</h1>
              {/* {frequentQuestions.map((item, index) => (

							<div key={index} >
								{console.log('index', index)} */}
              <CarrouselQuestions data={frequentQuestions} />

              {/* 
							</div>
							))} */}
            </div>
            <div>
              <ContainerFormRegister className="scroll-text">
                <CreateNewUser
                  onSubmit={createUserMain}
                  fileName={fileName}
                  uplodadName={this.getFileName}
                  onSubmitBeneficiary={updateUserBeneficiary}
                  genders={genders}
                  countries={countries}
                  identificationTypes={identificationTypes}
                  relationships={relationships}
                  beneficiares={this.state.beneficiares}
                  verifiers={this.state.verifiers}
                  beneficiaresA={beneficiariesA}
                  verifiersA={verifiersA}
                  renderError={this.renderError}
                  visible={this.state.visible}
                  toggleBeneficiares={this.toggleBeneficiares}
                  toggleVerifiers={this.toggleVerifiers}
                  toggleModalTerms={this.toggleModalTerms}
                  code={code}
                />
              </ContainerFormRegister>
            </div>
            <MessageResponse
              isActived={message === 'requiredOneBeneficiarie'}
              bgAlert="red"
              message={t('register.requiredOneBeneficiarie')}
            />
            <MessageResponse
              isActived={message === 'emailIsAlreadyUse'}
              bgAlert="red"
              message={t('register.emailIsAlreadyUse')}
            />
            <MessageResponse
              isActived={message === 'requiredOneVerifier'}
              bgAlert="red"
              message={t('register.requiredOneVerifier')}
            />
            <MessageResponse
              isActived={message === 'succesRegister'}
              message={t('register.succesRegister')}
            />
            <MessageResponse
              isActived={message === 'errorCodeActive'}
              message={t('register.errorCodeActive')}
              bgAlert="red"
            />
            <MessageResponse
              isActived={message === 'errorCodeNotFound'}
              message={t('register.errorCodeNotFound')}
              bgAlert="red"
            />
            <MessageResponse
              isActived={message === 'errorRegister'}
              message={t('register.errorRegister')}
              bgAlert="red"
            />
          </Content>
        </Wrapper>
        <Footer />
      </Container>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    data: store.form['Register'],
    genders: store.register ? store.register.genders : [],
    identificationTypes: store.register.identificationTypes,
    relationships: store.register.relationships,
    registerUrl: store.home.homes[0] ? store.home.homes[0].registerUrl : '',
    isLoading: store.app.isAppLoading,
    message: store.register.message,
    countries: store.register.countries,
    frequentQuestions: store.register ? store.register.frequentQuestions : [],
  };
};
const mapDispachtToProps = (dispatch) => {
  return {
    createUserMain: (fields, onSuccess) =>
      dispatch(createUserMain(fields, onSuccess)),
    updateUserBeneficiary: (fields) => dispatch(updateUserBeneficiary(fields)),
    setGenders: (value) => dispatch(setGenders(value)),
    getGender: () => dispatch(getGender()),
    setIdentificationType: (value) => dispatch(setIdentificationType(value)),
    getIdentificationTypes: () => dispatch(getIdentificationTypes()),
    setRelationship: (value) => dispatch(setRelationship(value)),
    getRelationships: () => dispatch(getRelationships()),
    setCountries: (value) => dispatch(setCountries(value)),
    getCountries: () => dispatch(getCountries()),
    getFrequentQuestions: () => dispatch(getFrequentQuestions()),
    setModalTerms: () => dispatch(setModalTerms(true)),
  };
};

const margeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  createUserMain: (values) => {
    dispatchProps.createUserMain(values, () => ownProps.history.push('/'));
  },
});
const enhance = compose(
  connect(mapStateToProps, mapDispachtToProps, margeProps),
  reduxForm({
    form: 'Register',
    reset,
  })
);

export default enhance(Register);
