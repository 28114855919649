import INITIAL_STATE from './initialState'
import CONST from './const'

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONST.ACTIVE_MODAL_CHANGE_PASSWORD:
      return Object.assign(
				{},
        {
          ...state,
          isActivedModalChangePass: action.value
        }
			)
    case CONST.ACTIVE_MODAL_CREATE_VEREFIER:
      return Object.assign(
				{},
        {
          ...state,
          isActivedmodalCreateVerefier: action.value
        }
			)
    case CONST.ACTIVE_MODAL_CREATE_BENEFICIARE:
      return Object.assign(
				{},
        {
          ...state,
          isActivedmodalCreateBeneficiare: action.value
        }
      )
    case CONST.ORDERS:
      return Object.assign(
				{},
        {
          ...state,
          orders: action.value
        }
      )  
    default:
      return state
  }
}
