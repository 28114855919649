/* Dependencies */
import styled, { css } from 'styled-components'
import { ifProp, prop }  from 'styled-tools'

export const SpanError = styled.div`
	margin-top: 13px;
	color: red;
  ${ifProp('isRegister', css`
    margin: 0 0 0 20px;
  `)};
`

export const Container = styled.div`
position: relative;
select{
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
select::-ms-expand {
    display: none;
}
i {
  position: absolute;
	right: 20px;
  top: ${prop('positionIcon', 'calc(50% - 13px)')};  
	width: 8px;
	height: 8px;
	display: block;
	border-left:2px solid #342573;
	border-bottom:2px solid #342573;
	transform: rotate(-45deg);
	transition: all 0.25s ease;
}
`

export const Select = styled.select`
	width: 100%;
  height: 50px;
  padding: 6px 20px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #999;
  background-color: #fff;
  background-image: none;
  border: 1px solid #CCC;
  border-radius: 25px;
  border: 1px solid #342573;
`



