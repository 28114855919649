import React, { useState } from 'react';

import { t } from '../../i18n';

import Modal from '../../components/Modal';
import Button from '../../components/Button';

import logo from '../../assets/images/logo-1.png';

import { Content } from './styles';

import withStore from './store';

function ChangeAbsence({
	handleSubmit,
	active,
	toggle,
	firstName,
	role,
	verifierId,
	onSubmit,
	userId,
}) {
	const [stateAbsence, setstateAbsence] = useState(false);
	const _toggleFileName = (stateAbsence) => {
		setstateAbsence({
			stateAbsence,
		});
	};

	const buttonFalse = () => {
		return (
			<Button
				bgColor='#ff8400'
				bgColorHover='#211261'
				onClick={() => _toggleFileName(false)}
			>
				{t('absences.buttonFalse')}
			</Button>
		);
	};
	return (
		<Modal
			width='fit-content'
			padding='0 25px'
			fullScreen
			active={active}
			toggle={toggle}
			zIndex={10}
		>
			<form
				onSubmit={handleSubmit(() => {
					onSubmit({
						userId,
						verifierId,
						approved: stateAbsence,
						role,
					});
				})}
			>
				<Content>
					<img src={logo} alt='logo' />
					{/* <p>{t('userVerifier.confirming')}</p> */}
					<p>
						{role === 2
							? `${t('absences.messageVerifierPartOne')} ${firstName}, ${t(
									'absences.messageVerifierPartTwo',
							  )}`
							: `${t('absences.messageMainPartOne')} ${firstName}, ${t(
									'absences.messageMainPartTwo',
							  )}`}
					</p>
					<div className='content-form'>
						<div className='container-buttons'>
							{role === 2 ? (
								<>
									<Button
										bgColor='#ff8400'
										bgColorHover='#211261'
										onClick={() => _toggleFileName(true)}
									>
										{t('absences.buttonTrue')}
									</Button>
									{buttonFalse()}
								</>
							) : (
								buttonFalse()
							)}
						</div>
					</div>
				</Content>
			</form>
		</Modal>
	);
}

export default withStore(ChangeAbsence);
