import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import media from 'styled-media-query'

export const Container = styled.div.attrs({
	className: 'animated fadeIn'
})`
	display: none;
	position: fixed;
	top: 0;
	z-index: ${prop('zIndex', 12)};
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 297px;
	outline: none;
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(52, 39, 105, 0.79);
	}
	${ifProp(
		'active',
		css`
			display: flex;
		`
	)}
	${ifProp(
		'top',
		css`
			top: ${prop('top', '15px')};
			align-items: initial;
			max-height: 0px;
		`
	)}
`
export const ModalContent = styled.div`
  position: absolute;
  /* background-color: #f8f8f8;   */
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: none;
  /* box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);   */
  text-align: ${prop('txtAlign', 'center')};
  width: 70%;
  height: fit-content;
  padding: ${prop('padding', '5px')};
  padding-bottom: 2px;
	box-sizing: border-box;  
	opacity: 1;
	border-radius: 20px;
	/* background-color: #fff;	 */
	transition: all .5s ease-in-out;
	&:hover {
		/* box-shadow: 0 10px 14px 0 rgba(155,155,155,0.5); */
	}	
  /* ${media.lessThan('small')`                 
      min-height: 176px;        
    `}
  ${media.lessThan('medium')`                 
      min-height: 176px;        
		`}   */
	@media (max-width: 768px) {
		width: 93%;
	}	
	@media (max-width: 550px) {
		width: 100%;
	}
`

export const ButtonClose = styled.div`
	z-index: 10;
	position: absolute;
	top: -5px;
	right: -5px;
	padding: 7px;
	border-radius: 50%;
	background: #211261;
	color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ContainerVideo = styled.div`
	position: relative;
	width: 100%;
	/* height: 90%; */
	overflow-y: auto;
	color: #eee;
	font-size: 0.8rem;
	.video {
		width: 100%;
		z-index: 11;
		min-height: 550px;
		@media (max-width: 768px) {
			min-height: 537px;
		}	
		@media (max-width: 550px) {
			min-height: 225px;
		}		
	}
`
