// Dependencies
import React from 'react'
// Styled component
import { Container } from './styles'

function TypeFileUpload (props) {
  const { title, icon, bgImg, toggle } = props
  return (
    <Container onClick={toggle}>
      <div className='action'>
        <i className={icon} />
        <h3>{title}</h3>
      </div>
      <img className='imgContent' src={bgImg} alt='take' />
    </Container>
  )
}

export default TypeFileUpload
